import { StyleSheet, Text, View, Image, TextInput, useWindowDimensions } from "react-native";
import {useEffect, useRef} from 'react';
import FontStyles from '../../../utils/FontStyles'
import Colors from '../../../utils/Colors'
import i18n from "i18n-js";
import Button from '../../../components/Button'
import { memo, useState } from "react";
import Animated, { Extrapolate, interpolate, useAnimatedStyle, withTiming } from 'react-native-reanimated'
import AnimatedText from "./AnimatedText";
import useAuth from '../../../hooks/auth-context'
import API from "../../../utils/API";

function LoginCode( { successOpacity, opacity, buttonWidth, height, loginId, email }){
  const [ code, setCode ] = useState('')
  const [ loading, setLoading ] = useState(false)  
  const [ error, setError ] = useState(null)
  const inputRef = useRef()
  
  useEffect(()=> {
    if(loginId){
      inputRef.current.focus()
    }
  }, [loginId])

  useEffect(()=> {
    if(error){
      opacity.value = withTiming(1, { duration: 350 })
    }
  },[error])

  const successAnim = useAnimatedStyle(()=> {
    const reverseOpacity = interpolate(
      successOpacity.value,
      [0,1],
      [1,0]
    )

    const y = interpolate(
      successOpacity.value,
      [1,1.1],
      [1, height],
      Extrapolate.CLAMP
    )
    
    return {
      transform: [
        {
          translateY: y
        }
      ],
      opacity: reverseOpacity
    }
  })

  const login = () => {
    setLoading(true)
    API.login(loginId, email, code).then((res)=> {
      successOpacity.value = withTiming(1.1, {duration: 350 })
    }).catch((e)=> {
      setLoading(false)
      setError(e)
      opacity.value = withTiming(1, {duration: 350 })
    })
  }

  return (
    <View style={[styles.inputContainer, 
      { 
        minWidth: buttonWidth + (height * 0.287 / 2) + 25, 
        paddingLeft: height * 0.287 / 2,

      }]}
    >
      <View style={styles.successContainer}>
        <Image 
          style={styles.checkIcon} 
          source={require('../../../assets/icons/check-icon.png')}
          resizeMode='contain'
        />
        <Text style={[FontStyles.title1.semibold, { color: Colors.dark.alpha1, marginTop: 36}]}>
          {i18n.t('loginSuccessTitle')}
        </Text>
        <Text style={[FontStyles.title3.regular, { color: Colors.dark.alpha08, marginTop: 24}]}>
          {i18n.t('loginSuccessBody', { firstname: 'Dicle', lastname: 'Tiryaki', company: 'Artımetrik' })}
        </Text>
      </View>
      <Animated.View style={[{width: '100%', alignItems: 'center', backgroundColor: 'white'}, successAnim]}>
        <AnimatedText
          opacity={opacity}
          title={i18n.t('loginCodeTitle')}
          subText={i18n.t('loginCodeSubText')}
          errorTitle={error?.errorTitle || i18n.t('wrongCode')}
          errorSubText={error?.errorMessage || i18n.t('wrongCodeSubText')}
        />
        <TextInput
          ref={inputRef}
          style={[FontStyles.body.regular, styles.input, {width: buttonWidth}]}
          placeholder={i18n.t('loginCodePlaceholder')}
          onChangeText={(text)=> setCode(text)}
          onSubmitEditing={login}
        />
        <Button
          style={[styles.button, { width: buttonWidth}]}
          enableShadow
          text={i18n.t('send')}
          onPress={login}
          loading={loading}
        />
      </Animated.View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    flexDirection: 'row'
  },
  inputContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    backgroundColor :'white',
    height: '100%'
  },
  input: {
    backgroundColor: Colors.lightGray.alpha1,
    color: Colors.gray.alpha08,
    borderRadius: 20,
    height: 64,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    outlineStyle: 'none'
  },
  button: {
    marginTop: 20, 
  },
  image: {
    width: '100%',
    height : '100%'
  },
  footerContainer: {
    flex: 1, 
    alignItems: 'center', 
    justifyContent: 'flex-end',
  },
  backButton: {
    paddingVertical: 6,
    paddingHorizontal: 6,
    flexDirection: 'row',
    marginTop: 32
  },
  checkIcon: {
    width: 80,
    height: 80,
  },
  successContainer: {
    position: 'absolute', 
    alignItems: 'center'
  }
})

export default memo(LoginCode)