import Colors from "./Colors"
const FontStyles = {
  largeTitle: {
    heavy:      { fontSize: 34, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-heavy'},
    black:      { fontSize: 34, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-black'},
    bold:       { fontSize: 34, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-bold'},
    semibold:   { fontSize: 34, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-semibold'},
    medium:     { fontSize: 34, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-medium'},
    regular:    { fontSize: 34, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-regular'},
    light:      { fontSize: 34, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-light'},
    thin:       { fontSize: 34, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-thin'},
    ultralight: { fontSize: 34, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-ultralight'},
  },
  title1: {
    heavy:      { fontSize: 28, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-heavy'},
    black:      { fontSize: 28, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-black'},
    bold:       { fontSize: 28, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-bold'},
    semibold:   { fontSize: 28, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-semibold'},
    medium:     { fontSize: 28, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-medium'},
    regular:    { fontSize: 28, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-regular'},
    light:      { fontSize: 28, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-light'},
    thin:       { fontSize: 28, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-thin'},
    ultralight: { fontSize: 28, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-ultralight'},
  },
  title2: {
    heavy:      { fontSize: 22, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-heavy'},
    black:      { fontSize: 22, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-black'},
    bold:       { fontSize: 22, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-bold'},
    semibold:   { fontSize: 22, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-semibold'},
    medium:     { fontSize: 22, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-medium'},
    regular:    { fontSize: 22, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-regular'},
    light:      { fontSize: 22, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-light'},
    thin:       { fontSize: 22, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-thin'},
    ultralight: { fontSize: 22, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-ultralight'},
  },
  title3: {
    heavy:      { fontSize: 20, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-heavy'},
    black:      { fontSize: 20, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-black'},
    bold:       { fontSize: 20, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-bold'},
    semibold:   { fontSize: 20, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-semibold'},
    medium:     { fontSize: 20, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-medium'},
    regular:    { fontSize: 20, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-regular'},
    light:      { fontSize: 20, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-light'},
    thin:       { fontSize: 20, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-thin'},
    ultralight: { fontSize: 20, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-ultralight'},
  },
  headline: {
    heavy:      { fontSize: 17, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-heavy'},
    black:      { fontSize: 17, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-black'},
    bold:       { fontSize: 17, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-bold'},
    semibold:   { fontSize: 17, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-semibold'},
    medium:     { fontSize: 17, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-medium'},
    regular:    { fontSize: 17, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-regular'},
    light:      { fontSize: 17, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-light'},
    thin:       { fontSize: 17, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-thin'},
    ultralight: { fontSize: 17, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-ultralight'},
  },
  body: {
    heavy:      { fontSize: 17, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-heavy'},
    black:      { fontSize: 17, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-black'},
    bold:       { fontSize: 17, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-bold'},
    semibold:   { fontSize: 17, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-semibold'},
    medium:     { fontSize: 17, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-medium'},
    regular:    { fontSize: 17, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-regular'},
    light:      { fontSize: 17, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-light'},
    thin:       { fontSize: 17, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-thin'},
    ultralight: { fontSize: 17, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-ultralight'},
  },
  callout: {
    heavy:      { fontSize: 16, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-heavy'},
    black:      { fontSize: 16, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-black'},
    bold:       { fontSize: 16, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-bold'},
    semibold:   { fontSize: 16, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-semibold'},
    medium:     { fontSize: 16, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-medium'},
    regular:    { fontSize: 16, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-regular'},
    light:      { fontSize: 16, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-light'},
    thin:       { fontSize: 16, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-thin'},
    ultralight: { fontSize: 16, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-ultralight'},
  },
  subhead: {
    heavy:      { fontSize: 15, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-heavy'},
    black:      { fontSize: 15, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-black'},
    bold:       { fontSize: 15, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-bold'},
    semibold:   { fontSize: 15, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-semibold'},
    medium:     { fontSize: 15, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-medium'},
    regular:    { fontSize: 15, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-regular'},
    light:      { fontSize: 15, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-light'},
    thin:       { fontSize: 15, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-thin'},
    ultralight: { fontSize: 15, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-ultralight'},
  },
  footnote: {
    heavy:      { fontSize: 13, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-heavy'},
    black:      { fontSize: 13, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-black'},
    bold:       { fontSize: 13, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-bold'},
    semibold:   { fontSize: 13, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-semibold'},
    medium:     { fontSize: 13, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-medium'},
    regular:    { fontSize: 13, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-regular'},
    light:      { fontSize: 13, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-light'},
    thin:       { fontSize: 13, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-thin'},
    ultralight: { fontSize: 13, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-ultralight'},
  },
  caption: {
    heavy:      { fontSize: 12, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-heavy'},
    black:      { fontSize: 12, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-black'},
    bold:       { fontSize: 12, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-bold'},
    semibold:   { fontSize: 12, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-semibold'},
    medium:     { fontSize: 12, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-medium'},
    regular:    { fontSize: 12, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-regular'},
    light:      { fontSize: 12, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-light'},
    thin:       { fontSize: 12, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-thin'},
    ultralight: { fontSize: 12, letterSpacing: -0.5, color: Colors.dark.alpha1, fontFamily: 'sf-ultralight'},
  }
}

export default FontStyles

