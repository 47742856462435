import React from "react";
import { ScrollView, StyleSheet, Text, View, Animated, Pressable } from "react-native";
import API from '../utils/API.js';
import FontStyles from "../utils/FontStyles";
import Input from '../components/Input'
import i18n from "i18n-js";
import DatePicker from "../components/DatePicker";
import Colors from "../utils/Colors";
import SurveyTags from "../components/SurveyTags";
import QuestionAdder from "../components/QuestionAdder";
import Button from "../components/Button";
import SurveyPreview from "../components/SurveyPreview"
import WebAlert from "../components/WebAlert";
import { Feather } from '@expo/vector-icons'

const bg = "#F3FAFF"

const errorCheckList = [
  { key: 'title', translation: 'titleError' }, 
  { key: 'startsAt', translation: 'startsAtError' }, 
  { key: 'tags', translation: 'tagError' },
  { key: 'endsAt', translation: 'endsAtError' },
  { key: 'questions', translation: 'questionError' }
]

export default class CreateSurvey extends React.PureComponent{
  constructor(props){
    super(props)
    this.errorTextOpacity = new Animated.Value(0)
    this.inputs = {
      title: null,
      description: null,
      tags: null,
      startsAt: null,
      endsAt: null,      
      questions: null
    }

    this.state={
      openDatePicker: false,
      errorType: null,
      clock: { start: 11, end: 11 }
    }
  }

      // tagIds: tagIds,
			// startsAt: startsAt,
			// endsAt: endsAt,
			// title: title,
			// description: description,
			// questions: questions
  
  setInputs = (text, type) => {
    this.inputs[type] = text
  }
  
  onDateChange = (object) => {
    const {startsAt, endsAt} = object
    
    if(startsAt){
      this.inputs.startsAt = startsAt.valueOf() + ( -1 * new Date().getTimezoneOffset() - API.timezoneOffset ) * 60 * 1000

      if(startsAt.isSame(new Date(), 'day')){
        const currentHour = new Date(Date.now() + API.timezoneOffset * 60 * 1000).getUTCHours()
        let clockObj = {
          start: (this.state.clock.start < currentHour + 1) ? currentHour + 1 : this.state.clock.start,
          end: this.state.clock.end
        }

        this.setState({ minClock: currentHour + 1, clock: clockObj})
      }else{
        this.setState({
          minClock: 0
        })
      }
    }
    
    if(endsAt){
      this.inputs.endsAt = endsAt.valueOf() + ( -1 * new Date().getTimezoneOffset() - API.timezoneOffset ) * 60 * 1000
    }
  }

  onTagPressed = (tags) => {
    this.inputs.tags = [...tags]
  }

  onSavePress = () => {
    this.inputs.questions = [...this.questionAdderRef.state.questions]
    const errorType = this.errorHandler()

    if(!errorType){
      this.animateErrorText(0,()=> {
        const inputs = JSON.parse(JSON.stringify(this.inputs))
        
        inputs.startsAt += this.state.clock.start * 60 * 60 * 1000
        inputs.endsAt += this.state.clock.end * 60 * 60 * 1000

        this.surveyPreview.setIsShowing(true, inputs)
        this.setState({ errorType: null })
      })
    }else{
      this.setState({ errorType: errorType },()=> {
        this.animateErrorText(1)
      })
    }
  }

  errorHandler = () => {
    for(const obj of errorCheckList){
      if(!this.inputs[obj.key] || this.inputs[obj.key].length === 0){
        console.log(obj)
        return obj
      }
    }

    return null
  }

  handleAlert = (data) => {
    this.alert.setIsShowing(data)
  }

  animateErrorText = (toValue, callback) => {
    Animated.timing(this.errorTextOpacity, {
      toValue: toValue,
      duration: 350,
      useNativeDriver: true
    }).start(()=> {
      callback && callback()
    })
  }

  handleClock = (increment, type) => {
    if(type === 'start'){
      this.setState(function(prevState){
        if( prevState.clock.start === 23 && increment === 1 ){
          return { clock: {...prevState.clock, start: prevState.minClock} }
        }else if( prevState.clock.start <= prevState.minClock && increment === -1 ){
          return { clock: {...prevState.clock, start: 23} }
        }
  
        return { clock: { start: prevState.clock.start + (increment), end: prevState.clock.end } }
      })
    }else{
      this.setState(function(prevState){
        if( prevState.clock.end === 23 && increment === 1 ){
          return { clock: {...prevState.clock, end: 0} }
        }else if( prevState.clock.end <= 0 && increment === -1 ){
          return { clock: {...prevState.clock, end: 23} }
        }
  
        return { clock: { end: prevState.clock.end + (increment), start: prevState.clock.start } }
      })
    }

  }

  render(){
    
    return (
      <View style={styles.container}>
        <ScrollView 
          style={{flex: 1}}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{ paddingTop: 24, paddingHorizontal: 24, paddingBottom: 24 }}
        >
          <View style={{ flexDirection: 'row', width: '100%'}}>
            
            <View style={{ flex: 1.5, paddingRight: 24, alignSelf: 'baseline' }}>
              <Input
                title={i18n.t('surveyTitle')}
                titleDescription={i18n.t('surveyTitleBody')}
                onChangeText={this.setInputs}
                type={'title'}
                style={{ width: '100%' }}
                inputStyle={{ width: '100%' }}
                maxLength={32}
              />
              <Input
                title={i18n.t('surveyDescription')}
                titleDescription={i18n.t('surveyDescriptionBody')}
                onChangeText={this.setInputs}
                type={'description'}
                style={{ marginTop: 24, width: '100%' }}
                inputStyle={{ width: '100%' }}
                maxLength={200}

              />
              <SurveyTags
                onTagPressed={this.onTagPressed}
                title={i18n.t('surveyTagTitle')}
                description={i18n.t('surveyTagBody')}
                withTagData
              />
              <Text style={[FontStyles.body.bold, { color: Colors.dark.alpha1, marginTop: 24 }]}>
                {i18n.t('surveyDates')}
              </Text>
              <Text style={[FontStyles.callout.regular, { color: Colors.dark.alpha08, marginTop: 12 }]}>
                {i18n.t('surveyDatesBody')}
              </Text>
              <DatePicker 
                onSelectionChange={this.onDateChange}
                containerStyle={{ marginTop: 24 }}
                colors={[ Colors.secondaryBlue.alpha1, Colors.secondaryBlue.alpha01 ]}
                enableRange
                isSingle
              />
              <View style={{flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', marginTop: 24}}>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <Pressable style={styles.clockSetter} onPress={()=> this.handleClock(-1, 'start')}>
                    <Feather
                      name='minus'
                      size={20}
                      color={Colors.dark.alpha08}
                    />                  
                  </Pressable>
                  <View style={styles.clock}>
                    <Text style={[FontStyles.body.medium, { color: Colors.dark.alpha08 }]}>
                      {this.state.clock.start.toString().padStart(2, '0') + ':00'}
                    </Text>
                  </View>
                  <Pressable style={styles.clockSetter} onPress={()=> this.handleClock(1, 'start')}>
                    <Feather
                      name='plus'
                      size={20}
                      color={Colors.dark.alpha08}
                    />
                  </Pressable>
                </View>
                <View style={{flexDirection: 'row', alignItems: 'center', marginLeft: 24}}>
                  <Pressable style={styles.clockSetter} onPress={()=> this.handleClock(-1, 'end')}>
                    <Feather
                      name='minus'
                      size={20}
                      color={Colors.dark.alpha08}
                    />                  
                  </Pressable>
                  <View style={styles.clock}>
                    <Text style={[FontStyles.body.medium, { color: Colors.dark.alpha08 }]}>
                      {this.state.clock.end.toString().padStart(2, '0') + ':00'}
                    </Text>
                  </View>
                  <Pressable style={styles.clockSetter} onPress={()=> this.handleClock(1, 'end')}>
                    <Feather
                      name='plus'
                      size={20}
                      color={Colors.dark.alpha08}
                    />
                  </Pressable>
                </View>
              </View>
              <View style={{ marginTop: 20, width: '50%', minWidth: 200 }}>
                <Animated.Text style={[FontStyles.footnote.medium, { color: Colors.red.alpha1, opacity: this.errorTextOpacity, textAlign: 'center' }]}>
                  {this.state.errorType ? i18n.t(this.state.errorType.translation) : 'sss'}
                </Animated.Text>
                <Button
                  text={i18n.t('next')}
                  style={styles.saveButton}
                  onPress={this.onSavePress}
                />
              </View>
            </View>
            <QuestionAdder
              ref={ref => this.questionAdderRef = ref}
              color={Colors.secondaryBlue.alpha1}
              title={i18n.t('surveyQuestionsTitle')}
              description={i18n.t('surveyQuestionsBody')}
              allowGrading
            />
          </View> 
        </ScrollView>
        <SurveyPreview 
          ref={ref => this.surveyPreview = ref}
          handleAlert={this.handleAlert}
          type={'SURVEY'}
          getData={this.props.route.params?.getData}
          navigation={this.props.navigation}

        />
        <WebAlert ref={ref => this.alert = ref}/>
      </View>
      
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: bg,
    borderRadius: 30,
    overflow: 'hidden',
  },
  dateContainer: {
    flexDirection: 'row',
    backgroundColor: Colors.lightGray.alpha1,
    borderRadius: 100,
    width: '100%',
    alignSelf: 'center',
    overflow: 'hidden',
    paddingHorizontal: 8,
    paddingVertical: 8

  },
  dateButton: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 12, 
    borderRadius: 100,
    shadowColor: Colors.dark.alpha1,
    shadowOffset: {width: 0, height: 1},
    shadowOpacity: 0.1,
    shadowRadius: 1
  },
  datePickerContainer: {
    overflow: 'hidden',
    marginTop: 24,
    width: '50%'
  },
  addButton: {
    width: '20%',
    minWidth: 200,
    paddingVertical: 16,
    backgroundColor: 'white',
    borderRadius: 15,
    alignItems: 'center',
    marginTop: 20
  },
  saveButton: {
    paddingVertical: 20,
    width: '100%',
    backgroundColor: Colors.secondaryBlue.alpha1,
    alignSelf: 'baseline',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 8,
    borderRadius: 12.5
  },
  clockSetter: {
    padding: 4, 
    borderRadius: 7.5, 
    justifyContent: 'center', 
    alignItems: 'center',
    backgroundColor: 'white'
  },
  clock: {
    marginHorizontal: 6, 
    width: 100, 
    backgroundColor: 'white', 
    paddingVertical: 8, 
    alignItems: 'center',
    borderRadius: 10
  }
})