import { memo, useEffect, useRef } from "react"
import { StyleSheet, TextInput, Animated } from 'react-native'
import Colors from "../utils/Colors"
import FontStyles from "../utils/FontStyles"

const AnimatedTextInput = Animated.createAnimatedComponent(TextInput)

function AnimatedText({ animValue, textColor, containerStyle, formatter, initialValue = '0%' }){
  const animatedText = useRef()

  useEffect(()=> {
    animatedText.current.setNativeProps({
      text: initialValue
    })

    animValue.addListener((val)=> {
      if(animatedText.current){
        const value = formatter(val.value)
        animatedText.current.setNativeProps({
          text: value
        })
      }
    })

    return ()=> {
      animValue.removeAllListeners()
    }

  },[])
  
  return (
    <AnimatedTextInput
      ref={animatedText}
      placeholder={animValue}
      style={[FontStyles.body.regular, containerStyle]}
      placeholderTextColor={textColor || Colors.dark.alpha1}
      editable={false}
      pointerEvents='none'
    />
  )
}

export default memo(AnimatedText)