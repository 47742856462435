import { memo, useState, useRef, useEffect } from "react"
import { View, Text, StyleSheet, Pressable, Animated, Easing } from 'react-native'
import Colors from "../utils/Colors"
import FontStyles from "../utils/FontStyles"
import { Feather, FontAwesome } from '@expo/vector-icons'
import AnimatedText from "./AnimatedText"
import Progress from "./Progress"
import { bytesToMB, clamp } from '../utils/Helper'

function AddedFile ({ file, type, onRemove }){
  const progressValue = useRef(new Animated.Value(0))
  const [ loaded, setLoaded ] = useState(false)

  useEffect(()=> {
    if(file){
      progressValue.current.setValue(0)
      Animated.timing(progressValue.current,{
        toValue: 1,
        duration: clamp( bytesToMB(file.file.size) * 1000, 1500 , 6000),
        delay: 250,
        easing: Easing.bezier(0.2, 0.5, 1, 0.6)
      }).start(()=> {
        setLoaded(true)
      })
    }
  },[file])

  return (
    <Animated.View style={[styles.fileContainer]}>
      <FontAwesome
        name={file.file.type.includes('video') ? 'file-video-o' : file.file.type.includes('image') ? 'file-image-o' : 'file-audio-o' }
        size={50}
        color={loaded ? Colors.secondaryBlue.alpha1 : Colors.dark.alpha06}
      />
      <View style={{ flex: 1, marginLeft: 16 }}>
        <View style={{ flexDirection: 'row', width: '100%' }}>
          <Text style={[FontStyles.subhead.regular, {color: Colors.dark.alpha08, flex: 1}]}>
            {file.file.name}
          </Text>
          <Pressable onPress={()=> onRemove(type)}>
            <Feather
              name='x'
              size={20}
              color={Colors.dark.alpha06}
            />
          </Pressable>
        </View>
        <Progress
          style={{ width: '100%', marginTop: 4 }}
          value={progressValue.current}
          
        />
        <View style={styles.fileProgressTextContainer}>
          <AnimatedText
            animValue={progressValue.current}
            containerStyle={[FontStyles.footnote.medium, { color: Colors.dark.alpha08 }]}
            textColor={Colors.dark.alpha08}
            formatter={(value)=> {
              return `${((value / 1) * 100).toFixed(0)} %`
            }}
          />
          <View style={{flexDirection: 'row', flex: 1, justifyContent: 'flex-end' }}>
            <AnimatedText
              animValue={progressValue.current}
              containerStyle={[FontStyles.footnote.medium, { color: Colors.dark.alpha08, width: 30, textAlign: 'right' }]}
              textColor={Colors.dark.alpha08}
              formatter={(value)=> {
                return (value * bytesToMB(file.file.size)).toFixed(1) 
              }}
            />
            <Text style={[FontStyles.footnote.medium, { color: Colors.dark.alpha08 }]}>
              {' / ' + bytesToMB(file.file.size).toFixed(1) + ' MB' }
            </Text>
          </View>

        </View>
      </View>
    </Animated.View>
  )
}


const styles = StyleSheet.create({
  fileContainer: {
    width: '100%',
    paddingVertical: 16,
    flexDirection: 'row',
  },
  fileProgressTextContainer: {
    flexDirection: 'row', 
    width: '100%', 
    justifyContent: 'space-between', 
    marginTop: 8,
  }
})

export default memo(AddedFile)