import React, { memo } from "react";
import { Animated, StyleSheet, Pressable, ActivityIndicator, View, Text, ScrollView } from "react-native";
import Colors from "../utils/Colors";
import { BlurView } from "expo-blur";
import { Feather } from '@expo/vector-icons'
import FontStyles from "../utils/FontStyles";
import API from "../utils/API";
import moment from "moment";
import I18n from "i18n-js";
import LeaderboardUser from "./LeaderboardUser";
import { Video } from "expo-av";
import Question from "./Question";

export default class MediaContentDetails extends React.PureComponent{
  constructor(props){
    super(props)
    this.opacityValue = new Animated.Value(0)
    this.lastContestId = null

    this.state={
      isShowing: false,
      data: null,
      mediaBasePath: null,
      questions: [],
    }
  }

  setIsShowing = (isShowing, id, startsAt, eligibleUserCount) => {
    if(isShowing){
      this.setState({ isShowing: isShowing, data: this.lastContestId === id ? this.state.data : null, rankings: this.lastContestId === id ? this.state.rankings : null}, ()=> {
        Animated.timing(this.opacityValue, {
          toValue: 1,
          duration: 300,
          useNativeDriver: false
        }).start(()=> {
          if(this.lastContestId !== id){
            this.getMediaContentDetails(id, startsAt, eligibleUserCount)
            this.lastContestId = id
          }
        })
      })

    }else{
      Animated.timing(this.opacityValue, {
        toValue: 0,
        duration: 300,
        useNativeDriver: false
      }).start(()=> {
        this.setState({ isShowing: isShowing })
      })
    }
  }

  getMediaContentDetails = async (id, startsAt, eligibleUserCount) => {
    const response = await API.getMediaContentDetails(id)
    this.setState({
      data: { ...response.mediaContent, startsAt: startsAt.getTime(), eligibleUserCount: eligibleUserCount },
      questions: response.questions,
      mediaBasePath: response.mediaBasePath
    })
  }

  getStatus = () => {
    if(this.state.data){
      if(this.state.data.startsAt > new Date().getTime()){
        return { translation: 'pending', color: Colors.badgeYellow.alpha1 }
      }else{
        return { translation: 'active', color: Colors.greenLight.alpha1 }
      }
    }
    return { translation: null, color: null }
  }

  renderUserRow = ({ item, index }) => {
    return (
      <LeaderboardUser
        user={item.user}
        index={index}
        score={item.score}
        photo={this.state.mediaBasePath + item.user.photo}
        ranking={item.ranking}
      />
    )
  }

  render(){
    if(!this.state.isShowing){
      return null
    }

    const { translation, color } = this.getStatus()

    return(
      <Animated.View style={[styles.container, { opacity: this.opacityValue }]}>
        <BlurView 
          style={styles.blur} 
          intensity={100} 
          tint={'light'}
        >
          <View style={styles.loadingContainer}>
            {
              this.state.data ? 
              <View style={[styles.contentContainer]}>
                <View style={{ height: '100%', width: '45%' }}>
                  <Text style={[ FontStyles.title2.semibold, { marginBottom: 12, color: Colors.dark.alpha1 } ]}>
                    {I18n.t('mediaContentDetails', {type: I18n.t(this.state.data.type.toLowerCase())})}
                  </Text>
                  <ScrollView 
                    style={[styles.innerContainer]}
                    contentContainerStyle={{ padding: 24 }}
                    showsVerticalScrollIndicator={false}
                  >
                    <Text style={[ FontStyles.title3.medium, { color: Colors.dark.alpha1 } ]}>
                      {this.state.data.title}
                    </Text>
                    <View style={styles.infoContainer}>
                      <View style={styles.infoTextContainer}>
                        <Feather
                          name="calendar"
                          size={18}
                          color={Colors.dark.alpha08}
                        />
                        <Text style={[ FontStyles.subhead.medium, styles.infoText ]}>
                          {moment(this.state.data.startsAt).utcOffset(API.timezoneOffset).format('DD MMM YYYY')}
                        </Text>
                      </View>
                      <View style={styles.infoTextContainer}>
                        <Feather
                          name='users'
                          size={18}
                          color={Colors.dark.alpha08}
                          
                        />
                        <Text style={[FontStyles.subhead.medium, styles.infoText]}>
                          {this.state.data.participantCount + ' / ' + this.state.data.eligibleUserCount}
                        </Text>
                      </View>
                      <View style={[styles.activeIndicator, { backgroundColor: color }]}>
                        <Text style={[FontStyles.footnote.medium, { color: 'white' }]}>
                          {I18n.t(translation)}
                        </Text>
                      </View>
                    </View>
                    {
                      this.state.questions.map((question, index)=> {
                        return (
                          <Question
                            question={question}
                            key={question.id}
                            participantCount={this.state.data.participantCount}
                            index={index}
                            type={this.state.type}
                          />
                        )
                      })
                    }
                  </ScrollView>
                </View>
                <View style={styles.leaderboardContainer}>
                  <Text style={[FontStyles.title2.semibold, { color: Colors.dark.alpha1, marginBottom: 12 }]} >
                    {this.state.data.title}
                  </Text>
                  <Video
                    source={{ uri: this.state.mediaBasePath + this.state.data.filename }}
                    style={{ width: '100%', borderRadius: 10 }}
                    useNativeControls
                    resizeMode="contain"
                  />
                  {
                    this.state.data.description &&
                    <Text style={[FontStyles.callout.regular, styles.descriptionText]}>
                      {this.state.data.description}
                    </Text>
                  }
                </View>
              </View>
              :
              <>
                <ActivityIndicator color={Colors.dark.alpha1} size='large'/>
                <Text style={[FontStyles.body.medium, { color: Colors.dark.alpha1, marginTop: 16 }]}>
                  {I18n.t('mediaContentLoading')}
                </Text>
              </>
            }
          </View>
          <Pressable style={[styles.closeButton, { backgroundColor: this.state.type === 'CONTEST' ? Colors.purple.alpha1 : Colors.primaryBlue.alpha1 }]} onPress={() => this.setIsShowing(false)}>
            <Feather name={"x"} color={Colors.white.alpha1} size={28}/>
          </Pressable>
          
        </BlurView>
      </Animated.View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
  },
  blur: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  closeButton: {
    borderRadius: 222,
    height: 48,
    width: 48,
    position: "absolute",
    right: "3.5%",
    top: "4%",
    alignSelf: "flex-end",
    justifyContent: "center",
    alignItems: "center",
  },
  loadingContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flex: 1,
  },
  contentContainer: {
    flex: 1,
    width: '100%',
    paddingHorizontal: '4%',
    paddingVertical: '3.5%',
    flexDirection: 'row'
  },
  innerContainer: {
    width: '100%',    
    flex: 1,
    backgroundColor: Colors.white.alpha1,
    borderRadius: 17.5,
  },
  activeIndicator: { 
    paddingHorizontal: 12,
    paddingVertical: 4,
    borderRadius: 100,
    marginTop: 16
  },
  optionContainer: {
    width: '100%',
  
  },
  percentageContainer: {
    width: '100%',
    borderRadius: 5,
    height: 8,
    backgroundColor: Colors.lightGray.alpha1,
    overflow: 'hidden',
    marginTop: 8
  },
  percantage: {
    height: '100%',
    borderRadius: 5,
  },
  infoContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap', 
    alignItems: 'center'
  },
  infoTextContainer: {
    flexDirection: 'row', 
    marginTop: 16, 
    marginRight: 32, 
    alignItems: 'center'
  },
  infoText: {
    color: Colors.dark.alpha08, 
    marginLeft: 8, 
    fontSize: 14
  },
  option: {
    flexDirection: 'row', 
    width: '100%', 
    alignItems: 'flex-end'
  },
  leaderboard: {
    flex: 1, 
    borderRadius: 17.5, 
    backgroundColor: Colors.white.alpha1
  },
  leaderboardContainer: {
    width: '40%', 
    minWidth: 250, 
    height: '100%', 
    marginLeft: '7.5%' 
  },
  descriptionText: {
    color: Colors.gray.alpha1, 
    marginTop: 24, 
    lineHeight: 26, 
  }
})