import {memo, useEffect, useMemo, useReducer} from 'react';
import {
  StyleSheet,
  View,
  Text,
  Pressable,
  Animated,
  ScrollView,
  ActivityIndicator,
  TouchableOpacity, FlatList,
} from 'react-native';
import {Feather, FontAwesome} from '@expo/vector-icons'
import {height} from '../utils/DeviceInfo.js';
import FontStyles from "../utils/FontStyles";
import Colors from "../utils/Colors";
import I18n from "i18n-js";
import API from "../utils/API";
import { BlurView } from "expo-blur";
import LeaderboardUser from "./LeaderboardUser";

const reducer = (state, action) => {
  return action.payload
}

const initialState = {
  mediaBasePath: '',
  rankings: [],
  loading: true
}

function LeaderboardDetails(props){
  const [ state, dispatch ] = useReducer(reducer , initialState)

  useEffect(()=> {
    if(props.type === 'general'){
      API.getGamificationData(1,100, true).then(({ rankings, mediaBasePath })=> {
        setTimeout(() => {
          dispatch({ payload: {
            mediaBasePath: mediaBasePath,
            rankings: rankings,
            loading: false
          } })  
        }, 50);
      })
    }else if(typeof props.type === 'string'){
      API.getContestRankings(props.type).then(({ rankings, mediaBasePath })=> {
        setTimeout(() => {
          dispatch({ payload: {
            mediaBasePath: mediaBasePath,
            rankings: rankings,
            loading: false
          } })  
        }, 50);
      })
    }
  },[])
  
  const downloadRankings = () => {
    API.downloadRankings()
        .then((response)=>{
          if(response.filename){
            let a = document.createElement("a");
            a.style = "display: none";
            document.body.appendChild(a);
          
            a.href = response.file;
            a.download = response.filename;
            a.click();
            window.URL.revokeObjectURL(response.file);
            a.remove();
          }
          else {
            window.open(response.file, "_blank");
          }
        })
        .catch((e)=>{
          alert(e.errorMessage);
        });
  }
  
  const listEmpty = () => {
    return <View style={styles.emptyContainer}>
      <Feather
          name= 'gift'
          size={32}
          color={Colors.gray.alpha06}
      />
      <Text style={[FontStyles.body.medium, { color: Colors.gray.alpha06, marginTop: 16, textAlign: 'center' }]}>
        {I18n.t('leaderboardEmpty')}
      </Text>
    </View>
  }
  
  const renderRow = ({item, index}) => {
    return <LeaderboardUser
        user={item.user}
        index={index}
        score={item.score}
        key={item.user.id}
        photo={state.mediaBasePath + '~' + item.user.photo}
        ranking={item.ranking}
    />
  }

  return (
    <Animated.View style={[styles.leaderboardDetailedContainer, {
      
      transform: [{translateY: props.leaderboardDetailAnimVal.interpolate({
          inputRange: [0,1],
          outputRange: [1.25 * height, 0]
        })}]
      
      
    }]}>
      <BlurView tint="light" intensity={100} style={styles.blurView}>
        <View style={styles.leftSideContainer}>
          <View style={styles.leftScrollContainer}>
            <Text style={[ FontStyles.title2.semibold, { marginBottom: 12, color: Colors.dark.alpha1 } ]} >
              {I18n.t('ranking')}
            </Text>
            {
              state.loading ?
                  <ActivityIndicator  size={"large"} style={{ marginTop: "66%" }} color={Colors.secondaryBlue.alpha1}/>
                  :
                  <FlatList
                      style={styles.scroll}
                      showsVerticalScrollIndicator={false}
                      contentContainerStyle={{ paddingVertical: "2%", paddingHorizontal: "4%" }}
                      keyExtractor={item=>item.user.id}
                      ListEmptyComponent={()=>listEmpty()}
                      renderItem={renderRow}
                      initialNumToRender={16}
                      data={state.rankings}
                      updateCellsBatchingPeriod={100}
                      maxToRenderPerBatch={30}
                      removeClippedSubviews={true}
                  />
            }
            
          </View>
        </View>
        <View style={styles.rightSideContainer}>
          <View style={{ marginRight: "20%", marginVertical: "10%", marginLeft: "2.5%" }}>
            {
                state.rankings.length !== 0 &&
                <TouchableOpacity activeOpacity={0.8} style={styles.downloadDataButton} onPress={downloadRankings}>
                  <FontAwesome name="file-excel-o" size={21} color={Colors.white.alpha1} style={{ marginRight: 8}} />
                  <Text style={[FontStyles.footnote,{color:Colors.white.alpha1}]}>
                    {I18n.t('downloadAllRankings')}
                  </Text>
                </TouchableOpacity>
            }
            
            <Text style={[FontStyles.title2.semibold, { color: Colors.dark.alpha1 }]} >
              {I18n.t('leaderboardTitle')}
            </Text>
            <Text style={[FontStyles.subhead.regular, { marginTop: 12, lineHeight: 26, color: Colors.gray.alpha1 }]} >
              {props.type == 'general' ? I18n.t('leaderboardDescriptionLong') : I18n.t('contestRankingDescription')}
            </Text>

            <View style={styles.tipContainer}>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Feather size={18} color={Colors.orange.alpha1} name={"info"} />
                <Text style={[FontStyles.subhead.medium, { marginLeft: 8, color: Colors.dark.alpha1 }]} >
                  {I18n.t('leaderboardTipTitle')}
                </Text>
              </View>
              <Text style={[FontStyles.footnote.regular, styles.descriptionText]} >
                {props.type == 'general' ? I18n.t('leaderboardTipDescription') : I18n.t('contestTipDescription')}
              </Text>
            </View>

            <View style={[styles.tipContainer, { marginTop: 12 }]}>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Feather size={18} color={Colors.orange.alpha1} name={"info"} />
                <Text style={[ FontStyles.subhead.medium, { marginLeft: 8, color: Colors.dark.alpha1 } ]} >
                  {I18n.t('leaderboardTipTitle')}
                </Text>
              </View>
              <Text style={[ FontStyles.footnote.regular, styles.tipDescriptionText ]} >
                {I18n.t('leaderboardTipDescription2')}
              </Text>
            </View>
            <Pressable style={styles.closeButton} onPress={() => props.leaderboardDetailsController(null)}>
              <Text style={[ FontStyles.subhead.semibold, { color: Colors.white.alpha1 } ]} >
                {I18n.t('goBack')}
              </Text>
            </Pressable>
          </View>
        </View>
      </BlurView>
    </Animated.View>   
  )
}

const styles = StyleSheet.create({
  leaderboardDetailedContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    flexDirection: "row",
    alignItems: 'center',
    zIndex: 1,
  },
  blurView: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    flexDirection: "row",
    alignItems: 'center',
    zIndex: 1,
  },

  //left side
  leftSideContainer: {
    flex: 1,
    height: "100%",
  },
  emptyContainer: {
    justifyContent: 'center', 
    alignItems: 'center',
    height: 200
  },
  //right side
  rightSideContainer: {
    flex: 1,
    height: "100%",
  },
  tipContainer: {
    borderWidth: 0,
    borderColor: Colors.lightGray.alpha1,
    borderRadius: 15,
    paddingHorizontal: 16,
    paddingVertical: 12,
    backgroundColor: Colors.white.alpha1,
    marginTop: 24,
    width: "100%",
  },
  closeButton: {
    backgroundColor: Colors.primaryBlue.alpha1,
    borderRadius: 222,
    marginTop: 24,
    paddingHorizontal: 36,
    width: "40%",
    height: 44,
    alignSelf: "flex-end",
    justifyContent: "center",
    alignItems: "center",
  },
  scroll: {
    flex: 1, 
    zIndex: 111, 
    borderRadius: 17.5, 
    borderWidth: 0, 
    borderColor: Colors.white.alpha1, 
    backgroundColor: Colors.white.alpha1
  },
  leftScrollContainer: {
    flex: 1, 
    marginLeft: "20%", 
    marginVertical: "10%", 
    marginRight: "2.5%"
  },
  descriptionText: {
    marginTop: 4, 
    letterSpacing: -0.25, 
    lineHeight: 22, 
    color: Colors.gray.alpha08
  },
  tipDescriptionText: {
    marginTop: 4, 
    letterSpacing: -0.25, 
    lineHeight: 22, 
    color: Colors.gray.alpha08
  },
  downloadDataButton:{
    borderRadius:10,
    backgroundColor:Colors.greenLight.alpha1,
    paddingHorizontal:16,
    justifyContent:'center',
    alignItems:'center',
    marginLeft:16,
    flexDirection:'row',
    paddingVertical: 12,
    height: 40,
    alignSelf: 'flex-end',
    marginBottom: 20,
  },
})

export default memo(LeaderboardDetails)