import React, { memo } from "react";
import { StyleSheet, View, Text, Pressable, ScrollView, TextInput } from "react-native";
import API from "../../utils/API";
import Colors from "../../utils/Colors";
import FontStyles from "../../utils/FontStyles";
import I18n from "i18n-js";
import moment from "moment";
import { Feather } from '@expo/vector-icons'
import Dropdown from "../../components/FilterDropdown";
import Hoverable from "../../components/Hoverable";
import ContestDetails from "../../components/ContestDetails";

const ContestCard = memo(
  function({ contest, index, length, onPress, status }){  

    return (
      <Pressable 
        style={[styles.contestRow, { marginRight: index !== length - 1 ? 16 : 0, marginTop: 24 }]}
        onPress={()=> onPress(contest.id)}
      >
        <Text style={[FontStyles.title3.semibold, { color: Colors.dark.alpha1 }]}>
          {contest.title}
        </Text>
        <View style={{ flexDirection: 'row', marginTop: 8, alignItems: 'center' }}>
          <Feather
            name='calendar'
            size={18}
            color={Colors.dark.alpha06}
          />
          <Text style={[FontStyles.footnote.medium, { color: Colors.gray.alpha06, marginLeft: 8, flex: 1 }]}>
            {moment(contest.startsAt).utcOffset(API.timezoneOffset).format('DD MMM YYYY') + ' - ' + moment(contest.endsAt).utcOffset(API.timezoneOffset).format('DD MMM YYYY')}
          </Text>
          <View style={[styles.activeIndicator, { backgroundColor: status.color }]}>
            <Text style={[FontStyles.footnote.medium, { color: 'white' }]}>
              {I18n.t(status.translation)}
            </Text>
          </View>
        </View>
        {
          contest.description &&
          <Text numberOfLines={4} style={[FontStyles.subhead.regular, { color: Colors.dark.alpha1, lineHeight: 20, marginTop: 16 }]}>
            {contest.description} 
          </Text>
        }

        <Text style={[FontStyles.subhead.medium, { color: Colors.gray.alpha04, fontSize: 15, marginTop: 20 }]}>
          {I18n.t('participantCount')}
        </Text>
        {
          <View style={{flexDirection: 'row', marginTop: 8}}>
            <Text style={[FontStyles.subhead.medium, styles.participantRatioText]}>
              {`${contest.participantCount} / ${contest.eligibleUserCount}`}
            </Text>
            {
              contest.eligibleUserCount !== 0 &&
              <Text style={[FontStyles.subhead.semibold, styles.participantRatioText, { marginLeft: 4 }]}>
                {`(${((contest.participantCount / contest.eligibleUserCount) * 100).toFixed(0)}%)`}
              </Text>
            }

          </View>
        }
        <Text style={[FontStyles.subhead.medium, { color: Colors.gray.alpha04, fontSize: 15, marginTop: 20 }]}>
          {I18n.t('tags')}
        </Text>
        <View style={styles.tagContainer}>
          {
            contest.tags &&
            contest.tags.map((tag, index)=> {
              return (
                <View 
                  key={tag.id} 
                  style={[styles.tag, { marginBottom: 8, marginRight: 4, backgroundColor: tag.colorHex }]}
                >
                  <Text style={[ FontStyles.caption.bold, { color: 'white' }]}>
                    {tag.name}
                  </Text>
                </View>
              )
            })
          }
        </View>
        
      </Pressable>
    )
})

export default class Contests extends React.PureComponent{
  constructor(props){
    super(props)

    this.searchContests = []
    this.allContests = []
    this.searchText = []
    this.searchText = ''

    this.activityInfo = [
      { name: I18n.t('active'), color: Colors.greenLight },
      { name: I18n.t('pending'), color: Colors.badgeYellow },
      { name: I18n.t('inactive'), color: Colors.red },
    ]

    this.state = {
      contests: [],
      isFiltering: false,
      tags: [],
      selectedTags: [],
      activityFilterIndex: null
    }
  }

  componentDidMount(){
    this.getContests()
    this.getTags()
  }

  getTags = () => {
    API.getCompanyTags().then(({tags})=> {
      this.setState({tags: tags})
    })
  }

  getContests = async () => {
    const { contests } = await  API.getContests()
    this.allContests = contests
    this.setState({contests: contests})
  }

  onSearch = (text) => {
    const filtered = this.filterContests(text, this.state.selectedTags, this.state.activityFilterIndex)

    this.searchText = text

    this.setState({ contests: filtered })
  }

  onSelectTag = (id) => {
    let index = this.state.selectedTags.indexOf(id)
    let arr = [...this.state.selectedTags]
    
    if(index !== -1){
      arr.splice(index, 1)
    }else{
      arr = [...arr, id]
    }

    let filtered = this.filterContests(this.searchText, arr, this.state.activityFilterIndex)

    this.setState({ selectedTags: arr, contests: filtered })

  }

  setActivityFilter = (index) => {
    const filtered = this.filterContests(this.searchText, this.state.selectedTags, this.state.activityFilterIndex !== index ? index : null)
    this.setState({ activityFilterIndex: this.state.activityFilterIndex === index ? null : index, contests: filtered })
  }

  getStatus = (contest) => {
    if(contest.endsAt > new Date().getTime() && contest.startsAt < new Date().getTime()){
      return { translation: 'active', color: Colors.greenLight.alpha1, index: 0 }
    }else if(contest.endsAt > new Date().getTime() &&  contest.startsAt >= new Date().getTime()){
      return { translation: 'pending', color: Colors.badgeYellow.alpha1, index: 1 }
    }else if(new Date().getTime() > contest.endsAt){
      return { translation: 'inactive', color: Colors.red.alpha08, index: 2 }
    }
  }

  filterContests = (text, tags, activity) => {
    let filtered = this.allContests.filter((contest)=> {
      return contest.title.toLowerCase().includes(text.toLowerCase())
    })

    if(activity !== null){
      filtered = filtered.filter((contest)=> {
        return this.getStatus(contest).index === activity
      })
    }

    if(tags.length){
      filtered = filtered.filter((contest)=> {
        for(let tag of contest.tags){
          if(tags.includes(tag.id)){
            return true
          }
          return false
        }
      })  
    }

    return filtered
  }

  removeAllFilters = () => {
    this.searchInputRef.clear()
    this.searchContests = this.allContests
    this.setState({ contests: this.allContests, selectedTags: [], activityFilterIndex: null })
  }

  onContestPress = (id) => {
    this.contestDetailsRef.setIsShowing(true, id)
  }

  render(){
    return (
      <View style={styles.container}>
        <ScrollView 
          style={{ flex: 1 }}
          contentContainerStyle={{ padding: 24 }}
        >
          <Text style={[styles.activeContestsTitle, FontStyles.title2.semibold]}>
            {I18n.t('contests')}
          </Text>
          <View style={styles.header}>
            <View style={styles.searchInputContainer}>
              <Feather
                name='search'
                size={16}
                color={Colors.dark.alpha06}
              />
              <TextInput
                style={[styles.searchInput, FontStyles.subhead.medium, { color: Colors.dark.alpha1 }]}
                placeholder={I18n.t('searchContest')}
                placeholderTextColor={Colors.dark.alpha06}
                onChangeText={this.onSearch}
                ref={ref => this.searchInputRef = ref}
              />
            </View>
            <View style={{width: 56}}>
              <Dropdown
                color={Colors.purple.alpha1}
              >
                <Text style={[FontStyles.body.medium, {marginTop: 12, color: Colors.dark.alpha08}]}>
                  {I18n.t('tags')}
                </Text>
                <View style={styles.filterTagContainer}>
                  {
                    this.state.tags.map((tag, index)=> {
                      return (
                        <Hoverable
                          
                          key={tag.id}
                          style={[styles.tag, { marginTop: 8, marginRight: index !== this.state.tags.length - 1 ? 12 : 0, borderColor: this.state.selectedTags.includes(tag.id) ? tag.colorHex : Colors.lightGray.alpha1, borderWidth: 2, backgroundColor: this.state.selectedTags.includes(tag.id) ? tag.colorHex : 'white' }]}
                          onPress={()=> this.onSelectTag(tag.id)}
                          hoverStyle={{ backgroundColor: tag.colorHex, borderColor: tag.colorHex }}
                          text={tag.name}
                          textStyle={[FontStyles.footnote.medium, { color: this.state.selectedTags.includes(tag.id) ? 'white' : Colors.dark.alpha06 }]}
                          hoverTextStyle={{ color: 'white' }}
                        />
                      )
                    })
                  }
                </View>
                <Text style={[FontStyles.body.medium, { marginTop: 24, color: Colors.dark.alpha08 }]}>
                  {I18n.t('status')}
                </Text>
                <View style={styles.filterTagContainer}>
                  {
                    this.activityInfo.map((item, index)=> {
                      return(
                        <Hoverable
                          key={item.name}
                          style={[styles.tag, { marginTop: 8, marginRight: index !== this.activityInfo.length - 1 ? 12 : 0, borderColor: this.state.activityFilterIndex === index ? item.color.alpha1 : Colors.lightGray.alpha1, borderWidth: 2, backgroundColor: this.state.activityFilterIndex === index ? item.color.alpha1 : 'white' }]}
                          onPress={()=> this.setActivityFilter(index)}
                          hoverStyle={{ backgroundColor: item.color.alpha1, borderColor: item.color.alpha1 }}
                          text={item.name}
                          textStyle={[FontStyles.footnote.medium, { color: this.state.activityFilterIndex === index ? 'white' : Colors.dark.alpha06 }]}
                          hoverTextStyle={{ color: 'white' }}
                        />
                      )
                    })
                  }
                </View>
                <Pressable onPress={this.removeAllFilters} style={{ marginTop: 16}}>
                  <Text style={[FontStyles.subhead.medium, { color: Colors.red.alpha08, fontSize: 14 }]}>
                    {I18n.t('clearFilters')}
                  </Text>
                </Pressable>
              </Dropdown>
            </View>
            <Pressable style={styles.createContestButton} onPress={()=> this.props.navigation.navigate('CreateContest', { getData: this.getContests })}>
              <Text style={[FontStyles.subhead.medium, { color: 'white' }]}>
                {I18n.t('newContest')}
              </Text>
            </Pressable>
          </View>

          <View style={styles.activeContestContainer}>
            {
              this.state.contests.map((contest, index) => {
                return (
                  <ContestCard
                    key={contest.id}
                    contest={contest}
                    index={index}
                    length={this.state.contests.length}
                    onPress={this.onContestPress}
                    status={this.getStatus(contest)}
                  />
                )
              })
            }
          </View>
        </ScrollView>
        <ContestDetails
          ref={ref => this.contestDetailsRef = ref}
          type={'CONTEST'}
        />
      </View>
      
    )
  }
  
}

const styles = StyleSheet.create({
  container: {
    flex: 1, 
    backgroundColor: '#F3FAFF', 
    borderRadius: 15, 
    overflow: 'hidden' 
  },
  oldContestContainer: {
    backgroundColor: 'white',
    flex: 1,
    minWidth: 300,
    maxWidth: 450,
    borderRadius: 20,
    shadowColor: '#C4C4C4',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.15,
    shadowRadius: 10,
    margin: 10,
  },
  contestRow: {
    paddingVertical: 24,
    shadowColor: '#C4C4C4',
    borderRadius: 20,
    backgroundColor: 'white',
    paddingHorizontal: 24,
    width: 300,
    
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.15,
    shadowRadius: 10,
  },
  activeContestContainer: {
    flex: 1,
    flexDirection: 'row', 
    flexWrap: 'wrap',
    alignSelf: 'baseline',
    zIndex: -1
  },
  circleContainer: {
    justifyContent: 'center', 
    alignItems :'center',
    marginTop: 24
  },
  tag: {
    paddingHorizontal: 12,
    paddingVertical: 6,
    borderRadius: 7.5,
    alignSelf: 'baseline'
  },
  tagContainer: {
    marginTop: 8,
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: 240,
  },
  contestCardFooter: {
    width: '100%', 
    flexDirection: 'row', 
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 24
  },
  participantRatioText: {
    color: Colors.gray.alpha08,
  },
  activeIndicator: { 
    paddingHorizontal: 12,
    paddingVertical: 4,
    borderRadius: 100
  },
  header: {
    flexDirection: 'row',
    marginTop: 12,
    alignItems: 'center',
    flexWrap: 'wrap',

  },
  searchInputContainer: {
    width: 400,
    flexDirection: 'row',
    borderRadius: 100,
    backgroundColor: 'white',
    paddingRight: 16,
    height: 40,
    paddingLeft: 16,
    alignItems: 'center',
  },
  searchInput: {
    height: '100%',
    paddingVertical: 16,
    outlineStyle: 'none',
    paddingLeft: 6,
    flex: 1
  },
  filterTagContainer: {
    width: '100%', 
    flexDirection: 'row', 
    flexWrap: 'wrap',
  },
  createContestButton: {
    paddingHorizontal: 12,
    height: 40,
    justifyContent: 'center',
    marginLeft: 16,
    borderRadius: 10,
    backgroundColor: Colors.purple.alpha1,
    zIndex: -1,
  }
})