import { NavigationContainer, DefaultTheme, getPathFromState } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import useAuth, { AuthProvider } from "./hooks/auth-context";
import i18n from "i18n-js";
import Languages from './utils/Languages'
import { useFonts } from 'expo-font'
import * as Localization from 'expo-localization';
import API from "./utils/API";
import { useEffect, useState } from "react";

if(!__DEV__){
  console.log = function(){}
}

i18n.translations = Languages

const linking = {
  config: {
    screens: {
      Home: {
        screens: {
          Dashboard: '/dashboard',
          Contests: '/contests',
          Announcements: '/announcements',
          SmartTimeline: '/smart-timeline',
          Users: '/dashboard/users',
          Surveys: '/dashboard/surveys',
          CreateSurvey: '/dashboard/create-survey',
          Reports: '/dashboard/reports',
          Notifications: '/dashboard/notifications',
          AddMediaContent: '/smart-timeline/add-media-content'
        }
      },
      Login: '/login',
    },
  },
  getPathFromState(state, config){
    let path = getPathFromState(state, config);
    const index = path.indexOf("?")
    if(index >= 0){
      path = path.substring(0, index);
    }
    return path;
  }
};

const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: 'white',
  },
}

const Stack = createNativeStackNavigator()

function Router(){
  const { user } = useAuth()

  return (
    <NavigationContainer theme={theme} linking={linking} >
      <Stack.Navigator screenOptions={{ headerShown: false }} >
        {
          user ? 
          <>
            <Stack.Screen 
              name="Home"  
              component={Home} 
              
            />
          </>

          :
          <Stack.Screen 
            name="Login" 
            component={Login} 
          />
        }
      </Stack.Navigator>
    </NavigationContainer>
  )
}

export default function App() {

  const [ isAppReady, setIsAppReady ] = useState(false)

  const [fontsLoaded] = useFonts({
    'sf-heavy': require('./assets/fonts/sf-pro-rounded/SF-Pro-Rounded-Light.otf'),
    'sf-black': require('./assets/fonts/sf-pro-rounded/SF-Pro-Rounded-Black.otf'),
    'sf-bold': require('./assets/fonts/sf-pro-rounded/SF-Pro-Rounded-Bold.otf'),
    'sf-semibold': require('./assets/fonts/sf-pro-rounded/SF-Pro-Rounded-Semibold.otf'),
    'sf-medium': require('./assets/fonts/sf-pro-rounded/SF-Pro-Rounded-Medium.otf'),
    'sf-regular': require('./assets/fonts/sf-pro-rounded/SF-Pro-Rounded-Regular.otf'),
    'sf-light': require('./assets/fonts/sf-pro-rounded/SF-Pro-Rounded-Light.otf'),
    'sf-thin': require('./assets/fonts/sf-pro-rounded/SF-Pro-Rounded-Thin.otf'),
    'sf-ultralight': require('./assets/fonts/sf-pro-rounded/SF-Pro-Rounded-Ultralight.otf'),
  })


  useEffect(()=> {
    async function prepare(){
      try{
        const [user] = await Promise.all([
          API.getCurrentUser(),
          API.initialize(),
        ])
        
        API.companyId = user.companyId

        if(!user){
          if(API.availableLanguages.includes(Localization.locale.substring(0,2))){
            moment.locale(Localization.locale.substring(0,2))
            await API.setLocalLanguage(Localization.locale.substring(0,2))
          }else{
            moment.locale(API.DEFAULT_LANGUAGE)
            await API.setLocalLanguage(API.DEFAULT_LANGUAGE)
          }
        }else{
          API.setLocalLanguage(user.language)
          if(user.authRole === 'COMPANY-MODERATOR'){
            API.getCompanyInformation().then(()=>{ console.log('Timezone: ', API.timezoneOffset) }).catch(()=>{})
          }
          
        }
      }catch(e){
        console.log(e)
      }finally{
        setIsAppReady(true)
      }
    }
    prepare()
  },[])


  if(!fontsLoaded || !isAppReady){
    return null
  }

  return (
    <AuthProvider>
      <Router/>
    </AuthProvider>
  );
}

import Home from "./screens/Home/Home";

//login
import Login from "./screens/Register/Login/Login";


