import React from "react";
import {StyleSheet, View, Text, Pressable, Animated, ScrollView, TextInput} from 'react-native';
import FontStyles from "../utils/FontStyles";
import Colors from "../utils/Colors";
import I18n from "i18n-js";
import ResizingTextInput from "./ResizingTextInput";
import { Feather } from '@expo/vector-icons'

export default class QuestionAdder extends React.PureComponent{
  constructor(props){
    super(props)
    this.opacityValue = new Animated.Value(0)
    this.currentQuestion = ''
    this.currentAnswers = []
    this.errorOpacityValue = new Animated.Value(0)

    this.state = {
      questionType: "MULTIPLE-CHOICE",
      answers: [1, 1],
      questions: [],
      errorMessage: 'sss',
      correctAnswerIndex: null
    }
  }

  onAddAnswer = () => {
    if(this.state.answers.length < 5){
      this.setState((state)=> {
        return {
          answers: [...state.answers, '']
        }
      })
    }
  }

  setCurrentAnswers = (text, index) => {
    if(this.state.errorMessage == 'Lütfen en az 2 cevap giriniz.' && text.length > 0 ){
      Animated.timing(this.errorOpacityValue,{
        toValue: 0,
        duration: 250,
        useNativeDriver: true
      }).start()
    }

    this.currentAnswers[index] = text
  }

  addNewQuestion = () => {
    const error = this.errorHandling()

    if(!error){
      let obj = {
        text: this.currentQuestion.trim(),
      }

      if(this.state.questionType !== 'GRADING'){
        obj['options'] = this.currentAnswers
      }

      if(this.props.enableCorrectAnswer){
        obj['correctOption'] = this.state.correctAnswerIndex
      }else{
        obj['type'] = this.state.questionType
      }
  
      let arr = [...this.state.questions]
      arr.push(obj)
      this.setState({ questions: arr },()=> {
        this.refreshInputs()
      })
    }
  }

  refreshInputs = () => {
    this.questionInputRef.clear()
    this.currentQuestion = ''
    this.currentAnswers = []
    let arr = [Math.random(), Math.random()]

    if(this.state.questionType === 'MULTIPLE-CHOICE'){
      for(let i = 0; i < this.state.answers.length; i++){
        this[`answerInputRef${i}`].clear()
      }
  
      this.setState({ answers: arr, correctAnswerIndex: null })
    }
  }

  errorHandling = () => {
    if(this.currentQuestion.length === 0){
      this.setState({ errorMessage: 'Lütfen bir soru giriniz.'},()=> {
        Animated.timing(this.errorOpacityValue, {
          toValue: 1,
          duration: 250,
          useNativeDriver: true
        }).start()
      })
      return true
    }else if(this.currentAnswers.length < 2 && this.state.questionType !== 'GRADING'){
      this.setState({ errorMessage: 'Lütfen en az 2 cevap giriniz.'},()=> {
        Animated.timing(this.errorOpacityValue, {
          toValue: 1,
          duration: 250,
          useNativeDriver: true
        }).start()
      })
      return true
    }else if(this.props.enableCorrectAnswer && this.state.correctAnswerIndex === null){
      this.setState({ errorMessage: 'Lütfen doğru bir cevap belirleyiniz.'},()=> {
        Animated.timing(this.errorOpacityValue, {
          toValue: 1,
          duration: 250,
          useNativeDriver: true
        }).start()
      })
      return true
    }else{
      return false
    }
  }

  removeQuestion = (index) => {
    console.log(index)
    let arr = [...this.state.questions]
    arr.splice(index, 1)
    this.setState({ questions: arr })
  }

  setQuestionType = () => {
    this.currentAnswers = []

    if(this.state.questionType === 'MULTIPLE-CHOICE'){
      for(let i = 0; i < this.state.answers.length; i++){
        this[`answerInputRef${i}`].clear()
      }
    }

    this.setState({ questionType: this.state.questionType === 'MULTIPLE-CHOICE' ? 'GRADING' : 'MULTIPLE-CHOICE' }, ()=> {
      if(this.state.questionType === 'GRADING'){
        Animated.timing(this.errorOpacityValue, {
          toValue: 0,
          duration: 250,
          useNativeDriver: true
        }).start(()=> {
          this.setState({errorMessage: 'sss'})
        })
      }
    })
  }

  onChangeText = (text) => {
    this.currentQuestion = text
    if(this.state.errorMessage == 'Lütfen bir soru giriniz.' && text.length > 0){
      Animated.timing(this.errorOpacityValue,{
        toValue: 0,
        duration: 250,
        useNativeDriver: true
      }).start()
    }
  }

  render(){
    return (
      <View style={[styles.container, this.props.style]}>
        <ScrollView
          style={{flex: 1}}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{ paddingTop: 24, paddingHorizontal: 16 }}
        >
          <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
              <Text style={[FontStyles.body.bold, { color: Colors.dark.alpha1}]}>
                {this.props.title }
              </Text>
              <Text style={[FontStyles.callout.regular, { color: Colors.dark.alpha08, marginTop: 12}]}>
                {this.props.description}
              </Text>
            </View>
            <Pressable style={[styles.addQuestionButton, { backgroundColor: this.props.color}]} onPress={this.addNewQuestion}>
              <Feather
                name='plus'
                size={22}
                color='white'
              />
            </Pressable>
            
          </View>
          
          <Animated.Text style={[FontStyles.footnote.semibold, {color: Colors.red.alpha1, marginTop: 8, opacity: this.errorOpacityValue}]}>
            {this.state.errorMessage}
          </Animated.Text>
          
          <ResizingTextInput  
            inputHeight={120}
            ref={ref => this.questionInputRef = ref}
            style={[FontStyles.callout.regular, styles.questionInput, { marginTop: 8 }]}
            placeholder={'Soruyu buraya yazın.'}
            placeholderTextColor={Colors.dark.alpha06}
            onChangeText={this.onChangeText}
            maxLength={200}
          />
          {
            this.props.allowGrading &&
            <View style={styles.gradingContainer}>
              <Text style={[FontStyles.subhead.regular, { color: Colors.dark.alpha08, flex: 1 }]}>
                {'1-10 arası puanlamalı soru tipine geçin.'}
              </Text>
              <Pressable style={[styles.answerTypeSwitch, { backgroundColor: this.state.questionType === 'MULTIPLE-CHOICE' ? Colors.lightGray.alpha1 : Colors.greenLight.alpha1 }]} onPress={this.setQuestionType}>
                {
                  this.state.questionType == 'GRADING' &&
                  <Feather
                    name= 'check'
                    color={Colors.white.alpha1}
                    size={20}
                  />
                }
              </Pressable>
            </View>
          }

          {
            this.state.questionType === 'MULTIPLE-CHOICE' &&
            <>
            {
              this.state.answers.map((item, index)=> {
                if(!this.props.enableCorrectAnswer){
                  return (
                    <TextInput
                      ref = { ref => this[`answerInputRef${index}`] = ref}
                      key={index + 'ansBtn' + item}
                      style={[FontStyles.callout.regular, styles.questionInput]}
                      placeholder={`${index + 1}. cevabı buraya yazın.`}
                      placeholderTextColor={Colors.dark.alpha06}
                      onChangeText={(text)=>this.setCurrentAnswers(text, index)}
                      maxLength={100}
                      multiline={false}
                    />
                  )
                }

                return (
                  <View style={{flexDirection: 'row', justifyContent: 'space-between', marginTop: 12, alignItems: 'center'}}>
                    <TextInput
                      ref = { ref => this[`answerInputRef${index}`] = ref}
                      key={index + 'ansBtn' + item}
                      style={[FontStyles.callout.regular, styles.questionInput, { width:'80%', marginTop: 0 }]}
                      placeholder={`${index + 1}. cevabı buraya yazın.`}
                      placeholderTextColor={Colors.dark.alpha06}
                      onChangeText={(text)=>this.setCurrentAnswers(text, index)}
                      maxLength={100}
                      multiline={false}
                    />
                    <Pressable 
                      style={[styles.correctAnswerButton, { backgroundColor: this.state.correctAnswerIndex === index  ? (this.props.color || Colors.purple.alpha1) : Colors.lightGray.alpha1 }]}
                      onPress={()=> this.setState({ correctAnswerIndex: index })}
                    >
                      {
                        this.state.correctAnswerIndex === index &&
                        <Feather
                          name='check'
                          size={22}
                          color={Colors.white.alpha1}
                        />
                      }
                    </Pressable>
                  </View>

                )
              })
            }
            {
              this.state.answers.length !== 5 &&
              <Pressable style={styles.addAnswerButton} onPress={this.onAddAnswer}>
                <Feather
                  name='plus'
                  size={22}
                  color={Colors.dark.alpha06}
                />
                <Text style={[FontStyles.body.regular, {color: Colors.dark.alpha06}]}>
                  {I18n.t('addAnswer')}
                </Text>
              </Pressable>
            }
            </>
          }

          <View style={{ flex: 1, marginTop: 24 }}>
            {
              this.state.questions.map(({text, options, type, correctOption}, index)=> {
                return (
                  <View                   
                    key={text + 'qstn' + index}
                    style={styles.questionContainer}
                  >
                    <View style={{ flexDirection: 'row', marginBottom: 8 }}>
                      <Text style={[FontStyles.body.medium, { color: Colors.dark.alpha1, flex: 1, marginRight: 8 }]}>
                        {text}
                      </Text>
                      <Pressable
                        onPress={()=> this.removeQuestion(index)}
                        style={styles.removeQuestionButton}
                      >
                        <Feather
                          name='trash'
                          color={'white'}
                          size={22}
                        />
                      </Pressable>
                    </View>
                    {
                      !type || type === 'MULTIPLE-CHOICE' ?
                      options.map((answer, i)=> {
                        
                        return (
                          <View key={i + answer + 'scv'} style={{ flexDirection: 'row', marginVertical: 8 }}>
                            
                            <Text style={[FontStyles.body.medium, { color: Colors.dark.alpha1, lineHeight: 24 }]}>
                              {`${i + 1}`}
                            </Text>
                            <Text style={[FontStyles.body.regular, { color: Colors.dark.alpha1, lineHeight: 24, marginLeft: 12 }]}>
                              {`${answer}`}
                            </Text>
                            {
                              correctOption === i &&
                              <View style={[styles.correctAnswerIconContainer, { backgroundColor: this.props.color || Colors.purple.alpha1 }]}>
                                <Feather
                                  name='check'
                                  color={Colors.white.alpha1}
                                  size={16}
                                />
                              </View>
                            }
                          </View>
                        )
                      })
                      :
                      <View style={{ flexDirection: 'row', alignItems: 'center'}}>
                        <Feather
                          name= 'info'
                          size={20}
                          color={this.props.color}
                        />
                        <Text style={[FontStyles.footnote.regular, {color: Colors.dark.alpha1, marginLeft: 8}]}>
                          {I18n.t('gradingQuestion')}
                        </Text>
                      </View>
                    }
                    
                  </View>
                )
              })
            }
          </View>
        </ScrollView>
      </View>
    )
  }
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white.alpha1,
    borderRadius: 17.5,
    alignSelf: 'baseline'
  },
  questionContainer: {
    borderTopWidth: 1, 
    borderTopColor: Colors.dark.alpha06,
    paddingVertical: 16
  },
  addQuestionButton: {
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
  },
  questionInput: {
    color: Colors.dark.alpha1,
    backgroundColor: Colors.lightGray.alpha1,
    borderRadius: 12.5,
    paddingHorizontal: 12,
    paddingVertical: 16,
    marginTop: 12,
    outlineStyle: 'none'
  },
  answerTypeSwitch: {
    width: 30,
    height: 30,
    borderRadius: 7.5,
    justifyContent: 'center',
    alignItems: 'center'
  },
  addAnswerButton: {
    width: '100%',
    paddingVertical: 12,
    borderWidth: 1,
    borderColor: Colors.dark.alpha04,
    borderStyle: 'dashed',
    borderRadius: 15,
    marginTop: 12,
    alignItems: 'center'
  },
  removeQuestionButton: {
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    backgroundColor: Colors.red.alpha1
  },
  gradingContainer: { 
    flexDirection: 'row', 
    alignItems: 'center', 
    marginTop: 24 
  },
  correctAnswerButton: {
    width: 50,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    marginLeft: 12
  },
  correctAnswerIconContainer: {
    width: 24, 
    height: 24, 
    borderRadius: 12, 
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 12
  }
})
