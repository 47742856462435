import { PureComponent } from "react";
import { StyleSheet, View, Text, Image, Pressable, Animated, ScrollView, TextInput} from "react-native";
import {Feather} from '@expo/vector-icons'
import FontStyles from "../utils/FontStyles";
import Colors from "../utils/Colors";
import I18n from "i18n-js";
import API from "../utils/API";
import { BlurView } from "expo-blur";
import { ActivityIndicator } from "react-native-web";
import { hexToRgba } from "../utils/Helper";
import Alert from './Alert';
const defaultTagId = "000000000000000000000000"

export default class TagDetails extends PureComponent {
  constructor(props){
    super(props)

    this.alertRef = null
    this.newTagTitle = ""

    this.state = {
      mediaBasePath: "",
      errorMessageLeft: null,
      errorMessageMiddle: null,
      errorMessageRight: null,
      
      tags: [],
      selectedTagId: null,
      loadingLeft: true,    // loading for COMPANY TAGS COLUMN    (left side of screen)

      users: [],
      searchUsers: [],
      loadingMiddle: true, // loading for COMPANY USER COLUMN    (middle of screen)

      selectedTagUsers: [],
      loadingRight: false,  // loading for USERS of TAG COLUMN    (right side of screen) 

    }
  }

  componentDidMount(){
    this.getCompanyTags() //left column
    this.getUsersWithTags() //middle column
  }

  errorHandler = (error, loadingSide) => {
    if(loadingSide === "left"){
      this.setState({loadingLeft: false, errorMessageLeft: error?.errorMessage || null})
    }else if(loadingSide === "middle"){
      this.setState({loadingMiddle: false, errorMessageMiddle: error?.errorMessage || null})
    }else if(loadingSide === "right"){
      this.setState({loadingRight: false, errorMessageRight: error?.errorMessage || null})
    }
  }
  
  //API CALLS
  //left column
  getCompanyTags(){
    API.getCompanyTags().then(({ tags }) => {
      setTimeout(() => {
        this.setState({tags: tags, loadingLeft: false, errorMessageLeft: null})                
      }, 150);
    }).catch((e) => { 
      this.errorHandler(e, "left")
    })
  }

  createTag = () => {
    this.newTagTitle.length > 0 ?
      API.createTag(this.newTagTitle).then(({ tag }) => {
        this.newTagTitle = ""
        this.newTagInput.clear()
        tag["userCount"] = 0
        this.setState({loadingLeft: false, tags: [...this.state.tags, tag], errorMessageLeft: null})
      }).catch((err) => {
        this.errorHandler(err, "left")
      })
      :
      this.setState({loadingLeft: false, errorMessageLeft: I18n.t('tagErrorMessage')})
  }

  deleteTag = (tagId) => {
    this.alertRef.hideAlert()
    this.setState({loadingLeft: true, loadingMiddle: true, selectedTagId: null, selectedTagUsers: []}, () => {
      API.deleteTag(tagId).then(({ tags }) => {
        this.setState({loadingLeft: false, tags: tags }, () => {
          this.searchUserInput.clear()  
          this.getUsersWithTags()
        })
      }).catch(err => {
        this.errorhandler(err, "left")
      })
    })
  }

  deleteTagAlert(tagId){
    this.alertRef.showAlert({
      title: I18n.t('deleteTagTitle'),
      description: I18n.t('deleteTagDescription'),
      type: "warning",
      buttons: [
        {title: I18n.t('deleteTag'), type: null, onPress: () =>  this.deleteTag(tagId)},
        {title: I18n.t('cancel'), type: 'passive'},
      ],
    })
  }

  //middle column
  getUsersWithTags(){
    API.getCompanyUsersWithTags().then(({ users, mediaBasePath }) => {
      setTimeout(() => {
        this.setState({
          users: users.filter(user=>user.authRole==='EMPLOYEE'),
          searchUsers: users.filter(user=>user.authRole==='EMPLOYEE'),
          mediaBasePath: mediaBasePath,
          loadingMiddle: false})
      }, 250);
    }).catch(err => {
      this.errorhandler(err, "middle")
    })   
  }

  addTagToUser = (user) => {
    API.addTagToUser(this.state.selectedTagId, user.id).then(({ users }) => {
      
      let tags = this.state.tags
      let usersLocale = this.state.searchUsers
      let tagIndex = tags.findIndex(tag => tag.id === this.state.selectedTagId)
      let userIndex = usersLocale.findIndex(userFromArr => userFromArr.id === user.id)

      if(tagIndex > -1 && userIndex > -1){
        tags[tagIndex]["userCount"] = tags[tagIndex]["userCount"] + 1        
        usersLocale[userIndex]["tags"].push(tags[tagIndex])
      }
      
      this.setState({loadingMiddle: false, tags: tags, searchUsers: usersLocale, selectedTagUsers: users})
    }).catch((err) => {
      this.errorHandler(err, "middle")
    })
  }

  removeTagFromUser = (user) => { 
    API.removeTagFromUser(this.state.selectedTagId, user.id).then(({ users }) => {

      let tags = this.state.tags
      let usersLocale = this.state.users
      let tagIndex = tags.findIndex(tag => tag.id === this.state.selectedTagId)
      let userIndex = usersLocale.findIndex(userFromArr => userFromArr.id === user.id)

      if(tagIndex > -1 && userIndex > -1){
        tags[tagIndex]["userCount"] = tags[tagIndex]["userCount"] - 1  
        usersLocale[userIndex]["tags"].splice(usersLocale[userIndex]["tags"].findIndex(tag => tag.id === this.state.selectedTagId), 1)
        this.searchUserInput.clear()
      }

      this.setState({loadingRight: false, tags: tags, searchUsers: usersLocale, selectedTagUsers: users})
    })
  }

  getUsersByTag = (tagId) => {
    API.getUsersByTag(tagId).then(({ users, mediaBasePath })=> {
      setTimeout(() => {
        this.searchUserInput.clear()
        this.setState({selectedTagUsers: users, selectedTagId: tagId, mediaBasePath: mediaBasePath, loadingRight: false})        
      }, 300);
    }).catch(err => {
      this.errorHandler(err, "right")
    })
  }

  filterUsersFromArray(users, text){
    if(users && text && text.length > 0){
      return users.filter(user => {
        return user.email?.toLowerCase().includes(text.toLowerCase()) || user.firstName.toLowerCase().includes(text.toLowerCase()) || user.lastName.toLowerCase().includes(text.toLowerCase())
      })
    }else if(users && text.length === 0){
      return users
    }else{
      return []
    }
  }
  
  
  //COMPONENT FUNCTIONS
  leftColumn = () => {
    return (
      <View style={styles.leftSideContainer}>
        <View style={styles.companyTagsContainer}>
          <Text style={[FontStyles.title2.semibold, {marginBottom: 12}]} >
            {I18n.t('tags')}
          </Text>
          
          {this.state.loadingLeft && <ActivityIndicator style={styles.positionAbsRightTop} size={"small"} color={Colors.gray.alpha1}/> }
          
          <ScrollView 
            style={styles.scrollViewContainer}
            showsVerticalScrollIndicator={false}
            contentContainerStyle={styles.scrollViewContentContainer}
          >
            <View style={styles.newTagContainer}>
              <TextInput
                ref={(input) => { this.newTagInput = input }}
                style={styles.newTagInput}
                placeholder={I18n.t('addTag')}
                selectionColor={Colors.primaryBlue.alpha1}
                autoCapitalize="none"
                autoCorrect={false}
                placeholderTextColor={Colors.gray.alpha06}
                onChangeText={text => this.newTagTitle = text}           
              />
              <Pressable disabled={this.state.loadingLeft} onPress={() => this.setState({loadingLeft: true}, () => this.createTag())} style={[styles.addTagButton, {backgroundColor: this.state.loadingLeft ? Colors.gray.alpha01 : Colors.secondaryBlue.alpha01}]}>
                <Feather name="plus" size={18} color={this.state.loadingLeft ? Colors.gray.alpha06 : Colors.secondaryBlue.alpha1}/>
              </Pressable>
            </View>
            
            {
              this.state.errorMessageLeft &&
              <Text style={[FontStyles.caption.medium, {color: Colors.red.alpha1, marginTop: 2, marginBottom: 4}]}>
                {this.state.errorMessageLeft}
              </Text>
            }
            
            {
              this.state.tags.length !== 0 &&
              this.state.tags.map((tag,index) => this.tagRow(tag, index))
            }
          </ScrollView>
        </View>
      </View>
    )
  }

  rightColumn = () => {
    return (
      <View style={styles.rightSideContainer}>
        <View style={styles.tagDetailsContainer}>
          <Text style={[FontStyles.title2.semibold]} >
            {I18n.t('tagDetailsTitle')}
          </Text>
          <Text style={[FontStyles.subhead.regular, {marginTop: 8, lineHeight: 26}]} >
            {I18n.t('tagDetailsDescriptionLong')}
          </Text>
          
          <View style={styles.rightChildColumnsContainer}>
            <View style={{flex: 1, marginRight: 8}}>

              {this.state.loadingMiddle && <ActivityIndicator style={styles.positionAbsRightTop} size={"small"} color={Colors.gray.alpha1}/> }

              <Text style={[FontStyles.headline.medium, {marginBottom: 4}]} >
                Tüm Kullanıcılar
              </Text>
              <Text style={[FontStyles.footnote.regular, {marginBottom: 8, color: Colors.dark.alpha08, lineHeight: 20}]} >
                Kayıtlı tüm kullanıcılar listelenmektedir.
              </Text>
              <View style={styles.searchUserContainer}>
                <TextInput
                  style={[styles.searchUserInput]}
                  placeholder='Kullanıcı Ara'
                  selectionColor={Colors.primaryBlue.alpha1}
                  autoCapitalize="none"
                  autoCorrect={false}
                  ref={(input) => { this.searchUserInput = input }}
                  placeholderTextColor={Colors.gray.alpha06}
                  onChangeText={text => { this.setState({searchUsers: this.filterUsersFromArray(this.state.users, text)})}}           
                />
                <Feather name="search" size={18} color={Colors.gray.alpha04}/>
              </View>

              <ScrollView 
                style={[styles.scrollViewContainer]}
                showsVerticalScrollIndicator={false}
                contentContainerStyle={styles.scrollViewContentContainer}
              >
                {
                  this.state.searchUsers && this.state.searchUsers.length !== 0 &&
                  this.state.searchUsers.map((user,index) => this.userRow(user, index, false))
                }
              </ScrollView>
            </View>
            
            <View style={{flex: 1,marginLeft: 8}}>
              
              {this.state.loadingRight && <ActivityIndicator style={styles.positionAbsRightTop} size={"small"} color={Colors.gray.alpha1}/> }
              
              <Text style={[FontStyles.headline.medium, {marginBottom: 4, color: Colors.dark.alpha1}]} >
                {this.state.selectedTagId ? (this.state.tags.find(tag => tag.id == this.state.selectedTagId)).name : "Etiket Seçin"}
              </Text>
              <Text style={[FontStyles.footnote.regular, {marginBottom: 8, color: Colors.dark.alpha08, lineHeight: 20}]} >
                Seçtiğiniz etikete ait kullanıcılar.
              </Text>
              
              <ScrollView 
                style={[styles.scrollViewContainer]}
                showsVerticalScrollIndicator={false}
                contentContainerStyle={styles.scrollViewContentContainer}
              >
                {
                  this.state.selectedTagId === defaultTagId ?
                  <Text style={[FontStyles.subhead.medium, { width: "80%", alignSelf: "center", lineHeight: 24, color: Colors.gray.alpha06, marginTop: 36, textAlign: 'center' }]}>
                    <Feather name={"x-circle"} color={Colors.gray.alpha06} size={32} />
                    {"\n\nGenel kategorisinde tüm çalışanlar bulunmaktadır ve 'Genel' etiketi kaldırılamaz."}
                  </Text>
                  :
                  this.state.selectedTagUsers && this.state.selectedTagUsers.length !== 0 ? 
                  this.state.selectedTagUsers.map((user,index) => this.userRow(user, index, true))
                  :
                  <View style={styles.emptyContainer}>
                    <Feather 
                      name="tag"
                      size={32}
                      color={Colors.gray.alpha06}
                    />
                    <Text style={[FontStyles.subhead.medium, { width: "66%", lineHeight: 24, color: Colors.gray.alpha06, marginTop: 16, textAlign: 'center' }]}>
                      {I18n.t('tagNotSelected')}
                    </Text>
                  </View>
                }
              </ScrollView>
            </View>
          </View>  
        </View>
      </View>
    )
  }

  userRow = (user, index, isTagList) => {
    return (
      <View key={isTagList ? index + "keyTagID" : index + "keyAllUsers"} style={styles.userRowContainer}>
        <View style={styles.userImageContainer}>
          <Image 
            source={user.photo ? {uri: this.state.mediaBasePath + user.photo} : require('../assets/icons/user_dummy_image.png')}
            style={[styles.userImage, {tintColor: user.photo ? null : Colors.gray.alpha06}]}
            resizeMode='cover'
          />
        </View>
        <View style={styles.userInfoContainer}>
          <Text numberOfLines={1} style={[FontStyles.subhead.semibold]}>
            {user.firstName + " " + user.lastName}
          </Text>
          <View style={{flexDirection: "row", marginTop: 4}}>
            { 
              user.tags && user.tags.map((tag, index) => {
                return (
                  <View key={index + "keyx"} style={styles.tagContainer}>
                    <Text style={[FontStyles.caption.regular]}>
                      {tag.name}
                    </Text>
                  </View>
                )
              })
            }
          </View> 
        </View>
        {
          isTagList ?
          <Pressable onPress={() => this.setState({loadingRight: true}, () => this.removeTagFromUser(user))} style={[styles.removeTagFromUserButton]}>
            <Feather name={"x"} size={18} color={Colors.red.alpha1}/>
          </Pressable>
          :
          this.state.selectedTagId === defaultTagId ||
          user.tags.find(tag => tag.id === this.state.selectedTagId) ? 
          <View style={[styles.addTagToUserButton, {backgroundColor: Colors.greenLight.alpha01}]}>
            <Feather name={"check"} size={18} color={Colors.greenLight.alpha1}/>
          </View> 
          :
          <Pressable 
            disabled={!this.state.selectedTagId}
            onPress={() => this.setState({loadingMiddle: true}, () => this.addTagToUser(user))}
            style={[styles.addTagToUserButton, {backgroundColor: this.state.selectedTagId ? Colors.secondaryBlue.alpha01 : Colors.lightGray.alpha1}]}
          >
            <Feather name={"plus"} size={18} color={this.state.selectedTagId ? Colors.secondaryBlue.alpha1 : Colors.gray.alpha06}/>
          </Pressable>
        }
        
      </View>
    )
  }
  
  tagRow = (tag, index) => {
    return (
      <View key={index + "tagrowkey"} style={[styles.userContainer, {borderTopWidth: index !== 0 ? 1.5 : 0, borderColor: Colors.lightGray.alpha1}]}>
        <View style={{backgroundColor: hexToRgba(tag.colorHex, 0.9), width: 12, height: 12, borderRadius: 4}}/>
        <View style={{marginLeft: 15, flex: 1}}>
          <Text numberOfLines={1} style={[FontStyles.callout.semibold, {color: Colors.dark.alpha1, letterSpacing: -0.5}]}>
            {`${tag.name}`}
          </Text>
          <Text numberOfLines={1} style={[FontStyles.footnote.regular, {color: Colors.dark.alpha08, letterSpacing: -0.5}]}>
            {`${tag.userCount} çalışan`}
          </Text>
        </View>
        {
          (tag.id !== defaultTagId) && 
          <Pressable onPress={() => this.deleteTagAlert(tag.id)} style={{width: 44, height: 40,  justifyContent: "center", alignItems: "center" }}>
            <Feather name='trash' color={Colors.red.alpha06} size={16}/>
          </Pressable>
        }
        <Pressable onPress={() => this.setState({loadingRight: true}, () => this.getUsersByTag(tag.id))} style={styles.tagDetailsButton}>
          <Text style={[FontStyles.footnote.medium, {color: Colors.gray.alpha1 }]}>
            {I18n.t("details")}
          </Text>
        </Pressable>
      </View>
    )
  }


  render(){ 
    return (
      <Animated.View style={[styles.tagDetailedContainer, {opacity: this.props.tagDetailsAnimValue}]}>
        <BlurView tint={"light"} intensity={100} style={styles.blurView}>
          
          {this.leftColumn()}
          
          {this.rightColumn()}
          
          <Pressable style={styles.closeButton} onPress={() => this.props.tagDetailsController(false)}>
            <Feather name={"x"} color={Colors.white.alpha1} size={28}/>
          </Pressable>
          
          <Alert ref={ref => this.alertRef = ref}/>
          
        </BlurView>           
      </Animated.View>   
    )
  }
}

const styles = StyleSheet.create({
  //LAYER 01 - GENERAL
  tagDetailedContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    flexDirection: "row",
    alignItems: 'center',
    zIndex: 1,
    borderRadius: 25,
    overflow: "hidden"
  },
  blurView: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    flexDirection: "row",
    alignItems: 'center',
    zIndex: 1,
  },
  closeButton: {
    backgroundColor: Colors.primaryBlue.alpha1,
    borderRadius: 222,
    height: 48,
    width: 48,
    position: "absolute",
    right: "3.5%",
    top: "4%",
    alignSelf: "flex-end",
    justifyContent: "center",
    alignItems: "center",
  },
  positionAbsRightTop: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  scrollViewContainer: {
    flex: 1, 
    borderRadius: 17.5, 
    overflow: "hidden", 
    borderWidth: 0, 
    borderColor: Colors.white.alpha1, 
    backgroundColor: Colors.white.alpha1
  },
  scrollViewContentContainer: {
    paddingVertical: "2%", 
    paddingHorizontal: "5%" 
  },
  userRowContainer: {
    flexDirection: "row", 
    alignItems: "center", 
    borderBottomColor: Colors.lightGray.alpha1, 
    borderBottomWidth: 1.5, 
    paddingVertical: 12
  },





  //LAYER 02 - LEFT COLUMN
  leftSideContainer: {
    flex: 1,
    height: "100%",
  },
  companyTagsContainer: {
    flex: 1, 
    marginLeft: "15%", 
    marginVertical: "8%", 
    marginRight: "2.5%"
  },
  tagDetailsContainer: {
    flex: 1, 
    marginRight: "15%", 
    marginVertical: "0%", 
    marginLeft: "2.5%"
  },
  tagDetailsButton: {
    backgroundColor: Colors.lightGray.alpha1,
    borderRadius: 17.5,
    borderWidth: 0,
    borderColor: Colors.white.alpha1,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  userContainer: {
    width: '100%',
    flexDirection: 'row',
    paddingVertical: 12,
    alignItems: 'center',
    flex: 1,
  },
  removeTagFromUserButton: {
    backgroundColor: Colors.red.alpha01, 
    opacity: 0.9, 
    borderRadius: 16, 
    width: 32, 
    height: 32, 
    justifyContent: 'center', 
    alignItems: "center"
  },
  addTagToUserButton: {
    opacity: 0.9, 
    borderRadius: 16,
    width: 32, 
    height: 32, 
    justifyContent: 'center', 
    alignItems: "center"
  },


  //LAYER 03 - RIGHT COLUMN
  rightChildColumnsContainer: {
    flexDirection: "row", 
    flex: 1, 
    marginTop: 16
  },

  //left side
  emptyContainer: {
    justifyContent: 'center', 
    alignItems: 'center',
    height: 200,
  },
  newTagContainer: {
    marginTop: 12,
    marginBottom: 8,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  newTagInput: {
    height: "100%",
    paddingHorizontal: 16,
    height: 40,
    borderRadius: 25,
    borderWidth: 2, 
    marginRight: 12,
    marginLeft: -2,
    fontSize: 14,
    fontWeight: "500",
    backgroundColor: Colors.white.alpha1,
    borderColor: Colors.lightGray.alpha1, 
    flex: 1,
    outlineStyle: "none",
  },
  addTagButton: {
    backgroundColor: Colors.secondaryBlue.alpha01,
    width: 32,
    height: 32,
    borderRadius: 18,
    marginRight: 4,
    justifyContent: "center",
    alignItems: "center"
  },
  userImageContainer: {
    width: 36,
    height: 36,
    backgroundColor: Colors.gray.alpha02,
    borderRadius: 18,
  },
  userImage: {
    width: 36,
    height: 36,
    borderRadius: 18,
  },
  userInfoContainer: {
    flex: 1,
    marginLeft: 8,
  },
  tagContainer: {
    borderWidth: 1,
    borderColor: Colors.lightGray.alpha1,
    borderRadius: 5,
    paddingHorizontal: 4,
    paddingVertical: 2,
    marginRight: 3,
  },

  //right side
  rightSideContainer: {
    flex: 2,
    paddingVertical: "3%",
    height: "100%",
  },
  tipContainer: {
    borderWidth: 0,
    borderColor: Colors.lightGray.alpha1,
    borderRadius: 15,
    paddingHorizontal: 16,
    paddingVertical: 12,
    backgroundColor: Colors.white.alpha1,
    marginTop: 24,
    width: "100%",
  },
  searchUserContainer: {
    borderWidth: 2, 
    borderRadius: 20,
    height: 40,
    width: "100%",
    borderColor: Colors.lightGray.alpha1,
    backgroundColor: Colors.white.alpha1,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 16,
    marginBottom: 8
  },
  searchUserInput: {
    fontSize: 14,
    fontWeight: "500",
    outlineStyle: "none",
    flex: 1,
    height: 40
  }
})