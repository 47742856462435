import React, { memo, useEffect } from "react";
import { StyleSheet, View, ScrollView, Text, Pressable, ActivityIndicator } from "react-native";
import Colors from "../utils/Colors";
import FontStyles from "../utils/FontStyles";
import { Feather } from '@expo/vector-icons'
import moment from "moment";
import I18n from "i18n-js";
import EducationPackages from "./EducationPackages";
import API from "../utils/API";
import { useNavigation } from "@react-navigation/native";

const iconSet = {
  'VIDEO': { name: 'play-circle', color: Colors.badgeYellow.alpha1 },
  'PODCAST': { name: 'mic', color: Colors.badgeYellow.alpha1 },
  'MINI-TEST': { name: 'edit-2', color: Colors.primaryBlue.alpha1 },
  'SURVEY': { name: 'file-text', color: Colors.secondaryBlue.alpha1 },
  'CONTEST': { name: 'target', color: Colors.purple.alpha1 }
}

const statusComponent = {
  'ACTIVE': { text: 'active', color: Colors.greenLight.alpha1 },
  'PENDING': { text: 'pending', color: Colors.yellow.alpha1 },
  'INACTIVE': { text: 'inactive', color: Colors.red.alpha1 }
}

const RowItem = memo(function({ onPressRow, item, status, loading, removeMediaContent, index, monthIndex }){
  return (
    <Pressable disabled={loading} onPress={()=> onPressRow(item)} 
      key={item.id} 
      style={{ borderBottomWidth: 1, borderColor: Colors.dark.alpha04, paddingVertical: 12, width: '100%' }}
    >
      <View style={{ flexDirection: 'row', alignItems : 'center' }}>
        <Feather name={iconSet[item.type].name} size={18} color={iconSet[item.type].color}/>
        <Text style={[FontStyles.subhead.regular, { color: Colors.dark.alpha1, marginLeft: 8, flex: 1 }]}>
          {item.title}
        </Text>
        {
          item.isDeletable &&
          <Pressable disabled={loading} onPress={()=>{
            if(confirm('Bu medya içeriğini silmek istediğinizden emin misiniz?')){
              removeMediaContent(item, index, monthIndex)
            }
          }}>
            <Feather name='trash-2' size={16} color={Colors.red.alpha1} />
          </Pressable>
        }
      </View>
      <View style={{flexDirection: 'row', width: '100%', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between'}}>
        <View style={{flexDirection: 'row', marginTop: 8, alignItems: 'center'}}>
          <Feather name='calendar' size={14} color={Colors.dark.alpha06} style={{marginRight: 4}}/>
          <Text style={[FontStyles.caption.medium, { color: Colors.dark.alpha06, marginRight: 4}]}>
            {moment(item.startsAt).format('DD MMM YYYY') + (item.endsAt ? (' - ' + moment(item.endsAt).format('DD MMM YYYY')) : '')}
          </Text>
        </View>
        {
          status &&
          <View style={{ paddingHorizontal: 8, paddingVertical: 4, backgroundColor: status.color, borderRadius: 5, marginTop: 8 }}>
            <Text style={[FontStyles.caption.medium, { color: Colors.white.alpha1 }]}>
              {I18n.t(status.text)}
            </Text>
          </View>
        }
      </View>
      
      <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 8}}>
        <Feather size={14} name='users' color={Colors.dark.alpha06}/>
        <Text style={[FontStyles.caption.medium, { color: Colors.dark.alpha06, flex: 1, marginLeft: 4 }]}>
          { (item.completedUserCount || item.participantCount || 0) + ' / ' + item.eligibleUserCount + (item.eligibleUserCount ? ` (${Math.round(((item.completedUserCount || item.participantCount || 0) / item.eligibleUserCount) * 100)}%)` : '') }
        </Text>
      </View>
    </Pressable>
  )
})

const MonthPackages = memo(function({ packages, loading, onRemovePackage, contentLength, date }){

  return (
    <View style={styles.header}>
      <View>
        {
          packages && packages.length ?
          packages.map((item, index)=> {
            return (
              <View key={item.id} style={{flexDirection: 'row', marginTop: index !== 0 ? 8 : 0, alignItems: 'center'}}>
                {
                  date.isAfter(moment(new Date().getTime())) &&
                  <Pressable 
                    style={{marginRight: 8}} 
                    disabled={loading} 
                    onPress={()=> onRemovePackage(item, index)}
                  >
                    {
                      loading ? 
                      <ActivityIndicator color={Colors.red.alpha1}/>
                      :
                      <Feather name='trash-2' color={Colors.red.alpha1} size={18}/>
                    }
                  </Pressable>
                }

                <Text style={[FontStyles.body.medium, { color: Colors.dark.alpha1}]}>
                  {item.name}
                </Text>
              </View>
            )
          })
          :
          <Text style={[FontStyles.footnote.regular, { color: Colors.dark.alpha08, fontSize: 14, width: '100%'}]}>
            {I18n.t('noTheme')}
          </Text>
        }
      </View>
      <Text style={[FontStyles.caption.medium, { color: Colors.dark.alpha1, marginTop: 1 }]}>
        • { contentLength + ' ' + I18n.t('content') }
      </Text>
    </View>
  )
})

const Header = memo(function({ date, loading, handleAvailablePackagePress, index, addMediaContent }){
  const navigation = useNavigation()
  
  return (
    <View>
      <Text style={[FontStyles.title3.semibold, { color: Colors.dark.alpha1 }]}>
        {date.format('MMMM YYYY')}
      </Text>
      {
        date.isSameOrAfter(moment(new Date().getTime())) &&
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          {/* <Pressable 
            disabled={ loading } 
            style={styles.addButton} 
            onPress={()=> handleAvailablePackagePress(index)}
          >
            <Feather 
              name='plus' 
              size={20} 
              color={Colors.dark.alpha06}
            />
            <Text style={[FontStyles.subhead.medium, { color: Colors.dark.alpha06, marginTop: 6, fontSize: 14, textAlign: 'center' }]}>
              {I18n.t('addTheme')}
            </Text>
          </Pressable> */}
          <Pressable 
            disabled={ loading } 
            style={styles.addButton} 
            onPress={()=> navigation.navigate('AddMediaContent', { date: date, addMediaContent: addMediaContent, index: index })}
          >
            <Feather 
              name='plus' 
              size={20} 
              color={Colors.dark.alpha06}
            />
            <Text style={[FontStyles.subhead.medium, { color: Colors.dark.alpha06, marginTop: 6, fontSize: 14, textAlign: 'center'  }]}>
              {I18n.t('addMediaContent')}
            </Text>
          </Pressable>
        </View>
      }
    </View>
  )
})

export default class MonthlyContent extends React.PureComponent{
  constructor(props){
    super(props)
    this.state = {
      data: null,
      loading: false,
    }
  }

  getStatus = (data) => {
    if(data.startsAt > new Date().getTime()){
      return 'PENDING'
    }else if(data.endsAt < new Date().getTime()){
      return 'INACTIVE'
    }else{
        return 'ACTIVE'
    }
  }

  onRemovePackage = (p, index) => {
    if(confirm(`${p.name} isimli eğitim paketini ${this.props.data.date.format('MMMM YYYY')} ayından kaldırmak istediğinize emin misiniz?`)){
      this.setState({ loading: true })
      API.removeEducationPackage(p.id).then(()=> {
        this.setState({loading: false})
        this.props.removeEducationPackage(this.props.index, index)

      }).catch((e)=> { 
        this.setState({loading: false})
        this.props.handleAlert && this.props.handleAlert({isShowing: true, type: 'warning', text: e.errorMessage})
      })
    }
  }
  
  render(){
    if((!this.props.data.contents || !this.props.data.contents.length) && !this.props.showAvailablePackages){
      return (
        <View style={[styles.container, { height: '100%', padding: 24, borderRadius: 15 }]}>
          <Header
            date={this.props.data.date}
            loading={this.state.loading}
            handleAvailablePackagePress={this.props.handleAvailablePackagePress}
            index={this.props.index}
            addMediaContent={this.props.addMediaContent}

          />
          <Text style={[FontStyles.subhead.regular, { color: Colors.dark.alpha08, marginTop: 12, lineHeight: 22 }]}>
            {I18n.t('noContent')}
          </Text>
        </View>
      )
    }

    return (
      <ScrollView 
        style={styles.container}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ padding: 24, flexGrow: 1 }}
      >
        {
          this.props.showAvailablePackages ? 
          <EducationPackages
            packages={this.props.availablePackages}
            handleAvailablePackagePress={this.props.handleAvailablePackagePress}
            onPressRow={this.props.onPressRow}
            index={this.props.index}
            getTimelineData={this.props.getTimelineData}
            date={this.props.data.date}
            handleAlert={this.props.handleAlert}
            addEducationPackage={this.props.addEducationPackage}
          />
          :
          <>
            <Header
              date={this.props.data.date}
              loading={this.state.loading}
              handleAvailablePackagePress={this.props.handleAvailablePackagePress}
              index={this.props.index}
              addMediaContent={this.props.addMediaContent}
            />
            <MonthPackages
              loading={this.state.loading}
              onRemovePackage={this.onRemovePackage}
              packages={this.props.data.packages}
              date={this.props.data.date}
              contentLength={this.props.data.contents.length}
            />
            <View style={{ borderTopWidth: 1, borderColor: Colors.dark.alpha04, marginTop: 16 }}>
              {
                this.props.data.contents.map((item, index)=> {
                  const status = statusComponent[this.getStatus(item)]
                  return (
                    <RowItem
                      key={item.id}
                      status={status}
                      item={item}
                      index={index}
                      onPressRow={this.props.onPressRow}
                      loading={this.state.loading}
                      removeMediaContent={this.props.removeMediaContent}
                      monthIndex={this.props.index}
                    />
                  )
                })
              }
            </View>
          </>
        }
        
      </ScrollView>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    width: '90%',
    backgroundColor: Colors.white.alpha1, 
    borderRadius: 15,
    shadowColor: '#C4C4C4',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.15, 
    shadowRadius: 10,
  },
  header: {
    flexDirection: 'row', 
    marginTop: 12, 
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
  },
  addButton: {
    borderWidth: 1, 
    flex: 1,
    borderStyle: 'dashed', 
    borderColor: Colors.dark.alpha04,
    borderRadius: 15,
    marginTop: 12, 
    paddingVertical: 12,
    justifyContent: 'center', 
    alignItems: 'center',
    paddingHorizontal: 4
  }
})