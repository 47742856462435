
// let storageColors = getColors()

const Colors = {
  greenLight: {
    alpha1: 'rgba(125, 211, 129, 1)',
    alpha09: 'rgba(125, 211, 129,0.9)',
    alpha08: 'rgba(125, 211, 129,0.8)',
    alpha07: 'rgba(125, 211, 129,0.7)',
    alpha06: 'rgba(125, 211, 129,0.6)',
    alpha05: 'rgba(125, 211, 129,0.5)',
    alpha04: 'rgba(125, 211, 129,0.4)',
    alpha03: 'rgba(125, 211, 129,0.3)',
    alpha02: 'rgba(125, 211, 129,0.2)',
    alpha01: 'rgba(125, 211, 129,0.1)',
  },
  yellow: {
    alpha1: 'rgba(249, 226, 102,1)',
    alpha09: 'rgba(249, 226, 102,0.9)',
    alpha08: 'rgba(249, 226, 102,0.8)',
    alpha07: 'rgba(249, 226, 102,0.7)',
    alpha06: 'rgba(249, 226, 102,0.6)',
    alpha05: 'rgba(249, 226, 102,0.5)',
    alpha04: 'rgba(249, 226, 102,0.4)',
    alpha03: 'rgba(249, 226, 102,0.3)',
    alpha02: 'rgba(249, 226, 102,0.2)',
    alpha01: 'rgba(249, 226, 102,0.1)',
  },
  orange: {
    alpha1: 'rgba(255, 166, 0,1)',
    alpha09: 'rgba(255, 166, 0,0.9)',
    alpha08: 'rgba(255, 166, 0,0.8)',
    alpha07: 'rgba(255, 166, 0,0.7)',
    alpha06: 'rgba(255, 166, 0,0.6)',
    alpha05: 'rgba(255, 166, 0,0.5)',
    alpha04: 'rgba(255, 166, 0,0.4)',
    alpha03: 'rgba(255, 166, 0,0.3)',
    alpha02: 'rgba(255, 166, 0,0.2)',
    alpha01: 'rgba(255, 166, 0,0.1)',
  },
  badgeOrange: {
    alpha1: 'rgba(236, 112, 99, 1)',
    alpha09: 'rgba(236, 112, 99,0.9)',
    alpha08: 'rgba(236, 112, 99,0.8)',
    alpha07: 'rgba(236, 112, 99,0.7)',
    alpha06: 'rgba(236, 112, 99,0.6)',
    alpha05: 'rgba(236, 112, 99,0.5)',
    alpha04: 'rgba(236, 112, 99,0.4)',
    alpha03: 'rgba(236, 112, 99,0.3)',
    alpha02: 'rgba(236, 112, 99,0.2)',
    alpha01: 'rgba(236, 112, 99,0.1)',
  },
  badgeYellow: {
    alpha1: 'rgba(246, 214, 92, 1)',
    alpha09: 'rgba(246, 214, 92,0.9)',
    alpha08: 'rgba(246, 214, 92,0.8)',
    alpha07: 'rgba(246, 214, 92,0.7)',
    alpha06: 'rgba(246, 214, 92,0.6)',
    alpha05: 'rgba(246, 214, 92,0.5)',
    alpha04: 'rgba(246, 214, 92,0.4)',
    alpha03: 'rgba(246, 214, 92,0.3)',
    alpha02: 'rgba(246, 214, 92,0.2)',
    alpha01: 'rgba(246, 214, 92,0.1)',
  },
  red: {
    alpha1: 'rgba(255, 74, 50,1)',
    alpha09: 'rgba(255, 74, 50,0.9)',
    alpha08: 'rgba(255, 74, 50,0.8)',
    alpha07: 'rgba(255, 74, 50,0.7)',
    alpha06: 'rgba(255, 74, 50,0.6)',
    alpha05: 'rgba(255, 74, 50,0.5)',
    alpha04: 'rgba(255, 74, 50,0.4)',
    alpha03: 'rgba(255, 74, 50,0.3)',
    alpha02: 'rgba(255, 74, 50,0.2)',
    alpha01: 'rgba(255, 74, 50,0.1)',
  },
  secondaryBlue: {
    alpha1: 'rgba(30, 165, 252,1)',
    alpha09: 'rgba(30, 165, 252,0.9)',
    alpha08: 'rgba(30, 165, 252,0.8)',
    alpha07: 'rgba(30, 165, 252,0.7)',
    alpha06: 'rgba(30, 165, 252,0.6)',
    alpha05: 'rgba(30, 165, 252,0.5)',
    alpha04: 'rgba(30, 165, 252,0.4)',
    alpha03: 'rgba(30, 165, 252,0.3)',
    alpha02: 'rgba(30, 165, 252,0.2)',
    alpha01: 'rgba(30, 165, 252,0.1)',
  },
  primaryBlue: {
    alpha1: 'rgba(0, 91, 255,1)',
    alpha09: 'rgba(0, 91, 255,0.9)',
    alpha08: 'rgba(0, 91, 255,0.8)',
    alpha07: 'rgba(0, 91, 255,0.7)',
    alpha06: 'rgba(0, 91, 255,0.6)',
    alpha05: 'rgba(0, 91, 255,0.5)',
    alpha04: 'rgba(0, 91, 255,0.4)',
    alpha03: 'rgba(0, 91, 255,0.3)',
    alpha02: 'rgba(0, 91, 255,0.2)',
    alpha01: 'rgba(0, 91, 255,0.1)',
  },
  lightGray: {
    alpha1:  'rgba(241, 242, 246,1)',
    alpha09: 'rgba(241, 242, 246,0.9)',
    alpha08: 'rgba(241, 242, 246,0.8)',
    alpha07: 'rgba(241, 242, 246,0.7)',
    alpha06: 'rgba(241, 242, 246,0.6)',
    alpha05: 'rgba(241, 242, 246,0.5)',
    alpha04: 'rgba(241, 242, 246,0.4)',
    alpha03: 'rgba(241, 242, 246,0.3)',
    alpha02: 'rgba(241, 242, 246,0.2)',
    alpha01: 'rgba(241, 242, 246,0.1)',
  },
  white: {
    alpha1:  'rgba(255, 255, 255, 1)',
    alpha09: 'rgba(255, 255, 255,0.9)',
    alpha08: 'rgba(255, 255, 255,0.8)',
    alpha07: 'rgba(255, 255, 255,0.7)',
    alpha06: 'rgba(255, 255, 255,0.6)',
    alpha05: 'rgba(255, 255, 255,0.5)',
    alpha04: 'rgba(255, 255, 255,0.4)',
    alpha03: 'rgba(255, 255, 255,0.3)',
    alpha02: 'rgba(255, 255, 255,0.2)',
    alpha01: 'rgba(255, 255, 255,0.1)',
  },
  gray: {
    alpha1: '#434A54',
    alpha08: '#656D78',
    alpha06: '#A3A7AE',
    alpha04: '#C1C5C9',
    alpha02: '#E0E2E4',
    alpha01: '#F0F0F1'
  },
  dark: {
    alpha1: '#403D52',
    alpha08: '#8C8B97',
    alpha06: '#B3B1BA',
    alpha04: '#D9D8DC',
    alpha02: '#ECECEE'
  },
  purple: {
    alpha1: 'rgba(116,23,189,1)',
    alpha09: 'rgba(116,23,189,0.9)',
    alpha08: 'rgba(116,23,189,0.8)',
    alpha07: 'rgba(116,23,189,0.7)',
    alpha06: 'rgba(116,23,189,0.6)',
    alpha05: 'rgba(116,23,189,0.5)',
    alpha04: 'rgba(116,23,189,0.4)',
    alpha03: 'rgba(116,23,189,0.3)',
    alpha02: 'rgba(116,23,189,0.2)',
    alpha01: 'rgba(116,23,189,0.1)'
  }
}

// Colors = {
//   ...Colors,
//   storageColors
// }

export default Colors