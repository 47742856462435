import { View, StyleSheet, Text, TouchableOpacity } from 'react-native'
import React, { memo, useRef } from 'react'
import i18n from 'i18n-js'
import Colors from '../utils/Colors'
import FontStyles from '../utils/FontStyles'
import { Feather } from '@expo/vector-icons'
import { LinearGradient } from 'expo-linear-gradient'

const minBarHeight = 35
const width = 450

function ScoreDistributionCard({score, scoreDistribution, onInfoPressed}){

  const scoreData = useRef([
    { type: 'VIDEO', name: i18n.t("video"), color: "#EC7063"},
    { type: 'PODCAST', name: i18n.t("podcast"), color: "#005BFF"},
    { type: 'MINI-TEST', name: i18n.t("miniTest"), color: "#7417BD"},
    { type: 'REPORT', name: i18n.t("report"), color: "#F6D65C"},
  ]).current
  
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={[FontStyles.subhead, {fontSize: 14, marginLeft: 8, color: Colors.primaryBlue.alpha1, fontWeight: '700' }]}>
          {i18n.t('scoreDistribution')}
        </Text>
      </View>
      <Text style={[FontStyles.headline, {marginLeft: 8, color: Colors.dark.alpha1, marginTop: 4}]}>
        {i18n.t('scoreDistributionText1')}
        <Text style={[FontStyles.headline, {color: Colors.primaryBlue.alpha1}]}>
          {i18n.t('scoreDistributionText2', {score: score})}
        </Text>
        {i18n.t('scoreDistributionText3')}
      </Text>
      
      <View style={styles.graphContainer}>
        
        <View style={styles.maxLineContainer}>
          <View style={{ height: 2, flex: 1, marginRight: 8, borderRadius: 1, borderWidth: 1, borderColor: Colors.dark.alpha06, borderStyle: 'dashed', zIndex: 0, }}>
            <View style={{ position: 'absolute', left: 0, bottom: 0, width: '100%', height: 3, backgroundColor: 'white', zIndex: 1 }} />
          </View>
          <Text style={[FontStyles.footnote, {color: Colors.dark.alpha06, fontWeight: "600", marginTop: -8}]}>
            {score}
          </Text>
        </View>

        <View style={styles.barsContainer}>
          <LinearGradient start={[0.5,1]} end={[0.5,0]} style={styles.linearGradient} colors={[Colors.dark.alpha02, "rgba(255,255,255,0.0)"]}/>
          {
            scoreData.map((item, index) => {
              let typeScore = scoreDistribution[item.type]

              let itemHeight =  (typeScore == 0 || score == 0) ? minBarHeight : (minBarHeight + (typeScore/score)*((width*0.4 - minBarHeight)))
              return (
                <View key={index + "ttt"} style={[styles.bar, {height: itemHeight, backgroundColor: item.color}]}>
                  <Text style={[FontStyles.footnote, {color: Colors.white.alpha1, fontWeight: '600'}]}>
                    {(typeScore || 0) + "P"}
                  </Text>
                </View>
              )
            })
          }
        </View>

        <View style={styles.textContainer}>
          {
            scoreData.map((item, index) => {
              return (
                <Text key={index}  style={[FontStyles.subhead, {marginHorizontal: 6, textAlign: "center", flex: 1, color: Colors.dark.alpha06, fontWeight: '500'}]}>
                  {item.name}
                </Text>
              )
            })
            }
          </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: width - 48,
    paddingBottom: 12,
    paddingHorizontal: 16,
    backgroundColor: Colors.white.alpha1,
    marginTop: 12,
    borderRadius: 20,
    shadowColor: '#C4C4C4',
    shadowOffset: {width: 0, height: 4},
    shadowRadius: 7,
    shadowOpacity: 0.25,
    elevation: 9,
    overflow: 'hidden',
  },
  header: {
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingTop: 16,
    alignItems: "flex-end"
  },
  infoButton: {
    paddingLeft: 20,
    paddingTop: 16
  },

  //graph
  graphContainer: {
    width: width-48,
    marginLeft: -16,
    marginTop: 24,
  },
  maxLineContainer: {
    marginTop: -8,
    flexDirection: "row", 
    alignItems: "center", 
    position: "absolute", 
    top: 0, 
    alignSelf: "center",
    paddingHorizontal: 16,
    width: width - 48,
    
  },
  maxScoreLine: {
    borderStyle: 'dotted',
    borderWidth: 1,
    borderRadius: 1,
    flex: 1,
    borderStyle: "dashed",
  },
  linearGradient: {
    position: "absolute",
    bottom: 0,
    width: width - 48,
    left: 0,
    height: ((width - 48) * 0.25),
  },
  barsContainer: {
    width: width - 48,
    height: width*0.4,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    paddingHorizontal: 12,
  },
  bar: {
    flex: 1,
    marginHorizontal: 6,
    minHeight: minBarHeight,
    justifyContent: "flex-end",
    alignItems: "center",
    paddingBottom: 4,
    paddingTop: 4,
    borderTopRightRadius: minBarHeight/2,
    borderTopLeftRadius: 5,
  },
  textContainer: {
    marginTop: 10,
    paddingHorizontal: 12,
    width: width - 48,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  }
})

export default memo(ScoreDistributionCard)