import {PureComponent} from 'react';
import i18n from "i18n-js"
import { View, Text, StyleSheet } from 'react-native'
import FileDragDrop from "./FileDragDrop"
import Colors from "../utils/Colors"
import FontStyles from "../utils/FontStyles"
import { AntDesign } from '@expo/vector-icons'
import AddedFile from "./AddedFile";

export default class FileAdder extends PureComponent {
    constructor() {
        super()
        
        this.state = {
            file: null,
        }
    }
    
    onFileDrop = (file) => {
        this.setState({
            file: file,
        })
        this.props.onChange?.(file)
    }
    
    onRemove = () => {
        this.setState({
            file: null
        });
        this.props.onChange?.(null)
    }
    
    clear = () => {
        this.onRemove()
    }
    
    render(){
        return (
            <View style={{ marginTop: 24, width: '100%' }}>
                <Text style={[FontStyles.body.bold, { color: Colors.dark.alpha1 }]}>
                    {this.props.title || i18n.t('addFile')}
                </Text>
                <Text style={[FontStyles.callout.regular, { color: Colors.dark.alpha08, marginTop: 12 }]}>
                    {this.props.subText || i18n.t('addFileBody')}
                </Text>
                <FileDragDrop
                    containerStyle={styles.filePicker}
                    onDrop={this.onFileDrop}
                    multiple={typeof this.props.multiple === 'boolean' ? this.props.multiple : true}
                    acceptedFormats={this.props.acceptedFormats}
                >
                    <AntDesign
                        name='addfile'
                        size={32}
                        color={Colors.secondaryBlue.alpha1}
                    />
                    <Text style={[FontStyles.body.bold, { color: Colors.secondaryBlue.alpha1, marginTop: 16 }]}>
                        {i18n.t('pickFile')}
                    </Text>
                </FileDragDrop>
                <View style={{ marginTop: 16, width: '80%' }}>
                    {
                        this.state.file &&
                        <AddedFile
                            file={this.state.file.file}
                            type={'file'}
                            onRemove={this.onRemove}
                        />
                    }
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
  filePicker: {
    width: '80%',
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: Colors.dark.alpha06,
    justifyContent: 'center',
    alignItems :'center',
    paddingVertical: 24,
    borderRadius: 10,
    marginTop: 24
  }
})