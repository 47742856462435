import React, { memo, useState } from "react";
import { ScrollView, StyleSheet, View, Text, Pressable, TouchableOpacity, Animated } from "react-native";
import API from "../utils/API";
import Colors from "../utils/Colors";
import FontStyles from "../utils/FontStyles";
import {Feather, FontAwesome} from '@expo/vector-icons'
import moment from "moment";
import I18n from "i18n-js";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import { firstLetterToUpperCase } from '../utils/Helper'
import ReportFilter from '../components/ReportFilter'
import ReportReviewCard from "../components/ReportReviewCard";
import ReportsSeeAllModal from '../components/ReportsSeeAllModal';

const colors = [
  Colors.primaryBlue.alpha1,
  Colors.red.alpha1,
  Colors.badgeYellow.alpha1,
  Colors.greenLight.alpha1,
  Colors.purple.alpha1,
  Colors.gray.alpha1,
]

function generateChartData(data){
  if(data === null) return null

  let arr = []
  delete data.nameKey
  let keys = Object.keys(data)
  let total = 0


  for(let i = 0; i < keys.length; i++){
    total += data[keys[i]].value
    arr.push({...data[keys[i]], color: colors[i % colors.length ]})
  }

  arr.sort((a, b)=> {
    return a.value - b.value
  })


  return {data: arr, total: total}
}

const renderActiveShape = (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, percent, color, name } = props;

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <foreignObject
        width={150}
        height={150}
        x={cx - 75}
        y={cy - 75}
      >
        <View style={{alignItems: 'center', width: 150, height: '100%', justifyContent: 'center'}}>
          <Text style={[FontStyles.caption.medium, { color: Colors.dark.alpha1, textAlign: 'center'}]}>
            {name}
          </Text>
          <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 8}}>
            <View style={{width: 6, height: 6, borderRadius: 3, backgroundColor: color}}/>
            <Text style={[FontStyles.caption.bold, { color: Colors.dark.alpha1, marginLeft: 6}]}>
              {(percent * 100).toFixed(2) + '%'}

            </Text>
          </View>
        </View>
      </foreignObject>
    </g>
  );
};

const Chart = memo(function({ data, type }){
  const chartData = generateChartData(data)
  const [ activeIndex, setActiveIndex ] = useState(0)

  if(!chartData || chartData.data.length === 0){
    return null
  }


  return (
    <View  style={styles.graphContainer}>
      <Text style={[FontStyles.body.semibold, { color: Colors.dark.alpha1, textAlign: 'center', position: 'absolute', top: 24 }]}>
        {I18n.t(type)}
      </Text>
      <ResponsiveContainer width={'100%'} height={'100%'}>
        <PieChart >
          <Pie
            data={chartData.data}
            startAngle={90 + (360 * (chartData.data.at(-1).value / chartData.total)) / 2}
            endAngle={450 + (360 * (chartData.data.at(-1).value / chartData.total)) / 2}
            activeShape={renderActiveShape}
            activeIndex={activeIndex}
            innerRadius={85}
            outerRadius={110}
            dataKey="value"
            onMouseEnter={(_,index)=> setActiveIndex(index)}
          >
            {
              chartData.data.map((item,i)=> {
                return (
                  <Cell key={`cell-${i}`} fill={item.color}/>
                )
              })
            }
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </View>

  )
})

export default class Reports extends React.PureComponent{
  constructor(props){
    super(props)
    
    this.reportReviewVal = new Animated.Value(0)

    this.availableFilters = {
      type: {'ACCIDENT': {name: I18n.t('ACCIDENT')}, 'NEAR-MISS': { name: I18n.t('NEAR-MISS') }, 'THREAT': { name: I18n.t('THREAT') }},
      isCausedByBehaviour: { true: { name: I18n.t('isCausedByBehaviourTrue') }, false: { name: I18n.t('isCausedByBehaviourFalse') }},
      emergencyDegree: {'MODERATE': {name: I18n.t('MODERATE')}, 'LOW': { name: I18n.t('LOW') }, 'HIGH': { name: I18n.t('HIGH') }},
      yearsOfExperienceOfVictim: {'0-1': {name: I18n.t('0-1')}, '2-5': { name: I18n.t('2-5') }, '6+': { name: I18n.t('6+') }},
      incidentSites: {},
      incidentTypes: {},
      injuredPartsOfBody: {},
      injuryTypes: {},
      occupationsOfVictim: {},
      sexesOfVictim: {},

    }

    this.reports=[]

    this.state = {
      reports: [],
      pendingReports: [],
      graphData: null,
      acceptedReports: [],
      rejectedReports: [],
      activeFilterIndex: null,
      availableFilters: null,
      selectedFilters: null,
      mediaBasePath: ""
    }
  }

  componentDidMount(){
    this.getReports()
  }

  getReports = () => {
    API.getReports().then((reports)=> {
      const {graphData, allReports} = this.generateReportData(reports)
      this.generateFilterData(reports)
      this.reports = reports
      this.setState({ 
        reports: reports.reports,
        graphData: graphData, 
        mediaBasePath: reports.mediaBasePath,
        pendingReports: allReports.pending, 
        acceptedReports: allReports.accepted, 
        rejectedReports: allReports.rejected,
        availableFilters: this.availableFilters
      })
    })
  }

  generateFilterData = (reports) => {
    
    let keys = Object.keys(this.availableFilters)
    for(let i = 0; i < keys.length; i++){
      if(reports[keys[i]]){
        this.availableFilters[keys[i]] = reports[keys[i]]
      }
    }

  }

  generateReportData = (reports) => {
    let obj = {
      type: { nameKey: null },
      emergencyDegree: { nameKey: null },
      incidentSite: { nameKey: 'incidentSites' },
      incidentTypes: { nameKey: 'incidentTypes' },
      injuryType: {nameKey: 'injuryTypes' },
      injuredPartOfBody: {nameKey: 'injuredPartsOfBody'},
      sexOfVictim: { nameKey: 'sexesOfVictim' },
      occupationOfVictim: { nameKey: 'occupationsOfVictim' },
      yearsOfExperienceOfVictim: { nameKey: null },
      isCausedByBehaviour: { nameKey: 'isCausedByBehaviour' }
    }

    let allReports = {
      pending: [],
      accepted: [],
      rejected: []
    }

    for(let report of reports.reports){
      if(report.isAccepted === true){
        allReports.accepted.push(report)
      }else if(report.isAccepted === false){
        allReports.rejected.push(report)
      }else{
        allReports.pending.push(report)
      }

      for(let field in obj){
        let nameKey = obj[field].nameKey
        let current = report[field]
        if(current !== undefined && current !== null){
          if(Array.isArray(current)){
            current.forEach((item)=> {
              if(obj[field][item]){
                obj[field][item].value++
              }else{
                for(let key in reports[nameKey]){
                  for( let subtype in reports[nameKey][key].subtypes){
                    if(subtype == item){
                      let name = reports[nameKey][key].subtypes[subtype].name
                      obj[field][item] = { value: 1, name: name }
                      break
                    }
                  }
                }
              }
            })  
          }else{
            if(obj[field][current]){
              obj[field][current].value++
            }else{
              let name = nameKey == 'isCausedByBehaviour' ? I18n.t(nameKey + firstLetterToUpperCase(String(current))) : nameKey ? reports[nameKey][current].name : I18n.t(String(current))
              obj[field][current] = { value: 1, name: name }
            }
          }
        }
      }
    }
    return {graphData: obj, allReports: allReports}
  }


  onOpenReport = (item) => {
    this.setState({selectedReviewReport: item}, () => {
      this.reportReviewController(1)
    })
  }

  reportReviewController = (toValue, needRefresh) => {
    if(needRefresh){
      this.getReports()
    }
    Animated.spring(this.reportReviewVal, {
      toValue: toValue ? 1 : 0,
      useNativeDriver: true,
      delay: 50
    }).start()    
  }


  onSelectFilter = (item) => {
    if(!item){
      const { graphData } = this.generateReportData(this.reports)
      this.setState({ selectedFilters: null, graphData: graphData})
      return
    }

    const { type, filter, currentIndex } = item
    const keyObject = {
      incidentSites:'incidentSite' ,
      incidentTypes: 'incidentType',
      injuredPartsOfBody: 'injuredPartOfBody',
      injuryTypes: 'injuryType',
      occupationsOfVictim: 'occupationOfVictim',
      sexesOfVictim: 'sexOfVictim',
      type: 'type',
      isCausedByBehaviour: 'isCausedByBehaviour',
      emergencyDegree: 'emergencyDegree',
      yearsOfExperienceOfVictim: 'yearsOfExperienceOfVictim'
    }

    let filters = { ...this.state.selectedFilters }

    if(currentIndex === -1){
      if(filters[type]){
        filters[type].push(filter)
      }else{
        filters[type] = [filter]
      }
    }else{
      filters[type].splice(currentIndex, 1)
      if(filters[type].length === 0){
        delete filters[type]
      }
    }

    let reports = this.reports.reports
    for(let filter in filters){
      reports = reports.filter((report)=> {
        for(let i = 0; i < filters[filter].length; i++){
          if(filters[filter][i].key === String(report[keyObject[filter]])){
            return true
          }
        }

        return false
      })
    }



    if(reports.length === 0){
      this.setState({ selectedFilters: filters, graphData: null  })
    }else{
      const { graphData } = this.generateReportData({ ...this.reports, reports: reports })
      this.setState({ selectedFilters: filters, graphData: graphData  })
    }

  }
  
  
  downloadReports = () => {
    API.downloadAllReports()
        .then((response)=>{
          if(response.filename){
            let a = document.createElement("a");
            a.style = "display: none";
            document.body.appendChild(a);
  
            a.href = response.file;
            a.download = response.filename;
            a.click();
            window.URL.revokeObjectURL(response.file);
            a.remove();
          }
          else {
            window.open(response.file, "_blank");
          }
        })
        .catch((e)=>{
          alert(e.errorMessage);
        });
  }
  
  openSeeAllModal = (isAccepted) => {
    this.reportsSeeAllModal.setIsShowing(true, isAccepted)
  }
  
  render(){
    const pendingReportRows = [];
    
    for(let i = 0; i < 4; i++){
      pendingReportRows.push([this.state.pendingReports[i*2], this.state.pendingReports[i*2+1]])
    }
    
    return (
      <View style={styles.container}>
        <ScrollView 
          style={styles.container}
          contentContainerStyle={{ padding: 24 }}
          showsHorizontalScrollIndicator={false}
        >
          <View style={{ flexDirection: 'row',  width: '100%', justifyContent: 'space-between' }}>
            <View style={{ flex: 1, paddingRight: 54 }}>
              <View style={{ flexDirection: 'row', width : '100%' }}>
                <Text style={[FontStyles.title2.medium, { color: Colors.dark.alpha1, flex: 1 }]}>
                  {I18n.t('pendingReports') + ` (${this.state.pendingReports.length})`}
                </Text>
              </View>
              <View style={{ marginTop: 24 }}>
                {this.state.pendingReports.length !== 0 ?
                    
                    pendingReportRows.map((row, index) => <View style={styles.pendingReportRow} key={'pending-report-row-'+index}>
                      {
                        row.map((item, index2) => {
                          if(!item){
                            return <View key={''+index+'-'+index2+'elt'} style={[styles.reportRow, {borderBottomWidth: 0}]}/>
                          }
                          
                          return (<View key={item.id} style={styles.reportRow}>
                          <Feather
                              name='clock'
                              size={24}
                              color={Colors.badgeYellow.alpha1}
                          />
                          <View style={{marginLeft: 12, flex: 1}}>
                            <Text style={[FontStyles.body.medium, { color: Colors.dark.alpha1 }]}>
                              {I18n.t(item.type)}
                            </Text>
                            <Text style={[FontStyles.caption.regular, { color: Colors.dark.alpha1, marginTop: 6 }]}>
                              {item.submittingEmployee.firstName + ' ' + item.submittingEmployee.lastName + ' • ' + moment(item.createdAt).utcOffset(API.timezoneOffset).format('DD.MM.YY  HH:mm') }
                            </Text>
                          </View>
                          <Pressable style={styles.reviewButton} onPress={()=> this.onOpenReport(item)}>
                            <Text style={[FontStyles.footnote.medium, { color: Colors.white.alpha1 }]}>
                              {I18n.t('review')}
                            </Text>
                          </Pressable>
                        </View>)
                        })
                      }
                    </View>)
                    
                  :
                  <Text style={[FontStyles.body.medium, { color: Colors.dark.alpha08 }]}>
                    {'Bekleyen bir raporunuz bulunmamaktadır.'}
                  </Text>
                }
              </View>
            </View>
            <View style={{ flexDirection: 'column', marginLeft:16, }}>
              {
                  this.state.reports.length !== 0 &&
                  <TouchableOpacity activeOpacity={0.8} style={styles.downloadDataButton} onPress={this.downloadReports}>
                    <FontAwesome name="file-excel-o" size={21} color={Colors.white.alpha1} style={{ marginRight: 8}} />
                    <Text style={[FontStyles.footnote,{color:Colors.white.alpha1}]}>
                      {I18n.t('downloadAllReports')}
                    </Text>
                  </TouchableOpacity>
              }
              
              <View style={[styles.reportSummaryContainer, {marginTop: 24, alignSelf: 'stretch'}]}>
                <View style={{flexDirection: 'row', alignItems: 'center', alignSelf: 'center',}}>
                  <Text style={[FontStyles.body.medium, { color: Colors.dark.alpha08 }]}>
                    {'Onaylanan: '}
                  </Text>
                  <Text style={[FontStyles.body.medium, { color: Colors.dark.alpha08, marginLeft: 8 }]}>
                    {this.state.acceptedReports.length}
                  </Text>
                </View>
                <Pressable style={styles.seeAllReports} onPress={()=>this.openSeeAllModal(true)}>
                  <Text style={[FontStyles.subhead.medium, { color: Colors.white.alpha1 }]}>
                    {I18n.t('seeAll')}
                  </Text>
                </Pressable>
              </View>
  
              <View style={[styles.reportSummaryContainer, {marginTop: 24, alignSelf: 'stretch'}]}>
                <View style={{flexDirection: 'row', alignItems: 'center', alignSelf: 'center',}}>
                  <Text style={[FontStyles.body.medium, { color: Colors.dark.alpha08 }]}>
                    {'Reddedilen: '}
                  </Text>
                  <Text style={[FontStyles.body.medium, { color: Colors.dark.alpha08, marginLeft: 8 }]}>
                    {this.state.rejectedReports.length}
                  </Text>
                </View>
                <Pressable style={styles.seeAllReports} onPress={()=>this.openSeeAllModal(false)}>
                  <Text style={[FontStyles.subhead.medium, { color: Colors.white.alpha1 }]}>
                    {I18n.t('seeAll')}
                  </Text>
                </Pressable>
              </View>
              
            </View>
          </View>
          <Text style={[FontStyles.title2.medium, { color: Colors.dark.alpha1, marginTop: 36 }]}>
            {'Bildirim Grafikleri'}
          </Text>
          <View style={styles.filterContainer}>
            {
              this.state.availableFilters &&
              Object.keys(this.state.availableFilters).map((key, index)=> {
                return (
                  <ReportFilter
                    data={this.state.availableFilters[key]}
                    selectedFilters={this.state.selectedFilters ? this.state.selectedFilters[key] : null}
                    key={key}
                    title={key}
                    onSelect={this.onSelectFilter}
                    onPress={()=> this.setState({ activeFilterIndex: this.state.activeFilterIndex === index ? null : index })}
                    isActive={this.state.activeFilterIndex === index}
                    index={index}
                  />
                )
              })
            }
          </View>
          <View style={styles.addedFiltersContainer}>
            {
              this.state.selectedFilters &&
              Object.keys(this.state.selectedFilters).map((key, index)=> {
                return (
                  this.state.selectedFilters[key].map((filter, index)=> {
                    return (
                      <View key={filter.name + key} style={styles.addedFilter}>
                        <Text style={[FontStyles.caption.medium, { color: Colors.white.alpha1 }]}>
                          {filter.name}
                        </Text>
                      </View>
                    )
                  })
                )
              })
            }
            {
              this.state.selectedFilters && Object.keys(this.state.selectedFilters).length !== 0 ?
              <Pressable style={styles.removeFilterButton} onPress={()=>this.onSelectFilter(null)}>
                <Text style={[FontStyles.body, { color: Colors.primaryBlue.alpha1, textDecorationLine: 'underline' }]}>
                  {I18n.t('clearFilters')}
                </Text>
              </Pressable>
              : null
            }
          </View>
          <View style={{width: '100%', flexDirection: 'row', flexWrap: 'wrap'}}>
            
            {
              this.state.graphData ?
              Object.keys(this.state.graphData).map((item, index)=> {
                return (
                  <Chart 
                    data={this.state.graphData[item]}
                    type={item}
                    key={item}
                  />
                )
              })
              :
              <Text style={[FontStyles.body.medium, { color: Colors.dark.alpha08, marginTop: 24 }]}>
                {I18n.t('noReportFilter')}
              </Text>
            }
          </View>
        </ScrollView>
        
        <ReportsSeeAllModal
            mediaBasePath={this.state.mediaBasePath}
            reports={this.state.reports}
            onOpenReport={this.onOpenReport}
            ref={ref => this.reportsSeeAllModal = ref}
        />

        <ReportReviewCard
          mediaBasePath={this.state.mediaBasePath}
          reportReviewVal={this.reportReviewVal}
          selectedReviewReport={this.state.selectedReviewReport}
          reportReviewController={this.reportReviewController}
          navigation={this.props.navigation}
        />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F3FAFF',
    borderRadius: 15,
    width: '100%',
    overflow: 'hidden',
  },
  rowButton: {
    width: '80%',
    paddingVertical: 12,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: Colors.red.alpha08,
    shadowColor: Colors.greenLight.alpha1,
    shadowOpactiy: 0.5,
    shadowOffset: {
      width: 1,
      height: 1,
    },
    shadowRadius: 12
  },
  reviewButton: {
    paddingHorizontal: 15,
    paddingVertical: 7,
    borderRadius: 25,
    backgroundColor: Colors.primaryBlue.alpha1,
    alignSelf: 'center'
  },
  reportRow: {
    flexDirection: 'row', 
    paddingVertical: 16, 
    borderBottomWidth: 1, 
    borderColor: Colors.dark.alpha04,
    alignItems: 'center',
    flex: 0.45,
  },
  reportSummaryContainer: {
    paddingHorizontal: 24,
    paddingVertical: 16,
    backgroundColor: 'white',
    borderRadius: 15,
    alignSelf: 'baseline'
  },
  graphContainer: {
    paddingHorizontal: 24,
    borderRadius: 15,
    marginRight: 24,
    width: '31%',
    minWidth: 270,
    height: 350,
    paddingTop: 36,
    backgroundColor: 'white',
    justifyContent: 'center',
    marginTop: 24,
    alignItems: 'center',
    shadowColor: '#C4C4C4',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.15, 
    shadowRadius: 10,
  },
  seeAllReports: {
    backgroundColor: Colors.primaryBlue.alpha1,
    borderRadius: 10,
    paddingVertical: 8,
    paddingHorizontal: 24,
    marginTop: 24,
    justifyContent: 'center',
    alignItems: 'center',
  },
  filterContainer: {
    flexDirection: 'row', 
    width: '100%', 
    marginTop: 24, 
    zIndex: 100,
    flexWrap: 'wrap'
  },
  addedFilter: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderRadius: 6,
    backgroundColor: Colors.primaryBlue.alpha05,
    marginRight: 8,
    alignItems: 'center'
  },
  addedFiltersContainer: {
    flexDirection: 'row', 
    flexWrap: 'wrap', 
    marginTop: 24, 
    alignItems: 'center' 
  },
  downloadDataButton:{
    borderRadius:10,
    backgroundColor:Colors.greenLight.alpha1,
    paddingHorizontal:16,
    justifyContent:'center',
    alignItems:'center',
    flexDirection:'row',
    paddingVertical: 12,
    height: 40,
    alignSelf: 'flex-start',
  },
  pendingReportRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 12,
  }
})