import { memo } from "react";
import { StyleSheet, View, Text, Image } from "react-native";
import FontStyles from "../utils/FontStyles";
import Colors from "../utils/Colors";

function LeaderboardUser({ user, index, score, ranking, photo }){
  console.log(user)
  return (
    <View style={[styles.userContainer, {borderTopWidth: index !== 0 ? 1.5 : 0, borderColor: Colors.lightGray.alpha1}]}>
      <View style={styles.imageContainer}>
        <Image 
          source={user.photo ? { uri: photo } : require('../assets/icons/user_dummy_image.png')}
          style={[styles.userImage, {tintColor: user.photo ? null : Colors.gray.alpha06}]}
          resizeMode='cover'
        />
      </View>
      
      <View style={{ marginLeft: 15, flex: 1 }}>
        <Text style={[FontStyles.subhead.medium, {color: Colors.dark.alpha1, letterSpacing: -0.5}]}>
          {`${user.firstName} ${user.lastName}`}
        </Text>
        <View style={{flexDirection: 'row', marginTop: 4, flexWrap: 'wrap'}}>
          <Text style={[FontStyles.caption.medium, {color: Colors.dark.alpha08,}]}>
            {score}
          </Text>
          {
            user.tags.map((tag)=> {
              if(tag.id === "000000000000000000000000") return null
              return (
                <View style={{flexDirection: 'row', marginLeft: 6, alignItems: 'center'}}>
                  <View style={{width: 4, height: 4, borderRadius: 2, backgroundColor: tag.colorHex}}/>
                  <Text style={[FontStyles.caption.medium, { color: tag.colorHex, marginLeft: 2}]}>
                    {tag.name}
                  </Text>
                </View>
              )
            })
          }
        </View>
      </View>
      <Text style={[FontStyles.title3.medium, {color: Colors.dark.alpha08, marginHorizontal: 6}]}>
        #{ranking}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  userContainer: {
    width: '100%',
    flexDirection: 'row',
    paddingVertical: 10,
    alignItems: 'center',
    flex: 1,
  },
  userImage: {
    width: 40,
    height: 40,
    borderRadius: 12,
    opacity: 1
  },
  imageContainer: {
    backgroundColor: Colors.gray.alpha01, 
    borderRadius: 12, 
    alignSelf: "baseline"
  }
})

export default memo(LeaderboardUser)