import React, { memo, useRef } from "react";
import { Pressable, StyleSheet, View, Text, Animated, Alert, ActivityIndicator } from "react-native";
import FontStyles from "../utils/FontStyles";
import { Feather } from "@expo/vector-icons";
import Colors from "../utils/Colors";
import I18n from "i18n-js";
import API from "../utils/API";

const iconSet = {
  'VIDEO': { name: 'play-circle', color: Colors.badgeYellow.alpha1 },
  'PODCAST': { name: 'mic', color: Colors.badgeYellow.alpha1 },
  'MINI-TEST': { name: 'edit-2', color: Colors.primaryBlue.alpha1 },
  'SURVEY': { name: 'file-text', color: Colors.secondaryBlue.alpha1 },
  'CONTEST': { name: 'target', color: Colors.purple.alpha1 }
}

const ITEM_HEIGHT = 54

const Item = memo(
  function(props){
    const heightAnimValue = useRef(new Animated.Value(0)).current
    const shouldOpen = useRef(true)

    const animateHeight = () => {
      Animated.timing(heightAnimValue, {
        toValue: shouldOpen.current ? props.height : 0,
        duration: 300,
        useNativeDriver: false
      }).start(()=> {
        shouldOpen.current = !shouldOpen.current
      })
    }

    const rotateAnimation = {
      transform: [
        {
          rotate: heightAnimValue.interpolate({
            inputRange: [0, props.height],
            outputRange: ['0deg', '-90deg'],
            extrapolate: 'clamp'
          })
        }
      ]
    }

    return(
      <View style={{width: '100%', overflow: 'hidden'}}>
        <Pressable onPress={animateHeight} style={styles.header}>
          <View style={{ flexDirection: 'row', width: '100%', alignItems: 'center' }}>
            <Animated.View style={[styles.indicator, rotateAnimation]}>
              <Feather name='chevron-left' size={18} color={Colors.primaryBlue.alpha1}/>
            </Animated.View>
            <Text style={[FontStyles.body.medium, { color: Colors.dark.alpha1, flex: 1 }]}>
              {props.package.name}
            </Text>
            <Pressable style={styles.selectButton} onPress={()=>props.onSelect(props.index)}>
              <View style={styles.selectButtonInner}>
                {
                  props.isSelected &&
                  <View style={styles.selected}/>
                }
              </View>
            </Pressable>
          </View>
          <View style={{ flexDirection: 'row', marginTop: 12 }}>
            {
              Object.keys(props.typeCount).map((item, index)=> {
                return (
                  <View key={index + item + props.parentIndex} style={{flexDirection: 'row', marginLeft: index !== 0 ? 12 : 0, alignItems: 'center'}}>
                    <Feather name={iconSet[item].name} size={14} color={iconSet[item].color}/>
                    <Text style={[FontStyles.footnote.regular, { color: Colors.dark.alpha06, marginLeft: 4 }]}>
                      {props.typeCount[item] + ' ' + I18n.t(item).toLocaleLowerCase()}
                    </Text>
                  </View>
                )
              })
            }
          </View>
        </Pressable>   
        <Animated.View style={{ height: heightAnimValue }}>
          {
            props.package.contents.map((item, index)=> {
              return (
                <Pressable 
                  key={item.id + props.parentIndex} 
                  style={styles.button}
                  onPress={()=> props.onPressRow(item)}
                >
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Feather name={iconSet[item.type].name} size={18} color={iconSet[item.type].color}/>
                    <Text style={[FontStyles.subhead.regular, { color: Colors.dark.alpha1, marginLeft: 8 }]}>
                      {item.title}
                    </Text>
                  </View>
                </Pressable>
              )
            })
          }
        </Animated.View>
      </View>
    )
  }
)

export default class EducationPackages extends React.PureComponent{
  constructor(props){
    super(props)
    this.errorOpacityValue = new Animated.Value(0)
    this.state = {
      addedPackages: [],
      loading: false
    }
  }

  componentDidUpdate(_, prevState){
    if(!prevState.addedPackages.length && this.state.addedPackages.length){
      this.animateErrorText(0)
    }
  }
  
  onRowPressed = (index) => {
    console.log(index)
    let arr = [...this.state.addedPackages]
    if(this.state.addedPackages.includes(index)){
      arr.splice(arr.indexOf(index), 1)
    }else{
      arr.push(index)
    }

    this.setState({ addedPackages: arr })
  }

  getContentCountByType = (data) => {
    let obj = {}

    data.forEach((item)=> {
      obj[item.type] = (obj[item.type] || 0) + 1 
    })

    return obj
  }

  animateErrorText = (value) => {
    Animated.timing(this.errorOpacityValue, {
      toValue: value,
      duration: 250,
      useNativeDriver: false
    }).start()
  }

  addPressed = async () => {
    
    if(!this.state.addedPackages.length){
      this.animateErrorText(1)
      return
    }

    try{
      if(confirm(`Seçtiğiniz eğitim paketlerini ${this.props.date.format('YYYY-MM')} ayına eklemek istediğinize emin misiniz?`)){
        this.setState({ loading: true })
          await this.props.addEducationPackage(this.state.addedPackages, this.props.index)
          this.setState({ loading: false })
      }
    }catch(e){
      this.setState({ loading: false })
      this.props.handleAlert({ 
        isShowing: true, 
        type: 'warning', 
        text: e.errorMessage
      })
    }
  } 

  render(){
    return (
      <View style={{ flex: 1 }}>
        <View style={styles.headerContainer}>
          <Text style={[FontStyles.title2.semibold, { color: Colors.dark.alpha1 }]}>
            {I18n.t('themes')}
          </Text>
          <Pressable style={styles.closeButton} onPress={this.props.handleAvailablePackagePress}>
            <Feather name="x" size={18} color={Colors.white.alpha1}/>
          </Pressable>
        </View>
        {
          this.props.packages.length ?
          <View style={{ marginTop: 16 }}>
            {
              this.props.packages.map((p, index)=> {
                const typeCount = this.getContentCountByType(p.contents)
                return (
                  <Item
                    height={ITEM_HEIGHT * p.contents.length}
                    package={p}
                    index={index}
                    key={p.id + this.props.index}
                    typeCount={typeCount}
                    parentIndex={this.props.index}
                    onPressRow={this.props.onPressRow}
                    isSelected={this.state.addedPackages.includes(index)}
                    onSelect={this.onRowPressed}
                    
                  />
                )
              })
            }  
          </View>
          :
          <Text style={[FontStyles.subhead.regular, { color: Colors.dark.alpha08, marginTop: 16, lineHeight: 22 }]}>
            {I18n.t('noAvailablePackage')}
          </Text>
        }

        <View style={styles.addButtonContainer}>
          <Animated.Text style={[FontStyles.caption.bold, { color: Colors.red.alpha1, opacity: this.errorOpacityValue, marginBottom: 6, textAlign: 'center' }]}>
            {I18n.t('noThemeSelected')}
          </Animated.Text>
          <Pressable 
            disabled={this.state.loading} 
            onPress={this.addPressed} 
            style={[styles.addButton, { backgroundColor: this.state.addedPackages.length !== 0 ? Colors.primaryBlue.alpha1 : Colors.dark.alpha04 }]}
          >
            {
              this.state.loading ? 
              <ActivityIndicator color={'white'}/>
              :
              <Text style={[ FontStyles.body.medium, { color: 'white' } ]}>
                {I18n.t('add')}
              </Text>
            }
          </Pressable>  
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  row: {
    width: '100%',
    paddingHorizontal: 12,
    paddingVertical: 16
  },
  button: {
    borderBottomWidth: 1, 
    borderColor: Colors.dark.alpha04, 
    width: '100%',
    height: ITEM_HEIGHT,
    justifyContent: 'center'
  },
  container: {
    width: '100%'
  },
  header: {
    height: ITEM_HEIGHT + 32, 
    justifyContent: 'center', 
    width: '100%',
    borderBottomWidth: 1,
    borderColor: Colors.dark.alpha04,
  },
  indicator: {
    width: 26,
    height: 26,
    borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.lightGray.alpha1,
    marginRight: 12
  },
  closeButton: {
    width: 30,
    height: 30,
    borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.primaryBlue.alpha1,
    alignSelf: 'flex-end'
  },
  headerContainer: {
    flexDirection: 'row', 
    width: '100%', 
    alignItems: 'center', 
    justifyContent: 'space-between'
  },
  addButton: {
    width: '100%',
    borderRadius: 10,
    height: 54,
    justifyContent: 'center',
    alignItems: 'center'
  },
  selectButton: {
    width: 30,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center'
  },
  selectButtonInner: {
    width: 16,
    height: 16,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.dark.alpha04,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 2
  },
  selected: {
    width: '100%',
    height: '100%',
    borderRadius: 100,
    backgroundColor: Colors.primaryBlue.alpha1
  },
  addButtonContainer: {
    position: 'absolute',
    bottom: 12,
    width: '100%'
  }
})