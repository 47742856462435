import { View, Image, StyleSheet } from "react-native";
import Animated, { useAnimatedStyle } from 'react-native-reanimated'
import Colors from "../../../utils/Colors";

function AnimatedImage (props){

  const warningOpacity = useAnimatedStyle(()=> {
    return {
      opacity: props.opacity.value
    }
  })

  const successOpacity = useAnimatedStyle(()=> {
    return {
      opacity: props.successOpacity?.value || 0
    }
  })

  return(
    <View style={{ zIndex: -1, width: props.width, height: props.height }}>
      <Image 
        source={props.imageSource} 
        style={styles.image} 
        resizeMode={'contain'}
      />
      <Animated.Image 
        source={props.warningImageSource} 
        style={[styles.image, { position: 'absolute'}, warningOpacity]} 
        resizeMode={'contain'}
      />
      {
        props.successImageSource &&
        <Animated.Image 
          source={props.successImageSource} 
          style={[styles.image, { position: 'absolute'}, successOpacity]} 
          resizeMode={'contain'}
        />
      }

    </View>
  )
}

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: 'white',
      flexDirection: 'row'
    },
    inputContainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center'
    },
    input: {
      width: '55%',
      minWidth: 250,
      backgroundColor: Colors.lightGray.alpha1,
      color: Colors.gray.alpha08,
      borderRadius: 20,
      height: 64,
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      maxWidth: 360
    },
    languageContainer: {
      width: 120,
      justifyContent: 'space-between',
      flexDirection: 'row',
      position: 'absolute',
      bottom: '3%'
    },
    button: {
      marginTop: 20, 
      width: '55%', 
      minWidth: 250,
      maxWidth: 360
    },
    image: {
      width: '100%',
      height : '100%'
    }
  })

  export default AnimatedImage