import I18n from "i18n-js";
import { memo, useEffect, useState } from "react";
import { StyleSheet, View, Image, Text, Pressable } from "react-native";
import Colors from "../utils/Colors";
import FontStyles from "../utils/FontStyles";
import moment from "moment";
import { hexToRgba } from "../utils/Helper";
import API from "../utils/API";
import { useNavigation } from "@react-navigation/native";

function ContestCard(props){
  const [ activeContests, setActiveContests ] = useState([])
  const navigation = useNavigation()

  useEffect(()=> {

    async function getContests(){
      try {
        const { contests } = await API.getContests()
        const activeContests = contests.filter((contest, index)=> {
          return contest.endsAt > new Date().getTime()
        })

        setActiveContests(activeContests)
      }catch(e){
        console.log(e)
      }
    }

    getContests()
  },[])

  return (
    <View style={styles.container}>
      <Image 
        source={require('../assets/icons/contest-icon.png')}
        style={styles.icon}
      />
      {
        activeContests[0] ? 
        <>
          <Text style={[FontStyles.subhead.medium, { color: Colors.dark.alpha08, marginTop: 18 }]}>
            {I18n.t('contestEndsAt')}
          </Text>
          <Text style={[FontStyles.title2.semibold, { color: Colors.purple.alpha1, marginTop: 8 }]}>
            {moment(activeContests[0].endsAt).utcOffset(API.timezoneOffset).fromNow()}
          </Text>
        </>
        :
        <View style={{width: '100%', paddingHorizontal: 24, alignItems: 'center'}}>
          <Text style={[FontStyles.body.medium, { color: Colors.purple.alpha1, marginTop: 18 }]}>
            {I18n.t('noActiveContest')}
          </Text>
          <Text style={[FontStyles.subhead.medium, { color: Colors.dark.alpha08, marginTop: 8, textAlign: 'center', lineHeight: 24 }]}>
            {I18n.t('noActiveContestSubText')}
          </Text>
        </View>
      }

      <View style={styles.surveyButtonContainer}>
        <Pressable style={styles.surveyButton} onPress={()=> {
          if(activeContests[0]){
            props.leaderboardDetailsController(true, activeContests[0].id)
          }else{
            navigation.navigate('CreateContest')
          }
          
        }}>
          <Text style={[FontStyles.subhead.medium, { color: Colors.purple.alpha1 }]}>
            {activeContests[0] ? I18n.t('seeDetails') : I18n.t('startContest')}
          </Text>
        </Pressable>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    minWidth: 280,
    flex: 1,
    shadowColor: '#C4C4C4',
    borderRadius: 20,
    alignSelf: "baseline",
    backgroundColor: 'white',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.15,
    shadowRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 15
  },
  icon: {
    width: 80,
    height: 80,
    marginTop: 36
  },
  surveyButton: {
    width: '80%',
    height: 48,
    backgroundColor:  hexToRgba('#7417BD', 0.05),
    borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 24,
    alignSelf: 'center'
  },
  surveyButtonContainer:{
    flex: 1, 
    width: '100%', 
    justifyContent: 'flex-end', 
    paddingHorizontal: 24, 
    marginTop: 36
  }
})


export default memo(ContestCard)