import * as Device from 'expo-device';
import Constants  from 'expo-constants';
import { Dimensions } from 'react-native';

const devicesWithNotch = [
  {
    brand: 'Apple',
    model: 'iPhone 13 mini',
  },
  {
    brand: 'Apple',
    model: 'iPhone 13',
  },
  {
    brand: 'Apple',
    model: 'iPhone 13 Pro',
  },
  {
    brand: 'Apple',
    model: 'iPhone 13 Pro Max',
  },
  {
    brand: 'Apple',
    model: 'iPhone 12 mini',
  },
  {
    brand: 'Apple',
    model: 'iPhone 12',
  },
  {
    brand: 'Apple',
    model: 'iPhone 12 Pro',
  },
  {
    brand: 'Apple',
    model: 'iPhone 12 Pro Max',
  },
  {
    brand: 'Apple',
    model: 'iPhone 11',
  },
  {
    brand: 'Apple',
    model: 'iPhone 11 Pro',
  },
  {
    brand: 'Apple',
    model: 'iPhone 11 Pro Max',
  },
  {
    brand: 'Apple',
    model: 'iPhone X',
  },
  {
    brand: 'Apple',
    model: 'iPhone XS',
  },
  {
    brand: 'Apple',
    model: 'iPhone XS Max',
  },
  {
    brand: 'Apple',
    model: 'iPhone XR',
  },
  {
    brand: 'Asus',
    model: 'ZenFone 5',
  },
  {
    brand: 'Asus',
    model: 'ZenFone 5z',
  },
  {
    brand: 'google',
    model: 'Pixel 3 XL',
  },
  {
    brand: 'google',
    model: 'Pixel 4a',
  },
  {
    brand: 'Huawei',
    model: 'P20',
  },
  {
    brand: 'Huawei',
    model: 'P20 Plus',
  },
  {
    brand: 'Huawei',
    model: 'P20 Lite',
  },
  {
    brand: 'Huawei',
    model: 'ANE-LX1',
  },
  {
    brand: 'Huawei',
    model: 'INE-LX1',
  },
  {
    brand: 'Huawei',
    model: 'POT-LX1',
  },
  {
    brand: 'Huawei',
    model: 'Honor Play',
  },
  {
    brand: 'Huawei',
    model: 'Honor 10',
  },
  {
    brand: 'Huawei',
    model: 'Mate 20 Lite',
  },
  {
    brand: 'Huawei',
    model: 'Mate 20 Pro',
  },
  {
    brand: 'Huawei',
    model: 'ELE-L29', // P30
  },
  {
    brand: 'Huawei',
    model: 'P30 Lite',
  },
  {
    brand: 'Huawei',
    model: 'P30 Pro',
  },
  {
    brand: 'Huawei',
    model: 'JNY-LX1', // P40 Lite
  },
  {
    brand: 'Huawei',
    model: 'Nova 3',
  },
  {
    brand: 'Huawei',
    model: 'Nova 3i',
  },
  {
    brand: 'Leagoo',
    model: 'S9',
  },
  {
    brand: 'LG',
    model: 'G7',
  },
  {
    brand: 'LG',
    model: 'G7 ThinQ',
  },
  {
    brand: 'LG',
    model: 'G7+ ThinQ',
  },
  {
    brand: 'LG',
    model: 'LM-Q910', //G7 One
  },
  {
    brand: 'LG',
    model: 'LM-G710', //G7 ThinQ
  },
  {
    brand: 'LG',
    model: 'LM-V405', //V40 ThinQ
  },
  {
    brand: 'Motorola',
    model: 'Moto g7 Play',
  },
  {
    brand: 'Motorola',
    model: 'Moto g7 Power',
  },
  {
    brand: 'Motorola',
    model: 'One',
  },
  {
    brand: 'Motorola',
    model: 'Motorola One Vision',
  },
  {
    brand: 'Nokia',
    model: '5.1 Plus',
  },
  {
    brand: 'Nokia',
    model: 'Nokia 6.1 Plus',
  },
  {
    brand: 'Nokia',
    model: '7.1',
  },
  {
    brand: 'Nokia',
    model: '8.1',
  },
  {
    brand: 'OnePlus',
    model: '6',
  },
  {
    brand: 'OnePlus',
    model: 'A6003',
  },
  {
    brand: 'ONEPLUS',
    model: 'A6000',
  },
  {
    brand: 'OnePlus',
    model: 'OnePlus A6003',
  },
  {
    brand: 'OnePlus',
    model: 'ONEPLUS A6010',
  },
  {
    brand: 'OnePlus',
    model: 'ONEPLUS A6013',
  },
  {
    brand: 'OnePlus',
    model: 'ONEPLUS A6000',
  },
  {
    brand: 'Oppo',
    model: 'R15',
  },
  {
    brand: 'Oppo',
    model: 'R15 Pro',
  },
  {
    brand: 'Oppo',
    model: 'F7',
  },
  {
    brand: 'Oukitel',
    model: 'U18',
  },
  {
    brand: 'Redmi',
    model: 'M2004J19C',
  },
  {
    brand: 'Sharp',
    model: 'Aquos S3',
  },
  {
    brand: 'Vivo',
    model: 'V9',
  },
  {
    brand: 'Vivo',
    model: 'X21',
  },
  {
    brand: 'Vivo',
    model: 'X21 UD',
  },
  {
    brand: 'xiaomi',
    model: 'MI 8',
  },
  {
    brand: 'xiaomi',
    model: 'MI 8 Explorer Edition',
  },
  {
    brand: 'xiaomi',
    model: 'MI 8 SE',
  },
  {
    brand: 'xiaomi',
    model: 'MI 8 UD',
  },
  {
    brand: 'xiaomi',
    model: 'MI 8 Lite',
  },
  {
    brand: 'xiaomi',
    model: 'Mi 9',
  },
  {
    brand: 'xiaomi',
    model: 'POCO F1',
  },
  {
    brand: 'xiaomi',
    model: 'POCOPHONE F1',
  },
  {
    brand: 'xiaomi',
    model: 'Redmi 6 Pro',
  },
  {
    brand: 'xiaomi',
    model: 'Redmi Note 7',
  },
  {
    brand: 'xiaomi',
    model: 'Redmi 7',
  },
  {
    brand: 'xiaomi',
    model: 'Redmi Note 8',
  },
  {
    brand: 'xiaomi',
    model: 'Redmi Note 8 Pro',
  },
  {
    brand: 'xiaomi',
    model: 'Mi A2 Lite',
  },
  {
    brand: 'Blackview',
    model: 'A30',
  },
  {
    brand: 'Samsung',
    model: 'SM-A217F',
  },
  {
    brand: 'Samsung',
    model: 'SM-A715F',
  },
];

let notch = undefined
let _notchHeight = undefined
let tablet = undefined
let statusBarHeight = Constants.statusBarHeight
export const { width, height } = Dimensions.get('screen')

function hasNotch() {
  if (notch === undefined) {
    let _brand = Device.brand;
    let _model = Device.modelName;
    notch = devicesWithNotch.findIndex((item) => item.brand.toLowerCase() === _brand.toLowerCase() && item.model.toLowerCase() === _model.toLowerCase()) !== -1;
    console.log("hasNotch:" + notch); 
  }
  return notch;
}

function notchHeight() {
  if (_notchHeight === undefined) {
    let _brand = Device.brand;
    let _model = Device.modelName;
    _notchHeight = devicesWithNotch.findIndex((item) => item.brand.toLowerCase() === _brand.toLowerCase() && item.model.toLowerCase() === _model.toLowerCase()) !== -1 ? 34 : 0;
    console.log("notchHeight:" + _notchHeight); 
  }
  return _notchHeight;
}

async function isTablet() { 
  if(tablet === undefined) {
    let _DeviceType = await Device.getDeviceTypeAsync();
    tablet = "TABLET" === Device.DeviceType[_DeviceType];
    console.log("istablet: " + tablet)
    console.log("deviceType: " + Device.DeviceType[_DeviceType])
  }
  return tablet
}

export default { 
  hasNotch, 
  isTablet, 
  notchHeight, 
  statusBarHeight,
}
