import { memo, useState } from "react";
import { View, StyleSheet, Image, Text, Pressable, ActivityIndicator } from "react-native";
import FontStyles from "../../utils/FontStyles";
import Colors from "../../utils/Colors";
import Animated, { Extrapolate, interpolate, useAnimatedStyle } from "react-native-reanimated";
import {Feather} from '@expo/vector-icons'
import I18n from "i18n-js";
import { Link } from "@react-navigation/native";
import API from "../../utils/API";

const selectedBg = '#E9F6FF'
const footerBg = '#F3FAFF'
const buttonHeight = 60
const buttonMargin = 10

const MenuButton = memo(
  function({ item, opacityValue, index }){

    const opacityAnim = useAnimatedStyle(() =>{

      const op = interpolate(
        opacityValue.value, 
        [index - 1, index, index + 1],
        [0, 1, 0],
        Extrapolate.CLAMP
      )

      return {
        opacity: op
      }
    })

    return(
      <Link to={item.screenName}>
        <View style={[styles.menuButton, { marginTop: index == 0 ? 0 : buttonMargin }]} >
          <Feather name={item.icon} size={22} color={Colors.gray.alpha08}/>
          <Text style={[FontStyles.body.medium, { color: Colors.gray.alpha08, marginLeft: 16,  }]}>
            {I18n.t(item.name)}
          </Text>
          <Animated.View style={[styles.selectedIndicator, opacityAnim]}>
            <Feather name={item.icon} size={22} color={Colors.primaryBlue.alpha1}/>
            <Text style={[FontStyles.body.medium, { color: Colors.primaryBlue.alpha1, marginLeft: 16 }]}>
              {I18n.t(item.name)}
            </Text>
          </Animated.View>
        </View>
      </Link>
    )
  }
)

const Footer = memo(
  function(){
    const [ loading, setLoading ] = useState(false)

    return (
        <Pressable 
          onPress={()=> {
            setLoading(true)
            API.logout()
          }} 
          disabled={loading}
          
          style={{ flexDirection: 'row', width: '100%', paddingLeft: 24, alignItems: 'center', marginBottom: '2%' }}
        >
          {
            loading ? 
            <ActivityIndicator color={Colors.gray.alpha08}/>
            :
            <Feather name='log-out' size={22} color={Colors.gray.alpha08}/>
          }
          <Text style={[FontStyles.body.medium, { color: Colors.gray.alpha08, marginLeft: 16,  }]}>
            {I18n.t('logout')}
          </Text>
        </Pressable>
    )
  }
)

function Menu(props){
  const [ selected, setSelected ] = useState(0)

  const menuButtons = Object.keys(props.menuButtons).map((item)=> {
    return props.menuButtons[item]
  })

  const transformIndicator = useAnimatedStyle(()=> {
    const y = interpolate(
      props.menuButtonAnimValue.value,
      [0, menuButtons.length - 1],
      [0, (buttonHeight + buttonMargin) * (menuButtons.length - 1)],
      Extrapolate.CLAMP
    )
    return {
      transform: [
        {
          translateY: y
        }
      ]
    }
  })

  return (
    <View style={[styles.container]}>
      <View style={styles.headerContainer}>
        <Image
          source={require('../../assets/home/home-1.png')}
          style={styles.image}
        />
        <Text style={[FontStyles.title3.bold, { color: Colors.primaryBlue.alpha1, }]}>
          {'Safe & Sound'}
        </Text>
      </View>
      <View style={{flex: 1, justifyContent: 'center'}}>
        <View >
          <Animated.View style={[styles.selectedIndicator, transformIndicator, { backgroundColor: selectedBg }]}/>
          {
            menuButtons.map((item, index)=> {
              return (
                <MenuButton
                  item={item}
                  key={index + 'menuButton' + item.name}
                  index={index}
                  opacityValue={props.menuButtonAnimValue}
                  onPress={()=> setSelected(index)}
                  selected={selected}
                  
                />
              )
            })
          }
        </View>
      </View>
      <Footer/>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    minWidth: 200,
    maxWidth: 240,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: "space-between"
  },
  image: {
    width: 56,
    height: 35
  },
  menuButton: {
    width: '100%',
    height: buttonHeight,
    borderRadius: 20,
    flexDirection: 'row',
    paddingLeft: 24,
    alignItems: 'center',
  },
  selectedIndicator: {
    left: 0,
    right: 0,
    height: buttonHeight,
    position: 'absolute',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 24,
    borderRadius: 20
  },
  footer: {
    padding: 17.5,
    backgroundColor: footerBg,
    borderRadius: 20,
  },
  footerButton: {
    width: '50%',
    height: 40,
    borderRadius: 50,
    marginTop: 16,
    alignSelf: 'center'
  }
})


export default memo(Menu)