import { memo, useEffect, useState } from "react";
import { StyleSheet, View, Text, Pressable } from "react-native";
import {Feather} from '@expo/vector-icons'
import FontStyles from "../utils/FontStyles";
import Colors from "../utils/Colors";
import I18n from "i18n-js";
import moment from "moment";
import InfoCardHeader from "./InfoCardHeader";
import API from '../utils/API'
import { useNavigation } from "@react-navigation/native";

function SurveyCard(props){
  const [ surveys, setSurveys ] = useState([])
  const navigation = useNavigation()

  useEffect(()=> {
    async function getSurveyDetails(){
      try{
        const { surveys } = await  API.getSurveys()
        const surveyDetailPromiseArray = [];
        for(let i = 0; i < Math.min(2, surveys.length); i++){
          surveyDetailPromiseArray.push(API.getSurveyDetails(surveys[i].id));
        }
        const surveyArr = await Promise.all(surveyDetailPromiseArray)
        setSurveys(surveyArr)
      }catch(e){
        
      }
    }

    getSurveyDetails()
  },[])

  return (
    <View style={styles.container}>
      <InfoCardHeader
        iconName={'bar-chart-2'}
        title={I18n.t('survey')}
        subText={I18n.t('surveySubText')}
        arrowContainerColor={'#E9F6FF'}
        arrowColor={Colors.secondaryBlue.alpha1}
        iconContainerColor={Colors.secondaryBlue.alpha1}
        borderColor={Colors.secondaryBlue.alpha02}
        onPress={()=> navigation.navigate('Surveys')}
      />
      <View style={{ paddingHorizontal: 24, width: '100%', flex: 1 }}>
        {
          surveys.length !== 0 ?
          surveys.map(({survey}, index)=> {
            return (
              <View style={[styles.surveyContainer, {borderTopWidth: index !== 0 ? 2 : 0, borderColor: Colors.gray.alpha02}]} key={'t' + index}>
                <Text style={[FontStyles.footnote.regular, { color: Colors.dark.alpha08, lineHeight: 24 }]}>
                  {I18n.t('activeSurveyText1', { startsAt: moment(survey.startsAt).utcOffset(API.timezoneOffset).format('DD MMMM'), name: survey.title})}
                  <Text style={[FontStyles.footnote.semibold, { color: Colors.secondaryBlue.alpha1, }]}>
                    {I18n.t('activeSurveyText2', {completedCount: survey.participantCount})}
                  </Text>
                  {I18n.t('activeSurveyText3', {userPercentage: `%${((survey.participantCount / survey.eligibleUserCount) * 100).toFixed(0)}`})}
                  <Text style={[FontStyles.footnote.semibold, { color: Colors.secondaryBlue.alpha1,}]}>
                    {I18n.t('activeSurveyText4', {leftDay: moment(survey.endsAt).utcOffset(API.timezoneOffset).diff(new Date().getTime(), 'days')})}
                  </Text>
                  {I18n.t('activeSurveyText5')}
                </Text>
              </View>
            )
          })
          :
          <View style={styles.cardContainer}>
            <View style={styles.emptyContainer}>
              <Feather
                name="bar-chart-2"
                size={32}
                color={Colors.gray.alpha06}

              />
              <Text style={[FontStyles.callout.medium, {color: Colors.gray.alpha06, marginTop: 12}]}>
                {I18n.t('surveyEmpty')}
              </Text>
            </View>

            <Pressable style={styles.surveyButton} onPress={()=> navigation.navigate('Surveys')}>
              <Text style={[FontStyles.callout.medium, { color: Colors.secondaryBlue.alpha1, }]}>
                {I18n.t('manageSurveys')}
              </Text>
            </Pressable>
          </View>
        }
      </View>
      
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minWidth: 280,
    shadowColor: '#C4C4C4',
    borderRadius: 20,
    alignItems: 'center',
    backgroundColor: 'white',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.15,
    shadowRadius: 10,
    marginTop: 15
  },
  header: {
    flexDirection: 'row',
    width: '100%',
    alignItems: "center",
    borderBottomWidth: 2,
    borderColor: Colors.secondaryBlue.alpha03,
    paddingVertical: 17.5,
    paddingHorizontal: 17.5,
  },
  iconContainer: {
    width: 56,
    height: 56,
    borderRadius: 35,
    backgroundColor: Colors.secondaryBlue.alpha1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  arrowContainer: {
    backgroundColor: '#E9F6FF', 
    width: 48,
    height: 48,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center'
  },
  surveyContainer: {
    paddingVertical: 16,
  },
  surveyButton: {
    width: '80%',
    height: 48,
    backgroundColor: '#E9F6FF',
    borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 24,
    marginTop: 36
  },
  cardContainer: {
    alignItems :'center',
    flex: 1, 
    width: '100%', 
    justifyContent: 'center', 
  },
  emptyContainer: {
    flex: 1, 
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 36
  }
})

export default memo(SurveyCard)