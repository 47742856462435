
import { FlatList, StyleSheet, View, Pressable, Text, Dimensions } from 'react-native'
import React, { memo, useState } from 'react'
import API from '../utils/API.js';
import FontStyles from '../utils/FontStyles'
import Colors from '../utils/Colors'
import { Feather } from '@expo/vector-icons'
import moment from 'moment'
import I18n from 'i18n-js'

function createCalenderData(){
  let arr = []
  for(let i = 0; i < 12; i++){
    arr.push(moment().startOf('month').add(i,'month'))
  }

  return arr 
}

const days = [
  'mon',
  'tue',
  'wed',
  'thu',
  'fri',
  'sat',
  'sun'
]

const DayButton = memo( function({
  getRangeStyle,
  startsAt,
  endsAt,
  monthIndex,
  day,
  onPressDay,
  width,
  color
  }){

  const [ borderWidth, setBorderWidth ] = useState(0)

  return (
    <View 
      style={[styles.dayButtonContainer, {width: width, height: width}, getRangeStyle(monthIndex, day)]}         
      onMouseEnter={()=> {
        if(!(monthIndex === 0 && (day + 1) < moment().date())){
          setBorderWidth(1)
        }
      }}
      onMouseLeave={()=> {
        if(!(monthIndex === 0 && (day + 1) < moment().date())){
          setBorderWidth(0)
        }
      }}
    >
      <Pressable 
        disabled={monthIndex === 0 && (day + 1) < moment().date()}
        style={[styles.dayButton, { borderWidth: borderWidth, borderColor: color, backgroundColor: startsAt?.monthIndex === monthIndex && startsAt.day === day + 1 || endsAt?.monthIndex === monthIndex && endsAt.day === day + 1 ? color : 'transparent'}]} 
        onPress={()=> onPressDay({ monthIndex: monthIndex, day: day + 1 })}

      >
        <Text style={[FontStyles.callout.semibold , { textAlign: 'center', color: startsAt?.monthIndex === monthIndex && startsAt.day === day + 1 || endsAt?.monthIndex === monthIndex && endsAt.day === day + 1 ? Colors.white.alpha1 : monthIndex === 0 && (day + 1) < moment().date() ? Colors.gray.alpha02 : Colors.dark.alpha1 }]}>
          {day + 1}
        </Text>
      </Pressable>
    </View>
  )
})

const initialWidth = 330
const headerHeight = 54

//props 
//isSingle: boolean (default: false) -> if true date picker will show only 1 month if false 2 months at the same time
//monthWidth: number (default: 330) -> width of the one month. If isSingle prop is not true, total width of date picker would be 2 * width
//enableRange: boolean (default: false) -> 
//onSelectionChange: function -> callback function on date change
//colors: array of colors -> first color is used for selected dates and second 
//containerStyle 

export default class DatePicker extends React.PureComponent{
  constructor(props){
    super(props)
    
    this.months = createCalenderData()
    this.currentDate = moment().utcOffset(API.timezoneOffset).format('D/M/YYYY')
    this.width = Dimensions.get('window').width
    this.listener = null

    this.state = {
      currentIndex: 0,
      startsAt: null,
      endsAt: null,
      datePickerWidth: this.props.monthWidth || initialWidth,
      isSingle: this.props.isSingle,
      isShowing: false
    }
  }
  
  clear = () => {
    this.currentDate = moment().utcOffset(API.timezoneOffset).format('D/M/YYYY')
    this.width = Dimensions.get('window').width
    this.listener = null
  
    this.setState({
      currentIndex: 0,
      startsAt: null,
      endsAt: null,
      datePickerWidth: this.props.monthWidth || initialWidth,
      isSingle: this.props.isSingle,
      isShowing: false
    })
  }

  onPressDay = (object) => {
    const { monthIndex, day } = object

    if(this.state.startsAt?.monthIndex === monthIndex && this.state.startsAt?.day === day){
      return
    }

    if(!this.props.enableRange){
      let selectedDate = this.months[monthIndex].clone()
      selectedDate.set('date', day )
      object['date'] = selectedDate
      this.setState({ startsAt: object },()=> {
        this.props.onSelectionChange && this.props.onSelectionChange(selectedDate)
      })
    }else{
      if(!this.state.startsAt){
        let selectedDate = this.months[monthIndex].clone()
        selectedDate.set('date', day )
        object['date'] = selectedDate
        this.setState({ startsAt: object },()=> {
          this.props.onSelectionChange && this.props.onSelectionChange({ startsAt: selectedDate })
        })
      }else if(!this.state.endsAt){
        const { monthIndex: firstMonth, day: firstDay } = this.state.startsAt
  
        if(monthIndex < firstMonth || monthIndex == firstMonth && day < firstDay){
          let selectedDate = this.months[monthIndex].clone()
          selectedDate.set('date', day )
          object['date'] = selectedDate
  
          this.setState({ startsAt: object, endsAt: this.state.startsAt },()=> {
            this.props.onSelectionChange && this.props.onSelectionChange({ startsAt: selectedDate, endsAt: this.state.endsAt.date })
          })
        }else{
          let selectedDate = this.months[monthIndex].clone()
          selectedDate.set('date', day )
          object['date'] = selectedDate
  
          this.setState({ endsAt: object },()=> {
            
            this.props.onSelectionChange && this.props.onSelectionChange({ endsAt: selectedDate })
          })
        }
      }else{
        let selectedDate = this.months[monthIndex].clone()
        selectedDate.set('date', day)
        object['date'] = selectedDate
  
        this.setState({ startsAt: object, endsAt: null },()=> {
          this.props.onSelectionChange && this.props.onSelectionChange({ startsAt: selectedDate })
        })
      }
    }

    
  }

  getRangeStyle = (index, i) => {
    if(this.state.startsAt && this.state.endsAt){
      const { monthIndex: firstMonth, day: firstDay } = this.state.startsAt
      const { monthIndex: lastMonth, day: lastDay } = this.state.endsAt

      if(firstMonth == lastMonth && ((firstMonth == index && i >= firstDay - 1) && (lastMonth == index && i <= lastDay - 1))){
        return { 
          backgroundColor: this.props.colors[1], 
          borderTopLeftRadius: index == firstMonth && i == firstDay - 1 ? 100 : 0,
          borderBottomLeftRadius: index == firstMonth && i == firstDay - 1 ? 100 : 0,
          borderTopRightRadius: index == lastMonth && i == lastDay - 1 ? 100 : 0,
          borderBottomRightRadius: index == lastMonth && i == lastDay - 1 ? 100 : 0,

        }
      }else if(firstMonth != lastMonth && ((firstMonth == index && i >= firstDay - 1) || (lastMonth == index && i <= lastDay - 1))){
        return { 
          backgroundColor: this.props.colors[1],  
          borderTopLeftRadius: index == firstMonth && i == firstDay - 1 ? 100 : 0,
          borderBottomLeftRadius: index == firstMonth && i == firstDay - 1 ? 100 : 0,
          borderTopRightRadius: index == lastMonth && i == lastDay - 1 ? 100 : 0,
          borderBottomRightRadius: index == lastMonth && i == lastDay - 1 ? 100 : 0,
        }
      }
    }

    return {backgroundColor: 'transparent'}
  }

  renderMonths = ({ item, index }) => {
    return (
      <View style={[styles.listItemContainer, { width: this.state.datePickerWidth }]}>
        <Text style={[ FontStyles.body.semibold, { color: Colors.dark.alpha1, textAlign: 'center' } ]}>
          { item.format('MMMM YYYY') }
        </Text>
        <View style={[styles.dayContainer,]}>
          {
            days.map((day, index)=> {
              return (
                <Text 
                  key={'d' + index} 
                  style={[FontStyles.subhead.medium, { color: Colors.dark.alpha08, width: (this.state.datePickerWidth - 48) / 7, textAlign: 'center', flex: 1, fontSize: 14 }]}
                >
                  {I18n.t(day)}
                </Text>
              )
            })
          }
        </View>
        <View style={styles.daysContainer}>
          <View style={{ width: (item.day() - 1) * ((this.state.datePickerWidth - 48) / 7), marginTop: 0 }}/>
          {
            new Array(item.daysInMonth()).fill(0).map((_, i)=> {
              return (
                <DayButton
                  getRangeStyle={this.getRangeStyle}
                  monthIndex={index}
                  width={(this.state.datePickerWidth - 48) / 7}
                  day={i}
                  onPressDay={this.onPressDay}
                  startsAt={this.state.startsAt}
                  endsAt={this.state.endsAt}
                  key={i + 'dbtn'}
                  color={this.props.colors[0]}
                />
              )
            })
          }
        </View>
      </View>
    )
  }

  componentDidUpdate(_, prevState){
    if(this.state.currentIndex !== prevState.currentIndex){
      this.listRef.scrollToIndex({index: this.state.currentIndex, animated: true, viewOffset: 0.5})
    }
  } 

  onNextPress = (val) => {
    if(this.state.currentIndex + val < 0 || this.state.currentIndex + val > 11){
      return
    }

    this.setState({currentIndex: this.state.currentIndex + val})
  }
  

  render(){
    return (
      <View style={[styles.container, { width: this.state.isSingle ? this.state.datePickerWidth  : this.state.datePickerWidth * 2 }, this.props.containerStyle]}>
        <Pressable 
          style={styles.openButton}
          onPress={()=> this.setState({ isShowing: !this.state.isShowing })}
        >
          <Text style={[this.state.isSingle ? FontStyles.subhead.medium : FontStyles.body.medium, {color: Colors.dark.alpha1,  textAlign: this.props.enableRange ? 'center' : 'left' }]}>
            {I18n.t('startsAt', { date: this.state.startsAt?.date.format(this.state.isSingle ? 'DD/MM/YY' : 'DD MMMM YYYY') || I18n.t('addDate') })}
          </Text>
          {
            this.props.enableRange &&
            <Text style={[ this.state.isSingle ? FontStyles.subhead.medium : FontStyles.body.medium, { color: Colors.dark.alpha1, textAlign: 'center' }]}>
              {I18n.t('endsAt', { date: this.state.endsAt?.date.format(this.state.isSingle ? 'DD/MM/YY' : 'DD MMMM YYYY') || I18n.t('addDate') })}
            </Text>
          }
          {
            !(this.state.isSingle && this.props.enableRange) &&
            <Feather 
              name={this.state.isShowing ? 'x' : 'chevron-down'}
              style={{ position: 'absolute', right: 24 }}
              size={20}
              color={Colors.dark.alpha1}
            />
          }
        </Pressable>
        {
          this.state.isShowing &&
          <>
            <FlatList
              ref={ ref => this.listRef = ref }
              horizontal
              style={styles.datePicker}
              data={this.months}
              renderItem={this.renderMonths}
              scrollEnabled={false}
              windowSize={9}
              contentContainerStyle={{ paddingVertical: 16 }}
              keyExtractor={(item, index)=> index + 'sd'}
              getItemLayout={(data, index) => (
                {length: this.state.datePickerWidth, offset: this.state.datePickerWidth * index, index}
              )}
            />
            <View style={styles.header}>
              <Pressable style={styles.nextButton} onPress={()=> this.onNextPress(-1)}>
                <Feather
                  name='chevron-left'
                  size={22}
                  color={this.state.currentIndex === 0 ? Colors.dark.alpha06 : Colors.dark.alpha1}
                />
              </Pressable>
              <Pressable style={styles.nextButton} onPress={()=> this.onNextPress(1)}>
                <Feather
                  name='chevron-right'
                  size={22}
                  color={this.state.currentIndex === 11 ? Colors.dark.alpha06 : Colors.dark.alpha1}
                />
              </Pressable>
            </View>
          </>
        }
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white.alpha1,
    borderRadius: 15,
    overflow: 'hidden',

  },
  datePicker: {
    flex: 1,
    width: '100%'
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 24,
    position: 'absolute',
    top: headerHeight + 16
  },
  nextButton: {
    height: '100%',
    alignItems: 'center',
  },
  dayContainer: {
    flexDirection: 'row',
    width: '100%', 
    marginTop: 24
  },
  dayButton: {
    width: '100%',
    borderRadius: 100, 
    height: '100%',
    justifyContent :'center', 
    alignItems: 'center',
    paddingVertical: 4
  },
  dayButtonContainer: {
    alignItems: 'center', 
    marginTop: 2,  
    justifyContent: 'center',
  },
  listItemContainer: {
    flex: 1, 
    paddingHorizontal: 24,
  },
  daysContainer: {
    flex: 1,  
    flexDirection: 'row', 
    flexWrap: 'wrap',
    width: '100%',
    marginTop: 12,
    alignContent: 'flex-start'
  },
  openButton: {
    backgroundColor: Colors.white.alpha1,
    borderRadius: 10,
    flexDirection: 'row',
    height: headerHeight,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 24
  }
})