import React, { memo } from "react";
import {
  Animated,
  StyleSheet,
  Pressable,
  ActivityIndicator,
  View,
  Text,
  ScrollView,
  FlatList,
  TouchableOpacity,
} from 'react-native';
import Colors from "../utils/Colors";
import { BlurView } from "expo-blur";
import {Feather, FontAwesome} from '@expo/vector-icons'
import {height} from '../utils/DeviceInfo.js';
import FontStyles from "../utils/FontStyles";
import API from "../utils/API";
import moment from "moment";
import I18n from "i18n-js";
import LeaderboardUser from "./LeaderboardUser";
import Question from "./Question";

export default class ContestDetails extends React.PureComponent{
  constructor(props){
    super(props)
    this.animationValue = new Animated.Value(0)
    this.lastContestId = null

    this.state={
      isShowing: false,
      data: null,
      mediaBasePath: null,
      rankings: [],
      questions: [],
      type: null
    }
  }

  setIsShowing = (isShowing, id, miniTestData) => {
    if(isShowing){
      this.setState({ isShowing: isShowing, data: this.lastContestId === id ? this.state.data : null, rankings: this.lastContestId === id ? this.state.rankings : null, type: !miniTestData ? 'CONTEST' : 'MINI-TEST'}, ()=> {
        
        Animated.spring(this.animationValue, {
          toValue: 1,
          duration: 200,
          delay: 0,
          useNativeDriver: false,
          bounciness: 5,
        }).start()
  
        if(this.lastContestId !== id){
          this.getContestDetails(id, miniTestData)
          this.lastContestId = id
        }
      })

    }else{
      Animated.spring(this.animationValue, {
        toValue: 0,
        duration: 200,
        delay: 0,
        useNativeDriver: false,
        bounciness: 5,
      }).start(()=> {
        this.setState({ isShowing: isShowing })
      })
    }
  }

  getContestDetails = async (id, miniTestData) => {
    let data = null
    let rankings = []
    let mediaBasePath = ''
    let q = []
    if(!miniTestData){
      const { contest, questions } = await API.getContestDetails(id)

      if(contest.startsAt < new Date().getTime()){
        const r =  await API.getContestRankings(id) 
        rankings = r.rankings
        mediaBasePath = r.mediaBasePath
      }

      data = {...contest, type: 'CONTEST'}
      q = questions

    }else{
      const { miniTest, questions } = await API.getMiniTestDetails(id)
      const r = await API.getGamificationData(1, 100, true)
      data = {...miniTest, eligibleUserCount: miniTestData.eligibleUserCount, startsAt: miniTestData.startsAt, endsAt: miniTestData.endsAt, type: 'MINI-TEST'}
      q = questions
      rankings = r.rankings
      mediaBasePath = r.mediaBasePath
    }

    this.setState({
      data: data,
      rankings: rankings,
      questions: q,
      mediaBasePath: mediaBasePath,
    })
  }

  getStatus = () => {
    if(this.state.data){
      if(this.state.data.endsAt > new Date().getTime() && this.state.data.startsAt < new Date().getTime()){
        return { translation: 'active', color: Colors.greenLight.alpha1 }
      }else if(this.state.data.endsAt > new Date().getTime() &&  this.state.data.startsAt >= new Date().getTime()){
        return { translation: 'pending', color: Colors.badgeYellow.alpha1 }
      }else if(new Date().getTime() > this.state.data.endsAt){
        return { translation: 'inactive', color: Colors.red.alpha1 }
      }
    }
    return { translation: null, color: null }
  }

  renderUserRow = ({ item, index }) => {
    return (
      <LeaderboardUser
        user={item.user}
        index={index}
        score={item.score}
        photo={this.state.mediaBasePath + '~' + item.user.photo}
        ranking={item.ranking}
      />
    )
  }
  
  downloadRankings = () => {
    API.downloadContestRankings(this.state.data.id)
        .then((response)=>{
          if(response.filename){
            let a = document.createElement("a");
            a.style = "display: none";
            document.body.appendChild(a);
          
            a.href = response.file;
            a.download = response.filename;
            a.click();
            window.URL.revokeObjectURL(response.file);
            a.remove();
          }
          else {
            window.open(response.file, "_blank");
          }
        })
        .catch((e)=>{
          alert(e.errorMessage);
        });
  }

  render(){
    console.log(this.state.data)
    if(!this.state.isShowing){
      return null
    }

    const { translation, color } = this.getStatus()

    return(
      <Animated.View style={[styles.container, {
        transform: [{translateY: this.animationValue.interpolate({
            inputRange: [0,1],
            outputRange: [1.25 * height, 0]
          })}]
        
      }]}>
        <BlurView 
          style={styles.blur} 
          intensity={100} 
          tint={'light'}
        >
          <View style={styles.loadingContainer}>
            {
              this.state.data ? 
              <View style={[styles.contentContainer, {justifyContent: this.state.rankings.length !== 0 ? 'flex-start' : 'center'}]}>
                <View style={{ height: '100%', width: this.state.rankings.length !== 0 ? '45%' : '60%' }}>
                  <Text style={[ FontStyles.title2.semibold, { marginBottom: 12, color: Colors.dark.alpha1 } ]}>
                    {this.state.data.type === 'CONTEST' ? I18n.t('contestDetails') : I18n.t('miniTestDetails')}
                  </Text>
                  <ScrollView 
                    style={[styles.innerContainer]}
                    contentContainerStyle={{ padding: 24 }}
                    showsVerticalScrollIndicator={false}
                  >
                    <Text style={[ FontStyles.title3.medium, { color: Colors.dark.alpha1 } ]}>
                      {this.state.data.title}
                    </Text>
                    <View style={styles.infoContainer}>
                      <View style={styles.infoTextContainer}>
                        <Feather
                          name="calendar"
                          size={18}
                          color={Colors.dark.alpha08}
                        />
                        <Text style={[ FontStyles.subhead.medium, styles.infoText ]}>
                          {moment(this.state.data.startsAt).utcOffset(API.timezoneOffset).format('DD MMM YYYY') + ' - ' + moment(this.state.data.endsAt).format('DD MMM YYYY') }
                        </Text>
                      </View>
                      <View style={styles.infoTextContainer}>
                        <Feather
                          name='users'
                          size={18}
                          color={Colors.dark.alpha08}
                          
                        />
                        <Text style={[FontStyles.subhead.medium, styles.infoText]}>
                          {this.state.data.participantCount + ' / ' + this.state.data.eligibleUserCount}
                        </Text>
                      </View>
                      <View style={[styles.activeIndicator, { backgroundColor: color }]}>
                        <Text style={[FontStyles.footnote.medium, { color: 'white' }]}>
                          {I18n.t(translation)}
                        </Text>
                      </View>
                    </View>
                    { this.state.data.tags &&
                      this.state.data.tags.map((tag, index)=> {
                        return (
                          <View key={tag.id} style={[styles.tagContainer, {backgroundColor: tag.colorHex }]}>
                            <Text style={[FontStyles.footnote.medium, { color: 'white' }]}>
                              {tag.name}
                            </Text>
                          </View> 
                        )
                      })
                    }
                    {
                      this.state.data.description &&
                      <Text style={[FontStyles.callout.regular, styles.descriptionText]}>
                        {this.state.data.description}
                      </Text>
                    }
                    {
                      this.state.questions.map((question, index)=> {
                        return (
                          <Question
                            question={question}
                            key={question.id}
                            participantCount={this.state.data.participantCount}
                            index={index}
                            type={this.state.type}
                          />
                        )
                      })
                    }
                  </ScrollView>
                </View>
                
                {
                  this.state.rankings.length !== 0 &&
                  <View style={styles.leaderboardContainer}>
                    <TouchableOpacity activeOpacity={0.8} style={styles.downloadDataButton} onPress={this.downloadRankings}>
                      <FontAwesome name="file-excel-o" size={21} color={Colors.white.alpha1} style={{ marginRight: 8}} />
                      <Text style={[FontStyles.footnote,{color:Colors.white.alpha1}]}>
                        {I18n.t('downloadAllRankings')}
                      </Text>
                    </TouchableOpacity>
                    <Text style={[FontStyles.title2.semibold, { color: Colors.dark.alpha1, marginBottom: 12 }]} >
                      {I18n.t('leaderboardTitle')}
                    </Text>
                    <FlatList
                      style={styles.leaderboard}
                      data={this.state.rankings}
                      keyExtractor={item => item.user.id}
                      renderItem={this.renderUserRow}
                      showsVerticalScrollIndicator={false}
                      contentContainerStyle={{paddingVertical: "2%", paddingHorizontal: "4%"}}
                      initialNumToRender={16}
                      updateCellsBatchingPeriod={100}
                      maxToRenderPerBatch={30}
                      removeClippedSubviews={true}
                    />
                  </View>
                }
              </View>
              :
              <>
                <ActivityIndicator color={Colors.dark.alpha1} size='large'/>
                <Text style={[FontStyles.body.medium, { color: Colors.dark.alpha1, marginTop: 16 }]}>
                  {this.state.type === 'CONTEST' ? I18n.t('contestLoading') : I18n.t('miniTestLoading')}
                </Text>
              </>
            }
          </View>
          {
            this.state.data &&
            <Pressable style={[styles.closeButton, {backgroundColor: this.state.type === 'CONTEST' ? Colors.purple.alpha1 : Colors.primaryBlue.alpha1}]} onPress={() => this.setIsShowing(false)}>
              <Feather name={"x"} color={Colors.white.alpha1} size={28}/>
            </Pressable>
          }
          
        </BlurView>
      </Animated.View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
  },
  blur: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  closeButton: {
    borderRadius: 222,
    height: 48,
    width: 48,
    position: "absolute",
    right: "3.5%",
    top: "4%",
    alignSelf: "flex-end",
    justifyContent: "center",
    alignItems: "center",
  },
  loadingContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flex: 1,
  },
  contentContainer: {
    flex: 1,
    width: '100%',
    paddingHorizontal: '4%',
    paddingVertical: '3.5%',
    flexDirection: 'row',
  },
  innerContainer: {
    width: '100%',    
    flex: 1,
    backgroundColor: Colors.white.alpha1,
    borderRadius: 17.5,
  },
  activeIndicator: { 
    paddingHorizontal: 12,
    paddingVertical: 4,
    borderRadius: 100,
    marginTop: 16
  },
  optionContainer: {
    width: '100%',
  
  },
  percentageContainer: {
    width: '100%',
    borderRadius: 5,
    height: 8,
    backgroundColor: Colors.lightGray.alpha1,
    overflow: 'hidden',
    marginTop: 8
  },
  percentage: {
    height: '100%',
    borderRadius: 5,
  },
  infoContainer: {
    flexDirection: 'row', 
    alignItems: 'center', 
    flexWrap: 'wrap',
  },
  infoTextContainer: {
    flexDirection: 'row', 
    marginTop: 16, 
    marginRight: 32, 
    alignItems: 'center'
  },
  infoText: {
    color: Colors.dark.alpha08, 
    marginLeft: 8, 
    fontSize: 14
  },
  leaderboard: {
    flex: 1, 
    borderRadius: 17.5, 
    backgroundColor: Colors.white.alpha1
  },
  leaderboardContainer: {
    width: '35%', 
    minWidth: 250, 
    height: '100%', 
    marginLeft: '7.5%',
    marginTop: 40,
  },
  descriptionText: {
    color: Colors.gray.alpha1, 
    marginTop: 24, 
    lineHeight: 26, 
    width: '80%' 
  },
  tagContainer: {
    paddingVertical: 5,
    paddingHorizontal: 12,
    borderRadius: 7.5,
    alignSelf: 'baseline',
    marginTop: 12
  },
  downloadDataButton:{
    borderRadius:10,
    backgroundColor:Colors.greenLight.alpha1,
    paddingHorizontal:16,
    justifyContent:'center',
    alignItems:'center',
    marginLeft:16,
    flexDirection:'row',
    paddingVertical: 12,
    height: 40,
    alignSelf: 'flex-end',
    marginBottom: 20,
    
  },
})