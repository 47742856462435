import i18n from "i18n-js";

export function firstLetterToUpperCase(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export function clamp(value, lowerBound, upperBound){
  "worklet";
  return Math.min(Math.max(lowerBound, value), upperBound);
}

export function ordinalSuffix(i) {
  if(i18n.locale == 'tr'){
    return i + '.'
  }

  let j = i % 10
  let k = i % 100

  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";

}

//format ms to mm:ss
export function getMMSSFromMillis(millis) {
  'worklet'

  if(!millis) return '00:00'
  
  const totalSeconds = millis / 1000;
  const hours = Math.floor(totalSeconds / (60 * 60))
  const seconds = Math.floor(totalSeconds % 60);
  const minutes = Math.floor(totalSeconds / 60);

  const padWithZero = number => {
    const string = number.toString();
    if (number < 10) {
      return "0" + string;
    }
    return string;
  }


  return  (hours ? padWithZero(hours) : '') + padWithZero(minutes) + ":" + padWithZero(seconds);
}

export function getFullDateFromMillis(millis){
  if(!millis) return '00:00'

  
  const totalSeconds = millis / 1000;
  const hours = Math.floor(totalSeconds / (60 * 60))
  const seconds = Math.floor(totalSeconds % 60);
  const minutes = Math.floor(totalSeconds / 60);

  const padWithZero = number => {
    const string = number.toString();
    if (number < 10) {
      return "0" + string;
    }
    return string;
  }


  return  (hours ? padWithZero(hours) : '') + padWithZero(minutes) + ":" + padWithZero(seconds);
}

export function hexToRgba(hex, opacity){
  let c;
  if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
      c= hex.substring(1).split('');
      if(c.length== 3){
        c= [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c= '0x'+c.join('');
      return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+','+opacity+')';
  }
}

export function bytesToMB(bytes) {
  if (bytes == 0) return 0
  return bytes / Math.pow(1024, 2)
}

export function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
      
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  
  return new File([u8arr], filename, {type:mime});
}

export function convertMillisToDate(millis){
  const minutes = millis / 1000
  
}

