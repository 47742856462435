
import { memo, useEffect, useReducer, useRef } from "react";
import {StyleSheet, TextInput, Text, View, Image, Animated, ScrollView, Pressable, TouchableOpacity} from 'react-native'
import API from '../utils/API'
import FontStyles from '../utils/FontStyles'
import Colors from '../utils/Colors'
import {Feather, FontAwesome} from '@expo/vector-icons'
import { hexToRgba } from "../utils/Helper";
import { ActivityIndicator } from "react-native";
import TagDetails from "../components/TagDetails";
import I18n from "i18n-js";
import WebAlert from '../components/WebAlert'
import UserDetails from "../components/UserDetails";
import { runOnJS, useSharedValue, withTiming } from "react-native-reanimated";


// authRole: "COMPANY-MODERATOR"
// companyId: "628a702f246640ff360013a6"
// email: "tolga.atam@beynex.com
// firstName: "Tolga"
// id: "628a702f246640ff360013a7"
// isActive: true
// joinedAt: 1651848869271
// language: "tr"
// lastName: "Atam"
// photo: null


const reducer = (state, action) => {
  return {...state, ...action.payload}
}

const initialState = {
  users: [],
  tags: [],
  searchUsers: [],
  selectedTags: [],
  selectedUser: null,
  loading: true,
  filterVisible: false,
  mediaBasePath: "",
  tagDetailsVisible: false
}

function filterUsersFromArray(users, text){
  // return results for users with name or email in array
  if(users && text && text.length > 0){
    return users.filter(user => {
      return user.username?.toLowerCase().includes(text.toLowerCase()) || user.firstName.toLowerCase().includes(text.toLowerCase()) || user.lastName.toLowerCase().includes(text.toLowerCase())
    })
  }else if(users && text.length === 0){
    return users
  }else{
    return []
  }
}

//function that filter users by tags
function filterUsersByTags(users, selectedTags){
  if(users && selectedTags && selectedTags.length > 0){
    //filter users who have selected tags anywhere in their tags array
    return users.filter(user => {
      return user.tags.some(tag => {
        return selectedTags.includes(tag.name)
      })
    })
  }else if(users && selectedTags.length === 0){
    return users
  }else{
    return []
  }
}

function Users(props){
  const [ state, dispatch ] = useReducer(reducer ,initialState)
  const alertRef = useRef()
  const searchInput = useRef()
  const tagDetailsAnimValue = useRef(new Animated.Value(0)).current
  const opacityValue = useSharedValue(0)

  useEffect(()=> {
    async function getData(){
      try{
        let { users, mediaBasePath } = await API.getCompanyUsersWithTags()
        users = users.filter(user => user.authRole === 'EMPLOYEE')
        
        const { tags } = await API.getCompanyTags()
  
        dispatch({ payload: {
          users: users,
          mediaBasePath: mediaBasePath,
          searchUsers: users,
          loading: false,
          tags: tags
        } })
      }catch(e){
        alertRef.setIsShowing({
          isShowing: true,
          type: 'warning',
          text: e.errorMessage
        })
      }
      
    }

    getData()

  },[])
  
  async function getUserEvents(id, filter){
    try{
      const events = await API.getUserEvents(id, filter)
      return Promise.resolve(events)
    }catch(e){
      console.log(e)
    }
  }

  function tagDetailsController(toValue){
    toValue == true && dispatch({ payload: { tagDetailsVisible: true } })
    setTimeout(() => {
      Animated.timing(tagDetailsAnimValue, {
        toValue: toValue === false ? 0 : 1,
        useNativeDriver: true,
        duration: 150,
        delay: 50
      }).start(() => {
        toValue === false && dispatch({ payload: { tagDetailsVisible: toValue } })
      })    
    }, 0);
  }

  const onUserPressed = (user) => {
    API.getUserDetails(user.id).then((u)=> {
      getUserEvents(user.id, { limit: 30 }).then((events)=> {
        dispatch({ payload: { selectedUser: {...u, events: events} }})
      })
    })
  }
  
  function downloadPasswords(){
    API.downloadEmployeePasswords()
        .then((response)=>{
          if(response.filename){
            let a = document.createElement("a");
            a.style = "display: none";
            document.body.appendChild(a);
          
            a.href = response.file;
            a.download = response.filename;
            a.click();
            window.URL.revokeObjectURL(response.file);
            a.remove();
          }
          else {
            window.open(response.file, "_blank");
          }
        })
        .catch((e)=>{
          alert(e.errorMessage);
        });
  }

  return (
    <View style={styles.container}>
      <View style={styles.leftContainer}>
        <View style={{flexDirection: "row", alignItems: "center", marginTop: 16, marginBottom: 16, justifyContent: "space-between",}}>
          <Text style={[FontStyles.headline.semibold, {marginLeft: 8, color: Colors.dark.alpha1,}]}>
            {I18n.t('employees')}
            <Text style={[FontStyles.footnote.regular, {color: Colors.gray.alpha06}]}>
              {"\n" + state.searchUsers.length + " kayıtlı çalışan var."}
            </Text>
          </Text>
          <Pressable onPress={() => tagDetailsController(true)} style={styles.tagsButton} >
            <Text style={[FontStyles.footnote.medium, {color: Colors.white.alpha1}]} >
              {I18n.t('tagSettings')}
            </Text>
          </Pressable>
        </View>
        
        <View style={{flexDirection: "row", width: "100%", alignItems: "center",}}>
          <View style={styles.searchContainer}>
            <TextInput
              ref={searchInput}
              style={[styles.searchInput]}
              placeholder='Ad, soyad veya kullanıcı adı ara.'
              selectionColor={Colors.primaryBlue.alpha1}
              autoCapitalize="none"
              autoCorrect={false}
              placeholderTextColor={Colors.gray.alpha06}
              onChangeText={text => {
                dispatch({ payload: { searchUsers: filterUsersFromArray(state.users, text) }})
              }}            
            />
            <Feather 
              name="search" 
              size={18} 
              color={Colors.gray.alpha04}
            />
          </View>
          <Pressable onPress={() => { dispatch({ payload: { filterVisible: !state.filterVisible }})}} style={{padding: 4, paddingLeft: 12 }}>
            <Feather name={state.filterVisible ? "x" : "filter"} color={Colors.primaryBlue.alpha1} size={18}/> 
          </Pressable>
        </View>
        {
          state.filterVisible ?
          <View style={{flexDirection: "row", marginHorizontal: 0, marginTop: 8, flexWrap: "wrap", alignItems: "center", width: "100%", overflow: "hidden"}}>
            {
              state.tags && state.tags.map((tag, index) => {
                return (
                  <Pressable 
                    onPress={() => {
                      //add or remove tag to selectedTags
                      searchInput.current && searchInput.current.clear()
                      let updatedSelectedTags = state.selectedTags.includes(tag.name) ? 
                      state.selectedTags.filter(selectedTag => selectedTag !== tag.name) :
                      [...state.selectedTags, tag.name]
                      dispatch({ payload: { selectedTags: updatedSelectedTags, searchUsers: filterUsersByTags(state.users, updatedSelectedTags)}})
                    }} 
                    style={[styles.tagContainer, {borderWidth: 2, borderColor: state.selectedTags.includes(tag.name) ? "white" : Colors.lightGray.alpha1, backgroundColor: state.selectedTags.includes(tag.name) ? hexToRgba(tag.colorHex, 1) : "white"}]} key={"key" + index + "tags"}
                  >
                    <Text style={[FontStyles.footnote.medium, {userSelect: "none", color: state.selectedTags.includes(tag.name) ? Colors.white.alpha1 : Colors.dark.alpha06}]}>
                      {tag.name}
                    </Text>
                  </Pressable>
                )
              })
            }  
          </View> 
          :
          null
        } 
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{paddingVertical: 0}}
          style={{flex: 1, borderTopWidth: 2, marginTop: 16, borderColor: Colors.lightGray.alpha1}}
        >
          {
            state.loading === false ? state.searchUsers.map((user, index) => {
              return (
                <Pressable onPress={() => {
                  opacityValue.value = withTiming(0, {duration: 200},()=> {
                    runOnJS(onUserPressed)(user)
                  })
                  
                }} style={styles.userContainer} key={"key" + index + "users"}>
                  <View style={styles.userImageContainer}>
                    <Image 
                      source={user.photo ? {uri: state.mediaBasePath + user.photo} : require('../assets/icons/user_dummy_image.png')}
                      style={[styles.userImage, {tintColor: user.photo ? null : Colors.gray.alpha06}]}
                      resizeMode='cover'
                    />
                  </View>
                  <View style={styles.userInfoContainer}>
                    <Text numberOfLines={1} style={[FontStyles.subhead.semibold, { color: Colors.dark.alpha1, marginLeft: 8 }]}>
                      {user.firstName + " " + user.lastName}
                    </Text>
                    <Text numberOfLines={1} style={[FontStyles.footnote.regular, { color: Colors.dark.alpha06, marginTop: 3, marginLeft: 8 }]}>
                      {user.email}
                    </Text>  
                  </View>
                  <View style={{backgroundColor: Colors.secondaryBlue.alpha01, opacity: 0.75, borderRadius: 200, width: 36, height: 36, justifyContent: 'center', alignItems: "center"}} >
                    <Feather name="arrow-right" size={18} color={Colors.secondaryBlue.alpha1}/>
                  </View>
                </Pressable>
              )
            })
            :
            <View style={{flex: 1, justifyContent: 'center', marginTop: "20%", alignItems: "center"}}>
              <ActivityIndicator size="small" color={Colors.dark.alpha08} />
              <Text numberOfLines={1} style={[FontStyles.subhead.regular, { color: Colors.dark.alpha08, marginTop:16 }]}>
                {I18n.t('usersLoading')}
              </Text>
            </View>
          }
        </ScrollView>
        
      </View>

      <View style={styles.rightContainer}>
        {
          state.selectedUser ?
          <UserDetails 
            user={state.selectedUser}
            opacityValue={opacityValue}
          />
          : 
          <View style={styles.userInfoEmptyContainer}>
  
            {state.users.length !== 0 &&
                <TouchableOpacity activeOpacity={0.8} style={styles.downloadDataButton} onPress={downloadPasswords}>
                  <FontAwesome name="file-excel-o" size={21} color={Colors.white.alpha1} style={{ marginRight: 8}} />
                  <Text style={[FontStyles.footnote,{color:Colors.white.alpha1}]}>
                    {I18n.t('downloadEmployeePasswords')}
                  </Text>
                </TouchableOpacity>
            }
            
            <Feather  name='users' size={36} color={Colors.dark.alpha1} />
            <Text style={[FontStyles.title2.medium, {color: Colors.dark.alpha1, marginTop: 16, textAlign: "center"}]}>
              {I18n.t('employees')}
            </Text>
            <Text style={[FontStyles.callout.regular, {color: Colors.dark.alpha08, marginTop: 12, textAlign: "center"}]}>
              {I18n.t('selectUser')}
            </Text>
          </View>
        }
      </View>
      
      {
        state.tagDetailsVisible &&
        <TagDetails tagDetailsAnimValue={tagDetailsAnimValue} tagDetailsController={tagDetailsController}/>
      }
      <WebAlert 
        ref={alertRef}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: { 
    flex: 1,
    width: "100%",
    flexDirection: "row",
    borderRadius: 30,
    paddingHorizontal: 20,
    paddingVertical: 15,
    backgroundColor: "#F3FAFF",
    overflow: "hidden"
  },

  //left container
  leftContainer: {
    flex: 1,
    backgroundColor: 'white',
    paddingHorizontal: 16,
    borderRadius: 20,
    minWidth: 280,
    overflow: "hidden",
    marginTop: 4,
  },
  searchContainer: {
    borderRadius: 100,
    borderWidth: 2,
    backgroundColor: Colors.white.alpha1,
    borderColor: Colors.lightGray.alpha1, 
    flex: 1,
    height: 44,
    paddingHorizontal: 16,
    flexDirection: "row",
    alignItems: "center",
  },
  searchInput: {
    height: "100%",
    flex: 1,
    outlineStyle: "none",
    
  },
  userContainer: {
    borderBottomWidth: 2,
    flexDirection: "row",
    alignItems: "center",
    borderBottomColor: Colors.lightGray.alpha1,
    paddingVertical: 16,
    paddingHorizontal: 8
  },
  userImageContainer: {
    width: 36,
    height: 36,
    backgroundColor: Colors.gray.alpha02,
    borderRadius: 18,
  },
  userImage: {
    width: 36,
    height: 36,
    borderRadius: 18,
  },
  userInfoContainer: {
    flex: 1,
    paddingHorizontal: 12
  },
  tagsButton: {
    backgroundColor: Colors.primaryBlue.alpha1,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 8,
    paddingHorizontal: 16,
  },
  tagContainer: {
    paddingHorizontal: 8,
    paddingVertical: 4,
    marginTop: 6,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 7.5,
    marginRight: 6
  },


  //right container
  rightContainer: {
    flex: 2.5,
    borderRadius: 20,
    marginLeft: 8,
    marginTop: 4,
    backgroundColor: '#F3FAFF',
  },
  userInfoEmptyContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 16,
    paddingHorizontal: 8,

  },
  downloadDataButton:{
    borderRadius:10,
    backgroundColor:Colors.greenLight.alpha1,
    paddingHorizontal:16,
    justifyContent:'center',
    alignItems:'center',
    flexDirection:'row',
    paddingVertical: 12,
    height: 40,
    position: 'absolute',
    top: 4,
    right: 4,
  },
})

export default memo(Users)