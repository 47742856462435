import { useRef, useState } from "react";
import { StyleSheet, View, useWindowDimensions } from "react-native";
import { useSharedValue } from 'react-native-reanimated'
import AnimatedImage from "./AnimatedImage";
import LoginCode from './LoginCode'
import LoginScreen from "./LoginScreen";

const buttonWidth = 340

export default function Login(props){
  const { width, height } = useWindowDimensions()
  const screenNavigationValue = useSharedValue(0)
  const successOpacity = useSharedValue(0)
  const opacity = useSharedValue(0)
  const [ loginId, setLoginId ] = useState('')
  const [ email, setEmail ] = useState('')

  return (
    <View style={[styles.container, { width: width, height: height, overflow: 'hidden', }]}>
      <AnimatedImage
        opacity={opacity}
        width={height * 0.678}
        height={height}
        imageSource={require('../../../assets/login/login-bg-1.png')}
        warningImageSource={require('../../../assets/login/login-bg-1-warning.png')}
        successImageSource={require('../../../assets/login/login-bg-1-success.png')}
        successOpacity={successOpacity}
      />
      <View style={{flex: 1, justifyContent: 'center'}}>
        <LoginCode
          opacity={opacity}
          successOpacity={successOpacity}
          buttonWidth={buttonWidth}
          height={height}
          loginId={loginId}
          email={email}
        />
        <LoginScreen
          opacity={opacity}
          height={height}
          width={width}
          navigation={props.navigation}
          buttonWidth={buttonWidth}
          screenNavigationValue={screenNavigationValue}
          onEmailVerified={(email, code)=> {setEmail(email);setLoginId(code)}}

        />
      </View>
      <AnimatedImage
        opacity={opacity}
        width={height * 0.287}
        height={height}
        imageSource={require('../../../assets/login/login-bg-2.png')}
        warningImageSource={require('../../../assets/login/login-bg-2-warning.png')}
        successImageSource={require('../../../assets/login/login-bg-2-success.png')}
        successOpacity={successOpacity}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    flexDirection: 'row'
  },
  image: {
    width: '100%',
    height : '100%'
  },
})