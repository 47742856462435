// functions in this module are called with the `this` context of API.js object

export function getReports(body){
	const incidentSubtypeToNameMap = {}
	
	for(const incidentType in body.incidentTypes){
		if(body.incidentTypes.hasOwnProperty(incidentType)){
			for(const incidentSubType in body.incidentTypes[incidentType].subtypes)
				if(body.incidentTypes[incidentType].subtypes.hasOwnProperty(incidentSubType)){
					incidentSubtypeToNameMap[incidentSubType] = body.incidentTypes[incidentType].subtypes[incidentSubType].name
				}
		}
	}
	
	for(const report of body.reports){
		report.incidentSiteName = body.incidentSites[report.incidentSite].name
		report.incidentTypesNames = report.incidentTypes.map(type => incidentSubtypeToNameMap[type])
		
		if(report.occupationOfVictim){
			report.occupationOfVictimName = body.occupationsOfVictim[report.occupationOfVictim].name
		}
		
		if(report.sexOfVictim){
			report.sexOfVictimName = body.sexesOfVictim[report.sexOfVictim].name
		}
		
		if(report.injuryType){
			report.injuryTypeName = body.injuryTypes[report.injuryType].name
		}
		
		if(report.injuredPartOfBody){
			report.injuredPartOfBodyName = body.injuredPartsOfBody[report.injuredPartOfBody].name
		}
	}
	
	console.log(body)
	
	return body
}