import { StyleSheet, View, Text, Pressable } from "react-native";
import Colors from "../utils/Colors";
import FontStyles from "../utils/FontStyles";
import { Feather } from '@expo/vector-icons'
import { memo } from "react";
import I18n from "i18n-js";

function ReportFilter({data, onSelect, title, isActive, onPress, index, selectedFilters}){

  const onSelectFilter = (item, index, key) => {
    const currentIndex = selectedFilters ? selectedFilters.findIndex(i => i.key == key) : -1
    let currentFilter = {...item, key: key}
    onSelect({ currentIndex: currentIndex, filter: currentFilter, type: title })
  }

  return(
    <View style={[ styles.container, { zIndex: index * -2 } ]}>
      <Pressable style={[styles.x, { backgroundColor: isActive ? Colors.primaryBlue.alpha1 : 'transparent'}]} onPress={onPress}>
        <Text style={[FontStyles.subhead.semibold, { color: isActive ? 'white' : Colors.gray.alpha1, flex: 1, marginRight: 8 }]}>
          {I18n.t(title)}
        </Text>
        <Feather 
          name={isActive ? "x" : 'chevron-down'}
          size={18} 
          color={isActive ? Colors.white.alpha1 : Colors.dark.alpha08} 
        />
      </Pressable>
      {
        isActive &&
        <View style={styles.buttonContainer}>
          {
            Object.keys(data).map((item, index)=> {
              let obj = data[item]
              return (
                <Pressable key={obj.name} style={[styles.button, { marginTop: index !== 0 ? 12 : 0 } ]} onPress={()=> onSelectFilter(obj, index, item)}>
                  <View style={[styles.indicator, { backgroundColor: selectedFilters?.find(filter => filter.key === item) ? Colors.primaryBlue.alpha1 : 'transparent' }]}/>
                  <Text style={[FontStyles.body.medium, { color: selectedFilters?.find(filter => filter.key === item) ? Colors.primaryBlue.alpha1 : Colors.dark.alpha08, marginLeft: 8 }]}>
                    {obj.name}
                  </Text>
                </Pressable>
              )
            })
          }
        </View> 
      }
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 170,
    height: 50,
    backgroundColor: Colors.gray.alpha01,
    marginRight: 8,
    borderRadius: 12,
    marginTop: 8,
  },
  indicator: {
    width: 16,
    height: 16,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.primaryBlue.alpha1
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  buttonContainer: {
    paddingHorizontal: 16,
    paddingVertical: 16,
    width: '100%',
    backgroundColor: Colors.white.alpha1,
    alignSelf: 'center',
    zIndex: 1000,
    shadowColor: '#C4C4C4',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.15, 
    shadowRadius: 10,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12

  },
  x: {
    width: '100%',
    height: '100%', 
    paddingHorizontal: 16,
    flexDirection: 'row',
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    justifyContent: 'center',
    alignItems: 'center'
  }
})

export default memo(ReportFilter)