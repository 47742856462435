import React from "react";
import { View } from "react-native";

export default class FileDragDrop extends React.PureComponent{

  handleDrop = async (e) => {
    e.preventDefault()
    e.stopPropagation()

    let filesObj = {}

    let files = e.dataTransfer.files

    const base64 = await this.convertToBase64(files[0])

    filesObj['file'] = {
      file: files[0],
      base64: base64
    }

    console.log(filesObj)

    this.props.onDrop(filesObj)
  }

  convertToBase64 = async (file) => {
    return new Promise((resolve)=> {
      let fileReader = new FileReader()
      fileReader.readAsDataURL(file)
  
      fileReader.onload = (e) => {
        let fileURL = fileReader.result;
        resolve(fileURL)
      }
    })
  }

  onFileSelect = async (e) => {
    let filesObj = {}

    let files = e.target.files

    const base64 = await this.convertToBase64(files[0])

    filesObj['file'] = {
      file: files[0],
      base64: base64
    }

    console.log(filesObj)
    this.props.onDrop(filesObj)
  }

  render(){

    return (
      <View style={this.props.containerStyle}>
        {this.props.children}
        <input
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            cursor: 'pointer',
            outlineStyle: 'none',
            opacity: 0
          }}
          onChange={this.onFileSelect}
          type={'file'}
          onDrop={this.handleDrop}
          accept={this.props.acceptedFormats || "video/*,image/*"}
          max={1}
          multiple={this.props.multiple}
          
        />
      </View>
    )
  }
}