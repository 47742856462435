import React from "react"
import { StyleSheet, View, Animated, Pressable, Text } from "react-native"
import FontStyles from "../utils/FontStyles"
import Colors from "../utils/Colors"
import { Feather } from '@expo/vector-icons'

const alertTypes = {
  standart: {color: Colors.primaryBlue, iconName: "check"}, 
  info:     {color: Colors.orange, iconName: "info"}, 
  success:  {color: Colors.secondaryBlue, iconName: "check"}, 
  warning:  {color: Colors.red, iconName: "alert-triangle"},
}

export default class  WebAlert extends React.PureComponent{
  constructor(props){
    super(props)
    this.animValue = new Animated.Value(0)

    this.animation = {
      opacity: this.animValue,
      transform: [
        { perspective: 500 },
        {
          translateY: -44
        },
        {
          rotateX: this.animValue.interpolate({
            inputRange: [0, 1],
            outputRange: ['45deg', '0deg'],
            extrapolate: 'clamp'
          })
        },
        {
          translateY: 44
        }
      ]
    }

    this.state={
      type: null,
      isShowing: false
    }
  }

  setIsShowing = ({ text, isShowing, type, timeout}) => {
    if(isShowing){
      this.setState({
        isShowing: isShowing,
        type: type,
        text: text
      },()=> {
        Animated.timing(this.animValue, {
          toValue: 1,
          duration: 200,
          useNativeDriver: false
        }).start(()=> {
          setTimeout(() => {
            if(this.state.isShowing){
              Animated.timing(this.animValue, {
                toValue: 0,
                duration: 200,
                useNativeDriver: false
              }).start()
            }

          }, timeout || 3000);
        })
      })
    }else{
      Animated.timing(this.animValue, {
        toValue: 0,
        duration: 200,
        useNativeDriver: false
      }).start(()=> {
        this.setState({
          isShowing: isShowing,
        })
      })
    }
  }

  render(){

    if(!this.state.isShowing){
      return null
    }
    

    return (
      <Animated.View style={[styles.container, this.animation, { backgroundColor: alertTypes[this.state.type].color.alpha02, borderWidth: 1, borderColor: alertTypes[this.state.type].color.alpha01}]}>
        <View style={[styles.iconContainer, { backgroundColor: alertTypes[this.state.type].color.alpha1 }]}>
          <Feather name={alertTypes[this.state.type].iconName} size={20} color={'white'}/>
        </View>
        <Text style={[FontStyles.subhead.medium, { marginLeft: 12, flex: 1}]}>
          {this.state.text}
        </Text>
        <Pressable style={styles.closeButton} onPress={()=> this.setIsShowing({ isShowing: false })}>
          <Feather name={'x'} size={15} color={Colors.dark.alpha08}/>
        </Pressable>
      </Animated.View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 8,
    paddingHorizontal: 8,
    alignSelf: 'center',
    position: 'absolute',
    top: 0,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 10,
    shadowColor: '#C4C4C4',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.15, 
    shadowRadius: 10,
  },
  closeButton: {
    padding: 4, 
    justifyContent: 'center', 
    alignItems: 'center',
    marginLeft: 18
  },
  iconContainer: {
    width: 30,
    height: 30,
    justifyContent: 'center',
    alignItems:'center',
    backgroundColor: 'white',
    borderRadius: 10
  }
})