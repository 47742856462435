import { memo } from "react"
import { StyleSheet, View, ScrollView, Text, Image } from "react-native"
import Colors from "../utils/Colors"
import FontStyles from "../utils/FontStyles"
import I18n from "i18n-js"
import { Feather } from '@expo/vector-icons'
import ScoreDistributionCard from "./ScoreDistributionCard"

const statKeys = {
  completedContestCount: { iconName: 'target', color: Colors.purple.alpha1 },
  completedMiniTestCount: { iconName: 'edit-2', color: Colors.primaryBlue.alpha1 },
  completedPodcastCount: {iconName: 'mic', color: Colors.badgeYellow.alpha1 },
  completedSurveyCount: { iconName: 'file-text', color: Colors.secondaryBlue.alpha1},
  completedVideoCount: { iconName: 'play', color: Colors.badgeYellow.alpha1}
}

function UserStatics({ stats }){
  return (
    <ScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={false}>
      <View style={{ flexDirection: 'row', justifyContent: "flex-start", width: '100%', paddingHorizontal: 12, paddingVertical: 16}}>
        <View style={{minWidth: 450, paddingRight: 24 }}>
          <Text style={[FontStyles.headline.medium, { color: Colors.dark.alpha1, marginBottom: 8 }]}>
            {'Puan Dağılımı'}
          </Text>
          <ScoreDistributionCard
            score={stats.gamification.score}
            scoreDistribution={stats.gamification.scoreDistribution}
          />
          <Text style={[FontStyles.headline.medium, { color: Colors.dark.alpha1, marginBottom: 8, marginTop: 24 }]}>
            {I18n.t('badges')}
          </Text>
          <View style={{flexDirection: 'row', flexWrap: 'wrap',}}>
            {
              Object.keys(stats.gamification.badges).length ?
              Object.keys(stats.gamification.badges).map((badgeKey, index)=> {
                const badgeCount = stats.gamification.badges[badgeKey]
                return (
                  <View key={badgeKey} style={styles.badgeContainer}>
                    <Text style={[FontStyles.body.medium, {color: Colors.dark.alpha1}]}>
                      {I18n.t(badgeKey)}
                    </Text>
                    <Text style={[FontStyles.footnote.medium, {color: Colors.dark.alpha08, marginTop: 4, marginBottom: 8}]}>
                      {'Level ' + badgeCount}
                    </Text>
                    <Image  source={badgeCount ? require(`../assets/images/badges/${badgeKey}-${badgeCount}.png`) : require(`../assets/images/badges/badge_locked.png`)} style={{width: 75, height: 75}}/>
                  </View>
                )
              })
              :
              <Text style={[FontStyles.body.medium, { color: Colors.dark.alpha1, alignSelf: 'center', marginTop: 16 }]}>
                {I18n.t('noBadge')}
              </Text>
            }
          </View>
        </View>
        <View style={{flex: 1, minWidth: 250}}>
          <Text style={[FontStyles.headline.medium, { color: Colors.dark.alpha1, marginBottom: 8 }]}>
            {I18n.t('completedContents')}
          </Text>
          {
            Object.keys(statKeys).map((key, index)=> {
              const item = stats[key] 
              return (
                <View key={key} style={[styles.container]} >
                  <View style={[styles.iconContainer, { backgroundColor: statKeys[key].color }]}>
                    <Feather
                      name={statKeys[key].iconName}
                      size={18}
                      color={'white'}
                    />
                  </View>
                  <View style={{marginLeft: 16, flex: 1}}>
                    <Text style={[FontStyles.body.medium, {color: Colors.dark.alpha08}]}>
                      {I18n.t(key)}
                    </Text>
                    <Text style={[{fontSize: 26, fontWeight: "800", color: Colors.gray.alpha1}]}>
                      {item}
                    </Text>
                  </View>
                </View> 
              )
            })
          }
        </View>
        
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  iconContainer: {
    width: 44,
    height: 44,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: {
    flexDirection: 'row',
    width: '100%',
    paddingVertical: 12,
    paddingHorizontal: 16,
    backgroundColor: 'white',
    shadowColor: '#C4C4C4',
    alignItems: 'center',
    borderRadius: 20,
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.15,
    shadowRadius: 10,
    alignSelf: 'baseline',
    marginTop: 12
  },
  badgeContainer: {
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.15,
    shadowRadius: 10,
    shadowColor: '#C4C4C4',
    backgroundColor: 'white',
    paddingVertical: 16,
    paddingHorizontal: 16,
    borderRadius: 17.5,
    alignItems: 'center',
    marginTop: 16,
    marginRight: 12
  }
})

export default memo(UserStatics)