const Languages = {
  tr: {

    //general
    email: "E-Posta",
    others: "Diğer",
    yes: 'Evet',
    no: 'Hayır',

    //Login Screen
    login: 'Giriş Yap',
    loginHeader: 'Giriş Yap',
    loginSubText: 'Giriş yapmak için bilgilerinizi girin.',
    password: 'Şifre',
    wrongEmail: 'Geçersiz Adres',
    wrongEmailSubText: 'Girdiğiniz e-posta adresi geçersiz.',
    loginProblem: 'Bir sorun mu var?',
    contactUs: 'Bizimle İletişime Geçin',
    wrongCode: 'Hatalı Deneme',
    wrongCodeSubText: 'Girdiğiniz güvenlik kodu hatalı.\nLütfen tekrar deneyin.',

    //LoginCode Screen
    loginCodePlaceholder: "6 haneli kodunuzu girin",
    loginCodeTitle: 'Güvenlik Kodu',
    loginCodeSubText: 'Kayıtlı e-posta adresinize gönderilen\n6 haneli giriş kodunuzu giriniz.',
    send: 'Gönder',
    loginSuccessTitle: 'Giriş Başarılı, hoş geldiniz.',
    loginSuccessBody: '{{company}} adına {{firstname}} {{lastname}}',

    //tab home
    dashboard: 'Ana Sayfa',
    smartTimeline: 'İçerik Programı',
    announcements: 'Duyurular',
    contests: 'Yarışmalar',
    notifications: 'Bildirimler',
    settings: 'Ayarlar',
    go: 'Git',
    welcome: 'Merhaba {{name}}, hoş geldin!',
    

    //dashboard
    activeUsers: "Aktif Kullanıcılar",
    users: 'Tüm Kullanıcılar',
    employees: 'Tüm Çalışanlar',
    reports: 'Bildirimler',
    pendingReports: 'Bekleyen Bildirimler',
    leaderboard: 'Liderlik Tablosu',
    leaderboardSubText: 'Sıralamaları inceleyin.',
    leaderboardEmpty: 'Henüz puan kazanan kullanıcı yok.',
    survey: 'Anketler',
    surveySubText: 'Anketlerinizi yönetin.',
    activeSurveyText1: '{{startsAt}} tarihinde başlatılan `{{name}}` adlı anketinizi toplamda',
    activeSurveyText2: ' {{completedCount}} kişi',
    activeSurveyText3: ' ({{userPercentage}}) cevapladı. Anketin kapanmasına',
    activeSurveyText4: ' {{leftDay}} gün',
    activeSurveyText5: ' kaldı.',
    surveyEmpty: 'Aktif anketiniz yok',
    manageSurveys: 'Anketleri Yönet',
    contestEndsAt: 'Yarışma bitimine',
    seeRankings: 'Sıralamayı Gör',
    startContest: 'Yarışma Başlat',
    noActiveContest: 'Aktif yarışma yok',
    noActiveContestSubText: 'Yarışmalar ile ilgili daha fazla bilgi için sıkça sorulan sorular kısmına göz atın.',
    weekly: 'Haftalık',
    monthly: 'Aylık',
    active: 'Aktif',
    leaderboardTitle: 'Liderlik Tablosu',
    leaderboardDescriptionLong: 'Liderlik tablosu tüm kullanıcıların topladıkları puanlara göre sıralamalarını gösterir. Kullanıcılar video, podcast ve mini testlerdeki soruları hızlı ve doğru cevapladıkça veya gönderdikleri bildirimler onaylandığında puan kazanırlar.',
    leaderboardTipTitle: 'Bilgi',
    leaderboardTipDescription: 'Her yıl ya da dilediğiniz aralıklarla çalışanlarınızı ödüllendirmek, kullanıcıların içerik tüketimlerini ciddi oranda arttırmaktadır.',
    leaderboardTipDescription2: 'Belirlediğiniz yarışma detaylarını duyurular kısmından duyuru olarak yayınlayabilirsiniz.',
    goBack: "Geri Dön",
    ranking: "Sıralama",
    tags: "Etiketler",
    tagsEmpty: "Henüz bir etiket oluşturulmadı.",
    deleteTag: "Etiketi Sil",
    cancel: "Vazgeç",
    deleteTagTitle: "Etiketi Sil",
    deleteTagDescription: "Etiketi silmek istediğinizden emin misiniz?",
    details: "İncele",
    tagDetailsTitle: "Etiket Detayları",
    tagDetailsDescriptionLong: "Etiketler birçok amaç için kullanılabilir. Örneğin, sadece belirli çalışanlar için oluşturmak istediğiniz anket, yarışma veya duyurularda etiketleri kullanırsınız. Bu ekranda etiketleri düzenleyebilir ve hangi çalışanların hangi gruplara dahil olacağını yönetebilirsiniz.",
    tagErrorMessage: "Etiketler 1-16 karakter uzunluğunda olmalıdır.",
    tagNotSelected: "Kullanıcı atamak ya da kaldırmak için bir etiket seçmelisiniz.",
    contestRankingDescription: 'Yarışmanın liderlik tablosu, oluşturduğunuz yarışmaya katılanların sıralamasını gösterir. Kullanıcılar yarışmada verdiklere cevaplara göre puan kazanırlar.',
    contestTipDescription: 'Yaptığınız yarışmalar sonucunda çalışanlarınızı ödüllendirmek yarışmaya katılımı ciddi oranda arttırmaktadır.',
    addTag: 'Etiket Ekle',
    surveyLoading: 'Anket detayları yükleniyor. Lütfen bekleyin.',
    add: 'Ekle',
    tagSettings: 'Etiket Ayarları',
    selectUser: 'İncelemek istediğiniz kullanıcıyı sol taraftan aratabilirsiniz.',
    stats: 'İstatistikler',
    activity: 'Aktivite',
    completedContestCount: 'Yarışma',
    completedSurveyCount: 'Anket',
    completedMiniTestCount: 'Mini-Test',
    completedPodcastCount: 'Podcast',
    completedVideoCount: 'Video',


    plusTags: '+{{count}} etiket',
    activeSurveys: 'Aktif Anketler',
    oldSurveys: 'Geçmiş Anketler',
    searchInput: 'Anket Ara',
    participantCount: 'Katılımcı Sayısı',
    active: 'Aktif',
    inactive: 'İnaktif',
    status: 'Durum',
    clearFilters: 'Filtreleri Temizle',
    apply: 'Uygula',
    newSurvey: 'Yeni Anket',
    surveys: 'Anketler',
    answeredCount: 'Cevaplanma Sayısı',
    percentage: 'Yüzde',
    pending: 'Bekliyor',

    //create survey
    surveyTitle: 'Anket Başlığı',
    surveyTitleBody: 'Lütfen anketiniz için bir başlık ekleyin.',
    surveyDescription: 'Anket Açıklaması',
    surveyDescriptionBody: 'Lütfen anketiniz için bir açıklama ekleyin.',
    surveyQuestionsTitle: 'Anket Soruları',
    surveyQuestionsBody: 'Lütfen anketiniz için sorular oluşturun.',
    surveyDates: 'Anket Tarihleri',
    surveyDatesBody: 'Lütfen anketiniz için başlangıç ve bitiş tarihi belirleyin.',
    surveyTagTitle: 'Anket Etiketleri',
    surveyTagBody: 'Lütfen ankete katılmasını istediğiniz etiketleri seçin.',

    question: 'Soru',
    answers: 'Cevaplar',
    startsAt: 'Başlangıç: {{date}}',
    endsAt: 'Bitiş: {{date}}',
    addDate: 'Tarih Ekleyin',
    seeDetails: 'Görüntüle',
    logout: 'Çıkış Yap',

    contestTitle: 'Yarışma Başlığı',
    contestTitleBody: 'Lütfen yarışmanız için bir başlık ekleyin.',
    contestDescription: 'Yarışma Açıklaması',
    contestDescriptionBody: 'Lütfen yarışmanız için bir açıklama ekleyin.',
    contestQuestionsTitle: 'Yarışma Soruları',
    contestQuestionsBody: 'Lütfen yarışmanız için sorular oluşturun.',
    contestDates: 'Yarışma Tarihleri',
    contestDatesBody: 'Lütfen yarışmanız için başlangıç ve bitiş tarihi belirleyin.',
    contestTagTitle: 'Yarışma Etiketleri',
    contestTagBody: 'Lütfen yarışmaya katılmasını istediğiniz etiketleri seçin.',
    addAnswer: 'Cevap Ekle',
    newContest: 'Yeni Yarışma',
    searchContest: 'Yarışma ara',
    contestDetails: 'Yarışma Detayları',
    contestLoading: 'Yarışma detayları yükleniyor. Lütfen bekleyin.',
    mediaContentLoading: 'Medya içerik detayı yükleniyor. Lütfen bekleyin.',
    mediaContentDetails: '{{type}} Detayları',
    video: 'Video',
    podcast: 'Podcast',
    miniTestDetails: 'Mini Test Detayları',
    noTheme: 'Atanmış tema bulunmamaktadır.',
    miniTestLoading: 'Mini test detayları yükleniyor. Lütfen bekleyin...',
    usersLoading: 'Kullanıcılar yükleniyor...',
    joinedAt: '{{date}} tarihinde kayıt oldu.',
    notJoinedYet: 'Bu kullanıcı henüz uygulamaya giriş yapmamış.',
    loading: 'Yükleniyor',

    //annoucements
    announcement: 'Duyuru',
    announcementTitle: 'Duyuru Başlığı',
    announcementTitleBody: 'Lütfen duyurunuz için bir başlık giriniz.',
    announcementTextDescription: 'Lütfen yapmak istediğiniz duyuruyu giriniz.',
    link: 'Link',
    linkDescription: 'Eklemek istediğiniz linki buradan girebilirsiniz.',
    announcementTags: 'Duyuru Etiketleri',
    announcementTagsBody: 'Lütfen duyuruyu görmesini istediğiniz etiketleri seçin.',
    notifTags: 'Anlık Duyuru Etiketleri',
    notifTagsBody: 'Lütfen bildirimi görmesini istediğiniz etiketleri seçin.',
    addFile: 'Dosya Ekle',
    pickFile: 'Dosya Seç (Resim veya Video)',
    addFileBody: 'Lütfen yüklemek istediğiniz dosyaları seçin. Yalnızca 1 resim veya 1 video ekleyebilirsiniz.',
    announcementVideo: 'Duyuru Videosu',
    announcementPhoto: 'Duyuru Görseli',
    next: 'Devam Et',
    titleError: 'Devam etmek için bir başlık girmelisiniz.',
    textError: 'Devam etmek için bir duyuru yazısı girmelisiniz.',
    dateError: 'Devam etmek için bir yayınlanma tarihi seçmelisiniz.',
    tagError: 'Devam etmek için en az bir etiket seçmelisiniz.',
    questionError: 'Devam etmek için en az bir soru oluşturmalısınız.',
    descriptionError: 'Devam etmek için açıklama yazısı girmelisiniz.',
    startsAtError: 'Devam etmek için bir başlama tarihi seçmelisiniz.',
    endsAtError: 'Devam etmek için bir bitiş tarihi seçmelisiniz.',
    fileError: 'Devam etmek için bir dosya eklemelisiniz.',
    mediaContentUploading: 'Medya içeriği gönderilirken lütfen sayfayı kapatmadan bekleyin.',

    announcementSendSuccess: 'Tebrikler!',
    announcementSendSuccessBody: 'Duyurunuz başarıyla gönderilmiştir. Duyurunuzu ekranın sağ tarafındaki duyurular bölümünden görüntüleyebilirsiniz.',

    writeHere: 'Buraya yazın.',
    gradingQuestion: 'Bu soru 1-10 arasında puanlanacaktır.',
    
    mon: 'Pzt',
    tue: 'Sal',
    wed: 'Çar',
    thu: 'Per',
    fri: 'Cum',
    sat: 'Cmt',
    sun: 'Paz',
    save: 'Kaydet',

    mediaContentTitle: 'Medya İçerik Başlığı',
    mediaContentTitleBody: 'Lütfen medya içeriği için bir başlık giriniz.',
    mediaContentDescription: 'Medya İçerik Açıklaması',
    mediaContentDescriptionBody: 'Lütfen medya içeriği için bir açıklama giriniz.',
    addMediaContentBody: 'Lütfen yüklemek istediğiniz medya dosyasını aşağıdaki alana sürükleyip bırakınız.',
    addMediaContent: 'İçerik Ekle',
    mediaContentQuestionsTitle: 'Medya İçerik Soruları',
    mediaContentQuestionsBody: 'Lütfen içeriğiniz için sorular oluşturun.',

    //smarttimeline
    content: 'içerik',
    addTheme: 'Tema Ekle',
    addPodcast: 'Podcast Ekle',
    addVideo: 'Video Ekle',
    noContent: 'Bu ay için atanmış herhangi bir içerik bulunmamaktadır. Tema ekleyerek içerikleri oluşturmaya başlayabilirsiniz.',
    "MINI-TEST": "mini test",
    "VIDEO": "video",
    "PODCAST": "podcast",
    themes: 'Temalar',
    noThemeSelected: 'Lütfen eklemek için bir tema seçiniz.',
    noAvailablePackage: 'Eklemeye uygun herhangi bir tema bulunmamaktadır.',
    notifSuccess: 'Bildirim başarıyla gönderildi.',

    "NEAR-MISS": "Ramak Kala Bildirimi",
    "ACCIDENT": "Kaza Bildirimi",
    "THREAT": "Tehlike Bildirimi",
    review: 'İncele',
    seeAll: 'Tümünü Gör',
    

    emergencyDegree: 'Aciliyet',
    incidentSite: 'Olay Bölgesi',
    incidentSites: 'Olay Bölgesi',
    incidentSiteText: "Olay Bölgesi Tarifi",
    counterMeasure: "Alınması Gereken Önlem",
    reportPhotos: "Olay Yeri Resimleri",

    incidentTypes: 'Olay Türü',
    injuredPartOfBody: 'Yaralanan Bölge',
    injuredPartsOfBody: 'Yaralanan Bölge',

    injuredPartOfBodyText: "Yaralanan bölgeyle ilgili ekstra bilgi",
    injuryTypeText: "Yaralanma türüyle ilgili ekstra bilgi",
    occupationOfVictimText: "Göreviyle ilgili ekstra bilgi",
    emergencyChangeTitle: "Öncelik Seviyesi",
    emergencyChangeDescription: "Raporu onaylamadan önce raporun sizin için öncelik seviyesini belirleyin. Ardından onay tuşuna tıklayın.",
    reject: "Raporu Reddet",
    acceptReview: "Raporu Onayla",
    reportAlreadyAccepted: "Bu rapor onaylanmış.",
    reportAlreadyRejected: "Bu rapor reddedilmiş.",

    username: "Kullanıcı Adı",
    firstName: "Ad",
    lastName: "Soyad",

    injuryType: 'Yaralanma Türü',
    injuryTypes: 'Yaralanma Türü',

    isCausedByBehaviour: 'Olayın Sebebi',
    occupationOfVictim: 'Görev',
    occupationsOfVictim: 'Görev',

    sexOfVictim: 'Cinsiyet',
    sexesOfVictim: 'Cinsiyet',

    yearsOfExperienceOfVictim: 'Tecrübe',
    type: 'Bildirim Türü',
    "MODERATE": 'Orta',
    "LOW": 'Düşük',
    "HIGH": 'Yüksek',
    "0-1": '0-1 yıl',
    "2-5": '2-5 yıl',
    "6+": '6+ yıl',
    isCausedByBehaviourTrue: "Kişiden kaynaklı",
    isCausedByBehaviourFalse: "Ortamdan kaynaklı",
    

    //events 
    'LOGIN': 'Kullanıcı uygulamaya giriş yaptı.',
    'LOGOUT': 'Kullanıcı hesabından çıktı.',
    'LOGOUT_FROM_ALL_DEVICES': 'Kullanıcı tüm cihazlardaki oturumlarını kapattı.',
    'SUBMIT_REPORT': 'Kullanıcı yeni bir rapor gönderdi.',
    'BEGIN_CONTEST': 'Kullanıcı yarışmaya başladı.',
    'COMPLETE_CONTEST': 'Kullanıcı yarışmayı tamamladı.',
    'UPDATE_USER_PHOTO': 'Kullanıcı fotoğrafını değiştirdi.',
    'REMOVE_USER_PHOTO': 'Kullanıcı fotoğrafını sildi.',
    'BEGIN_MEDIA_CONTENT_TEST': 'Kullanıcı medya içeriğinin testine başladı.',
    'COMPLETE_MEDIA_CONTENT_TEST': 'Kullanıcı medya içeriğinin testini bitirdi.',
    'BEGIN_MINI_TEST': 'Kullanıcı mini-teste başladı.',
    'COMPLETE_MINI_TEST': 'Kullanıcı mini-testi bitirdi.',
    'BEGIN_SURVEY': 'Kullanıcı ankete başladı.',
    'COMPLETE_SURVEY': 'Kullanıcı anketi bitirdi.',
    noActivity: 'Bu kullanıcının etkinlik kaydı bulunmamaktadır.',
    scoreDistribution: "PUAN DAĞILIMI",
    scoreDistributionText1: "Toplam ",
    scoreDistributionText2: "{{score}} puan",
    scoreDistributionText3: ".",
    miniTest: "Mini Test",
    report: "Bildirim",
    completedContents: 'Tamamlanan İçerikler',
    "MINI-TEST-COUNT": "Soru Avcısı",
    "PODCAST-COUNT": "İyi Dinleyici",
    "VIDEO-COUNT": "Video Kralı",
    "REPORT-COUNT": "Güven Veren",
    "TOTAL-SCORE": "Skorer",
    "WELCOME": "Hoşgeldin",
    badges: 'Rozetler',
    noBadge: "Bu kullanıcı henüz bir rozet kazanmamış.",
    noUserAnswered: 'Henüz bu soru kimse tarafından cevaplanmamış.',
    announcementRemoveSuccess: '{{title}} isimli duyuru başarıyla silindi.',
    removeAnnouncement: '{{title}} isimli duyuruyu silmek istediğinize emin misiniz?',
    notifText: 'Lütfen göndermek istediğiniz anlık duyuruyu yazın.',
    notifications: 'Anlık Duyurular',
    notificationText: 'Anlık Duyuru Yazısı',
    downloadAllReports: 'Tüm Bildirimleri İndir',
    downloadAllRankings: 'Sıralama Verisini İndir',
    downloadEmployeePasswords: 'Çalışan Bilgilerini İndir',
    announcementSuccess: 'Duyurunuz başarıyla gönderildi.',
    announcementSending: 'Duyurunuz gönderilirken lütfen sayfayı kapatmadan bekleyin.',
    processing: 'İşleniyor',
    announcementPreview: 'Duyuru Ön İzleme',
    noReportFilter: 'Kriterlere uygun herhangi bir grafik bulunamadı.'

  },
  en: {
    //general
    email: "Email",
    others: "Others",
    yes: 'Yes',
    no: 'No',

    //Login Screen
    login: 'Log In',
    loginHeader: 'Login',
    loginSubText: 'Please enter your credentials',
    password: 'Password',
    wrongEmail: 'Invalid Email',
    wrongEmailSubText: 'The email address you entered is invalid.',
    loginProblem: 'Is there a problem?',
    contactUs: 'Contact Us',
    loginCodeSubText: 'Please enter the 6 digit security code\nsent to your e-mail address.',
    wrongCode: 'Failed Attempt',
    wrongCodeSubText: 'The security code you entered is incorrect.\nPlease try again.',

    //LoginCode Screen
    loginCodePlaceholder: "Login Code",
    loginCodeTitle: 'Login Code',
    send: 'Send',
    loginSuccessTitle: 'Login Successful, welcome.',
    loginSuccessBody: '{{firstname}} {{lastname}} on behalf of {{company}}',

    //tab home
    dashboard: 'Dashboard',
    smartTimeline: 'Content Timeline',
    announcements: 'Announcements',
    contests: 'Contests',
    notifications: 'Notifications',
    settings: 'Settings',
    go: 'Go',
    welcome: 'Hi {{name}}, welcome back!',

    //dashboard
    activeUsers: "Active Users",
    users: 'Users',
    employees: 'Employees',
    reports: 'Reports',
    pendingReports: 'Pending Reports',
    leaderboard: 'Leaderboard',
    leaderboardSubText: 'Check out the rankings.',
    leaderboardEmpty: 'No users have earned points yet.',
    survey: 'Survey',
    surveySubText: 'Manage your surveys.',
    activeSurveyText1: "Your `{{name}}` survey which was started at {{startsAt}} has been answered by",
    activeSurveyText2: ' {{completedCount}} people',
    activeSurveyText3: ' ({{userPercentage}}).',
    activeSurveyText4: ' {{leftDay}} days',
    activeSurveyText5: ' left until the end of the survey.',
    surveyEmpty: 'No active survey',
    manageSurveys: 'Manage Surveys',
    contestEndsAt: 'Contest ends in',
    seeRankings: 'See Rankings',
    startContest: 'Start Contest',
    noActiveContest: 'No active contest',
    noActiveContestSubText: 'Check out the frequently asked questions for more information.',
    weekly: 'Weekly',
    monthly: 'Monthly',
    active: 'Active',
    leaderboardTitle: 'Leaderboard',
    leaderboardDescriptionLong: "The leaderboard shows all user's rankings according to the points they have accumulated",
    leaderboardTipTitle: 'Useful Tip',
    leaderboardTipDescription: 'Rewarding your employees every year or at intervals increases content consumption significantly.',
    leaderboardTipDescription2: 'You can publish the details of the competition as an announcement in the announcements section.',
    goBack: "Go Back",
    ranking: "Ranking",
    tags: "Tags",
    tagsEmpty: "No tags created yet.", 
    deleteTag: "Delete Tag",
    cancel: "Cancel",
    deleteTagTitle: "Delete Tag",
    deleteTagDescription: "Are you sure you want to delete this tag?",
    details: "Details",
    tagDetailsTitle: "Tag Details",
    tagDetailsDescriptionLong: "Tags can be used for many purposes. For example, you would use tags in surveys, contests, or announcements that you want to create only for specific employees. On this screen, you can edit tags and manage which employees belong to which groups.",
    tagErrorMessage: "Tags must be 1-16 characters long.",
    tagNotSelected: "Please select tag for details.",
    addTag: "Add Tag",
    surveyLoading: 'The survey details are loading. Please wait.',
    add: 'Add',
    tagSettings: 'Tag Settings',
    selectUser: 'You can search for the user you want to view from the left side.',
    stats: 'Stats',
    activity: 'Activity',
    completedContestCount: 'Contest',
    completedSurveyCount: 'Survey',
    completedMiniTestCount: 'Mini-Test',
    completedPodcastCount: 'Podcast',
    completedVideoCount: 'Video',

    contestRankingDescription: 'The leaderboard of the competition shows the ranking of the participants in the competition you created. Users earn points according to their answers in the competition.',
    plusTags: '+{{count}} tags',
    activeSurveys: 'Active Surveys',
    oldSurveys: 'Past Surveys',
    searchInput: 'Search Survey',
    participantCount: 'Participant Count',
    active: 'Active',
    inactive: 'Inactive',
    status: 'Status',
    clearFilters: 'Clear Filters',
    apply: 'Apply',
    newSurvey: 'New Survey',
    surveys: 'Surveys',
    answeredCount: 'Number of Answers',
    percentage: 'Percentage',
    pending: 'Pending',

    //create survey
    surveyTitle: 'Survey Title',
    surveyTitleBody: 'Please add a title for your survey.',
    surveyDescription: 'Survey Description',
    surveyDescriptionBody: 'Please add a description for your survey.',
    surveyQuestionsTitle: 'Survey Questions',
    surveyQuestionsBody: 'Please create questions for your survey.',
    surveyDates: 'Survey Dates',
    surveyDatesBody: 'Please set a start and end date for your survey.',
    surveyTagTitle: 'Survey Tags',
    surveyTagBody: 'Please select the tags you want to answer your survey.',

    question: 'Question',
    answers: 'Answers',
    startsAt: 'Starts At: {{date}}',
    endsAt: 'Ends At: {{date}}',
    addDate: 'Add a Date',
    seeDetails: 'See Details',
    logout: 'Log Out',

    contestTitle: 'Contest Title',
    contestTitleBody: 'Please add a title for your contest.',
    contestDescription: 'Contest Description',
    contestDescriptionBody: 'Please add a description for your contest.',
    contestQuestionsTitle: 'Contest Questions',
    contestQuestionsBody: 'Please create questions for your contest.',
    contestDates: 'Contest Dates',
    contestDatesBody: 'Please set a start and end date for your contest.',
    contestTagTitle: 'Contest Tags',
    contestTagBody: 'Please select the tags you want to answer your contest.',
    addAnswer: 'Add Answer',
    newContest: 'New Contest',
    searchContest: 'Search contest',
    contestDetails: 'Contest Details',
    contestLoading: 'Contest details are loading. Please wait.',
    mediaContentLoading: 'Media content details are loading. Please wait.',
    mediaContentDetails: '{{type}} Details',
    video: 'Video',
    podcast: 'Podcast',
    miniTestDetails: 'Mini Test Details',
    noTheme: "There isn't any assigned theme.",
    miniTestLoading: 'Mini test details are loading. Lütfen bekleyin...',
    usersLoading: 'Users are loading...',
    joinedAt: 'Registered on {{date}}',
    notJoinedYet: 'This user is not yet logged into the application.',
    loading: 'Loading',

    //announcement
    announcement: 'Announcement',
    announcementTitle: 'Announcement Title',
    announcementTitleBody: 'Please add a title for your announcement.',
    announcementTextDescription: 'Please add a description for your announcement.',
    link: 'Link',
    linkDescription: 'You can enter the link you want to add here.',
    announcementTags: 'Announcement Tags',
    announcementTagsBody: 'Please select the tags you want to see your announcement.',
    notifTags: 'Notification Tags',
    notifTagsBody: 'Please select the tags you want to receive your notification.',
    addFile: 'Add File',
    pickFile: 'Pick File (Image or Video)',
    addFileBody: 'Please select files you want to upload. You can only add 1 image or 1 video.',
    announcementVideo: 'Announcement Video',
    announcementPhoto: 'Announcement Photo',
    next: 'Next',

    announcementSendSuccess: 'Congrats!',
    announcementSendSuccessBody: 'Your announcement has been sent successfully. You can view your announcement from the announcements section on the right side of the screen.',
    gradingQuestion: 'This question will be rated on a scale of 1-10.',

    titleError: 'You must enter a title to continue.',
    textError: 'You must enter an announcement text to continue.',
    dateError: 'You must enter a publish date to continue.',
    tagError: 'You must select at least 1 tag to continue.',
    questionError: 'You must create at least 1 question.',
    descriptionError: 'You must enter a description.',
    startsAtError: 'You must select a start date.',
    endsAtError: 'You must select an end date.',
    fileError: 'You must add a file.',
    mediaContentUplaoding: 'Please wait before closing the page while the media content is being sent.',

    writHere: 'Write here',
    mon: 'Mon',
    tue: 'Tue',
    wed: 'Wed',
    thu: 'Thu',
    fri: 'Fri',
    sat: 'Sat',
    sun: 'Sun',
    save: 'Save',

    mediaContentTitle: 'Media Content Title',
    mediaContentTitleBody: 'Please enter a title for media content.',
    mediaContentDescription: '{{type}} Description',
    mediaContentDescriptionBody: 'Please enter a description for media content.',
    addMediaContentBody: 'Please drag and drop the media file you want to upload.',
    addMediaContent: 'Add Content',
    mediaContentQuestionsTitle: 'Media Content Questions',
    mediaContentQuestionsBody: 'Please create questions for your content.',

    content: 'content',
    addTheme: 'Add Theme',
    addPodcast: 'Add Podcast',
    addVideo: 'Add Video',
    noContent: 'There is no content for this month. You can add themes to create some content.',
    "MINI-TEST": "mini test",
    "VIDEO": "video",
    "PODCAST": "podcast",
    themes: 'Themes',
    noThemeSelected: 'Please select a theme to add.',
    noAvailablePackage: 'There is no available theme to add.',
    notifSuccess: 'Notification sent successfully.',

    emergencyDegree: 'Emergency Degree',
    incidentSite: 'Incident Site',
    incidentSites: 'Incident Sites',
    incidentSiteText: "Incident Site Description",
    counterMeasure: "Counter Measure",
    reportPhotos: "Photos",

    injuredPartOfBodyText: "Extra Info - Injured Part of Body",
    injuryTypeText: "Extra Info - Injury Type",
    occupationOfVictimText: "Extra Info - Accupation of Victim",
    username: "Username",
    firstName: "First Name",
    lastName: "Last Name",
    emergencyChangeTitle: "Öncelik Seviyesi",
    emergencyChangeDescription: "Raporu onaylamadan önce raporun sizin için öncelik seviyesini belirleyin. Ardından onay tuşuna tıklayın.",
    "NEAR-MISS": "Near Miss",
    "ACCIDENT": "Accident",
    "THREAT": "Threat",
    review: 'Review',
    seeAll: 'See All',
    reject: "Reject",
    acceptReview: "Accept",
    reportAlreadyAccepted: "This report was accepted.",
    reportAlreadyRejected: "This report was rejected.",

    //events 
    'LOGIN': 'User is logged in.',
    'LOGOUT': 'User is logged out.',
    'LOGOUT_FROM_ALL_DEVICES': 'User has logged out of all devices.',
    'SUBMIT_REPORT': 'User submitted a new report.',
    'BEGIN_CONTEST': 'User started a contest.',
    'COMPLETE_CONTEST': 'User completed a contest.',
    'UPDATE_USER_PHOTO': 'User changed photo.',
    'REMOVE_USER_PHOTO': 'User removed photo.',
    'BEGIN_MEDIA_CONTENT_TEST': 'User started the test of a media content.',
    'COMPLETE_MEDIA_CONTENT_TEST': 'User has finished the test of a media content.',
    'BEGIN_MINI_TEST': 'User started a mini-test.',
    'COMPLETE_MINI_TEST': 'User completed a mini-test.',
    'BEGIN_SURVEY': 'User started a survey.',
    'COMPLETE_SURVEY': 'User completed a survey.',
    noActivity: 'This user has no event record.',
    scoreDistribution: "SCORE DISTRIBUTION",
    scoreDistributionText1: "This user ",
    scoreDistributionText2: "{{score}} points",
    scoreDistributionText3: " in total.",
    minitest: "Mini Test",
    report: "Report",
    completedContents: 'Completed Contents',
    "MINI-TEST-COUNT": "Question Hunter",
    "PODCAST-COUNT": "Podcast King",
    "VIDEO-COUNT": "Video Leader",
    "REPORT-COUNT": "Safe Driver",
    "TOTAL-SCORE": "Scorer",
    "WELCOME": "Welcome",
    badges: 'Badges',
    noBadge: "This user has not win any badge yet.",
    noUserAnswered: 'This question has not answered by any users yet.',
    announcementRemoveSuccess: 'Announcement named {{title}} has been removed successfully.',
    removeAnnouncement: 'Are you sure you want to remove the "{{title}}" announcement?',
    notifText: 'Please write your notification.',
    notifications: 'Notifications',
    notificationText: 'Notification Text',
    downloadAllReports: 'Download All Reports',
    downloadAllRankings: 'Download Ranking Data',
    downloadEmployeePasswords: 'Download Employee Info',
    announcementSuccess: 'Your announcement has sent successfuly.',
    announcementSending: "Please don't close the page while your announcement is sent.",
    processing: 'Processing',
    announcementPreview: 'Announcement Preview',
    noReportFilter: 'No charts found matching the filter criterias.'
  }
}

module.exports = Languages