import React from "react";
import { ScrollView, StyleSheet, Text, View, Animated, Pressable, Image, Dimensions } from "react-native";
import API from '../utils/API.js';
import FontStyles from "../utils/FontStyles";
import Input from '../components/Input'
import i18n from "i18n-js";
import DatePicker from "../components/DatePicker";
import Colors from "../utils/Colors";
import SurveyTags from "../components/SurveyTags";
import QuestionAdder from "../components/QuestionAdder";
import I18n from "i18n-js";
import Button from "../components/Button";
import SurveyPreview from "../components/SurveyPreview";
import WebAlert from "../components/WebAlert";
import { Feather } from "@expo/vector-icons"
import moment from "moment";
import { BlurView } from "expo-blur";

const bg = "#F3FAFF"
const { width, height } = Dimensions.get('window')

export default class CreateSurvey extends React.PureComponent{
  constructor(props){
    super(props)
    this.errorTextOpacity = new Animated.Value(0)
    this.reportValue = new Animated.Value(0)

    this.inputs = {
      title: null,
      tags: null,
      description: null,
      endsAt: null,      
      questions: null,
      startsAt: null
    }

    this.reportAnim = {
      transform: [
        {
          translateY: this.reportValue.interpolate({
            inputRange: [0,1],
            outputRange: [height, 0],
            extrapolate: 'clamp'
          })
        }
      ]
    }

    this.state = {
      openDatePicker: false,
      errorType: null,
      clock: { start: 11, end: 11 },
      minClock: 0
    }
  }

      // tagIds: tagIds,
			// startsAt: startsAt,
			// endsAt: endsAt,
			// title: title,
			// description: description,
			// questions: questions
  
  setInputs = (text, type) => {
    this.inputs[type] = text
  }

  onDateChange = (object) => {    
    const {startsAt, endsAt} = object

    if(startsAt){
      this.inputs.startsAt = startsAt.valueOf() + ( -1 * new Date().getTimezoneOffset() - API.timezoneOffset ) * 60 * 1000

      if(startsAt.isSame(new Date(), 'day')){
        const currentHour = new Date(Date.now() + API.timezoneOffset * 60 * 1000).getUTCHours()
        let clockObj = {
          start: (this.state.clock.start < currentHour + 1) ? currentHour + 1 : this.state.clock.start,
          end: this.state.clock.end
        }

        this.setState({ minClock: currentHour + 1, clock: clockObj})
      }else{
        this.setState({
          minClock: 0
        })
      }
    }

    if(endsAt){
      this.inputs.endsAt = endsAt.valueOf() + ( -1 * new Date().getTimezoneOffset() - API.timezoneOffset ) * 60 * 1000
    }
  }

  onTagPressed = (tags) => {
    this.inputs.tags = [...tags]
  }

  onSavePress = () => {
    this.inputs.questions = [...this.questionAdderRef.state.questions]
    const errorType = this.errorHandler()

    if(!errorType){
      this.animateErrorText(0,()=> {
        const inputs = JSON.parse(JSON.stringify(this.inputs))
        
        inputs.startsAt += this.state.clock.start * 60 * 60 * 1000
        inputs.endsAt += this.state.clock.end * 60 * 60 * 1000

        this.contestPreview.setIsShowing(true, inputs)
        this.setState({ errorType: null })
      })
    }else{
      this.setState({ errorType: errorType },()=> {
        this.animateErrorText(1)
      })
    }
  }

  errorHandler = () => {

    const requiredData = [
      {key: 'title', translation: 'titleError'}, 
      {key: 'startsAt', translation: 'dateError'}, 
      {key: 'tags', translation: 'tagError'},
      {key: 'endsAt', translation: 'dateError'},
      {key: 'questions', translation: 'questionError'}
    ]

    for(const obj of requiredData){
      if(!this.inputs[obj.key] || this.inputs[obj.key].length === 0){
        return obj
      }
    }

    return null
  }

  animateErrorText = (toValue, callback) => {
    Animated.timing(this.errorTextOpacity, {
      toValue: toValue,
      duration: 350,
      useNativeDriver: true
    }).start(()=> {
      callback && callback()
    })
  }

  handleAlert = (data) => {
    this.alert.setIsShowing(data)
  }

  handleClock = (increment, type) => {
    if(type === 'start'){
      this.setState(function(prevState){
        if( prevState.clock.start === 23 && increment === 1 ){
          return { clock: {...prevState.clock, start: prevState.minClock} }
        }else if( prevState.clock.start <= prevState.minClock && increment === -1 ){
          return { clock: {...prevState.clock, start: 23} }
        }
  
        return { clock: { start: prevState.clock.start + (increment), end: prevState.clock.end } }
      })
    }else{
      this.setState(function(prevState){
        if( prevState.clock.end === 23 && increment === 1 ){
          return { clock: {...prevState.clock, end: 0} }
        }else if( prevState.clock.end <= 0 && increment === -1 ){
          return { clock: {...prevState.clock, end: 23} }
        }
  
        return { clock: { end: prevState.clock.end + (increment), start: prevState.clock.start } }
      })
    }

  }

  animateReport = (value) => {
    Animated.timing(this.reportValue, {
      toValue: value, 
      duration: 350,
      useNativeDriver: true
    }).start()
  }

  report = () => {
    if(!this.props.route?.params?.report){
      return null
    }

    return (
      <Animated.View style={[{width: '100%', height: '100%', position: 'absolute'}, this.reportAnim]}>
        <BlurView intensity={100} style={{width: '100%', height: '100%'}}>
          <ScrollView 
            showsVerticalScrollIndicator={false}
            style={[styles.column, {flex: 1, marginLeft: 16}]}
          >
            <View style={{flexDirection: 'row', alignItems: 'center', flex: 1, marginTop: 24}}>
              <Text style={[FontStyles.title2.semibold, { marginLeft: 8, flex: 1}]}>
                {"Genel Bilgiler"}
              </Text>
              <Pressable style={styles.cancelButton} onPress={() => this.animateReport(0)} activeOpacity={0.9}>
                <Feather name="x" size={26} color={Colors.white.alpha08}/>
              </Pressable>
            </View>

            <View style={styles.innerColumn}>
              <View style={styles.row}>
                <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha08}]}>
                  Raporun Kategorisi 
                </Text>
                <Text style={[FontStyles.callout.regular, {color: Colors.secondaryBlue.alpha1}]}>
                  {I18n.t(this.props.route?.params?.report?.type)}
                </Text>
              </View>
              <View style={styles.seperator}/>

              <View style={styles.row}>
                <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha08}]}>
                  Gönderilme Tarihi
                </Text>
                <Text style={[FontStyles.subhead.medium, {color: Colors.secondaryBlue.alpha1}]}>
                  {moment(this.props.route?.params?.report?.createdAt).utcOffset(API.timezoneOffset).format('DD.MM.YY HH:mm') }
                </Text>
              </View>
              <View style={styles.seperator}/>

              <View style={styles.row}>
                <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha08}]}>
                  {I18n.t("emergencyDegree")}
                </Text>
                <View style={[styles.emergencyDegreeTextContainer, {backgroundColor: this.props.route?.params?.report?.emergencyDegree == "HIGH" ? Colors.red.alpha1 : this.props.route.params?.report?.emergencyDegree == "MODERATE" ? Colors.orange.alpha1 : Colors.yellow.alpha1}]}>
                  <Text style={[FontStyles.subhead.medium, {color: this.props.route.params?.report?.emergencyDegree == "LOW" ? Colors.dark.alpha1 : Colors.white.alpha1}]}>
                    {I18n.t(this.props.route.params?.report?.emergencyDegree)}
                  </Text>
                </View>
              </View>
              <View style={styles.seperator}/>

              <View style={styles.row}>
                  <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha08}]}>
                    {I18n.t("isCausedByBehaviour")}
                  </Text>
                  <Text style={[FontStyles.callout.regular, {color: Colors.secondaryBlue.alpha1}]}>
                    {this.props.route.params?.report?.isCausedByBehaviour ? I18n.t("isCausedByBehaviourTrue") :  I18n.t("isCausedByBehaviourFalse")}
                  </Text>
                </View>
                <View style={styles.seperator}/>

              <View style={styles.cell}>
                <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha08}]}>
                  Açıklama
                </Text>
                <View style={styles.textAreaContainer}>
                  <Text style={[FontStyles.subhead.regular, {color: Colors.dark.alpha1, lineHeight: 22}]}>
                    {this.props.route.params?.report?.description || "Çalışan açıklama yazısını eklemedi."}
                  </Text>
                </View>
              </View>
              <View style={styles.seperator}/>

              <View style={styles.row}>
                <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha08}]}>
                  {I18n.t("incidentTypes")}
                </Text>
                <Text style={[FontStyles.callout.regular, {color: Colors.secondaryBlue.alpha1}]}>
                  {this.props.route.params?.report?.incidentTypesNames.join(', ')}
                </Text>
              </View>
              <View style={styles.seperator}/>

              <View style={styles.row}>
                <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha08}]}>
                  {I18n.t("incidentSite")}
                </Text>
                <Text style={[FontStyles.callout.regular, {color: Colors.secondaryBlue.alpha1}]}>
                  {this.props.route.params?.report?.incidentSiteName}
                </Text>
              </View>
              <View style={styles.seperator}/>

              <View style={styles.cell}>
                <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha08}]}>
                  {I18n.t("incidentSiteText")}
                </Text>
                <View style={styles.textAreaContainer}>
                  <Text style={[FontStyles.subhead.regular, {color: Colors.dark.alpha1, lineHeight: 22}]}>
                    {this.props.route.params?.report?.incidentSiteText || "Olay yeri tarifi eklenmedi."}
                  </Text>
                </View>
              </View>
              <View style={styles.seperator}/>

              <View style={styles.cell}>
                <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha08}]}>
                  {I18n.t("counterMeasure")}
                </Text>
                <View style={styles.textAreaContainer}>
                  <Text style={[FontStyles.subhead.regular, {color: Colors.dark.alpha1, lineHeight: 22}]}>
                    {this.props.route.params?.report?.counterMeasure || "Alınması gereken önlem belirtilmedi."}
                  </Text>
                </View>
              </View>
              <View style={styles.seperator}/>

              <View style={styles.cell}>
                <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha08}]}>
                  {I18n.t("reportPhotos")}
                </Text>
                <View style={[styles.textAreaContainer, {alignSelf: null, flexDirection: "row", flexWrap: "wrap"}]}>
                  {
                    this.props.route.params?.report?.photos.length !== 0 ? 
                    this.props.route.params?.report?.photos.map((item, index) => {
                      return (
                        <Image key={index+"photos"} style={[styles.photo, {marginRight: index%2 === 0 ? "2.5%" : 0}]} source={{uri: this.props.mediaBasePath + item}} />
                      )
                    })
                    :
                    <Text style={[FontStyles.subhead.regular, {color: Colors.dark.alpha1, lineHeight: 22}]}>
                      {"Çalışan herhangi bir fotoğraf eklemedi."}
                    </Text>
                  }
                </View>
              </View>
              <View style={styles.seperator}/>
            </View>
          </ScrollView>

        </BlurView>
      </Animated.View>
      
    )
  }

  render(){
    return (
      <View style={styles.container}>
        <ScrollView 
          style={{flex: 1}}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{ paddingTop: 24, paddingHorizontal: 24, paddingBottom: 24 }}
        >
          <View style={{ flexDirection: 'row', width: '100%'}}>
            
            <View style={{ flex: 1.5, paddingRight: 24, alignSelf: 'baseline' }}>
              <Input
                title={i18n.t('contestTitle')}
                titleDescription={i18n.t('contestTitleBody')}
                onChangeText={this.setInputs}
                type={'title'}
                style={{ width: '100%' }}
                inputStyle={{ width: '100%' }}
                maxLength={32}
              />
              <Input
                title={i18n.t('contestDescription')}
                titleDescription={i18n.t('contestDescriptionBody')}
                onChangeText={this.setInputs}
                type={'description'}
                style={{ marginTop: 24, width: '100%' }}
                inputStyle={{ width: '100%' }}
                maxLength={200}
              />
              <SurveyTags
                onTagPressed={this.onTagPressed}
                title={I18n.t('contestTagTitle')}
                description={I18n.t('contestTagBody')}
                withTagData
              />
              <Text style={[FontStyles.body.bold, { color: Colors.dark.alpha1, marginTop: 24 }]}>
                {i18n.t('contestDates')}
              </Text>
              <Text style={[FontStyles.callout.regular, { color: Colors.dark.alpha08, marginTop: 12 }]}>
                {i18n.t('contestDatesBody')}
              </Text>
              <DatePicker 
                onSelectionChange={this.onDateChange}
                colors={[ Colors.purple.alpha1, Colors.purple.alpha01 ]}
                enableRange
                isSingle
                containerStyle={{marginTop: 24}}
              />
              <View style={{flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', marginTop: 24}}>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <Pressable style={styles.clockSetter} onPress={()=> this.handleClock(-1, 'start')}>
                    <Feather
                      name='minus'
                      size={20}
                      color={Colors.dark.alpha08}
                    />                  
                  </Pressable>
                  <View style={styles.clock}>
                    <Text style={[FontStyles.body.medium, { color: Colors.dark.alpha08 }]}>
                      {this.state.clock.start.toString().padStart(2, '0') + ':00'}
                    </Text>
                  </View>
                  <Pressable style={styles.clockSetter} onPress={()=> this.handleClock(1, 'start')}>
                    <Feather
                      name='plus'
                      size={20}
                      color={Colors.dark.alpha08}
                    />
                  </Pressable>
                </View>
                <View style={{flexDirection: 'row', alignItems: 'center', marginLeft: 24}}>
                  <Pressable style={styles.clockSetter} onPress={()=> this.handleClock(-1, 'end')}>
                    <Feather
                      name='minus'
                      size={20}
                      color={Colors.dark.alpha08}
                    />                  
                  </Pressable>
                  <View style={styles.clock}>
                    <Text style={[FontStyles.body.medium, { color: Colors.dark.alpha08 }]}>
                      {this.state.clock.end.toString().padStart(2, '0') + ':00'}
                    </Text>
                  </View>
                  <Pressable style={styles.clockSetter} onPress={()=> this.handleClock(1, 'end')}>
                    <Feather
                      name='plus'
                      size={20}
                      color={Colors.dark.alpha08}
                    />
                  </Pressable>
                </View>
              </View>
              <View style={{ marginTop: 20, width: '50%', minWidth: 200 }}>
                <Animated.Text style={[FontStyles.footnote.medium, { color: Colors.red.alpha1, opacity: this.errorTextOpacity, textAlign: 'center' }]}>
                  {this.state.errorType ? i18n.t(this.state.errorType.translation) : 'sss'}
                </Animated.Text>
                <Button
                  text={i18n.t('next')}
                  style={styles.saveButton}
                  onPress={this.onSavePress}
                />
              </View>
            </View>
            <QuestionAdder
              ref={ref => this.questionAdderRef = ref}
              color={Colors.purple.alpha1}
              title={I18n.t('contestQuestionsTitle')}
              description={I18n.t('contestQuestionsBody')}
              enableCorrectAnswer
            />
          </View> 
        </ScrollView>
        <SurveyPreview 
          ref={ref => this.contestPreview = ref}
          handleAlert={this.handleAlert}
          type={'CONTEST'}
          getData={this.props.route?.params?.getData}
          navigation={this.props.navigation}

        />
        <WebAlert ref={ref => this.alert = ref}/>
        {
          this.props.route?.params?.report &&
          <Pressable style={styles.reportButton} onPress={()=> this.animateReport(1)}>
            <Text style={[FontStyles.footnote.semibold, {color: 'white'}]}>
              {'Raporu Göster'}
            </Text>
          </Pressable>
        }
        {this.report()}
      </View>
      
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: bg,
    borderRadius: 30,
    overflow: 'hidden'
  },
  dateContainer: {
    flexDirection: 'row',
    backgroundColor: Colors.lightGray.alpha1,
    borderRadius: 100,
    width: '100%',
    alignSelf: 'center',
    overflow: 'hidden',
    padding: 8
  },
  dateButton: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 12, 
    borderRadius: 100,
    shadowColor: Colors.dark.alpha1,
    shadowOffset: {width: 0, height: 1},
    shadowOpacity: 0.1,
    shadowRadius: 1
  },
  datePickerContainer: {
    overflow: 'hidden',
    marginTop: 24,
    width: '50%'
  },
  addButton: {
    width: '20%',
    minWidth: 200,
    paddingVertical: 16,
    backgroundColor: 'white',
    borderRadius: 15,
    alignItems: 'center',
    marginTop: 20
  },
  saveButton: {
    paddingVertical: 20,
    width: '100%',
    backgroundColor: Colors.purple.alpha1,
    alignSelf: 'baseline',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 8,
    borderRadius: 12.5
  },
  cancelButton: {
    backgroundColor: Colors.primaryBlue.alpha1,
    borderRadius: 100,
    width: 48,
    height: 48,
    justifyContent: 'center',
    alignItems: "center"
  },
  clockSetter: {
    padding: 4, 
    borderRadius: 7.5, 
    justifyContent: 'center', 
    alignItems: 'center',
    backgroundColor: 'white'
  },
  clock: {
    marginHorizontal: 6, 
    width: 100, 
    backgroundColor: 'white', 
    paddingVertical: 8, 
    alignItems: 'center',
    borderRadius: 10
  },
  innerContainer: {
    flex: 1,
    width: "100%",
    alignItems: "flex-start",
    flexDirection: "row",
  },
  column: {
    flex: 1,
    marginRight: 16,
    height: "100%",

  },
  innerColumn: {
    backgroundColor: Colors.white.alpha1,
    marginTop: 16,
    marginBottom: 16,
    borderRadius: 20,
    width: "100%",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 16,
    paddingVertical: 16
  },
  seperator: {
    width: "100%",
    height: 2,
    alignSelf: "center",
    backgroundColor: Colors.lightGray.alpha05
  },
  cell: {
    paddingHorizontal: 16,
    paddingVertical: 20
  },
  textAreaContainer: {
    backgroundColor: Colors.secondaryBlue.alpha01,
    borderRadius: 8,
    marginTop: 12,
    paddingHorizontal: 12,
    alignSelf: "baseline",
    paddingVertical: 10
  },
  emergencyDegreeTextContainer: {
    paddingHorizontal: 15,
    paddingVertical: 5,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: "center"
  },
  priorityButton: {
    paddingHorizontal: 20,
    paddingVertical: 7.5,
    borderRadius: 25,
    borderWidth: 2,
    borderColor: Colors.gray.alpha04,
    justifyContent: 'center',
    alignItems: "center",
    marginRight: 8
  },
  photo: {
    width: "48.5%",
    marginBottom: 15,
    borderRadius: 10,
    backgroundColor: Colors.gray.alpha01,
    aspectRatio: 1,
  },
  reportButton: {
    position: 'absolute',
    bottom: 0,
    paddingVertical: 8,
    paddingHorizontal: 8,
    backgroundColor: Colors.purple.alpha1,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    left: '45%'
  }
})