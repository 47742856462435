import { memo, useEffect, useReducer } from "react";
import { StyleSheet, View, Text, Image, Pressable } from "react-native";
import {Feather} from '@expo/vector-icons'
import FontStyles from "../utils/FontStyles";
import Colors from "../utils/Colors";
import I18n from "i18n-js";
import { hexToRgba } from "../utils/Helper";
import InfoCardHeader from "./InfoCardHeader";
import API from "../utils/API";

const UserRow = memo(
  function({user, index, score, ranking, mediaBasePath}){
    return (
      <View style={[styles.userContainer, {borderTopWidth: index !== 0 ? 1.5 : 0, borderColor: Colors.lightGray.alpha1}]}>
        <View style={{backgroundColor: Colors.gray.alpha01, borderRadius: 12, alignSelf: "baseline"}}>
          <Image 
            source={user.photo ? { uri:  mediaBasePath + '~' +  user.photo } : require('../assets/icons/user_dummy_image.png')}
            style={[styles.userImage, { tintColor: user.photo ? null : Colors.gray.alpha06 }]}
            resizeMode='cover'
          />  
        </View>
        <View style={{ marginLeft: 15, flex: 1 }}>
          <Text style={[FontStyles.subhead.medium, {color: Colors.gray.alpha08, letterSpacing: -0.5}]}>
            {`${user.firstName} ${user.lastName}`}
          </Text>
          <Text style={[FontStyles.footnote.medium, {color: Colors.dark.alpha06, marginTop: 4}]}>
            {score}
          </Text>
        </View>
        <Text style={[FontStyles.title3.semibold, {color: Colors.gray.alpha06, marginRight: 6}]}>
          #{ranking}
        </Text>
      </View>
    )
  }
)

const reducer = (state, action) => {
  return action.payload
}

const initialState = {
  mediaBasePath: '',
  rankings: []
}

function Leaderboard(props){
  const [ state, dispatch ] = useReducer(reducer ,initialState)

  useEffect(()=> {
    API.getGamificationData(1,3, false).then(({ rankings, mediaBasePath })=> {
      dispatch({ payload: {
        mediaBasePath: mediaBasePath,
        rankings: rankings
      } })
    })
  },[])
  
  return (
    <View style={styles.container}>
      <InfoCardHeader
        iconName={'gift'}
        title={I18n.t('leaderboard')}
        subText={I18n.t('leaderboardSubText')}
        arrowContainerColor={hexToRgba('#F9E266', 0.15)}
        arrowColor={Colors.badgeYellow.alpha1}
        iconContainerColor={Colors.badgeYellow.alpha1}
        borderColor={Colors.yellow.alpha05}
        onPress={() => props.leaderboardDetailsController('general')}
      />
      <View style={styles.rankingContainer}>
        {
          state.rankings.length !== 0 ? 
          state.rankings.map((user,index)=> {
            return (
              <UserRow
                user={user.user}
                index={index}
                score={user.score}
                key={"d" + user.ranking + 'user'}
                mediaBasePath={state.mediaBasePath}
                ranking={user.ranking}
              />
            )
          })
          :
          <View style={styles.emptyContainer}>
            <Feather 
              name= 'gift'
              size={32}
              color={Colors.gray.alpha06}
            />
            <Text style={[FontStyles.body.medium, { color: Colors.gray.alpha06, marginTop: 16, textAlign: 'center' }]}>
              {I18n.t('leaderboardEmpty')}
            </Text>
          </View>
        }
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    shadowColor: '#C4C4C4',
    borderRadius: 20,
    alignItems: 'center',
    marginTop: 17.5,
    paddingBottom: 4,
    alignSelf: "baseline",
    backgroundColor: 'white',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.15,
    shadowRadius: 10,
    marginBottom: 16
  },
  header: {
    flexDirection: 'row',
    width: '100%',
    alignItems: "center",
    borderBottomWidth: 2,
    borderColor: Colors.yellow.alpha02,
    paddingVertical: 17.5,
    paddingHorizontal: 17.5,
  },
  iconContainer: {
    width: 56,
    height: 56,
    borderRadius: 35,
    backgroundColor: Colors.badgeYellow.alpha1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  arrowContainer: {
    backgroundColor: Colors.badgeYellow.alpha02, 
    width: 48,
    height: 48,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center'
  },
  userContainer: {
    width: '100%',
    flexDirection: 'row',
    paddingVertical: 16,
    alignItems: 'center',
    flex: 1,
  },
  userImage: {
    width: 44,
    height: 44,
    borderRadius: 12,
    opacity: 0.88
  },
  rankingContainer:{
    width :'100%',
    paddingHorizontal: 20,
  },
  emptyContainer: {
    justifyContent: 'center', 
    alignItems: 'center',
    height: 200
  }
})

export default memo(Leaderboard)