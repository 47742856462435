import { memo, useState, useEffect, useRef } from "react";
import { View, StyleSheet, Text, Pressable } from "react-native";
import {Feather} from '@expo/vector-icons'
import Colors from "../utils/Colors";
import FontStyles from "../utils/FontStyles";
import I18n from "i18n-js";
import { LinearGradient } from "expo-linear-gradient";
import moment from "moment";
import { CartesianGrid, Label, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import Animated, { interpolate, useAnimatedStyle, useSharedValue, withSpring} from "react-native-reanimated";
import API from "../utils/API";

function fillDataPoints(arr, limit, type){
  let data = [...arr]
  // console.log(data)
  let date = new Date(arr[0][type])

  while(data.length < limit){
    date = moment(date).subtract(1, type).valueOf()
    data.unshift({
      count: 0,
      [type]: date
    })
  }

  return data
} 

function activeUserGraph({weeklyActiveUserCounts, monthlyActiveUserCounts}){
  const [ switchFlag, setSwitchFlag ] = useState('weeklyActiveUsers') 

  const switchVal = useSharedValue(0)

  const translateX = useAnimatedStyle(() => {
    const x = interpolate(switchVal.value, [0, 1], [0, 77])
    return { transform: [{ translateX: x }] }
  })
  
  const weeklyActiveUsers = weeklyActiveUserCounts == null ? [] : fillDataPoints(weeklyActiveUserCounts, 16, 'week')
  const monthlyActiveUsers = monthlyActiveUserCounts == null ? [] : fillDataPoints(monthlyActiveUserCounts, 12, 'month')
  
  const data = (weeklyActiveUserCounts == null && monthlyActiveUserCounts == null) ? null : {weeklyActiveUsers, monthlyActiveUsers}
  
  return (
    <View style={styles.container}>
      <View style={[styles.horizontalContainer, {}]}>
        <View style={[styles.iconContainer, { marginBottom: 20 }]}>
          <Feather
            name="trending-up"
            size={24}
            color={'white'}
          />
        </View>
        <View style={{marginLeft: 16, marginBottom: 20}}>
          <Text style={[FontStyles.body.semibold, {color: Colors.dark.alpha08}]}>
            {I18n.t('activeUsers')}
          </Text>
          <Text style={[{fontSize: 32, fontWeight: "800", color: Colors.gray.alpha1}]}>
            {data == null ? "-" : (data[switchFlag] ? data[switchFlag][data[switchFlag].length - 1]?.count : "-")}
          </Text>
        </View>
        <View style={{flex: 1, marginRight: 8}}/>
        <View style={[styles.switchContainer, { marginBottom: 20 }]}>
          <Animated.View style={[styles.switchBg, translateX]}/>
          <Pressable style={[styles.switchButton, {paddingLeft: 15,}]} onPress={() => {setSwitchFlag('weeklyActiveUsers'); switchVal.value = withSpring(0, {damping: 25}) }}>
            <Text style={[FontStyles.footnote.medium, {color: Colors.secondaryBlue.alpha1}]}>
              { I18n.t('weekly')}
            </Text>
          </Pressable>
          <Pressable style={[styles.switchButton, {paddingRight: 15}]} onPress={() => { setSwitchFlag('monthlyActiveUsers'); switchVal.value = withSpring(1, {damping: 25}) }}>
            <Text style={[FontStyles.footnote.medium, {color: Colors.secondaryBlue.alpha1}]}>
              { I18n.t('monthly')}
            </Text>
          </Pressable>
        </View>
      </View>

        {
          data &&
          <ResponsiveContainer 
            width={"100%"} 
            height={200}
          >
            <LineChart 
              data={data[switchFlag]}
              margin={{ right: 21.5, left: 21.5, bottom: 0 }}
            >
              <CartesianGrid 
                strokeDasharray="2 4" 
                stroke={Colors.gray.alpha02} 
              />
              <XAxis 
                dataKey={switchFlag == 'weeklyActiveUsers' ? 'week' : "month"} 
                tickLine={false} 
                stroke={Colors.gray.alpha04}  
                fontFamily={"sf-medium"} 
                fontSize={12}  
                strokeWidth={2}
                interval={switchFlag == 'weeklyActiveUsers' ? 3 : 0}
                tickFormatter={(value)=> switchFlag == 'weeklyActiveUsers' ? moment(value).format('DD MMM') : moment(new Date(value)).format('MMM')}
              />
              <Tooltip 
                cursor={{ stroke: Colors.secondaryBlue.alpha02, strokeWidth: 2 }}
                content={({ payload, label, active }) => {
                  if (active && payload && payload.length) {
                    return (
                      <View style={styles.tooltip}>
                        <Text style={[FontStyles.subhead.semibold, {color: Colors.white.alpha06}]}>
                          {switchFlag == 'weeklyActiveUsers' ? moment(label).format('DD MMM') : moment(label).format('MMMM') }
                        </Text>
                        <Text style={[FontStyles.body.semibold, {marginTop: 5, color: Colors.white.alpha1}]}>
                          {`${I18n.t('active')}: ${payload[0].value}`}
                        </Text>
                      </View>
                    )
                  }
                  return null
                }}
                // labelStyle={{fontFamily: "sf-bold", fontSize: 15, color: Colors.secondaryBlue.alpha1, }} 
                // contentStyle={{borderRadius: 10, fontFamily: "sf-medium", fontColor: Colors.primaryBlue.alpha1, fontSize: 13, borderWidth: 2, borderColor: Colors.lightGray.alpha1}}
              />
              <Line 
                type="monotone" 
                dataKey="count" 
                stroke="#8ED2FE" 
                strokeWidth={2.5} 
                dot={false} 
              />
            </LineChart>
          </ResponsiveContainer>
      }
        
    </View>
  )
}

const styles = StyleSheet.create({
  container:{
    paddingVertical: 17.5,
    backgroundColor: 'white',
    shadowColor: '#C4C4C4',
    borderRadius: 20,
    width: '100%',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.15, 
    shadowRadius: 10,
    alignSelf: 'baseline'
  },
  horizontalContainer: {
    flexDirection: 'row',
    paddingHorizontal: 17.5,
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  iconContainer: {
    width: 56,
    height: 56,
    borderRadius: 30,
    backgroundColor: Colors.secondaryBlue.alpha1,
    justifyContent: 'center',
    alignItems: 'center'
  },

  //tooltip
  tooltip: {
    padding: 15,
    backgroundColor: Colors.primaryBlue.alpha1,
    borderRadius: 15,
    justifyContent: 'center',
  },


  graphContainer: {
    width: '100%',
  },
  graphHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 15,
  },
  dashedLine: {
    flex: 1,
    marginHorizontal: 15,
    borderStyle: 'dashed',
    borderWidth: 1,
    borderColor: Colors.gray.alpha02,
    borderRadius: 1,
  },
  barContainer: {
    height: 200,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    borderBottomWidth: 2,
    paddingHorizontal: 17.5,
    borderColor: Colors.lightGray.alpha1
  
  },
  bar: {
    flex: 1,
    height: '100%',
    marginRight: 4,
  },
  barFill: {
    flex: 1,
    backgroundColor: "#8ED2FE",
    borderRadius: 1,
    marginRight: 4,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },
  gradient: {
    backgroundColor: "transparent",
    width: "100%",
    height: 30,
    position: 'absolute',
    bottom: 0,
    left: 0,
    opacity: 0.5
  },
  graphFooter: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 15,
    marginHorizontal: 17.5,
    backgroundColor: "blue",
    height: 17.5,
    paddingHorizontal: 17.5,
  },
  switchContainer: {
    backgroundColor: Colors.secondaryBlue.alpha01,
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 10,
    overflow: "hidden",
    width: 140,
    alignSelf: "flex-start",
    paddingHorizontal: 5
  },
  switchButton: {
    flex: 1,
    paddingVertical: 10,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 15
  },
  switchBg: {
    backgroundColor: Colors.secondaryBlue.alpha02,
    height: "100%",
    width: "55%",
    left: "-5%",
    position: "absolute",
  }
})

export default memo(activeUserGraph)