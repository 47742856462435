import { ScrollView, StyleSheet, View, Animated } from "react-native";
import Leaderboard from "../../components/Leaderboard";
import SurveyCard from "../../components/SurveyCard";
import InfoCard from "../../components/InfoCard"
import ContestCard from "../../components/ContestCard";
import { hexToRgba } from '../../utils/Helper'
import I18n from "i18n-js";
import Colors from "../../utils/Colors";
import ActiveUserGraph from "../../components/ActiveUserGraph";
import { memo, useEffect, useRef, useState } from "react";
import API from "../../utils/API";
import LeaderboardDetails from "../../components/LeaderboardDetails";
import ContestDetails from "../../components/ContestDetails";

const bg = '#F3FAFF'

function Dashboard(props){
  const [ employeeCount, setEmployeeCount ] = useState(0)
  const [ pendingReportCount, setPendingReportCount] = useState(0)
  const [ weeklyActiveUserCounts, setWeeklyActiveUserCounts] = useState(null)
  const [ monthlyActiveUserCounts, setMonthlyActiveUserCounts] = useState(null)
  
  const [ leaderboardType, setLeaderboardType ] = useState(null)
  const leaderboardDetailAnimVal = useRef(new Animated.Value(0))
  const contestDetailsRef = useRef()
  
  useEffect(() => {
    
    API.getStats().then((res) => {
      setEmployeeCount(res.employeeCount)
      setPendingReportCount(res.pendingReportCount)
      setWeeklyActiveUserCounts(res.weeklyActiveUserCounts)
      setMonthlyActiveUserCounts(res.monthlyActiveUserCounts)
    })
  }, [])

  const leaderboardDetailsController = (toValue) =>{
    toValue && setLeaderboardType(toValue)
    setTimeout(() => {
      Animated.spring(leaderboardDetailAnimVal.current, {
        toValue: toValue ? 1 : 0,
        useNativeDriver: false,
        delay: 0,
        duration: 200,
        bounciness: 5,
      }).start(() => {
        !toValue && setLeaderboardType(toValue)
      })
    }, 0);
    
  }

  return (
    <View style={styles.container}>
      <View style={styles.scrollContainer}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{paddingVertical: 17.5}}
        >
          <ActiveUserGraph weeklyActiveUserCounts={weeklyActiveUserCounts} monthlyActiveUserCounts={monthlyActiveUserCounts}/>
          <View style={{flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between'}}>
            <SurveyCard/>
            <View style={{ width: '2%' }}/>
            <ContestCard
              leaderboardDetailsController={contestDetailsRef.current?.setIsShowing}
              
            />
          </View>
          
        </ScrollView>
      </View>
      <View style={styles.cardContainer}> 
        <InfoCard
          iconName={'users'}
          title={I18n.t('employees')}
          subText={employeeCount}
          arrowColor={Colors.greenLight.alpha1}
          iconContainerColor={Colors.greenLight.alpha1}
          arrowContainerColor={hexToRgba('#7DD381', 0.1)}
          onPress={()=> props.navigation.navigate('Users')}
        />
        <InfoCard
          iconName={'file'}
          title={I18n.t('pendingReports')}
          subText={pendingReportCount}
          arrowColor={Colors.badgeOrange.alpha1}
          iconContainerColor={Colors.badgeOrange.alpha1}
          arrowContainerColor={hexToRgba('#EC7063', 0.08)}
          containerStyle={{ marginTop: 17.5 }}
          onPress={()=> props.navigation.navigate('Reports')}
        />
        <InfoCard
          iconName={'bell'}
          title={I18n.t('notifications')}
          arrowColor={Colors.badgeYellow.alpha1}
          iconContainerColor={Colors.badgeYellow.alpha1}
          arrowContainerColor={hexToRgba('#f6d65c', 0.15)}
          containerStyle={{ marginTop: 17.5 }}
          onPress={()=> props.navigation.navigate('Notifications')}
        />

        <Leaderboard 
          leaderboardDetailsController={leaderboardDetailsController}
        />
      </View>

      {
        leaderboardType &&
        <LeaderboardDetails 
          leaderboardDetailAnimVal={leaderboardDetailAnimVal.current} 
          leaderboardDetailsController={leaderboardDetailsController}
          type={leaderboardType}
        />
      }
      <ContestDetails
        ref={contestDetailsRef}
        type={'CONTEST'}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: bg,
    borderRadius: 30,
    paddingHorizontal: 20,
    flexDirection: "row",
    overflow: "hidden",
  },
  scrollContainer: {
    flex: 1,
    height: "100%",
    marginRight: 15,
  },
  cardContainer: {
    alignSelf: 'flex-end', 
    width: '33%', 
    minWidth: 300, 
    height: '100%',
    maxWidth: 450,
    paddingTop: 15
  },
})

export default memo(Dashboard)