import { memo, useEffect, useState } from "react"
import { View, Text, Image, StyleSheet, Pressable } from 'react-native'
import Colors from "../utils/Colors"
import FontStyles from "../utils/FontStyles"
import I18n from "i18n-js"
import moment from "moment"
import Animated, { interpolate, useAnimatedStyle, useSharedValue, withSpring, withTiming } from "react-native-reanimated"
import API from "../utils/API"
import UserStatics from "./UserStatics"
import UserActivity from "./UserActivity"


function UserDetails({user, opacityValue}){
  const animVal = useSharedValue(0)
  const [ indicatorWidth, setIndicatorWidth ] = useState(null)
  const [ selectedIndex, setSelectedIndex ] = useState(0)

  useEffect(()=> {
    if(user){
      opacityValue.value = withTiming(1, { duration: 200 })
    }
  },[user])

  const transformSwitch = useAnimatedStyle(()=> {
    const x = interpolate( 
      animVal.value, 
      [0, 1],
      [0, indicatorWidth / 2]
    )

    return {
      transform: [
        {
          translateX: x
        }
      ]
    }
  })

  const containerOpacity = useAnimatedStyle(()=> {
    return {
      opacity: opacityValue.value
    }
  })

  return (
    <Animated.View style={[{ flex: 1 }, containerOpacity]}>
      <View style={styles.userInfoContainer}>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <View style={styles.userImageContainer}>
            <Image 
              style={[styles.userImage,{ tintColor: user.user.photo ? null : Colors.gray.alpha06}]} 
              resizeMode='cover' 
              source={user.user.photo ? { uri: user.mediaBasePath + user.user.photo } : require('../assets/icons/user_dummy_image.png')}
            />
          </View>
          <View style={{marginLeft: 16}}>
            <Text style={[FontStyles.title2.bold, { color: Colors.dark.alpha1}]}>
              { user.user.firstName + " " + user.user.lastName }
            </Text>
            <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 12}}>
              {
                user.user.username &&
                <Text style={[FontStyles.body.medium, { color: Colors.dark.alpha06, marginRight: 8 }]}>
                  { user.user.username }
                </Text>
              }
              <View style={{width: 6, height: 6, borderRadius: 5, backgroundColor: user.user.isActive ? Colors.greenLight.alpha1 : Colors.red.alpha1 }}/>
              <Text style={[FontStyles.caption.bold, { color: user.user.isActive ? Colors.greenLight.alpha1 : Colors.red.alpha1, marginLeft: 2}]}>
                {user.user.isActive ? I18n.t('active') : I18n.t('inactive')}
              </Text>
            </View>

            <Text style={[FontStyles.footnote.regular, {color: Colors.dark.alpha06, marginTop: 12}]}>
              {user.user.joinedAt ? I18n.t('joinedAt', { date: moment(user.user.joinedAt).utcOffset(API.timezoneOffset).format('DD.MM.YY') }) : I18n.t('notJoinedYet')}
            </Text>
          </View>
        </View>


        <View style={styles.buttonContainer} onLayout={({nativeEvent})=> setIndicatorWidth(nativeEvent.layout.width)}>
          <Pressable style={styles.switchButton} onPress={()=> {
            setSelectedIndex(0)
            animVal.value = withSpring(0)
          }}>
            <Text style={[FontStyles.title2.semibold, { color: selectedIndex === 0 ? Colors.dark.alpha1 : Colors.dark.alpha08 }]}>
              {I18n.t('stats')}
            </Text>
          </Pressable>
          <Pressable style={styles.switchButton} onPress={()=> {
              setSelectedIndex(1)
              animVal.value = withSpring(1)
            }}
          >
            <Text style={[FontStyles.title2.semibold, { color: selectedIndex === 1 ? Colors.dark.alpha1 : Colors.dark.alpha08}]}>
              {I18n.t('activity')}
            </Text>
          </Pressable>
          <Animated.View style={[styles.switchIndicator, transformSwitch]}/>
        </View>
      </View>
      {
        selectedIndex === 0 ?
        <UserStatics
          stats={user}
        />
        :
        <UserActivity
          data={user.events}
        />
      }
    </Animated.View>
    
  )
}

const styles = StyleSheet.create({
  userInfoContainer: {
    borderBottomWidth: 1,
    borderColor: Colors.dark.alpha02,
    paddingBottom: 24,
    paddingLeft: 12
  },
  userImageContainer: {
    width: 100,
    height: 100,
    backgroundColor: Colors.gray.alpha02,
    borderRadius: 50,
    overflow: 'hidden',
  },
  userImage: {
    width: '100%',
    height: '100%'
  },
  buttonContainer: {
    width: '50%',
    alignItems: 'center',
    minWidth: 250,
    flexDirection: 'row',
    marginTop: 16,
    alignSelf: 'center'
  },
  switchButton: {
    flex: 1,
    alignItems: 'center',
    paddingVertical: 12
  },
  switchIndicator: {
    width: '30%',
    backgroundColor: Colors.primaryBlue.alpha1,
    height: 3,
    position: 'absolute',
    bottom: 0,
    left: '10%',
    borderRadius: 100
  },
  statsContainer: {
    flex: 1
  },
  activityContainer: {
    flex: 1
  }
})

export default memo(UserDetails)