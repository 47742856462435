import { View, StyleSheet } from "react-native";
import Menu from './Menu'
import { memo, useEffect, useState } from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useSharedValue, withTiming } from "react-native-reanimated";
import API from "../../utils/API";
import moment from "moment";

//screens
import Dashboard from './Dashboard'
import SmartTimeline from './SmartTimeline'
import Announcements from './Announcements'
import Contests from './Contests'
import CreateSurvey from '../CreateSurvey'
import CreateContest from '../CreateContest'
import Users from '../Users'
import Surveys from "../Surveys";
import Reports from "../Reports";
import Notifications from "../Notifications";
import AddMediaContent from "../AddMediaContent"

const headerBg = '#E8F6FF'

const menuButtons = {
  Dashboard: { name: 'dashboard', icon: 'home', screenName: '/dashboard', index: 0 },
  SmartTimeline: { name: 'smartTimeline', icon: 'calendar', screenName: '/smart-timeline', index: 1 },
  Announcements: { name: 'announcements', icon: 'bell', screenName: '/announcements', index: 2 },
  Contests: { name: 'contests', icon: 'target', screenName: '/contests', index: 3 },
}

//create stack navigator for screens
const Stack = createNativeStackNavigator()


function Home(props){
  const menuButtonAnimValue = useSharedValue(0)
  const [ mediaBasePath, setMediaBasePath ] = useState(null)
  
  useEffect(()=> {
    async function initalCalls(){
      try {
        const { mediaBasePath } = await API.getUserFromServer() // required for mediaBasePath and companyId(API.js)
        await API.getCompanyInformation() // important for learning timezone(API.js)
        setMediaBasePath(mediaBasePath)
      }catch(e){
        console.log(e)
      }
    }

    initalCalls()

  },[])

  if(!mediaBasePath){
    return null
  }

  return(
    <View style={styles.container}>
      <Menu
        menuButtonAnimValue={menuButtonAnimValue} 
        menuButtons={menuButtons}     
      />
      <View style={styles.screenContainer}>
        <View style={styles.contentContainer}>
          <Stack.Navigator 
            screenOptions={{ headerShown: false }}
            screenListeners= {{
              state: ({data})=> {
                  let lastRoute = menuButtons[data.state.routes[data.state.routes.length - 1].name]
                  if(lastRoute){
                    menuButtonAnimValue.value = withTiming(lastRoute.index, {duration: 250})
                  }
                }
              }
            }
            
          >
            <Stack.Screen 
              name='Dashboard'
              component={Dashboard}
            />
            <Stack.Screen 
              name='SmartTimeline'
              component={SmartTimeline}
            />
            <Stack.Screen 
              name='Announcements'
              component={Announcements}
            />
            <Stack.Screen 
              name='Contests'
              component={Contests}
            />
            <Stack.Screen 
              name="Users"  
              component={Users} 
              
            />
            <Stack.Screen 
              name="Surveys"  
              component={Surveys} 
              
            />
            <Stack.Screen 
              name="CreateSurvey"  
              component={CreateSurvey} 
              
            />
            <Stack.Screen 
              name="CreateContest"  
              component={CreateContest} 
              
            />
            <Stack.Screen 
              name="Reports"  
              component={Reports} 
            />
            <Stack.Screen 
              name="Notifications"  
              component={Notifications} 
            />
            <Stack.Screen 
              name="AddMediaContent"  
              component={AddMediaContent} 
            />
          </Stack.Navigator>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    paddingLeft: 24,
    paddingTop: 20,
    paddingBottom: 20,
    backgroundColor: 'white',
    paddingRight: 24
  },
  screenContainer: {
    flex: 1,
    paddingLeft: 22.5,
    minWidth: 670,
  },
  header: {
    width: '100%',
    backgroundColor: headerBg,
    flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: 24,
    borderRadius: 20,
    alignItems: 'center'
  },
  userImage: {
    width: 54, 
    height: 54, 
    borderRadius: 27,
    borderWidth: 3,
    borderColor: 'white'
  },
  contentContainer: {
    flex: 1,
    marginTop: 4
  }
})

export default memo(Home)