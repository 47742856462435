import I18n from "i18n-js"
import moment from "moment"
import { memo } from "react"
import { FlatList, StyleSheet, Text, View } from "react-native"
import API from '../utils/API.js';
import Colors from "../utils/Colors"
import FontStyles from "../utils/FontStyles"
import Events from "../utils/Events"
import { Feather } from "@expo/vector-icons"

const listEmpty = () => {
  return (
    <Text style={[FontStyles.body.medium, { color: Colors.dark.alpha1, alignSelf: 'center', marginTop: 16 }]}>
      {I18n.t('noActivity')}
    </Text>
  )
}

function UserActivity({data}){

  const renderItem = ({ item, index }) => {
    return (
      <View style={styles.logContainer}>
        <View style={[styles.iconContainer, { backgroundColor: Events[item.key].color}]}>
          <Feather name={Events[item.key].icon} color='white' size={14}/>
        </View>
        <Text style={[FontStyles.body.medium, { color: Colors.dark.alpha1, flex: 1, marginRight: 12 }]}>
          {I18n.t(item.key)}
        </Text>
        <View style={{alignItems: 'center'}}>
          <Text style={[FontStyles.footnote.regular, { color: Colors.dark.alpha08 }]}>
            {moment(item.time).utcOffset(API.timezoneOffset).format('DD.MM.YY')}
          </Text>
          <Text style={[FontStyles.footnote.regular, { color: Colors.dark.alpha08, marginTop: 6 }]}>
            {moment(item.time).utcOffset(API.timezoneOffset).format('HH:mm')}
          </Text>
        </View>
      </View>
    )
  }

  return (
    <View style={styles.container}>
      <FlatList
        data={data.events}
        renderItem={renderItem}
        keyExtractor={(item,index)=> index + 'log'}
        ListEmptyComponent={listEmpty}
        showsVerticalScrollIndicator={false}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  logContainer: {
    paddingHorizontal: 12,
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderColor: Colors.lightGray.alpha1,
    flexDirection: 'row',
    alignItems: 'center'
  },
  iconContainer: {
    width: 30,
    height: 30,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10
  }
})


export default memo(UserActivity)