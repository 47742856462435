import React, { memo } from "react";
import { Animated, StyleSheet, Pressable, ActivityIndicator, View, Text, ScrollView } from "react-native";
import Colors from "../utils/Colors";
import { BlurView } from "expo-blur";
import { Feather } from '@expo/vector-icons'
import FontStyles from "../utils/FontStyles";
import API from "../utils/API";
import moment from "moment";
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts";
import I18n from "i18n-js";

function generateArrayFromObject(obj){
  let arr = []
  for(const key in obj){
    arr.push({name: key, value: obj[key]})
  }
  console.log(arr)
  return arr
}

const Question = memo(
  function({ question, participantCount, index }){
    
    return (
      <View style={[{ marginTop: 50 }]}>
        <Text style={[FontStyles.body.medium, { color: Colors.dark.alpha1 }]}>
          {`${index + 1}. ${question.text}`}
        </Text>
        {
          participantCount === 0 &&
          <Text style={[FontStyles.footnote.medium, { color: Colors.red.alpha08, marginTop: 8 }]}>
            {I18n.t('noUserAnswered')}
          </Text>
        }
        {
          question.options ?
          question.options.map((option, index)=> {
            return (
              <View key={option} style={[styles.optionContainer, {marginTop: index === 0 ? 16 : 16}]}>
                <View style={{flexDirection: 'row', width: '100%', alignItems: 'flex-end'}}>
                  <Text style={[FontStyles.subhead.regular, { color: Colors.dark.alpha1, marginRight: 12, flex: 1 }]}>
                    {option}
                  </Text>
                  <Text style={[FontStyles.footnote.medium, { color: Colors.gray.alpha06 }]}>
                    <Text style={{color: Colors.dark.alpha04, marginRight: 8}}>
                      {'(' + question.answerDistribution[index] + ')'}
                    </Text>
                    {(Math.round( 100 * question.answerDistribution[index] / participantCount) || 0 )+ '%'}
                  </Text>
                </View>
                <View style={styles.percentageContainer}>
                  <View style={[styles.percantage, {width: `${( (100 * question.answerDistribution[index] / participantCount) || 0).toFixed(0) }%`}]}/>
                </View> 
              </View>
            )
          })
          :
          <ResponsiveContainer width={'65%'} height={200}>
            <BarChart
              width={300}
              height={200}
              margin={{
                top: 24,
                left: -30
              }}
              data={generateArrayFromObject(question.answerDistribution)}
            >
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip
                cursor={{ stroke: Colors.secondaryBlue.alpha02, strokeWidth: 2, fill: Colors.secondaryBlue.alpha01 }}
                content={({ payload, label, active }) => {
                  if (active && payload && payload.length) {
                    return (
                      <View style={styles.tooltip}>
                        <Text style={[FontStyles.subhead.semibold, {color: Colors.white.alpha06}]}>
                          {`${I18n.t('answeredCount')}: ${payload[0].value}`}
                        </Text>
                        <Text style={[FontStyles.body.semibold, {marginTop: 5, color: Colors.white.alpha1}]}>
                          {`${Math.round((100 * payload[0].value / participantCount) || 0)}%`}
                        </Text>
                      </View>
                    )
                  }
                  return null
                }}
              />
              <Bar 
                dataKey="value" 
                fill={Colors.secondaryBlue.alpha1} 
              />
            </BarChart>
          </ResponsiveContainer>
          
        }
      </View>
    )
  }
)

export default class SurveyDetails extends React.PureComponent{
  constructor(props){
    super(props)
    this.opacityValue = new Animated.Value(0)
    this.lastSurveyId = null

    this.state={
      isShowing: false,
      data: null
    }
  }

  setIsShowing = (isShowing, id) => {
    if(isShowing){
      this.setState({ isShowing: isShowing, data: this.lastSurveyId === id ? this.state.data : null }, ()=> {
        Animated.timing(this.opacityValue, {
          toValue: 1,
          duration: 300,
          useNativeDriver: false
        }).start(()=> {
          if(this.lastSurveyId !== id){
            this.getSurveyDetails(id)
            this.lastSurveyId = id
          }
        })
      })

    }else{
      Animated.timing(this.opacityValue, {
        toValue: 0,
        duration: 300,
        useNativeDriver: false
      }).start(()=> {
        this.setState({ isShowing: isShowing })
      })
    }
  }

  getSurveyDetails = (id) => {
    API.getSurveyDetails(id).then((res)=> {
      console.log(res)
      this.setState({ data: res })
    })
  }

  getStatus = () => {
    if(this.state.data){
      if(this.state.data.survey.endsAt > new Date().getTime() && this.state.data.survey.startsAt < new Date().getTime()){
        return { translation: 'active', color: Colors.greenLight.alpha1 }
      }else if(this.state.data.survey.endsAt > new Date().getTime() &&  this.state.data.survey.startsAt >= new Date().getTime()){
        return { translation: 'pending', color: Colors.badgeYellow.alpha1 }
      }else if(new Date().getTime() > this.state.data.survey.endsAt){
        return { translation: 'inactive', color: Colors.red.alpha1 }
      }
    }
    return {translation: null, color: null}
  }

  render(){

    if(!this.state.isShowing){
      return null
    }

    const {translation, color} = this.getStatus()

    return(
      <Animated.View style={[styles.container, { opacity: this.opacityValue }]}>
        <BlurView 
          style={styles.blur} 
          intensity={100} 
          tint={'light'}
        >
          <View style={styles.loadingContainer}>
            {
              this.state.data ? 
              <View style={styles.contentContainer}>
                <ScrollView 
                  style={styles.innerContainer}
                  contentContainerStyle={{ padding: 24 }}
                  showsVerticalScrollIndicator={false}
                >
                  <Text style={[FontStyles.title3.medium, { color: Colors.dark.alpha1 }]}>
                    {this.state.data.survey.title}
                  </Text>
                  <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 16}}>
                    <Feather
                      name="calendar"
                      size={18}
                      color={Colors.dark.alpha08}
                    />
                    <Text style={[FontStyles.subhead.medium, { color: Colors.dark.alpha08, marginLeft: 8, fontSize: 14 }]}>
                      {moment(this.state.data.survey.startsAt).utcOffset(API.timezoneOffset).format('DD MMM YYYY') + ' - ' + moment(this.state.data.survey.endsAt).format('DD MMM YYYY')}
                    </Text>
                    <Feather
                      name='users'
                      size={18}
                      color={Colors.dark.alpha08}
                      style={{ marginLeft: 32 }}
                    />
                    <Text style={[FontStyles.subhead.medium, { color: Colors.dark.alpha08, marginLeft: 8, fontSize: 14 }]}>
                      {this.state.data.survey.participantCount + ' / ' + this.state.data.survey.eligibleUserCount}
                    </Text>
                    <View style={[styles.activeIndicator, { backgroundColor: color }]}>
                      <Text style={[FontStyles.footnote.medium, { color: 'white' }]}>
                        {I18n.t(translation)}
                      </Text>
                    </View>
                  </View>
                  {
                    this.state.data.survey.tags.map((tag, index)=> {
                      return (
                        <View key={tag.id} style={[styles.tagContainer, {backgroundColor: tag.colorHex }]}>
                          <Text style={[FontStyles.footnote.medium, { color: 'white' }]}>
                            {tag.name}
                          </Text>
                        </View> 
                      )
                    })
                  }
                  <Text style={[FontStyles.callout.regular, { color: Colors.gray.alpha1, marginTop: 24, lineHeight: 26, width: '80%' }]}>
                    {this.state.data.survey.description}
                  </Text>

                  {
                    this.state.data?.questions.map((question, index)=> {
                      return (
                        <Question
                          question={question}
                          key={question.id}
                          participantCount={this.state.data.survey.participantCount}
                          index={index}
                        />
                      )
                    })
                  }
                  
                </ScrollView>
              </View>
              :
              <>
                <ActivityIndicator color={Colors.dark.alpha1} size='large'/>
                <Text style={[FontStyles.body.medium, { color: Colors.dark.alpha1, marginTop: 16 }]}>
                  {I18n.t('surveyLoading')}
                </Text>
              </>
            }
          </View>
          <Pressable style={styles.closeButton} onPress={() => this.setIsShowing(false)}>
            <Feather name={"x"} color={Colors.white.alpha1} size={28}/>
          </Pressable>
        </BlurView>
      </Animated.View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
  },
  blur: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  closeButton: {
    backgroundColor: Colors.secondaryBlue.alpha1,
    borderRadius: 222,
    height: 48,
    width: 48,
    position: "absolute",
    right: "3.5%",
    top: "4%",
    alignSelf: "flex-end",
    justifyContent: "center",
    alignItems: "center",
  },
  loadingContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flex: 1,
  },
  contentContainer: {
    height: '85%', 
    width: '65%',
  },
  innerContainer: {
    width: '100%',
    flex: 1,
    backgroundColor: Colors.white.alpha1,
    borderRadius: 15,
  },
  activeIndicator: { 
    paddingHorizontal: 12,
    paddingVertical: 4,
    borderRadius: 100,
    marginLeft: 32
  },
  optionContainer: {
    width: '60%',
  
  },
  percentageContainer: {
    width: '100%',
    borderRadius: 5,
    height: 8,
    backgroundColor: Colors.lightGray.alpha1,
    overflow: 'hidden',
    marginTop: 12
  },
  percantage: {
    height: '100%',
    borderRadius: 5,
    backgroundColor: Colors.secondaryBlue.alpha1
  },
  tooltip: {
    padding: 15,
    backgroundColor: Colors.primaryBlue.alpha1,
    borderRadius: 15,
    justifyContent: 'center',
  },
  tagContainer: {
    paddingVertical: 5,
    paddingHorizontal: 12,
    borderRadius: 70,
    alignSelf: 'baseline',
    marginTop: 12
  }
})