import React, { memo } from "react";
import {
	Animated,
	StyleSheet,
	Pressable,
	View,
	Text,
} from 'react-native';
import API from '../utils/API.js';
import Colors from "../utils/Colors";
import { BlurView } from "expo-blur";
import {Feather} from '@expo/vector-icons'
import {width} from '../utils/DeviceInfo.js';
import FontStyles from "../utils/FontStyles";
import moment from "moment";
import I18n from "i18n-js";

export default class ContestDetails extends React.PureComponent{
	constructor(props){
		super(props)
		this.animationValue = new Animated.Value(0)
		this.lastContestId = null
		
		this.state={
			isShowing: false,
		}
	}
	
	setIsShowing = (isShowing, isAccepted) => {
		if(isShowing){
			this.setState({ isShowing, isAccepted}, ()=> {
				
				Animated.spring(this.animationValue, {
					toValue: 1,
					duration: 450,
					delay: 0,
					useNativeDriver: false,
					bounciness: 5,
				}).start()
				
			})
			
		}else{
			Animated.spring(this.animationValue, {
				toValue: 0,
				duration: 450,
				delay: 0,
				useNativeDriver: false,
				bounciness: 5,
			}).start(()=> {
				this.setState({ isShowing: isShowing })
			})
		}
	}
	
	renderReportRow = ({ item, index }) => {
		return (
			<View key={item.id} style={styles.reportRow}>
				<Feather
					name={item.isAccepted ? 'check' : 'x'}
					size={24}
					color={item.isAccepted ? Colors.greenLight.alpha1 : Colors.red.alpha1}
				/>
				<View style={{marginLeft: 12, flex: 1}}>
					<Text style={[FontStyles.body.medium, { color: Colors.dark.alpha1 }]}>
						{I18n.t(item.type)}
					</Text>
					<Text style={[FontStyles.caption.regular, { color: Colors.dark.alpha1, marginTop: 6 }]}>
						{item.submittingEmployee.firstName + ' ' + item.submittingEmployee.lastName + ' • ' + moment(item.createdAt).utcOffset(API.timezoneOffset).format('DD.MM.YY  HH:mm') }
					</Text>
				</View>
				<Pressable style={styles.reviewButton} onPress={()=> this.props.onOpenReport(item)}>
					<Text style={[FontStyles.footnote.medium, { color: Colors.white.alpha1 }]}>
						{I18n.t('details')}
					</Text>
				</Pressable>
			</View>
		)
	}
	
	listHeader = () => {
		return (
			<BlurView
				style={styles.blur}
				intensity={100}
				tint={'light'}>
					<View style={styles.listHeader}>
						<Text style={[FontStyles.title3.semibold ,styles.listHeaderText]}>{this.state.isAccepted ? 'Onaylanan Bildirimler': 'Reddedilen Bildirimler'}</Text>
					</View>
					
		</BlurView>)
	}
	
	render(){
		if(!this.state.isShowing){
			return null
		}
		
		return(
			<Animated.View style={[styles.container, {
				transform: [{translateX: this.animationValue.interpolate({
						inputRange: [0,0.7,1],
						outputRange: [1.25 * width, 0, 0]
					})}]
				
			}]}>
				<BlurView
					style={styles.blur}
					intensity={100}
					tint={'light'}
				>
					<Animated.FlatList
						style={[styles.reportList, {
							transform: [{translateX: this.animationValue.interpolate({
									inputRange: [0,0.7,1],
									outputRange: [0.6 * width, 0.6 * width, 0]
								})}]
						}]}
						data={this.props.reports.filter(report => report.isAccepted === this.state.isAccepted)}
						keyExtractor={item => item.id}
						renderItem={this.renderReportRow}
						showsVerticalScrollIndicator={false}
						initialNumToRender={24}
						updateCellsBatchingPeriod={100}
						maxToRenderPerBatch={30}
						removeClippedSubviews={true}
						ListHeaderComponent={() => this.listHeader()}
						stickyHeaderIndices={[0]}
					/>
					{
						this.props.reports &&
						<Pressable style={[styles.closeButton, {backgroundColor: Colors.primaryBlue.alpha1}]} onPress={() => this.setIsShowing(false)}>
							<Feather name={"x"} color={Colors.white.alpha1} size={28}/>
						</Pressable>
					}
				
				</BlurView>
			</Animated.View>
		)
	}
}

const styles = StyleSheet.create({
	container: {
		top: 0,
		bottom: 0,
		right: 0,
		left: 0,
		position: 'absolute',
	},
	blur: {
		width: '100%',
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center',
	},
	headerBlur: {
		width: '100%',
		height: '100%',
	},
	closeButton: {
		borderRadius: 222,
		height: 48,
		width: 48,
		position: "absolute",
		right: "3.5%",
		top: "4%",
		alignSelf: "flex-end",
		justifyContent: "center",
		alignItems: "center",
	},
	
	reportList: {
		borderRadius: 17.5,
		backgroundColor: Colors.white.alpha1,
		marginVertical: 60,
		width: '45%',
	},
	
	listHeader: {
		paddingTop: 24,
		paddingBottom: 18,
		backgroundColor: Colors.white.alpha06,
		alignSelf: 'stretch',
		borderBottomWidth: 1,
		borderColor: Colors.lightGray.alpha06,
	},
	
	listHeaderText: {
		color: Colors.gray.alpha08,
		marginLeft: '4%',
	},
	
	reviewButton: {
		paddingHorizontal: 15,
		paddingVertical: 7,
		borderRadius: 25,
		backgroundColor: Colors.primaryBlue.alpha1,
		alignSelf: 'center'
	},
	reportRow: {
		paddingHorizontal: "4%",
		flexDirection: 'row',
		paddingVertical: 16,
		borderBottomWidth: 1,
		borderColor: Colors.dark.alpha04,
		alignItems: 'center',
	},
})