import { StyleSheet, Text, View, Animated} from 'react-native';
import FontStyles from '../utils/FontStyles';
import Colors from '../utils/Colors';
import {Feather} from '@expo/vector-icons'
import React from 'react'
import { LinearGradient } from 'expo-linear-gradient';
import Button from './Button';

const alertTypes = {
  standart: {color: Colors.primaryBlue.alpha1,   iconName: "check"}, 
  info:     {color: Colors.orange.alpha1,        iconName: "info"}, 
  success:  {color: Colors.secondaryBlue.alpha1, iconName: "check"}, 
  warning:  {color: Colors.red.alpha1,           iconName: "alert-triangle"},
}
const defaultColor = alertTypes.standart.color

export default class Alert extends React.PureComponent {
  constructor(props){
    super(props)

    this.animVal = new Animated.Value(0)

    this.alertOpacity = this.animVal.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 1]
    })

    this.state = {
      alertActive: false,
      type: "standart",
      buttons: [],
      buttonDirection: "column",
      title: null,
      description: null
    }
  }
  
  //------props------
  // title (string) - title of the alert (required)
  // description (string) - description of the alert  (required) 
  // type (string) - type of the alert (standart, info, success, warning) - if not provided, the alert will be standart
  // buttonDirection (string) - direction of the buttons (row, column) - if not provided, the buttons will be displayed in a column
  // buttons (array) - array of buttons to be displayed in the alert (required)
      //props of the buttons:  
      // onPress (function) - function to be called when the button is pressed - if not provided, alert will be closed
      // title (string) - title of the button (required) 
      // type (string) - type of the button (passive, transparent, null)
          // if the button type is passive, the button will be gray colored
          // if the button type is transparent, the button will not have a background color
          // if the button type is null, the button will have the color of the alert
  //------props------

  showAlert = (alertOptions) => {
    this.setState({alertActive: true, ...alertOptions}, () => {
      Animated.spring(this.animVal, {
        toValue: 1,
        friction: 9,
        useNativeDriver: true
      }).start()
    })
  }

  hideAlert = () => {
    Animated.timing(this.animVal, {
      toValue: 0,
      duration: 200,
      useNativeDriver: true
    }).start(() => {
      this.setState({alertActive: false, buttonDirection: "column", type: "standart", buttons: [], title: null, description: null})
    })
  }

  render(){
    if(!this.state.alertActive) return null
    
    if(this.props.customAlert){
      return (
        <Animated.View style={[styles.container, {opacity: this.alertOpacity}]}>
          {this.props.children}
        </Animated.View>
      )
    }

    return (
      <Animated.View style={[styles.container, {opacity: this.alertOpacity}]}>
        <View style={styles.alertContainer}>
          <View style={styles.headerContainer}>
            <View style={[styles.bgCircle, {backgroundColor: alertTypes[this.state.type].color, width: 384, height: 384}]}/>
            <View style={[styles.bgCircle, {backgroundColor: alertTypes[this.state.type].color, width: 288, height: 288}]}/>
            <View style={[styles.bgCircle, {backgroundColor: alertTypes[this.state.type].color}]}/>
            <View style={[styles.iconContainer, {backgroundColor: alertTypes[this.state.type].color}]}>
              <LinearGradient start={[0.5,1]} end={[0.5,0]} colors={[alertTypes[this.state.type].color, "rgba(255,255,255,0.1)"]} style={styles.circleLinearGrad}/>
              <View style={styles.iconInnerContainer}>
                <Feather name={alertTypes[this.state.type].iconName} size={24} color={alertTypes[this.state.type].color}/>
              </View>
            </View>
          </View>

          <Text style={[FontStyles.title3.medium, {textAlign: "center", marginTop: 16, fontWeight: "600", color: alertTypes[this.state.type].color}]} >
            {this.state.title}
          </Text>

          {
            this.state.description &&
            <Text style={[FontStyles.body.regular, {textAlign: "center", marginTop: 8, color: Colors.gray.alpha1}]} >
              {this.state.description}
            </Text>
          }
          
          <View style={[styles.buttonsContainer, {flexDirection: this.state.buttonDirection || "column"}]}>
            {
              this.state.buttons.map((item, index) => {
                return (
                  <Button
                    key={index}
                    onPress={item.onPress ? item.onPress : () => this.hideAlert()}
                    style={
                      {
                        backgroundColor: item.type == "passive" ? Colors.lightGray.alpha1 : item.type == "transparent" ? "transparent" : alertTypes[this.state.type].color, 
                        marginTop: 12, 
                        height: 54,
                        width: this.state.buttonDirection == "row" ? "47.5%" : "100%",
                      }
                    }
                    text={item.title || "Ok"}
                    textStyle={{color: ["passive", "transparent"].includes(item.type) ? Colors.dark.alpha1 : Colors.white.alpha1}}
                  />
                )
              })
            }
          </View>
          
          
        </View>
      </Animated.View>
    )
  }
  
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 9999,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: "rgba(0,0,0,0.8)",
  },
  buttonsContainer: {
    marginTop: 12,
    alignSelf: "baseline",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
  },
  alertContainer: {
    alignSelf: "baseline",
    backgroundColor: Colors.white.alpha1,
    borderRadius: 24,
    alignSelf: "center",
    maxWidth: "60%",
    padding: 24,
    overflow: "hidden",
  },
  headerContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-end",
    height: 120,
  },
  bgCircle: {
    width: 192,
    height: 192,
    borderRadius: 200,
    position: "absolute",
    bottom: 0,
    alignSelf: "center",
    opacity: 0.125, 
  },
  iconContainer: {
    overflow: "hidden",
    width: 96,
    height: 96,
    justifyContent: 'center',
    alignItems: "center",
    borderRadius: 48,
    position: "absolute",
    bottom: 0,
    alignSelf: "center"
  },
  iconInnerContainer: {
    backgroundColor: Colors.white.alpha1,
    borderRadius: 10,
    width: 36,
    justifyContent: 'center',
    alignItems: "center",
    height: 36
  },
  circleLinearGrad: {
    position: "absolute",
    width: "100%",
    height: "100%",
  }

})