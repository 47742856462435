import { memo } from "react";
import { StyleSheet, View, Text, Pressable } from "react-native";
import {Feather} from '@expo/vector-icons'
import FontStyles from "../utils/FontStyles";
import Colors from "../utils/Colors";

function InfoCardHeader({
  iconName,
  title,
  subText,
  arrowContainerColor,
  arrowColor,
  iconContainerColor,
  borderColor,
  onPress
}){
  return(
    <Pressable onPress={() => onPress && onPress()} style={{width: '100%'}}>
      <View style={[styles.header, {borderColor: borderColor}]}>
        <View style={[styles.iconContainer, {backgroundColor: iconContainerColor} ]}>
          <Feather
            name={iconName}
            size={24}
            color={'white'}
          />
        </View>
        <View style={{ marginLeft: 12, flex: 1 }}>
          <Text style={[ FontStyles.body.medium, {color: Colors.dark.alpha1, letterSpacing: -0.75 } ]}>
            {title}
          </Text>
          <Text style={[FontStyles.subhead.regular, { color: Colors.dark.alpha08, marginTop: 4 }]}>
            {subText}
          </Text>
        </View>
        <View style={[styles.arrowContainer, {backgroundColor: arrowContainerColor}]}>
          <Feather
            name="arrow-right"
            size={24}
            color={arrowColor}
          />
        </View>
      </View>
    </Pressable>

  )
}

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    width: '100%',
    alignItems: "center",
    borderBottomWidth: 2,
    paddingVertical: 17.5,
    paddingHorizontal: 17.5,
  },
  iconContainer: {
    width: 56,
    height: 56,
    borderRadius: 35,
    backgroundColor: Colors.secondaryBlue.alpha1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  arrowContainer: {
    backgroundColor: '#E9F6FF', 
    width: 48,
    height: 48,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center'
  }

})

export default memo(InfoCardHeader)