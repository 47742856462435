import React, { memo } from "react";
import { StyleSheet, View, Text, Pressable, ScrollView, TextInput } from "react-native";
import API from "../utils/API";
import Colors from "../utils/Colors";
import FontStyles from "../utils/FontStyles";
import I18n from "i18n-js";
import moment from "moment";
import { Feather } from '@expo/vector-icons'
import Dropdown from "../components/FilterDropdown";
import Hoverable from "../components/Hoverable";
import SurveyDetails from "../components/SurveyDetails";

const SurveyCard = memo(
  function({ survey, index, length, onPress, status }){  

    return (
      <Pressable 
        style={[styles.surveyRow, { marginRight: index !== length - 1 ? 16 : 0, marginTop: 24 }]}
        onPress={()=> onPress(survey.id)}
      >
        <Text style={[FontStyles.title3.semibold, { color: Colors.dark.alpha1 }]}>
          {survey.title}
        </Text>
        <View style={{ flexDirection: 'row', marginTop: 8, alignItems: 'center' }}>
          <Feather
            name='calendar'
            size={18}
            color={Colors.dark.alpha06}
          />
          <Text style={[FontStyles.footnote.medium, { color: Colors.gray.alpha06, marginLeft: 8, flex: 1 }]}>
            {moment(survey.startsAt).utcOffset(API.timezoneOffset).format('DD MMM YYYY') + ' - ' + moment(survey.endsAt).utcOffset(API.timezoneOffset).format('DD MMM YYYY')}
          </Text>
          <View style={[styles.activeIndicator, { backgroundColor: status.color }]}>
            <Text style={[FontStyles.footnote.medium, { color: 'white' }]}>
              {I18n.t(status.translation)}
            </Text>
          </View>
        </View>
        <Text numberOfLines={4} style={[FontStyles.subhead.regular, { color: Colors.dark.alpha1, lineHeight: 20, marginTop: 16 }]}>
          {survey.description}
        </Text>
        <Text style={[FontStyles.subhead.medium, { color: Colors.gray.alpha04, fontSize: 15, marginTop: 20 }]}>
          {I18n.t('participantCount')}
        </Text>
        {
          <View style={{ flexDirection: 'row', marginTop: 8 }}>
            <Text style={[FontStyles.subhead.medium, styles.participantRatioText]}>
              {`${survey.participantCount} / ${survey.eligibleUserCount}`}
            </Text>
            <Text style={[FontStyles.subhead.semibold, styles.participantRatioText, { marginLeft: 4 }]}>
              {`(${((survey.participantCount / survey.eligibleUserCount) * 100).toFixed(0)}%)`}
            </Text>
          </View>
        }
        <Text style={[FontStyles.subhead.medium, { color: Colors.gray.alpha04, fontSize: 15, marginTop: 20 }]}>
          {I18n.t('tags')}
        </Text>
        <View style={styles.tagContainer}>
          {
            survey.tags &&
            survey.tags.map((tag, index)=> {
              return (
                <View 
                  key={tag.id} 
                  style={[styles.tag, { marginBottom: 8, marginRight: 4, backgroundColor: tag.colorHex }]}
                >
                  <Text style={[ FontStyles.caption.bold, { color: 'white' }]}>
                    {tag.name}
                  </Text>
                </View>
              )
            })
          }
        </View>
        
      </Pressable>
    )
})

export default class  Surveys extends React.PureComponent{
  constructor(props){
    super(props)
    this.allSurveys = []
    this.searchText = ''
    this.filteredSurveys = []
    
    this.activityInfo = [
      { name: I18n.t('active'), color: Colors.greenLight },
      { name: I18n.t('pending'), color: Colors.badgeYellow },
      { name: I18n.t('inactive'), color: Colors.red },
    ]

    this.state = {
      surveys: [],
      isFiltering: false,
      tags: [],
      selectedTags: [],
      activityFilterIndex: null
    }
  }

  componentDidMount(){
    this.getSurveys()
    this.getTags()
  }

  getTags = () => {
    API.getCompanyTags().then(({tags})=> {
      this.setState({tags: tags})
    })
  }

  getSurveys = async () => {
    const { surveys } = await  API.getSurveys()
    this.allSurveys = surveys
    this.filteredSurveys = surveys
    this.setState({surveys: surveys})
  }

  onSearch = (text) => {
    const filtered = this.filterSurveys(text, this.state.selectedTags, this.state.activityFilterIndex)

    this.searchText = text

    this.setState({ surveys: filtered })
  }

  onSelectTag = (id) => {
    let index = this.state.selectedTags.indexOf(id)
    let arr = [...this.state.selectedTags]
    
    if(index !== -1){
      arr.splice(index, 1)
    }else{
      arr = [...arr, id]
    }

    let filtered = this.filterSurveys(this.searchText, arr, this.state.activityFilterIndex)

    this.setState({ selectedTags: arr, surveys: filtered })

  }

  setActivityFilter = (index) => {  
    const filtered = this.filterSurveys(this.searchText, this.state.selectedTags, this.state.activityFilterIndex !== index ? index : null)

    this.setState({ activityFilterIndex: this.state.activityFilterIndex === index ? null : index, surveys: filtered })
  }

  filterSurveys = (text, tags, activity) => {
    let filtered = this.allSurveys.filter((survey)=> {
      return survey.title.toLowerCase().includes(text.toLowerCase())
    })

    if(activity !== null){
      filtered = filtered.filter((survey)=> {
        return this.getStatus(survey).index === activity
      })
    }

    if(tags.length){
      filtered = filtered.filter((survey)=> {
        for(let tag of survey.tags){
          if(tags.includes(tag.id)){
            return true
          }
          return false
        }
      })  
    }

    return filtered
  }

  removeAllFilters = () => {
    this.searchInputRef.clear()
    this.setState({ surveys: this.allSurveys, selectedTags: [], activityFilterIndex: null })
  }

  onSurveyPress = (id) => {
    this.surveyDetailsRef.setIsShowing(true, id)
  }

  getStatus = (survey) => {
    if(survey.endsAt > new Date().getTime() && survey.startsAt < new Date().getTime()){
      return { translation: 'active', color: Colors.greenLight.alpha1, index: 0 }
    }else if(survey.endsAt > new Date().getTime() &&  survey.startsAt >= new Date().getTime()){
      return { translation: 'pending', color: Colors.badgeYellow.alpha1, index: 1 }
    }else if(new Date().getTime() > survey.endsAt){
      return { translation: 'inactive', color: Colors.red.alpha08, index: 2 }
    }
  }

  render(){
    return (
      <View style={styles.container}>
        <ScrollView 
          style={{ flex: 1 }}
          contentContainerStyle={{ padding: 24 }}
          showsVerticalScrollIndicator={false}
        >
          <Text style={[styles.activeSurveysTitle, FontStyles.title2.semibold]}>
            {I18n.t('surveys')}
          </Text>
          <View style={styles.header}>
            <View style={styles.searchInputContainer}>
              <Feather
                name='search'
                size={16}
                color={Colors.dark.alpha06}
              />
              <TextInput
                style={[styles.searchInput, FontStyles.subhead.medium, { color: Colors.dark.alpha1 }]}
                placeholder={I18n.t('searchInput')}
                placeholderTextColor={Colors.dark.alpha06}
                onChangeText={this.onSearch}
                ref={ref => this.searchInputRef = ref}
              />
            </View>
            <View style={{ width: 56 }}>
              <Dropdown
                color={Colors.secondaryBlue.alpha1}
              >
                <Text style={[FontStyles.body.medium, {marginTop: 12, color: Colors.dark.alpha08}]}>
                  {I18n.t('tags')}
                </Text>
                <View style={styles.filterTagContainer}>
                  {
                    this.state.tags.map((tag, index)=> {
                      return (
                        <Hoverable
                          key={tag.id}
                          style={[styles.tag, {marginTop: 8, marginRight: index !== this.state.tags.length - 1 ? 12 : 0, borderColor: this.state.selectedTags.includes(tag.id) ? tag.colorHex : Colors.lightGray.alpha1, borderWidth: 2, backgroundColor: this.state.selectedTags.includes(tag.id) ? tag.colorHex : 'white' }]}
                          onPress={()=> this.onSelectTag(tag.id)}
                          hoverStyle={{ backgroundColor: tag.colorHex, borderColor: tag.colorHex }}
                          text={tag.name}
                          textStyle={[FontStyles.footnote.medium, { color: this.state.selectedTags.includes(tag.id) ? 'white' : Colors.dark.alpha06 }]}
                          hoverTextStyle={{ color: 'white' }}
                        />
                      )
                    })
                  }
                </View>
                <Text style={[FontStyles.body.medium, {marginTop: 24, color: Colors.dark.alpha08}]}>
                  {I18n.t('status')}
                </Text>
                <View style={styles.filterTagContainer}>
                  {
                    this.activityInfo.map((item, index)=> {
                      return(
                        <Hoverable
                          key={item.name}
                          style={[styles.tag, { marginTop: 8, marginRight: index !== this.activityInfo.length - 1 ? 12 : 0, borderColor: this.state.activityFilterIndex === index ? item.color.alpha1 : Colors.lightGray.alpha1, borderWidth: 2, backgroundColor: this.state.activityFilterIndex === index ? item.color.alpha1 : 'white' }]}
                          onPress={()=> this.setActivityFilter(index)}
                          hoverStyle={{ backgroundColor: item.color.alpha1, borderColor: item.color.alpha1 }}
                          text={item.name}
                          textStyle={[FontStyles.footnote.medium, { color: this.state.activityFilterIndex === index ? 'white' : Colors.dark.alpha06 }]}
                          hoverTextStyle={{ color: 'white' }}
                        />
                      )
                    })
                  }
                </View>
                <Pressable onPress={this.removeAllFilters} style={{ marginTop: 16 }}>
                  <Text style={[FontStyles.subhead.medium, { color: Colors.red.alpha08, fontSize: 14 }]}>
                    {I18n.t('clearFilters')}
                  </Text>
                </Pressable>
              </Dropdown>
            </View>
            <Pressable style={styles.createSurveyButton} onPress={()=> this.props.navigation.navigate('CreateSurvey', {getData: this.getSurveys})}>
              <Text style={[FontStyles.subhead.medium, { color: 'white' }]}>
                {I18n.t('newSurvey')}
              </Text>
            </Pressable>
          </View>

          <View style={styles.activeSurveyContainer}>
            {
              this.state.surveys.map((survey, index) => {
                return (
                  <SurveyCard
                    key={survey.id}
                    survey={survey}
                    index={index}
                    length={this.state.surveys.length}
                    onPress={this.onSurveyPress}
                    status={this.getStatus(survey)}
                  />
                )
              })
            }
          </View>
        </ScrollView>
        <SurveyDetails
          ref={ref => this.surveyDetailsRef = ref}
        />
      </View>
      
    )
  }
  
}

const styles = StyleSheet.create({
  container: {
    flex: 1, 
    backgroundColor: '#F3FAFF', 
    borderRadius: 15, 
    overflow: 'hidden' 
  },
  oldSurveyContainer: {
    backgroundColor: 'white',
    flex: 1,
    minWidth: 300,
    maxWidth: 450,
    borderRadius: 20,
    shadowColor: '#C4C4C4',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.15,
    shadowRadius: 10,
    margin: 10,
  },
  surveyRow: {
    paddingVertical: 24,
    shadowColor: '#C4C4C4',
    borderRadius: 20,
    backgroundColor: 'white',
    paddingHorizontal: 24,
    width: 300,
    
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.15,
    shadowRadius: 10,
  },
  activeSurveyContainer: {
    flex: 1,
    flexDirection: 'row', 
    flexWrap: 'wrap',
    alignSelf: 'baseline',
    zIndex: -1
  },
  circleContainer: {
    justifyContent: 'center', 
    alignItems :'center',
    marginTop: 24
  },
  tag: {
    paddingHorizontal: 12,
    paddingVertical: 6,
    borderRadius: 7.5,
    alignSelf: 'baseline'
  },
  tagContainer: {
    marginTop: 8,
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: 240,
  },
  surveyCardFooter: {
    width: '100%', 
    flexDirection: 'row', 
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 24
  },
  participantRatioText: {
    color: Colors.gray.alpha08,
  },
  activeIndicator: { 
    paddingHorizontal: 12,
    paddingVertical: 4,
    borderRadius: 100
  },
  header: {
    flexDirection: 'row',
    marginTop: 12,
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  searchInputContainer: {
    width: 400,
    flexDirection: 'row',
    borderRadius: 100,
    backgroundColor: 'white',
    paddingRight: 16,
    height: 40,
    paddingLeft: 16,
    alignItems: 'center',
  },
  searchInput: {
    height: '100%',
    paddingVertical: 16,
    outlineStyle: 'none',
    paddingLeft: 6,
    flex: 1
  },
  filterTagContainer: {
    width: '100%', 
    flexDirection: 'row', 
    flexWrap: 'wrap',
  },
  createSurveyButton: {
    paddingHorizontal: 12,
    height: 40,
    justifyContent: 'center',
    marginLeft: 16,
    borderRadius: 10,
    backgroundColor: Colors.secondaryBlue.alpha1,
    zIndex: -1,
  }
})


{/* <View style={styles.circleContainer}>
        <Svg 
          width={CIRCLE_R * 2  + STROKE}
          height={CIRCLE_R * 2 + STROKE}
        >
          <Circle
            cx={CIRCLE_R + STROKE / 2}
            cy={CIRCLE_R + STROKE / 2}
            r={CIRCLE_R}
            stroke={Colors.lightGray.alpha1}
            strokeWidth={STROKE}
            fill="white"
          />
          <AnimatedCircle
            cx={CIRCLE_R + STROKE / 2}
            cy={CIRCLE_R + STROKE / 2}
            r={CIRCLE_R} 
            stroke={Colors.primaryBlue.alpha1}
            strokeWidth={STROKE}
            strokeDasharray={CIRCUMFERECE}
            strokeDashoffset= {circleValue.current}
            strokeLinecap='round'
            fill="transparent"
            transform={`rotate(-90, ${CIRCLE_R + STROKE / 2}, ${CIRCLE_R + STROKE / 2})`}
          />
        </Svg>
        <AnimatedText
          animValue={circleValue.current}
          max={CIRCUMFERECE}
          containerStyle={[StyleSheet.absoluteFill, {textAlign: 'center'}]}
          formatter={(value)=> {
            return `${((1 - (value / CIRCUMFERECE)) * 100).toFixed(0)} %`
          }}
        />
      </View> */}