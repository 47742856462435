import i18n from 'i18n-js';
import Storage  from './Storage'; // BURAYA KENDI SEYINIZI SEY YAPIN.
import { Platform } from 'react-native';
import Constants from 'expo-constants';
import * as Device from 'expo-device';
import * as Application from 'expo-application';
import moment from 'moment';
// import AmplitudeAnalytics from './AmplitudeAnalytics';
import * as Transform from './APITransform';


//BUNU DAYIYCAN BABUS KENDINE GORE DUZENLERSIN
//AmplitudeAnalytics.track(AmplitudeAnalytics.events.API_ERROR, {'errorCode': String?, 'relativePath': String?})

import 'expo-random';
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid'
import { v5 as uuidv5 } from 'uuid'

const BASE_PATH = 'https://api.safeandsound.app/api/v1';
const STORAGE_SESSION_PATH = 'artiISG/currentSessionId';
export const STORAGE_USER_PATH = 'artiISG/currentUser';
const STORAGE_LOCAL_LANGUAGE_PATH = 'artiISG/localLanguage';
const STORAGE_LAST_SESSION_RENEWAL_DATE_PATH = 'artiISG/lastSessionRenewalDate';
const STORAGE_INSTALLATION_ID_PATH = 'artiISG/installationId';
const UUID_NAMESPACE = '29cc8a0d-747c-5f85-9ff9-f2f16636d963'; // uuidv5(0, "expo")

/*
	this module must be used after calling `initialize()` successfully.
	some functions need a working `installationId`, and calling initialize() ensures it.
*/

const getItem = (key) => {
	return Promise.resolve(Storage.getString(key))
}

const setItem = (key, value) => {
	return Promise.resolve(Storage.set(key,value))
}

const removeItem = (key) => {
	return Promise.resolve(Storage.delete(key))
}

const API = {
	_storage: {
		getItem: getItem,
		setItem: setItem,
		removeItem: removeItem,
	},
	
	
	DEFAULT_LANGUAGE: 'en',
	availableLanguages: ['en', 'tr'],
	installationId: null,
	companyId: null,
	timezoneOffset: (-1 * new Date().getTimezoneOffset()),

	_getInstallationIdInHouse: async function() {
		let _installationId;

		if(['android', 'ios'].includes(Platform.OS)) {
			let identifierForVendor;

			if(Platform.OS === 'android') {
				identifierForVendor = Application.androidId;
			} else { // ios
				identifierForVendor = await Application.getIosIdForVendorAsync();
			}

			const bundleIdentifier = Application.applicationId;

			// It's unlikely identifierForVendor will be null (it returns null if the
			// device has been restarted but not yet unlocked), but let's handle this
			// case.
			if (identifierForVendor) {
				_installationId = uuidv5(`${bundleIdentifier}-${identifierForVendor}`, UUID_NAMESPACE);
			} else {
				const installationTime = await Application.getInstallationTimeAsync();
				_installationId = uuidv5(`${bundleIdentifier}-${installationTime.getTime()}`, UUID_NAMESPACE);
			}
		}
		else { // WEB. random (uuid v4)
			_installationId = uuidv4();
		}

		return _installationId.toLowerCase();
	},

	initialize: async function () {
		let value = await this._storage.getItem(STORAGE_INSTALLATION_ID_PATH);
		if(value){
			this.installationId = value;
		}
		else{
			if(Constants.installationId) {
				this.installationId = Constants.installationId;
			}
			else{
				try{
					this.installationId = await this._getInstallationIdInHouse();
					
				} catch (e) {
					this.installationId = uuidv4(); // in case of an error, we fall back to a randomly generated uuid.
				}
			}

			await this._storage.setItem(STORAGE_INSTALLATION_ID_PATH, this.installationId);
		}


		return true;
	},

	/*--------- BASE REQUEST FUNCTION ------------*/
	makeHttpRequest: function(relativePath, method, params = null) {
		return Promise.all([
			this.getCurrentSessionId(),
			this.getLocalLanguage()
		]).then(([sessionId, localLanguage]) => {
			if (params === undefined || params === null || (typeof params) !== 'object') {
				params = null;
			}
			let fetchOptions = {
				method: method,
				headers: {
					'Accept': 'application/json',
					'Api-Language': this.availableLanguages.includes(localLanguage) ? localLanguage : this.DEFAULT_LANGUAGE,
				}
			};
			if (sessionId) {
				fetchOptions.headers['Authorization'] = 'Bearer ' + sessionId;
			}
			// GET requests do not use body, so we do not add Content-Type header or body to the request
			if (method !== 'GET') {
				fetchOptions.headers['Content-Type'] = 'application/json';
				if (params) {
					fetchOptions.body = JSON.stringify(params);
				} else {
					fetchOptions.body = '{}';
				}
			}

			if (relativePath.substr(0, 1) === '/') {
				relativePath = relativePath.substr(1, relativePath.length);
			}
			
			return fetch(BASE_PATH + '/' + relativePath, fetchOptions)
				.then((response) => { //this then includes catch as well.
					if (response.status < 400) {
						
						if(relativePath === 'auth/passwordless-login'){
							return response.json()
								.then(body => {
									return Promise.all([
										this.setCurrentSessionId(body.authorizationToken),
										this.setCurrentUser(body.user),
										this.setLocalLanguage(body.user.language),
									])
										.then(() => {
											return Promise.resolve(body);
										})
										.catch(() => {
											return Promise.resolve(body);
										});
								})
								.catch(() => {
									return this.throwServerError();
								});
						} else if(relativePath === 'auth/session-renewal'){
							return response.json()
								.then(body => {
									return this.setCurrentSessionId(body.authorizationToken)
										.then(() => {
											return Promise.resolve(body);
										})
										.catch(() => {
											return Promise.resolve(body);
										});
								})
								.catch(() => {
									return this.throwServerError();
								});
						} else if (relativePath.includes('companies/') && method === 'GET' && (relativePath.includes('report-breakdown') || relativePath.includes('employee-password-breakdown') || relativePath.includes('employee-gamification-ranking-breakdown') || (relativePath.includes('contests/') && relativePath.includes('employee-ranking-breakdown')))){
							return response.blob()
								.then(myBlob => {
									let objectURL = URL.createObjectURL(myBlob);
									return Promise.resolve({
										file: objectURL,
										filename: response.headers.get('Content-Disposition')?.split("filename")?.[1]?.replaceAll("\"", "")?.replace(/\s/g, "")?.replaceAll("=", ""),
									});
								})
								.catch(e => {
									return this.throwServerError(e);
								});
						} else {
							if (response.headers && typeof response.headers.get('Content-Type') === 'string' && response.headers.get('Content-Type').includes('application/json')) {
								return response.json()
									.then((body) => {
										if(relativePath.startsWith('users/current') && body.user) {
											return Promise.all([
												this.setCurrentUser(body.user),
												this.setLocalLanguage(body.user.language)
											])
												.then(() => {
													return Promise.resolve(body);
												})
												.catch(() => {
													return Promise.resolve(body);
												});
										} else {
											return Promise.resolve(body);
										}
									})
									.catch(() => {
										return this.throwServerError();
									});
							} else {
								return Promise.resolve({});
							}
						}
						
					} else {
						if (response.headers && typeof response.headers.get('Content-Type') === 'string' && response.headers.get('Content-Type').includes('application/json')) {
							return response.json()
								.then((body, error) => { // this then includes catch as well.
									if (error) {
										return this.throwServerError();
									} else {
										if(typeof body.errorCode === 'number' && [1003,1005, 1006, 1007].includes(body.errorCode)){
											if(localLanguage === "en") {
												alert('Your session has expired. You are being redirected to the login page.');
											} else { // tr
												alert('Oturumunuz sona ermiştir. Tekrar giriş yapmak için yönlendiriliyorsunuz.');
											}
											return Promise.all([
												this.setCurrentUser(null),
												this.setCurrentSessionId(null),
												this._storage.removeItem(STORAGE_LAST_SESSION_RENEWAL_DATE_PATH)
											]).finally(()=>{
												return Promise.reject(body);
											});
										}
									}
								})
						} else {
							if(localLanguage === "en"){
								return Promise.reject({errorTitle: "Error", errorMessage: "Internet connection unavailable."});
							} else { //tr
								return Promise.reject({errorTitle: "Hata", errorMessage: "İnternet bağlantısı yok."});
							}
						}
					}
				})
				.catch((error) => {
					if(!error.errorCode && !error.errorMessage){
							return this.throwServerError();
					} else {
							// AmplitudeAnalytics.track(AmplitudeAnalytics.events.API_ERROR, {'errorCode': error.errorCode, 'relativePath': relativePath});
							return Promise.reject(error);
					}
				})
		})
	},
	
	makeHttpRequestWithTransform: function (transformFunction, ...makeHttpRequestArgs) {
		const _this = this
		return this.makeHttpRequest(...makeHttpRequestArgs).then(body => transformFunction.bind(_this)(body))
	},

	/*--------- LOCAL SESSION ----------*/
	getCurrentSessionId: function() {
		return this._storage.getItem(STORAGE_SESSION_PATH)
	},
	
	setCurrentSessionId: function(id) {
		if ((typeof id) !== 'string' || id.length === 0) {
			return this._storage.removeItem(STORAGE_SESSION_PATH)
				.then(() => {
					return Promise.resolve(true);
				})
		} else {
			return this._storage.setItem(STORAGE_SESSION_PATH, id)
				.then((val) => {
					return Promise.resolve(val);
				})
		}
	},

	/*--------- LOCAL LANGUAGE ----------*/
	setLocalLanguage: function(language) {
		if (language) {
			i18n.locale = language;
			moment.locale(language)
			return this._storage.setItem(STORAGE_LOCAL_LANGUAGE_PATH, language);
		} else {
			i18n.locale = this.DEFAULT_LANGUAGE;
			return this._storage.removeItem(STORAGE_LOCAL_LANGUAGE_PATH);
		}
	},

	// only if the first parameter is given as `true`, null results will be returned as they are.
	// otherwise, the default language will be returned in error or null cases.
	getLocalLanguage: function(revealNullCases = false) {
		return this._storage.getItem(STORAGE_LOCAL_LANGUAGE_PATH)
			.then((language) => {
				if (language) {
					return Promise.resolve(language);
				} else {
					return Promise.resolve(revealNullCases ? null : this.DEFAULT_LANGUAGE);
				}
			})
			.catch(() => {
				return Promise.resolve(revealNullCases ? null : this.DEFAULT_LANGUAGE);
			});
	},

	// if `this.DEFAULT_LANGUAGE` changes, update this part, too
	throwServerError: function() {
		let message = 'A connection or server error occured.';
		let title = 'Error';
		return this.getLocalLanguage() // this function never throws.
			.then((language) => {
				// if the language is 'en' or unknown, we will show an English message.
				if (language === 'tr') {
					message = 'Bir bağlantı veya sunucu hatası yaşandı.';
					title = "Hata";
				}
				return Promise.reject({
					errorCode: 601,
					errorTitle: title,
					errorMessage: message
				});
			})
	},

	/*--------- CURRENT USER LOCAL ----------*/
	getCurrentUser: function() {
		return this._storage.getItem(STORAGE_USER_PATH).then(val => {
			if (val) {
				return JSON.parse(val);
			} else {
				return Promise.resolve(null);
			}
		})
	},
	
	setCurrentUser: function(user) {
		if (user) {
			this.companyId = user.companyId
			return this._storage.setItem(STORAGE_USER_PATH, JSON.stringify(user))
				.then((val) => {
					return Promise.resolve(val);
				})
		} else {
			this.companyId = null
			return this._storage.removeItem(STORAGE_USER_PATH)
				.then(() => {
					return Promise.resolve(true);
				})
		}
	},

	/*----------------- AUTHENTICATION / USER -----------------*/
	login: function(loginId, email, loginCode) { //OK
		let body = {
			loginId,
			email,
			loginCode,
			devicePlatform: Platform.OS.toUpperCase(),
			installationId: this.installationId,
			deviceName: (!Device.brand || !Device.modelName) ? undefined : (Device.brand + " | " + Device.modelName),
		}

		return this.makeHttpRequest('/auth/passwordless-login', 'POST', body)
			.then((respBody) => {
				return this._storage.setItem(STORAGE_LAST_SESSION_RENEWAL_DATE_PATH, (new Date()).toISOString())
					.then(()=>{
						return Promise.resolve(respBody);
					})
					.catch(()=>{
						return Promise.resolve(respBody);
					});
			})
	},
	
	requestLoginCode: function (email){ //OK
		let body = {email};
		
		return this.makeHttpRequest('/auth/passwordless-login-code-request', 'POST', body)
	},
	
	logout: function() { //OK
		return this.makeHttpRequest('/auth/logout', 'POST', {}).then(() => {
			return Promise.all([
				this.setCurrentUser(null),
				this.setCurrentSessionId(null),
				this._storage.removeItem(STORAGE_LAST_SESSION_RENEWAL_DATE_PATH)
			])
		})
	},
	
	logoutFromAllDevices: function() {
		return this.makeHttpRequest('/auth/logout-all', 'POST', {}).then(() => {
			return Promise.all([
				this.setCurrentUser(null),
				this.setCurrentSessionId(null),
				this._storage.removeItem(STORAGE_LAST_SESSION_RENEWAL_DATE_PATH)
			]);
		})
	},
	
	renewSession: function() {
		return this._storage.getItem(STORAGE_LAST_SESSION_RENEWAL_DATE_PATH)
			.then((value)=>{
				if(value == null){
					return this._storage.setItem(STORAGE_LAST_SESSION_RENEWAL_DATE_PATH, (new Date()).toISOString());
				}
				else{
					value = new Date(value);
					let oneMonthAgo = new Date();
					oneMonthAgo.setMonth(new Date().getMonth() - 1);
					
					if(value <= oneMonthAgo){
						let devicePlatform = Platform.OS
						devicePlatform = devicePlatform.toUpperCase()
						return this.makeHttpRequest('/auth/session-renewal', 'POST', {devicePlatform: devicePlatform,	installationId: this.installationId,	deviceName: (!Device.brand || !Device.modelName) ? undefined : (Device.brand + " | " + Device.modelName)})
							.then(()=>{
								return this._storage.setItem(STORAGE_LAST_SESSION_RENEWAL_DATE_PATH, (new Date()).toISOString());
							});
					}
					else{
						return Promise.resolve(true);
					}
				}
			});
	},
	
	getUserFromServer: function() {
		return this.makeHttpRequest('/users/current', 'GET', null);
	},
	
	getUserAgreement: function() { //OK - TOLGA: bunu yaptim ama simdilik Beynex'in text'lerini koydum. Artı'dan gelir heralde yenisi
		return this.makeHttpRequest("/user-agreement", "GET", null)
	},

	getPrivacyPolicy: function() { //OK - TOLGA: bunu yaptim ama simdilik Beynex'in text'lerini koydum. Artı'dan gelir heralde yenisi
		return this.makeHttpRequest("/privacy-policy", "GET", null)
	},

	getClarificationStatement: function (){ // TOLGA: bunu yaptim ama simdilik Beynex'in text'lerini koydum. Artı'dan gelir heralde yenisi
		return this.makeHttpRequest("/clarification-statement", "GET", null)
	},
	
	uploadUserPhoto: function(base64) { //OK
		return this.makeHttpRequest("/users/current/photo", 'PUT', {photo: base64})
	},
	
	removeUserPhoto: function(){
		return this.makeHttpRequest("/users/current/photo", 'DELETE', null)
	},

	/*
	// ornek olarak dursun bu, paginated seyleri yine bu sekilde filan yapariz.
	
	//NOTIFICATIONS
	getNotifications: function(limit, gt, lt) {
		let queryParams = [];
		if(limit){
			queryParams.push("limit=" + limit);
		}
		if(lt){
			queryParams.push("lt=" + lt);
		}
		if(gt){
			queryParams.push("gt=" + gt);
		}
		return this.makeHttpRequest(`/notifications/my?${queryParams.join("&")}`, 'GET', null);
	},
	*/
	

	logEvent: function(events){ // events -> [ { time: 120320, key: 'APP_LAUNCH', properties: {} } ]
		const body = {
			events: events
		}

		return this.makeHttpRequest("/users/current/events", 'PUT', body)
	},

	getAllContests: function(){
		return this.makeHttpRequest(`/contests`, 'GET', null)
	},

	getContest: function(contestId){
		return this.makeHttpRequest(`/contests/${contestId}`, 'GET', null)
	},

	/*getReports: function(){
		return this.makeHttpRequest(`/reports`, 'GET', null)
	},*/

	getReportDetails: function(reportId){
		return this.makeHttpRequest(`/reports/${reportId}`, 'GET', null)
	},

	reviewReport: function(reportId, companyId, isAccepted, priorityDegree){
		let params = {}
		params["isAccepted"] = isAccepted
		if(isAccepted){
			params["priorityDegree"] = priorityDegree
		}
		return this.makeHttpRequest(`/companies/${companyId}/reports/${reportId}/is-accepted`, 'PUT', params)
	},


	
	sendContestAnswers: function(contestId, answers){
		return this.makeHttpRequest(`/contests/${contestId}/answers`, 'POST', {answers: answers})
	},

	getContestRankings: function(contestId){
		return this.makeHttpRequest(`/companies/${this.companyId}/contests/${contestId}/rankings`, 'GET', null)
	},

	getAllSurveys: function(){
		return this.makeHttpRequest(`/surveys`, 'GET', null)
	},

	getSurvey: function(surveyId){
		return this.makeHttpRequest(`/surveys/${surveyId}`, 'GET', null)
	},

	sendSurveyAnswers: function(surveyId, answers){ //answers -> { answers: { 'questionId': 1 } }
		return this.makeHttpRequest(`/surveys/${surveyId}/answers`, 'POST', { answers: answers })
	},

	getMiniTestDetails: function(miniTestId){
		return this.makeHttpRequest(`/companies/${this.companyId}/mini-tests/${miniTestId}`, 'GET', null)
	},

	sendMiniTestAnswers: function(miniTestId, answers){
		return this.makeHttpRequest(`/mini-tests/${miniTestId}/answers`, 'POST', {answers: answers})
	},

	sendMediaContentAnswers: function(contentId, answers){
		return this.makeHttpRequest(`/media-contents/${contentId}/answers`, 'POST', {answers: answers})

	},

	getGamificationData: function(page, limit, includeTags){
		return this.makeHttpRequest(`/gamification-rankings?page=${page}&limit=${limit}&include_tags=${includeTags ? 'true' : 'false'}`, 'GET', null)
	},

	resetUserData: function(){
		return this.makeHttpRequest(`/users/current/all-data`, 'DELETE', null)
	},

	getAnnouncements: function(){
		return this.makeHttpRequest(`/companies/${this.companyId}/announcements`, 'GET', null)
	},

	getAnnouncement: function(id){
		return this.makeHttpRequest(`/companies/${this.companyId}/announcements/${id}`, 'GET', null)
	},

	getNotifications: function(){
		return this.makeHttpRequest(`/companies/${this.companyId}/notifications`, 'GET', null)

	},

	getCompanyInformation: function(){
		return this.makeHttpRequest(`/companies/${this.companyId}`, 'GET', null).then(body => {
			this.timezoneOffset = body.company.timezoneOffset;
			return Promise.resolve(body);
		})
	},

	getCompanyUsers: function(){
		return this.makeHttpRequest(`/companies/${this.companyId}/users`, 'GET', null)
	},

	createUser: function(firstName, lastName, username){
		const body = {
			firstName: firstName,
			lastName: lastName,
			username: username,
			authRole: 'EMPLOYEE'
		}

		return this.makeHttpRequest(`/companies/${this.companyId}/users`, 'POST', body)
	},

	getCompanyUsersWithTags: function(){
		return this.makeHttpRequest(`/companies/${this.companyId}/users?include_tags=true`, 'GET', null)
	},
	
	getCompanyTags: function(){
		return this.makeHttpRequest(`/companies/${this.companyId}/tags?include_user_counts=true`, 'GET', null)
	},

	createTag: function(name){
		return this.makeHttpRequest(`/companies/${this.companyId}/tags`, 'POST', { name: name })
	},

	deleteTag: function(tagId){
		return this.makeHttpRequest(`/companies/${this.companyId}/tags/${tagId}?add_tags=true&include_tags_user_counts=true`, 'DELETE', null)
	},

	addTagToUser: function(tagId, userId){
		return this.makeHttpRequest(`/companies/${this.companyId}/tags/${tagId}/users/${userId}?add_users=true&include_users_tags=true`, 'PUT', null)
	},

	removeTagFromUser: function(tagId, userId){
		return this.makeHttpRequest(`/companies/${this.companyId}/tags/${tagId}/users/${userId}?add_users=true&include_users_tags=true`, 'DELETE', null)
	},
	
	getUsersByTag: function(tagId){
		return this.makeHttpRequest(`/companies/${this.companyId}/tags/${tagId}/users?include_users_tags=true`, 'GET', null)
	},

	getTagsOfCurrentUser: function(userId){
		return this.makeHttpRequest(`/companies/${this.companyId}/users/${userId}/tags`, 'GET', null)
	},

	deleteUser: function(userId){
		return this.makeHttpRequest(`/companies/${this.companyId}/users/:${userId}`, 'DELETE', null)
	},

	getSurveys: function(){
		return this.makeHttpRequest(`/companies/${this.companyId}/surveys`, 'GET', null)
	},

	getSurveyDetails: function(surveyId){
		return this.makeHttpRequest(`/companies/${this.companyId}/surveys/${surveyId}`, 'GET', null)
	},

	setSurveyTimeInterval: function(surveyId, startsAt, endsAt){

		let body = {
			startsAt: startsAt,
			endsAt: endsAt
		}

		return this.makeHttpRequest(`/companies/${this.companyId}/surveys/${surveyId}/time-interval`, 'PUT', body)
	},

	createSurvey: function(body){
		
		// body = {
		// 	tagIds: tagIds,
		// 	startsAt: startsAt,
		// 	endsAt: endsAt,
		// 	title: title,
		// 	description: description,
		// 	questions: questions
		// }

		return this.makeHttpRequest(`/companies/${this.companyId}/surveys`, 'POST', body)
	},

	getContests: function(){
		return this.makeHttpRequest(`/companies/${this.companyId}/contests`, 'GET', null)
	},

	getContestDetails: function(contestId){
		return this.makeHttpRequest(`/companies/${this.companyId}/contests/${contestId}`, 'GET', null)
	},

	getStats: function() {
		return this.makeHttpRequest(`/companies/${this.companyId}/stats`, 'GET', null)
	},

	createAnnouncement: async function(body){
		try{
			const sessionId = await this.getCurrentSessionId()
			const localLanguage = await this.getLocalLanguage()

			let options = {
				method: 'POST',
				body: body,
				headers: {
					"Accept": "application/json",
					"Authorization": 'Bearer ' + sessionId,
					"Api-Language": this.availableLanguages.includes(localLanguage) ? localLanguage : this.DEFAULT_LANGUAGE
				}
			}


			let res = await fetch(`${BASE_PATH}/companies/${this.companyId}/announcements`, options)
			
			let response = await res.json()

			return Promise.resolve(response)
		}catch(e){
			return Promise.reject(e)
		}
	},

	setAnnouncementDate: function(announcementId, date){
		return this.makeHttpRequest(`/companies/${this.companyId}/announcements/${announcementId}/published-at`, 'PUT', { publishedAt: date })
		
	},

	getTimeline: function(){
		return this.makeHttpRequest(`/companies/${this.companyId}/timeline`, 'GET', null)
	},

	getEducationPackageDetails: function(educationPackageId){
		return this.makeHttpRequest(`/companies/${this.companyId}/education-packages/${educationPackageId}`, 'GET', null)
	},

	getMediaContentDetails: function(mediaContentId){
		
		return this.makeHttpRequest(`/companies/${this.companyId}/media-contents/${mediaContentId}`, 'GET', null)
	},

	putEducationPackageToMonth: function(educationPackageId, plannedMonth){
		return this.makeHttpRequest(`/companies/${this.companyId}/education-packages/${educationPackageId}/planned-month`, 'PUT', { plannedMonth: plannedMonth })
	},

	changeStatusOfReport: function(reportId, isAccepted){
		return this.makeHttpRequest(`/companies/${this.companyId}/reports/${reportId}/is-accepted`, 'PUT', { isAccepted: isAccepted })
	},

	getReports: function(){
		return this.makeHttpRequestWithTransform(Transform.getReports, `/companies/${this.companyId}/reports`, 'GET', null )
	},

	removeEducationPackage: function(educationPackageId){
		return this.makeHttpRequest(`/companies/${this.companyId}/education-packages/${educationPackageId}/planned-month`, 'DELETE', null)
	},

	createContest: function(data){
		return this.makeHttpRequest(`/companies/${this.companyId}/contests`, 'POST', data)
	},

	setContestInterval: function(contestId, startsAt, endsAt){
		return this.makeHttpRequest(`/companies/${this.companyId}/contests/${contestId}/time-interval`, 'PUT', {startsAt: startsAt, endsAt: endsAt})
	},

	getUserDetails: function(userId){
		return this.makeHttpRequest(`/companies/${this.companyId}/users/${userId}`, 'GET', null)
	},

	getUserEvents: function(userId, queryParam){
		let queryParams = [];
		const { limit, lt, gt } = queryParam

		if(limit){
			queryParams.push("limit=" + limit);
		}
		if(lt){
			queryParams.push("lt=" + lt);
		}
		if(gt){
			queryParams.push("gt=" + gt);
		}

		return this.makeHttpRequest(`/companies/${this.companyId}/users/${userId}/events?${queryParams.join("&")}`, 'GET', null)
	},

	removeAnnouncement: function(announcementId){
		return this.makeHttpRequest(`/companies/${this.companyId}/announcements/${announcementId}`, 'DELETE', null)
	},

	sendNotification: function(text, tagIds){
		return this.makeHttpRequest(`/companies/${this.companyId}/notifications`, 'POST', { text: text, tagIds: tagIds })
	},

	addMediaContent: async function(body){
		try{
			const sessionId = await this.getCurrentSessionId()
			const localLanguage = await this.getLocalLanguage()

			let options = {
				method: 'POST',
				body: body,
				headers: {
					"Accept": "application/json",
					"Authorization": 'Bearer ' + sessionId,
					"Api-Language": this.availableLanguages.includes(localLanguage) ? localLanguage : this.DEFAULT_LANGUAGE
				}
			}


			let res = await fetch(`${BASE_PATH}/companies/${this.companyId}/media-contents`, options)
			
			let response = await res.json()

			return Promise.resolve(response)
		}catch(e){
			return Promise.reject(e)
		}
	},

	setMediaContentDate: function(mediaContentId, date){
		return this.makeHttpRequest(`/companies/${this.companyId}/media-contents/${mediaContentId}/planned-month`, 'PUT', { plannedMonth: date })
	},

	removeMediaContent: function(mediaContentId){
		return this.makeHttpRequest(`/companies/${this.companyId}/media-contents/${mediaContentId}/planned-month`, 'DELETE', null)

	},
	
	downloadAllReports: function (){
		return this.makeHttpRequest(`/companies/${this.companyId}/report-breakdown`, 'GET', null)
	},
	
	downloadRankings: function (){
		return this.makeHttpRequest(`/companies/${this.companyId}/employee-gamification-ranking-breakdown`, 'GET', null)
	},
	
	downloadEmployeePasswords: function (){
		return this.makeHttpRequest(`/companies/${this.companyId}/employee-password-breakdown`, 'GET', null)
	},
	
	downloadContestRankings: function (contestId){
		return this.makeHttpRequest(`/companies/${this.companyId}/contests/${contestId}/employee-ranking-breakdown`, 'GET', null)
	},

	uploadMediaContent: function(mediaContentId){
		return this.makeHttpRequest(`/companies/${this.companyId}/media-contents/${mediaContentId}/probe`, 'GET', null)
	}

};


export default API;
