import { memo, useEffect, useState } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import Animated, { Easing, Extrapolate, interpolate, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { Feather } from '@expo/vector-icons'
import FontStyles from '../utils/FontStyles'

const height = 250

function FilterDropdown({
  children,
  color
}){
  const sv = useSharedValue(0)
  const [ isOpened, setIsOpened ] = useState(false)

  useEffect(()=> {
    sv.value = withTiming(isOpened ? 2 : 0, { duration: 500, easing: Easing.linear })
  },[isOpened])

  const containerAnimation = useAnimatedStyle(()=> {
    const width = interpolate(
      sv.value,
      [0, 1],
      [40, 300],
      Extrapolate.CLAMP
    )

    const h = interpolate(
      sv.value,
      [1, 2],
      [40, height + 40],
      Extrapolate.CLAMP
    )

    return {
      width: width,
      height: h
    }
  })

  const xIcon = useAnimatedStyle(()=> {
    const op = interpolate(
      sv.value,
      [0.5,1],
      [0,1],
      Extrapolate.CLAMP
    )

    return {
      opacity: op
    }
  })

  const filterIcon = useAnimatedStyle(()=> {
    const op = interpolate(
      sv.value,
      [0.5,1],
      [1,0],
      Extrapolate.CLAMP
    )
    
    return {
      opacity: op
    }
  })

  return (
    <Animated.View style={[styles.container, containerAnimation]}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Pressable 
          style={styles.openButton}
          onPress={()=> setIsOpened(isOpened => !isOpened)}
        > 
        <Animated.View style={xIcon}>
          <Feather
            name={'x'}
            size={20}
            color={color}
          />
        </Animated.View>
        <Animated.View style={[{ position: 'absolute' }, filterIcon]}>
          <Feather
            name={'filter'}
            size={20}
            color={color}
          />
        </Animated.View>
        </Pressable>
        <Animated.Text style={[FontStyles.subhead.medium, xIcon, { color: color }]}>
          {'Filtre ekleyin'}
        </Animated.Text>
      </View>
      <View style={[styles.contentContainer]} >
        {children}
      </View>
    </Animated.View>

  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 10,
    position: 'absolute',
    top: -20,
    marginLeft: 16,
    overflow: 'hidden',
    backgroundColor: 'white',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.15,
    shadowRadius: 10,
    shadowColor: '#C4C4C4',
  },
  openButton: {
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: {
    width: '100%',
    paddingHorizontal: 20,
    height: height
  }
})

export default memo(FilterDropdown)