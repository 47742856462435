import React, { memo } from "react";
import { Animated, StyleSheet, Pressable, ActivityIndicator, Image, View, Text, ScrollView, FlatList, Dimensions, TouchableOpacity } from "react-native";
import Colors from "../utils/Colors";
import { BlurView } from "expo-blur";
import { Feather } from '@expo/vector-icons'
import FontStyles from "../utils/FontStyles";
import API from "../utils/API";
import moment from "moment";
import I18n from "i18n-js";
import WebAlert from './WebAlert'

const { width } = Dimensions.get('screen')

export default class ReportReviewCard extends React.PureComponent{
  constructor(props){
    super(props)

    this.transformCard = {
      transform: [
        {
          translateX: this.props.reportReviewVal.interpolate({
            inputRange: [0, 0.01],
            outputRange: [width*2, 0],
            extrapolate: "clamp"
          })
        }
      ]
    }

    this.opacityChange = this.props.reportReviewVal.interpolate({
      inputRange: [0.2, 0.6],
      outputRange: [0, 1]
    })

    this.state = {
      selectedPriorityInd: null
    }
  }

  onPressAccept = (reportId, companyId) => {
    if(this.state.selectedPriorityInd == null){
      this.alertCardRef.setIsShowing({isShowing: true, type: 'info', text: `Raporu onaylamadan önce öncelik seviyesi seçin.`})
    }else{
      this.setState({loading: true}, () => {
        let priorityLevels = ["LOW", "MODERATE", "HIGH"]
        API.reviewReport(reportId, companyId, true, priorityLevels[this.state.selectedPriorityInd-1]).then((response) => {
          setTimeout(() => {
            this.setState({loading: false, selectedPriorityInd: null})
            this.props.reportReviewController(0, true)
          }, 1000);
        }).catch((e) => {
          this.setState({loading: false})
          this.alertCardRef.setIsShowing({isShowing: true, type: 'warning', text: e.errorMessage || "Bir şeyler ters gitti. Tekrar deneyiniz."})
        })
      })
    }
  }

  onPressReject = (reportId, companyId) => {
    this.setState({loading: true}, () => {
      API.reviewReport(reportId, companyId, false, null).then(() => {
        this.alertCardRef.setIsShowing({isShowing: true, type: 'standart', text: `Rapor başarıyla reddedildi.`})
        setTimeout(() => {
          this.setState({loading: false, selectedPriorityInd: null})
          this.props.reportReviewController(0, true)
        }, 1200);
      }).catch((e) => {
        console.log(e)
        this.setState({loading: false})
        this.alertCardRef.setIsShowing({isShowing: true, type: 'warning', text: e.errorMessage || "Bir şeyler ters gitti. Tekrar deneyiniz."})
      })
    })
    
  }

  render(){
    console.log(this.props.selectedReviewReport?.incidentTypesNames)

    return(
      <Animated.View style={[styles.container, this.transformCard, { opacity: this.opacityChange }]}>
        <BlurView 
          style={styles.blur} 
          intensity={100} 
          tint={'light'}
        >
          <View style={styles.innerContainer}>
            <ScrollView 
              showsVerticalScrollIndicator={false}
              style={[styles.column, {flex: 1.8, marginLeft: 16}]}
              contentContainerStyle={{}}
            >
              <Text style={[FontStyles.title2.semibold, {marginTop: 24, marginLeft: 8,}]}>
                {"Genel Bilgiler"}
              </Text>
              <View style={styles.innerColumn}>
                <View style={styles.row}>
                  <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha08}]}>
                    Raporun Kategorisi 
                  </Text>
                  <Text style={[FontStyles.callout.regular, {color: Colors.secondaryBlue.alpha1}]}>
                    {I18n.t(this.props.selectedReviewReport?.type)}
                  </Text>
                </View>
                <View style={styles.seperator}/>

                <View style={styles.row}>
                  <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha08}]}>
                    Gönderilme Tarihi
                  </Text>
                  <Text style={[FontStyles.subhead.medium, {color: Colors.secondaryBlue.alpha1}]}>
                    {moment(this.props.selectedReviewReport?.createdAt).utcOffset(API.timezoneOffset).format('DD.MM.YY HH:mm') }
                  </Text>
                </View>
                <View style={styles.seperator}/>

                <View style={styles.row}>
                  <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha08}]}>
                    {I18n.t("emergencyDegree")}
                  </Text>
                  <View style={[styles.emergencyDegreeTextContainer, {backgroundColor: this.props.selectedReviewReport?.emergencyDegree == "HIGH" ? Colors.red.alpha1 : this.props.selectedReviewReport?.emergencyDegree == "MODERATE" ? Colors.orange.alpha1 : Colors.yellow.alpha1}]}>
                    <Text style={[FontStyles.subhead.medium, {color: this.props.selectedReviewReport?.emergencyDegree == "LOW" ? Colors.dark.alpha1 : Colors.white.alpha1}]}>
                      {I18n.t(this.props.selectedReviewReport?.emergencyDegree)}
                    </Text>
                  </View>
                </View>
                <View style={styles.seperator}/>

                <View style={styles.row}>
                    <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha08}]}>
                      {I18n.t("isCausedByBehaviour")}
                    </Text>
                    <Text style={[FontStyles.callout.regular, {color: Colors.secondaryBlue.alpha1}]}>
                      {this.props.selectedReviewReport?.isCausedByBehaviour ? I18n.t("isCausedByBehaviourTrue") :  I18n.t("isCausedByBehaviourFalse")}
                    </Text>
                  </View>
                  <View style={styles.seperator}/>

                <View style={styles.cell}>
                  <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha08}]}>
                    Açıklama
                  </Text>
                  <View style={styles.textAreaContainer}>
                    <Text style={[FontStyles.subhead.regular, {color: Colors.dark.alpha1, lineHeight: 22}]}>
                      {this.props.selectedReviewReport?.description || "Çalışan açıklama yazısını eklemedi."}
                    </Text>
                  </View>
                </View>
                <View style={styles.seperator}/>

                <View style={styles.row}>
                  <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha08}]}>
                    {I18n.t("incidentTypes")}
                  </Text>
                  <Text style={[FontStyles.callout.regular, {color: Colors.secondaryBlue.alpha1}]}>
                    {this.props.selectedReviewReport?.incidentTypesNames.join(', ')}
                  </Text>
                </View>
                <View style={styles.seperator}/>

                <View style={styles.row}>
                  <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha08}]}>
                    {I18n.t("incidentSite")}
                  </Text>
                  <Text style={[FontStyles.callout.regular, {color: Colors.secondaryBlue.alpha1}]}>
                    {this.props.selectedReviewReport?.incidentSiteName}
                  </Text>
                </View>
                <View style={styles.seperator}/>

                <View style={styles.cell}>
                  <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha08}]}>
                    {I18n.t("incidentSiteText")}
                  </Text>
                  <View style={styles.textAreaContainer}>
                    <Text style={[FontStyles.subhead.regular, {color: Colors.dark.alpha1, lineHeight: 22}]}>
                      {this.props.selectedReviewReport?.incidentSiteText || "Olay yeri tarifi eklenmedi."}
                    </Text>
                  </View>
                </View>
                <View style={styles.seperator}/>

                <View style={styles.cell}>
                  <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha08}]}>
                    {I18n.t("counterMeasure")}
                  </Text>
                  <View style={styles.textAreaContainer}>
                    <Text style={[FontStyles.subhead.regular, {color: Colors.dark.alpha1, lineHeight: 22}]}>
                      {this.props.selectedReviewReport?.counterMeasure || "Alınması gereken önlem belirtilmedi."}
                    </Text>
                  </View>
                </View>
                <View style={styles.seperator}/>

                <View style={styles.cell}>
                  <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha08}]}>
                    {I18n.t("reportPhotos")}
                  </Text>
                  <View style={[styles.textAreaContainer, {alignSelf: null, flexDirection: "row", flexWrap: "wrap"}]}>
                    {
                      this.props.selectedReviewReport?.photos.length !== 0 ? 
                      this.props.selectedReviewReport?.photos.map((item, index) => {
                        return (
                          <Image key={index+"photos"} style={[styles.photo, {marginRight: index%2 === 0 ? "2.5%" : 0}]} source={{uri: this.props.mediaBasePath + item}} />
                        )
                      })
                      :
                      <Text style={[FontStyles.subhead.regular, {color: Colors.dark.alpha1, lineHeight: 22}]}>
                        {"Çalışan herhangi bir fotoğraf eklemedi."}
                      </Text>
                    }
                  </View>
                </View>
                <View style={styles.seperator}/>
              </View>
            </ScrollView>
            
            <ScrollView 
              showsVerticalScrollIndicator={false}
              style={styles.column}
            >
              <Text style={[FontStyles.title2.semibold, {marginTop: 64, marginLeft: 8,}]}>
                {"Rapor Onayı"}
              </Text>
              
  
                {this.props.selectedReviewReport?.isAccepted === null ?
    
                    <View style={[styles.innerColumn, {paddingBottom: 20}]}>
                      <View style={[styles.row, {paddingVertical: null, paddingTop: 16, flexDirection: "column", alignItems: "flex-start"}]}>
                        <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha1}]}>
                          {I18n.t("emergencyChangeTitle")}
                        </Text>
                        <Text style={[FontStyles.footnote.medium, {flex: 1, marginTop: 5, fontSize: 14, color: Colors.dark.alpha08}]}>
                          {I18n.t("emergencyChangeDescription")}
                        </Text>
        
                        <View style={{flexDirection: "row", alignItems: "center", paddingVertical: 16}}>
                          <TouchableOpacity onPress={() => this.setState({selectedPriorityInd: 1})} style={[styles.priorityButton, {borderColor: this.state.selectedPriorityInd == 1 ? Colors.white.alpha1 : Colors.gray.alpha02, backgroundColor: this.state.selectedPriorityInd == 1 ? Colors.yellow.alpha1 : Colors.white.alpha1}]}>
                            <Text style={[FontStyles.subhead.medium, {color: this.state.selectedPriorityInd == 1 ? Colors.dark.alpha08 : Colors.gray.alpha06}]}>
                              {I18n.t("LOW")}
                            </Text>
                          </TouchableOpacity>
          
                          <TouchableOpacity onPress={() => this.setState({selectedPriorityInd: 2})} style={[styles.priorityButton, {borderColor: this.state.selectedPriorityInd == 2 ? Colors.white.alpha1 : Colors.gray.alpha02, backgroundColor: this.state.selectedPriorityInd == 2 ? Colors.orange.alpha1 : Colors.white.alpha1}]}>
                            <Text style={[FontStyles.subhead.medium, {color: this.state.selectedPriorityInd == 2 ? Colors.white.alpha1 : Colors.gray.alpha06}]}>
                              {I18n.t("MODERATE")}
                            </Text>
                          </TouchableOpacity>
          
                          <TouchableOpacity onPress={() => this.setState({selectedPriorityInd: 3})} style={[styles.priorityButton, {borderColor: this.state.selectedPriorityInd == 3 ? Colors.white.alpha1 : Colors.gray.alpha02, backgroundColor: this.state.selectedPriorityInd == 3 ? Colors.red.alpha1 : Colors.white.alpha1}]}>
                            <Text style={[FontStyles.subhead.medium, {color: this.state.selectedPriorityInd == 3 ? Colors.white.alpha1 : Colors.gray.alpha06}]}>
                              {I18n.t("HIGH")}
                            </Text>
                          </TouchableOpacity>
                        </View>
                      </View>
      
                      <View style={styles.seperator}/>
      
                      <TouchableOpacity onPress={() => this.onPressReject(this.props.selectedReviewReport?.id, this.props.selectedReviewReport?.companyId)} style={[styles.priorityButton, {marginHorizontal: 24, marginTop: 16}]}>
                        {
                          this.state.loading ?
                              <ActivityIndicator color={Colors.gray.alpha08}/>
                              :
                              <Text style={[FontStyles.subhead.medium, {color: Colors.gray.alpha06}]}>
                                {I18n.t("reject")}
                              </Text>
                        }
                      </TouchableOpacity>
      
                      <TouchableOpacity onPress={() => this.onPressAccept(this.props.selectedReviewReport?.id, this.props.selectedReviewReport?.companyId)} style={[styles.priorityButton, {borderColor: Colors.greenLight.alpha1, backgroundColor: Colors.greenLight.alpha1, marginTop: 8, marginBottom: 16, marginHorizontal: 24}]}>
                        {
                          this.state.loading ?
                              <ActivityIndicator color={Colors.white.alpha1}/>
                              :
                              <Text style={[FontStyles.subhead.medium, {color: Colors.white.alpha1}]}>
                                {I18n.t("acceptReview")}
                              </Text>
                        }
                      </TouchableOpacity>
                    </View>
                    
                    :
    
                    <View style={[styles.innerColumn, {paddingBottom: 20}]}>
                      {this.props.selectedReviewReport?.priorityDegree && <View style={[styles.row, {
                        paddingVertical: null,
                        paddingTop: 16,
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }]}>
                        <Text style={[FontStyles.callout.medium, {
                          flex: 1,
                          color: Colors.dark.alpha1,
                          marginBottom: 12,
                        }]}>
                          {I18n.t('emergencyChangeTitle')}
                        </Text>
    
                        <View style={[styles.emergencyDegreeTextContainer, {
                          marginBottom: 12,
                          backgroundColor: this.props.selectedReviewReport?.priorityDegree == 'HIGH' ? Colors.red.alpha1 : this.props.selectedReviewReport?.priorityDegree == 'MODERATE' ? Colors.orange.alpha1 : Colors.yellow.alpha1,
                        }]}>
                          <Text
                              style={[FontStyles.subhead.medium, {color: this.props.selectedReviewReport?.priorityDegree == 'LOW' ? Colors.dark.alpha1 : Colors.white.alpha1}]}>
                            {I18n.t(this.props.selectedReviewReport?.priorityDegree)}
                          </Text>
                        </View>
                      </View>}
  
                      {this.props.selectedReviewReport?.priorityDegree && <View style={styles.seperator}/>}
      
                      <View style={{paddingHorizontal: 18, flexDirection: 'row', alignItems: 'center', paddingTop: 24,}}>
                        <Feather
                            name={this.props.selectedReviewReport?.isAccepted ? 'check' : 'x'}
                            size={24}
                            color={this.props.selectedReviewReport?.isAccepted ? Colors.greenLight.alpha1 : Colors.red.alpha1}
                        />
                        <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.gray.alpha1, marginLeft: 8,}]}>
                          {this.props.selectedReviewReport?.isAccepted ? I18n.t("reportAlreadyAccepted") : I18n.t("reportAlreadyRejected")}
                        </Text>
                        
                      </View>
                      
                    </View>
                
                
                }
              <Text style={[FontStyles.title2.semibold, {marginTop: 24, marginLeft: 8,}]}>
                {"Raporlayan Kişinin Bilgileri"}
              </Text>
              <View style={[styles.innerColumn]}>
                <View style={styles.row}>
                  <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha08}]}>
                    {I18n.t("firstName")}
                  </Text>
                  <Text style={[FontStyles.callout.regular, {color: Colors.secondaryBlue.alpha1}]}>
                    {this.props.selectedReviewReport?.submittingEmployee.firstName}
                  </Text>
                </View>
                <View style={styles.seperator}/>

                <View style={styles.row}>
                  <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha08}]}>
                    {I18n.t("lastName")}
                  </Text>
                  <Text style={[FontStyles.callout.regular, {color: Colors.secondaryBlue.alpha1}]}>
                    {this.props.selectedReviewReport?.submittingEmployee.lastName}
                  </Text>
                </View>
                <View style={styles.seperator}/>
                
                <View style={[styles.row, {marginBottom: 12,}]}>
                  <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha08}]}>
                    {I18n.t("username")}
                  </Text>
                  <Text style={[FontStyles.callout.regular, {color: Colors.secondaryBlue.alpha1}]}>
                    {this.props.selectedReviewReport?.submittingEmployee.username}
                  </Text>
                </View>
                
              </View>

              {
                this.props.selectedReviewReport?.type !== "THREAT"  &&
                <Text style={[FontStyles.title2.semibold, {marginTop: 24, marginLeft: 8,}]}>
                  {"Kazazede Bilgileri"}
                </Text>
              }

              {
                this.props.selectedReviewReport?.type !== "THREAT" &&
                <View style={[styles.innerColumn, {flex: null}]}>
                  <View style={styles.row}>
                    <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha08}]}>
                      {I18n.t("occupationOfVictim")}
                    </Text>
                    <Text style={[FontStyles.callout.regular, {color: Colors.secondaryBlue.alpha1}]}>
                      {this.props.selectedReviewReport?.occupationOfVictimName}
                    </Text>
                  </View>
                  <View style={styles.seperator}/>

                  <View style={styles.row}>
                    <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha08}]}>
                      {I18n.t("sexOfVictim")}
                    </Text>
                    <Text style={[FontStyles.callout.regular, {color: Colors.secondaryBlue.alpha1}]}>
                      {this.props.selectedReviewReport?.sexOfVictim ? this.props.selectedReviewReport?.sexOfVictim == "MALE" ? "Erkek" : "Kadın" : "-"}
                    </Text>
                  </View>
                  <View style={styles.seperator}/>

                  <View style={styles.row}>
                    <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha08}]}>
                      {I18n.t("yearsOfExperienceOfVictim")}
                    </Text>
                    <Text style={[FontStyles.callout.regular, {color: Colors.secondaryBlue.alpha1}]}>
                      {this.props.selectedReviewReport?.yearsOfExperienceOfVictim + "Yıl"}
                    </Text>
                  </View>
                  <View style={styles.seperator}/>

                  <View style={styles.row}>
                    <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha08}]}>
                      {I18n.t("injuryType")}
                    </Text>
                    <Text style={[FontStyles.callout.regular, {color: Colors.secondaryBlue.alpha1}]}>
                      {this.props.selectedReviewReport?.injuryTypeName}
                    </Text>
                  </View>
                  <View style={styles.seperator}/>

                  <View style={styles.row}>
                    <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha08}]}>
                      {I18n.t("injuredPartOfBody")}
                    </Text>
                    <Text style={[FontStyles.callout.regular, {color: Colors.secondaryBlue.alpha1}]}>
                      {this.props.selectedReviewReport?.injuredPartOfBodyName}
                    </Text>
                  </View>
                  <View style={styles.seperator}/>

                  <View style={styles.cell}>
                    <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha08}]}>
                      {I18n.t("occupationOfVictimText")}
                    </Text>
                    <View style={styles.textAreaContainer}>
                      <Text style={[FontStyles.subhead.regular, {color: Colors.dark.alpha1, lineHeight: 22}]}>
                        {this.props.selectedReviewReport?.occupationOfVictimText || "Çalışan ekstra bilgi belirtmedi."}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.seperator}/>

                  <View style={styles.cell}>
                    <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha08}]}>
                      {I18n.t("injuryTypeText")}
                    </Text>
                    <View style={styles.textAreaContainer}>
                      <Text style={[FontStyles.subhead.regular, {color: Colors.dark.alpha1, lineHeight: 22}]}>
                      {this.props.selectedReviewReport?.injuryTypeText || "Çalışan ekstra bilgi belirtmedi."}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.seperator}/>

                  <View style={styles.cell}>
                    <Text style={[FontStyles.callout.medium, {flex: 1, color: Colors.dark.alpha08}]}>
                      {I18n.t("injuredPartOfBodyText")}
                    </Text>
                    <View style={styles.textAreaContainer}>
                      <Text style={[FontStyles.subhead.regular, {color: Colors.dark.alpha1, lineHeight: 22}]}>
                      {this.props.selectedReviewReport?.injuredPartOfBodyText || "Çalışan ekstra bilgi belirtmedi."}
                      </Text>
                    </View>
                  </View>
                </View>
              }
              <Pressable onPress={()=> this.props.navigation.navigate('CreateContest', {report: this.props.selectedReviewReport})} style={styles.createContestButton}>
                <Text style={[FontStyles.subhead.medium, {color: Colors.white.alpha1}]}>
                  {'Yarışma Oluştur'}
                </Text>
              </Pressable>
            </ScrollView>
          </View>

          <TouchableOpacity style={styles.cancelButton} onPress={() => {this.setState({selectedPriorityInd: null, loading: false});this.props.reportReviewController(0)}} activeOpacity={0.9}>
            <Feather name="x" size={26} color={Colors.white.alpha08}/>
          </TouchableOpacity>
        </BlurView>

        <WebAlert ref={ref => this.alertCardRef = ref}/>

      </Animated.View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
  },
  blur: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cancelButton: {
    position: "absolute",
    top: 16,
    right: 16,
    backgroundColor: Colors.primaryBlue.alpha1,
    borderRadius: 100,
    width: 48,
    height: 48,
    justifyContent: 'center',
    alignItems: "center"
  },
  innerContainer: {
    flex: 1,
    width: "100%",
    alignItems: "flex-start",
    flexDirection: "row",
  },
  column: {
    flex: 1,
    marginRight: 16,
    height: "100%",

  },
  innerColumn: {
    backgroundColor: Colors.white.alpha1,
    marginTop: 16,
    marginBottom: 16,
    borderRadius: 20,
    width: "100%",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 16,
    paddingVertical: 16
  },
  seperator: {
    width: "100%",
    height: 2,
    alignSelf: "center",
    backgroundColor: Colors.lightGray.alpha05
  },
  cell: {
    paddingHorizontal: 16,
    paddingVertical: 20
  },
  textAreaContainer: {
    backgroundColor: Colors.secondaryBlue.alpha01,
    borderRadius: 8,
    marginTop: 12,
    paddingHorizontal: 12,
    alignSelf: "baseline",
    paddingVertical: 10
  },
  emergencyDegreeTextContainer: {
    paddingHorizontal: 15,
    paddingVertical: 5,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: "center"
  },
  priorityButton: {
    paddingHorizontal: 20,
    paddingVertical: 7.5,
    borderRadius: 25,
    borderWidth: 2,
    borderColor: Colors.gray.alpha04,
    justifyContent: 'center',
    alignItems: "center",
    marginRight: 8
  },
  photo: {
    width: "48.5%",
    marginBottom: 15,
    borderRadius: 10,
    backgroundColor: Colors.gray.alpha01,
    aspectRatio: 1,
  },
  createContestButton: {
    width: '100%',
    height: 44,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.purple.alpha1,
    borderRadius: 100,
    marginBottom: 16
  }
})

//SAMPLE DATA
//genel 
//*** */    "type": "ACCIDENT", ***
//*** */    "createdAt": 1665740853143, ******
//   "submittingEmployeeId": "628b53851be7074bc5dbb3cc",

//kaza related
//*** */   "incidentTypes": [ "INDUSTRIAL-LASERS","WELDING-BRAZING-SOLDERING" ],
//*** */   "incidentTypesNames"
//*** */   "description": "Yaralanma",
//*** */   "emergencyDegree": "HIGH",
//*** */   "incidentSite": "PRODUCTION-PROCESS-AREAS",
//*** */   "incidentSiteName"
//*** */   "incidentSiteText": null,
//*** */   "photos": [],
//*** */   "counterMeasure": "Yok",

//victim
//*** */   "occupationOfVictim": "OFFICE-WORKER",
//*** */   "sexOfVictim": "MALE",
//*** */   "occupationOfVictimText": null,
//*** */   "yearsOfExperienceOfVictim": "2-5",
//*** */   "injuryType": "AMPUTATION",
//*** */   "injuryTypeText": null,
//*** */   "injuredPartOfBody": "HEAD",
//*** */   "injuredPartOfBodyText": null,
//*** */   "isCausedByBehaviour": false,

//review related
//   "priorityDegree": null, //paneli kontrol eden kişi belirlicek
//   "isAccepted": null,

//submittingEmployee
//   "submittingEmployee": {
//       "id": "628b53851be7074bc5dbb3cc",
//       "companyId": "628a702f246640ff360013a6",
//       "joinedAt": 1652169467258,
//*** */       "email": null,
//*** */       "username": "demo_dicle",
//       "isActive": true,
//       "authRole": "EMPLOYEE",
//       "language": "tr",
//       "photo": "NfMWGypBX5MIKu2gbbqz8JeGiZhW.jpg",
//*** */       "firstName": "Dicle",
//*** */       "lastName": "Tiryaki"
//   },


// {
//   "id": "63493035c75498a0a430c209",
//   "reviewingModeratorId": null,

//   "reviewingModerator": null
// }
