import I18n from "i18n-js"
import React, { memo, useEffect, useRef, useState } from "react"
import { StyleSheet, View, Text } from "react-native"
import API from "../utils/API"
import Colors from "../utils/Colors"
import FontStyles from "../utils/FontStyles"
import { hexToRgba } from "../utils/Helper"
import Hoverable from '../components/Hoverable'

function SurveyTags(props){
  const [ tags, setTags ] = useState([])
  const [ selectedTags, setSelectedTags ] = useState([])
  const firstRender = useRef(true)

  useEffect(()=> {
    API.getCompanyTags().then(({tags})=> {
      console.log(tags)
      setTags(tags)
    })
  },[])

  useEffect(()=> {
    if(!firstRender.current){
      props.onTagPressed(selectedTags)
    }else{
      firstRender.current = false
    }
  },[selectedTags])

  const onTagSelect = (id) => {
    let index = selectedTags.indexOf(id)
    console.log(index)
    if(index !== -1){
      let arr = [...selectedTags]
      arr.splice(index, 1) 
      setSelectedTags(arr)
    }else{
      setSelectedTags(selectedTags => [...selectedTags, id])
    }
  }

  return (
    <View style={styles.container}>
      <Text style={[FontStyles.body.bold, { color: Colors.dark.alpha1 }]}>
        {props.title }
      </Text>
      <Text style={[FontStyles.callout.regular, { color: Colors.dark.alpha08, marginTop: 12 }]}>
        {props.description || I18n.t('surveyTagBody')}
      </Text>
      <View style={styles.tagContainer}> 
        {
          tags.map((tag, index)=> {
            // if(!tag.isActive){
            //   return null
            // }

            return (
              <Hoverable
                key={tag.id}
                style={[styles.tagButton, { marginRight: index !== tags.length - 1 ? 12 : 0, borderColor: hexToRgba(tag.colorHex, 0.5), borderWidth: 2, backgroundColor: selectedTags.includes(props.withTagData ? tag : tag.id) ? tag.colorHex : 'white' }]}
                onPress={()=> onTagSelect(props.withTagData ? tag : tag.id)}
                hoverStyle={{ backgroundColor: tag.colorHex}}
                text={tag.name}
                textStyle={[FontStyles.body.medium, { color: selectedTags.includes(props.withTagData ? tag : tag.id) ? 'white' : tag.colorHex }]}
                hoverTextStyle={{ color: 'white' }}
              />
            )
          })
        }
      </View>
      
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingRight: 12,
    marginTop: 24
  },
  tagButton: {
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderRadius: 10,
    marginTop: 16,
    alignItems: 'center',
    alignSelf: 'flex-start',
    minWidth: 100,
  },
  tagContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: 8
  }
})

export default memo(SurveyTags)