import { View, Animated, StyleSheet, Text, Pressable, ScrollView } from "react-native"
import { BlurView } from "expo-blur"
import React, { memo } from "react"
import FontStyles from "../utils/FontStyles"
import Colors from "../utils/Colors"
import I18n from "i18n-js"
import moment from "moment"
import { Feather } from '@expo/vector-icons'
import API from "../utils/API"
import WebAlert from './WebAlert'
import Button from "./Button"

// this.inputs = {
//   title: null, -> string
//   text: null, -> string
//   tagIds: null, -> array
//   link: null, -> 
//   date: null,
//   image, 
//   video
//   
// }

const Question = memo(
  function({ question, index }){
    
    return (
      <View style={{marginTop: 24}}>
        <Text style={[FontStyles.body.medium, { color: Colors.dark.alpha1 }]}>
          {`${index + 1}. ${question.text}`}
        </Text>
        { question.options &&
          question.options.map((option, index)=> {
            return (
              <View key={option} style={[styles.optionContainer, {marginTop: index === 0 ? 16 : 16}]}>
                <Text style={[FontStyles.subhead.regular, { color: Colors.dark.alpha1, marginRight: 12 }]}>
                  {option}
                </Text>
                {
                  question.correctOption === index &&
                  <View style={styles.correctOptionContainer}>
                    <Feather name="check" size={16} color='white' />
                  </View>
                }
              </View>
            )
          })
        }
      </View>
    )
  }
)

export default class SurveyPreview extends React.PureComponent{
  constructor(props){
    super(props)
    this.opacityValue = new Animated.Value(0)
    this.progressValue = new Animated.Value(0)
    this.pointOpacityValue = new Animated.Value(0)

    this.state={
      isShowing: false,
      data: null,
      loading: false
    }
  }

  componentDidMount(){
    this.animatePoints()
  }

  createSurvey = async () => {
    let tagIds = this.state.data.tags.map(item => item.id)

    let data = { ...this.state.data, tagIds: tagIds }
    delete data.tags
    
    if(data.description === null || data.description.length === 0){
      console.log(data)
      delete data.description
      console.log(data)
    }
    
    try{
      this.setState({ loading: true })
      if(this.props.type === 'SURVEY'){
        await API.createSurvey(data)
        if(this.props.getData){
          await this.props.getData()
        }
        this.setState({ loading: false, isShowing: false, data: null },()=> {
          this.props.navigation.navigate('Surveys')
        })
      }else if(this.props.type === 'CONTEST'){
        await API.createContest(data)
        if(this.props.getData){
          await this.props.getData()
        }     
      
        this.setState({ loading: false, isShowing: false, data: null },()=> {
          this.props.navigation.navigate('Contests')
        })
      }

    }catch(e){
      console.log(e)
      this.setState({ loading: false, isShowing: false, data: null })
      this.props.handleAlert({ isShowing: true, type: 'warning', text: e.errorMessage})
    }

  }

  setIsShowing = (isShowing, data) => {
    if(isShowing){
      this.setState({ isShowing: isShowing, data: data }, ()=> {
        Animated.timing(this.opacityValue, {
          toValue: 1,
          duration: 300,
          useNativeDriver: false
        }).start()
      })

    }else{
      Animated.timing(this.opacityValue, {
        toValue: 0,
        duration: 300,
        useNativeDriver: false
      }).start(()=> {
        this.setState({ isShowing: isShowing, data: null })
      })
    }
  }

  animatePoints = () => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(this.pointOpacityValue, {
          toValue: 1,
          duration: 300,
          useNativeDriver: true,
          delay: 500
        }),
        Animated.timing(this.pointOpacityValue, {
          toValue: 2,
          duration: 300,
          useNativeDriver: true,
          delay: 500

        }),
        Animated.timing(this.pointOpacityValue, {
          toValue: 3,
          duration: 300,
          useNativeDriver: true,
          delay: 500

        }),
      ])
    ).start()
  }

  modalInner = () => {

    return (
      <View style={styles.contentContainer}>
        <ScrollView 
          style={styles.innerContainer}
          contentContainerStyle={{ padding: 24 }}
          showsVerticalScrollIndicator={false}
        >
          <Text style={[FontStyles.title3.medium, { color: Colors.dark.alpha1 }]}>
            {this.state.data.title}
          </Text>
          <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 16}}>
            <Feather
              name="calendar"
              size={18}
              color={Colors.dark.alpha08}
            />
            <Text style={[FontStyles.subhead.medium, { color: Colors.dark.alpha08, marginLeft: 8, fontSize: 14 }]}>
              {moment(this.state.data.startsAt).utcOffset(API.timezoneOffset).format('DD MMM YYYY') + '-' + moment(this.state.data.endsAt).format('DD MMM YYYY')}
            </Text>
          </View>
          <View style={styles.tagContainer}>
            {
              this.state.data.tags.map((tag, index)=> {
                return (
                  <View key={tag.id} style={[styles.tag, { backgroundColor: tag.colorHex, marginRight: index !== this.state.data.tags.length - 1 ? 8 : 0 }]}>
                    <Text style={[ FontStyles.footnote.medium, { color: 'white' } ]}>
                      {tag.name}
                    </Text>
                  </View> 
                )
              })
            }
          </View>
          <Text style={[FontStyles.callout.regular, { color: Colors.gray.alpha1, marginTop: 12, lineHeight: 26, width: '80%' }]}>
            {this.state.data.description}
          </Text>

          {
            this.state.data?.questions.map((question, index)=> {
              return (
                <Question
                  question={question}
                  key={question.id}
                  index={index}
                  type={this.props.type}
                />
              )
            })
          }
          <Button
            text={I18n.t('send')}
            style={[styles.saveButton, { backgroundColor: this.props.type === 'CONTEST' ? Colors.purple.alpha1 : Colors.secondaryBlue.alpha1 }]}
            onPress={this.createSurvey}
            disabled={this.state.loading}
            loading={this.state.loading}
          />
        </ScrollView>

      </View>
      
    )
  }

  render(){

    if(!this.state.isShowing){
      return null
    }

    return (
      <Animated.View style={[styles.container, { opacity: this.opacityValue }]}>
        <BlurView 
          style={styles.blur} 
          intensity={100} 
          tint={'light'}
        >
          {this.modalInner()}
          <Pressable style={[styles.closeButton, { backgroundColor: this.props.type === 'SURVEY' ? Colors.secondaryBlue.alpha1 : Colors.purple.alpha1 }]} onPress={() => this.setIsShowing(false)}>
            <Feather name={"x"} color={Colors.white.alpha1} size={28}/>
          </Pressable>
          <WebAlert ref={ref => this.alert = ref}/>
        </BlurView>
      </Animated.View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
  },
  blur: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  row: {
    width: '100%',
    marginTop: 24
  },
  innerContainer: {
    width: '100%',
    flex: 1,
    backgroundColor: Colors.white.alpha1,
    borderRadius: 15,
  },
  tagContainer: {
    width: '100%', 
    flexDirection: 'row', 
    flexWrap: 'wrap'
  },
  tag: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderRadius: 100,
    marginTop: 16,
    alignItems: 'center',
    alignSelf: 'flex-start',
    minWidth: 50,    
  },
  closeButton: {
    backgroundColor: Colors.primaryBlue.alpha1,
    borderRadius: 222,
    height: 48,
    width: 48,
    position: "absolute",
    right: "3.5%",
    top: "4%",
    alignSelf: "flex-end",
    justifyContent: "center",
    alignItems: "center",
  },
  attachmentContainer: {
    flexDirection: 'row', 
    marginTop: 16, 
    alignItems: 'center'
  },
  submitButton: {
    marginTop: 32, 
    width: '50%', 
    alignSelf: 'center' 
  },
  titleContainer: {
    flexDirection: 'row', 
    justifyContent: 'space-between', 
    marginTop: 24
  },
  image: {
    width: 300, 
    height: 200, 
    borderRadius: 10, 
    alignSelf: 'center' 
  },
  loadingContainer: {
    width: '100%',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  contentContainer: {
    height: '85%', 
    width: '65%',
  },
  container: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
  },
  blur: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  closeButton: {
    borderRadius: 222,
    height: 48,
    width: 48,
    position: "absolute",
    right: "3.5%",
    top: "4%",
    alignSelf: "flex-end",
    justifyContent: "center",
    alignItems: "center",
  },
  loadingContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flex: 1,
  },
  contentContainer: {
    height: '85%', 
    width: '65%',
  },
  innerContainer: {
    width: '100%',
    flex: 1,
    backgroundColor: Colors.white.alpha1,
    borderRadius: 15,
  },
  activeIndicator: { 
    paddingHorizontal: 12,
    paddingVertical: 4,
    borderRadius: 100,
    marginLeft: 32
  },
  optionContainer: {
    width: '60%',
    flexDirection: 'row',
    alignItems: 'center'
  },
  saveButton: {
    width: '60%', 
    marginTop: 24, 
    alignSelf: 'center', 
  },
  correctOptionContainer: {
    width: 20,
    height: 20, 
    borderRadius: 16, 
    justifyContent: 'center', 
    alignItems: 'center', 
    backgroundColor: Colors.purple.alpha1
  }
})