import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import { Animated, View, StyleSheet } from "react-native";
import Colors from "../utils/Colors";

export default class Progress extends React.PureComponent{
  constructor(props){
    super(props)
    this.state={
      containerWidth: 0
    }
  }

  onLayout = ({nativeEvent}) => {
      this.setState({ containerWidth: nativeEvent.layout.width })
  }

  render(){
    const progress = {
      transform: [
        {
          translateX: this.props.value.interpolate({
            inputRange: [0, 1],
            outputRange: [0, this.state.containerWidth],
            extrapolate: 'clamp'
          })
        }
      ]
    }

    return (
      <View onLayout={this.onLayout} style={[styles.container, this.props.style]}>
        <Animated.View style={[styles.progress, progress, { width: '100%'}]}>
          <LinearGradient
            style={{ width: '100%', height: '100%', borderRadius: 100 }}
            colors={[ Colors.secondaryBlue.alpha1, Colors.primaryBlue.alpha1 ]}
            start={{ x: 0, y: 0.5 }}
            end={{ x: 1, y: 0.5 }}
          />
        </Animated.View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    height: 16,
    borderRadius: 100,    
    backgroundColor: Colors.gray.alpha02,
  },
  progress: {
    height: '100%',
    position: 'absolute',
    right: '100%',
  }
})