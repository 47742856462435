import { memo } from "react";
import { View, StyleSheet, Text, Pressable } from "react-native";
import { Feather } from '@expo/vector-icons'
import Colors from "../utils/Colors";
import FontStyles from "../utils/FontStyles";

function InfoCard({
  iconName, 
  title, 
  subText, 
  arrowColor, 
  iconContainerColor, 
  arrowContainerColor, 
  containerStyle,
  onPress
}){


  return (
    <Pressable onPress={onPress}>
      <View style={[styles.container, containerStyle]} >
        <View style={[styles.iconContainer, { backgroundColor: iconContainerColor }]}>
          <Feather
            name={iconName}
            size={24}
            color={'white'}
          />
        </View>
        <View style={{marginLeft: 16, flex: 1}}>
          <Text style={[FontStyles.body.medium, {color: Colors.dark.alpha08}]}>
            {title}
          </Text>
          <Text style={[{fontSize: 26, fontWeight: "800", color: Colors.gray.alpha1}]}>
            {subText}
          </Text>
        </View>
        {
          arrowColor && arrowContainerColor &&
          <View style={[styles.arrowContainer, { backgroundColor: arrowContainerColor}]}>
            <Feather
              name="arrow-right"
              size={22}
              color={arrowColor}
            />
          </View>
        }
      </View> 
    </Pressable>
    
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    width: '100%',
    paddingVertical: 17.5,
    paddingHorizontal: 17.5,
    backgroundColor: 'white',
    shadowColor: '#C4C4C4',
    alignItems: 'center',
    borderRadius: 20,
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.15,
    shadowRadius: 10,
    alignSelf: 'baseline'
  },
  iconContainer: {
    width: 56,
    height: 56,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center'
  },
  arrowContainer: {
    width: 48,
    height: 48,
    borderRadius: 27,
    justifyContent: 'center',
    alignItems: 'center'
  }
})

export default memo(InfoCard)