import Colors from "./Colors"

export default {
  'LOGIN': {icon: 'log-in', color: Colors.primaryBlue.alpha1},
  'LOGOUT': {icon: 'log-out', color: Colors.primaryBlue.alpha1},
  'LOGOUT_FROM_ALL_DEVICES': {icon: 'log-out', color: Colors.primaryBlue.alpha1},
  'SUBMIT_REPORT': {icon: 'alert-triangle', color: Colors.red.alpha1},
  'BEGIN_CONTEST': {icon: 'target', color: Colors.purple.alpha1},
  'COMPLETE_CONTEST': {icon: 'target', color: Colors.purple.alpha1},
  'UPDATE_USER_PHOTO': {icon: 'camera', color: Colors.badgeOrange.alpha1},
  'REMOVE_USER_PHOTO': {icon: 'camera', color: Colors.badgeOrange.alpha1},
  'BEGIN_MEDIA_CONTENT_TEST': {icon: 'play', color: Colors.badgeYellow.alpha1},
  'COMPLETE_MEDIA_CONTENT_TEST': {icon: 'play', color: Colors.badgeYellow.alpha1},
  'BEGIN_MINI_TEST': {icon: 'edit-2', color: Colors.primaryBlue.alpha1},
  'COMPLETE_MINI_TEST': {icon: 'edit-2', color: Colors.primaryBlue.alpha1},
  'BEGIN_SURVEY': {icon: 'file-text', color: Colors.secondaryBlue.alpha1},
  'COMPLETE_SURVEY': {icon: 'file-text', color: Colors.secondaryBlue.alpha1},
}