import React from "react";
import { FlatList, StyleSheet, View, Text, ScrollView, Animated, Pressable } from "react-native";
import API from "../../utils/API";
import i18n from "i18n-js";
import Colors from "../../utils/Colors";
import FontStyles from "../../utils/FontStyles";
import SurveyTags from "../../components/SurveyTags";
import Input from "../../components/Input";
import Button from '../../components/Button'
import DatePicker from '../../components/DatePicker'
import RowItemAnnouncement from "../../components/RowItemAnnouncement";
import FileAdder from "../../components/FileAdder";
import AnnouncementPreview from "../../components/AnnouncementPreview";
import WebAlert from "../../components/WebAlert";
import I18n from "i18n-js";
import { Feather } from '@expo/vector-icons'


export default class Announcements extends React.PureComponent{
  constructor(props){
    super(props)
    this.inputs = {
      title: null,
      text: null,
      tags: null,
      link: null,
      publishedAt: null,
    }
    
    this.files = {}
    this.clock = null
    
    this.errorTextOpacity = new Animated.Value(0)
    
    this.state = {
      data: [],
      mediaBasePath: '',
      errorType: null,
      loading: false,
      clock: 11,
      minClock: 0
    }
  }

  componentDidMount(){
    this.getData()
  }

  getData = async () => {
    const { announcements, mediaBasePath } = await API.getAnnouncements()

    this.setState({ data: announcements, mediaBasePath: mediaBasePath })
  }

  removeAnnouncement = async (item,index) => {
    if(confirm(I18n.t('removeAnnouncement', {title: item.title}))){
      try{
        await API.removeAnnouncement(item.id)
        let arr = [...this.state.data]
        arr.splice(index, 1)
        this.setState({ data: arr })
        this.alert.setIsShowing({isShowing: true, text: I18n.t('announcementRemoveSuccess', {title: item.title}), type: 'success'})
      }catch(e){
        this.alert.setIsShowing({isShowing: true, text: e.errorMessage, type: 'warning'})
      }
    }
  }

  addAnnouncement = (announcement) => {
    let arr = [...this.state.data]
    arr.unshift(announcement)
    this.setState({data: arr})
    
    this.clear()
  }

  renderItem = ({ item, index }) => {
    return (
      <RowItemAnnouncement
        navigation={this.props.navigation} 
        item={item}
        onPress={()=>this.onAnnouncementPress(item.id)}
        onPressTabButton={this.props.onPressTabButton}
        mediaBasePath={this.state.mediaBasePath}
        onRemovePressed={this.removeAnnouncement}
        index={index}
      />
    )
  }

  announcementHeader = () => {
    return (
      <Text style={[FontStyles.body.bold, { color: Colors.dark.alpha1 }]}>
        {i18n.t('announcements')}
      </Text>
    )
  }

  onAnnouncementPress = (id) => {
    API.getAnnouncement(id).then(({announcement})=> {
      console.log(announcement)
      this.previewRef.setIsShowing(true, announcement)
    })
  }

  setInputs = (text, type) => {
    this.inputs[type] = text.trim()
  }

  setTags = (tags) => {
    this.inputs.tags = [...tags]
  }
  
  clear = () => {
    this.inputs = {
      title: null,
      text: null,
      link: null,
    }
  
    this.files = {}
    this.clock = null
    
    this.titleRef.clear()
    this.textRef.clear()
    this.linkRef.clear()
    this.fileAdderRef.clear()
    this.datePickerRef.clear()
    this.setState({
      clock: 11,
      minClock: 0
    })
  }

  onDateChange = (startsAt) => {      
    if(startsAt.isSame(new Date(), 'day')){
      const currentHour = new Date().getHours()
      this.setState({ minClock: currentHour + 1, clock: this.state.clock < currentHour + 1 ? currentHour + 1 : this.state.clock})
    }else{
      this.setState({
        minClock: 0
      })
    }

    if(startsAt){
      this.inputs.publishedAt = startsAt.valueOf()
      if(this.state.company){
        this.inputs.publishedAt += ( ( -1 * new Date().getTimezoneOffset() ) - API.timezoneOffset ) * 60 * 1000
      }
    }
    
  }

  errorHandler = () => {

    const requiredData = [
      {key: 'title', translation: 'titleError'}, 
      {key: 'text', translation: 'textError'}, 
      {key: 'publishedAt', translation: 'dateError'}, 
      {key: 'tags', translation: 'tagError'}
    ]

    for(const obj of requiredData){
      if(!this.inputs[obj.key] || this.inputs[obj.key].length === 0){
        return obj
      }
    }

    return null
  }

  animateErrorText = (toValue, callback) => {
    Animated.timing(this.errorTextOpacity, {
      toValue: toValue,
      duration: 350,
      useNativeDriver: true
    }).start(()=> {
      callback && callback()
    })
  }

  onButtonPressed = () => {
    const errorType = this.errorHandler()
    if(!errorType){
      this.inputs.publishedAt += this.state.clock * 60 * 60 * 1000
      this.animateErrorText(0,()=> {
        this.setState({ errorType: null })
      })
      
      this.previewRef.setIsShowing(true, {...this.inputs, ...this.files })
    }else{
      this.setState({ errorType: errorType },()=> {
        this.animateErrorText(1)
      })
    }
  }

  onFileChange = (files) => {
    this.files = files
  }

  handleAlert = ({text, isShowing, type}) => {
    this.alert.setIsShowing({
      text: text,
      isShowing: isShowing,
      type: type
    })
  }

  handleClock = (increment) => {

    this.setState(function(prevState){
      if( prevState.clock === 23 && increment === 1 ){
        return { clock: this.state.minClock }
      }else if( prevState.clock <= this.state.minClock && increment === -1 ){
        return { clock: 23 }
      }

      return { clock: prevState.clock + (increment) }
    })
  }

  render(){
    return (
      <View style={ styles.container }>
        <ScrollView
          style={{ flex: 1 }}
          contentContainerStyle={{ paddingBottom: 24 }}
          showsVerticalScrollIndicator={false}
        >
          <View style={styles.innerContainer}>
            <View style={styles.leftColumn}>
              <Input
                title={i18n.t('announcementTitle')}
                titleDescription={i18n.t('announcementTitleBody')}
                onChangeText={this.setInputs}
                type={'title'}
                style={{ width: '100%' }}
                inputStyle={{ width: '100%' }}
                maxLength={32}
                ref={r => this.titleRef = r}
              />
              <Input
                title={i18n.t('announcement')}
                titleDescription={i18n.t('announcementTextDescription')}
                onChangeText={this.setInputs}
                type={'text'}
                style={{ marginTop: 24, width: '100%' }}
                inputStyle={{ width: '100%', height: 200 }}
                maxLength={4000}
                ref={r => this.textRef = r}
              />

              <SurveyTags
                onTagPressed={this.setTags}
                title={i18n.t('announcementTags')}
                description={i18n.t('announcementTagsBody')}
                withTagData
              />

              <FileAdder
                onChange={this.onFileChange}
                ref={r => this.fileAdderRef = r}
              />
              <View style={{flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', marginTop: 24}}>
                <DatePicker
                  onSelectionChange={this.onDateChange}
                  colors={[Colors.primaryBlue.alpha1, Colors.secondaryBlue.alpha01]}
                  isSingle
                  ref={r => this.datePickerRef = r}
                />
                <View style={{flexDirection: 'row', alignItems: 'center', marginLeft: 12}}>
                  <Pressable style={styles.clockSetter} onPress={()=> this.handleClock(-1)}>
                    <Feather
                      name='minus'
                      size={20}
                      color={Colors.dark.alpha08}
                    />                  
                  </Pressable>
                  <View style={styles.clock}>
                    <Text style={[FontStyles.body.medium, { color: Colors.dark.alpha08 }]}>
                      {this.state.clock.toString().padStart(2, '0') + ':00'}
                    </Text>
                  </View>
                  <Pressable style={styles.clockSetter} onPress={()=> this.handleClock(1)}>
                    <Feather
                      name='plus'
                      size={20}
                      color={Colors.dark.alpha08}
                    />
                  </Pressable>
                </View>
              </View>

              <Input
                title={i18n.t('link')}
                titleDescription={i18n.t('linkDescription')}
                onChangeText={this.setInputs}
                type={'link'}
                style={{ marginTop: 24, width: '100%' }}
                inputStyle={{ width: '100%' }}
                ref={r => this.linkRef = r}
              /> 
              <View style={styles.buttonContainer}>
                <Animated.Text style={[FontStyles.footnote.medium, { color: Colors.red.alpha1, opacity: this.errorTextOpacity, textAlign: 'center' }]}>
                  {this.state.errorType ? i18n.t(this.state.errorType.translation) : 'sss'}
                </Animated.Text>
                <Button
                  text={i18n.t('next')}
                  style={styles.saveButton}
                  onPress={this.onButtonPressed}
                />
              </View>
            </View>
            <View style={styles.rightColumn}>
              <FlatList
                data={this.state.data}
                style={{ height: 10 }}
                renderItem={this.renderItem}
                keyExtractor={(item, index) => item.id}
                ListHeaderComponent={this.announcementHeader}
                contentContainerStyle={{ paddingTop: 20 }}
                showsVerticalScrollIndicator={false}
              />
            </View>
          </View>
        </ScrollView>
        <WebAlert ref={ref => this.alert = ref}/>
        <AnnouncementPreview
          ref={ref => this.previewRef = ref}
          addAnnouncement={this.addAnnouncement}
          handleAlert={this.handleAlert}
          mediaBasePath={this.state.mediaBasePath}
        />
      </View>
      
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1, 
    backgroundColor: '#F3FAFF', 
    borderRadius: 15, 
    overflow: 'hidden'
  },  
  innerContainer: {
    flex: 1,
    flexDirection: 'row',
    borderRadius: 15
  },

  leftColumn: {
    flex: 1.5,
    paddingLeft: 24,
    paddingTop: 24,
    paddingRight: 36,
    alignSelf: 'baseline'
  },
  rightColumn: {
    flex: 1,
    paddingRight: 24,
  },

  fileDropInput: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    cursor: 'pointer',
    outlineStyle: 'none'
  },
  saveButton: {
    paddingVertical: 20,
    width: '100%',
    backgroundColor: Colors.primaryBlue.alpha1,
    alignSelf: 'baseline',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 8,
    borderRadius: 12.5
  },
  buttonContainer: {
    marginTop: 20, 
    width: '50%', 
    minWidth: 200 
  },
  clockSetter: {
    padding: 4, 
    borderRadius: 7.5, 
    justifyContent: 'center', 
    alignItems: 'center',
    backgroundColor: 'white'
  },
  clock: {
    marginHorizontal: 12, 
    width: 100, 
    backgroundColor: 'white', 
    paddingVertical: 8, 
    alignItems: 'center',
    borderRadius: 10
  }
})