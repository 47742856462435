import {memo, useState, forwardRef, useCallback} from 'react';
import { TextInput } from "react-native";


const ResizingTextInput = forwardRef(({ style, inputHeight, onChangeText, ...props }, ref) => {
  const [ height, setHeight ] = useState(inputHeight)

  return (
    <TextInput
      style={[ ...style, { height: height } ]}
      onContentSizeChange={({ nativeEvent })=> {setHeight(nativeEvent.contentSize.height)}}
      multiline
      ref={ref}
      onChangeText={(text)=> {
        
        if(text.length === 0){
          setHeight(inputHeight)
        }
        onChangeText && onChangeText(text)
      }}
      {...props}
    />
  )
})

export default memo(ResizingTextInput)