import React, { useContext } from 'react';
import { useMMKVObject } from "react-native-mmkv";
import Storage  from '../utils/Storage'
import { STORAGE_USER_PATH } from '../utils/API'


export const AuthContext = React.createContext(null)

export function AuthProvider(props){
  const [ user, setUser ] = useMMKVObject(STORAGE_USER_PATH, Storage)

  return (
    <AuthContext.Provider value={{
      user,
      setUser,
    }}>
      {props.children}
    </AuthContext.Provider>
  );
}

export default function useAuth() {
  return useContext(AuthContext);
}

