import { FlatList, StyleSheet, View, Text } from "react-native"
import React from "react"
import Input from "../components/Input"
import FontStyles from "../utils/FontStyles"
import I18n from "i18n-js"
import Colors from "../utils/Colors"
import API from '../utils/API'
import SurveyTags from "../components/SurveyTags"
import Button from "../components/Button"
import WebAlert from "../components/WebAlert"
import moment from "moment"

export default class Notifications extends React.PureComponent{
  constructor(props){
    super(props)
    this.text = ''
    this.tagIds = []
    this.state={
      notifs: [],
      loading: false
    }
  }

  componentDidMount(){
    this.getNotifs()
  }

  getNotifs = async () => {
    const { notifications } = await API.getNotifications()
    console.log(notifications)
    this.setState({ notifs: notifications })
  }

  sendNotif = async () => {
    try{
      this.setState({ loading: true })
      const { notification } = await API.sendNotification(this.text, this.tagIds)
      this.alert.setIsShowing({ isShowing: true, text: I18n.t('notifSuccess'), type: 'success' })
      const newNotifs = [notification, ...this.state.notifs]
      this.setState({ loading: false, notifs: newNotifs })
    }catch(e){
      this.setState({ loading: false })
      this.alert.setIsShowing({ isShowing: true, text: e.errorMessage, type: 'warning' })
    }
  }

  renderItem = ({ item }) => {
    return (
      <View style={styles.row}>
        <Text style={[ FontStyles.body.medium, { color: Colors.dark.alpha1 } ]}>
          {item.text}
        </Text>

        <View style={{ marginTop: 16, flexDirection: 'row' }}>
          <View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
            {
              item.tags.map((tag, index)=> {
                return (
                  <View key={tag.id} style={[styles.tagContainer, { backgroundColor: tag.colorHex }]}>
                    <Text style={[FontStyles.caption.medium, { color: 'white' }]}>
                      {tag.name}
                    </Text>
                  </View>
                )
              })
            }
          </View>
          <View>
            <Text style={[FontStyles.caption.medium, { color: Colors.dark.alpha06 }]}>
              {moment(item.sentAt).utcOffset(API.timezoneOffset).format('DD.MM.YYYY')}
            </Text>
            <Text style={[FontStyles.caption.medium, { color: Colors.dark.alpha06, textAlign: 'center' }]}>
              {moment(item.sentAt).utcOffset(API.timezoneOffset).format('HH:mm')}
            </Text>
          </View>
        </View>
      </View>
    )
  }

  setTags = (tagIds) => {
    this.tagIds = [...tagIds]
  }

  render(){
    return (
      <View style={{flex: 1}}>
        <View style={styles.container}>
          <View style={{flex: 1, paddingRight: 36}}>
            <Input
              inputStyle={{ width: '100%', height: 100 }}
              style={{ width: '100%' }}
              title={I18n.t('notificationText')}
              titleDescription={I18n.t('notifText')}
              onChangeText={(text)=> this.text = text}
              type={'title'}
              maxLength={178}
            />  
            <SurveyTags
              onTagPressed={this.setTags}
              title={I18n.t('notifTags')}
              description={I18n.t('notifTagsBody')}
            />
            <Button
              text={I18n.t('send')}
              style={styles.saveButton}
              onPress={this.sendNotif}
              loading={this.state.loading}
            />
          </View>
          <View style={{ flex: 1, paddingLeft: 36 }}>
            <Text style={[FontStyles.body.bold, { color: Colors.dark.alpha1 }]}>
              {I18n.t('notifications')}
            </Text>
            <FlatList
              data={this.state.notifs}
              style={{ flex: 1 }}
              contentContainerStyle={{ paddingTop: 16 }}
              keyExtractor={(item)=> item.id}
              renderItem={this.renderItem}
            />
          </View>
        </View>
        
        <WebAlert 
          ref={ref => this.alert = ref}
        />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    flexDirection: "row",
    borderRadius: 30,
    paddingHorizontal: 24,
    paddingVertical: 24,
    backgroundColor: "#F3FAFF",
    overflow: "hidden"  
  },
  row: {
    borderBottomWidth: 1,
    borderColor: Colors.dark.alpha04,
    paddingVertical: 16
  },
  saveButton: {
    paddingVertical: 20,
    width: '80%',
    backgroundColor: Colors.primaryBlue.alpha1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 8,
    borderRadius: 12.5
  },
  tagContainer: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 7.5,
    alignSelf: 'baseline',
    marginRight: 12
  }
})