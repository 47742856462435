import { memo } from "react"
import { View, Text} from "react-native";
import Animated, { useAnimatedStyle, interpolate } from 'react-native-reanimated'
import Colors from "../../../utils/Colors";
import FontStyles from "../../../utils/FontStyles";

function TextContainer(props){

  const reverseOpacity = useAnimatedStyle(()=> {
    const op = interpolate(
      props.opacity.value,
      [0,1],
      [1,0]
    )

    return {
      opacity: op
    }
  })

  const warningOpacity = useAnimatedStyle(()=> {
    return {
      opacity: props.opacity.value
    }
  })

  return(
    <View style={{width : '100%', alignItems: 'center', paddingBottom: 20}}>
      <Animated.View style={[{ alignItems: 'center' }, reverseOpacity]}>
        <Text style={[FontStyles.largeTitle.semibold, {color: Colors.dark.alpha1}]}>
          {props.title}
        </Text>
        <Text style={[FontStyles.body.regular, { color: Colors.dark.alpha08, marginTop: 12, lineHeight: 26, marginBottom: 12, textAlign: 'center'}]}>
          {props.subText}
        </Text>
      </Animated.View>
      <Animated.View style={[{ alignItems: 'center', position: 'absolute' }, warningOpacity]}>
        <Text style={[FontStyles.largeTitle.semibold, {color: Colors.dark.alpha1}]}>
          {props.errorTitle}
        </Text>
        <Text style={[FontStyles.body.regular, { color: Colors.dark.alpha08, marginTop: 12, lineHeight: 26, textAlign: 'center' }]}>
          {props.errorSubText}
        </Text>
      </Animated.View>
    </View>
  )
}

export default memo (TextContainer)