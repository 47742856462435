import React, {forwardRef, memo, useCallback, useEffect, useState} from 'react';
import { StyleSheet, Text, View } from "react-native";
import FontStyles from "../utils/FontStyles";
import i18n from 'i18n-js'
import Colors from "../utils/Colors";
import ResizingTextInput from "./ResizingTextInput";

const Input = forwardRef(({
  title,
  titleDescription,
  onChangeText,
  type,
  style,
  inputStyle,
  maxLength
}, ref) => {

  const [ text, setText ] = useState(null)

  useEffect(()=> {
    if(text !== null){
      onChangeText(text, type)
    }
  },[text])

  return (
    <View style={style}>
      <Text style={[FontStyles.body.bold, { color: Colors.dark.alpha1 }]}>
        {maxLength ? `${title} (${maxLength - (text !== null ? text.length : 0)})` : title}
      </Text>
      <Text style={[FontStyles.callout.regular, { color: Colors.dark.alpha08, marginTop: 12 }]}>
        {titleDescription}
      </Text>
      <ResizingTextInput
        style={[styles.input, FontStyles.callout.regular, inputStyle ]}
        inputHeight={inputStyle.height}
        onChangeText={(text)=> {
          setText(text)
        }}
        maxLength={maxLength}
        placeholder={i18n.t('writeHere')}
        autoComplete='off'
        autoCorrect={false}
        multiline
        placeholderTextColor={Colors.dark.alpha06}
        ref={ref}
      />
    </View>
  )
})

const styles = StyleSheet.create({
  input: {
    marginTop: 24,
    backgroundColor: Colors.white.alpha1,
    borderRadius: 15,
    width: '50%',
    justifyContent: 'center',
    paddingVertical: 20,
    paddingHorizontal: 16,
    outlineStyle: 'none',
    
  },
})

export default memo(Input)