import React from "react";
import { StyleSheet, View, ScrollView, Animated, Text, Image, Easing } from "react-native";
import API from "../utils/API";
import i18n from "i18n-js";
import Colors from "../utils/Colors";
import FontStyles from "../utils/FontStyles";
import Input from "../components/Input";
import Button from '../components/Button'
import FileAdder from "../components/FileAdder";
import WebAlert from "../components/WebAlert";
import I18n from "i18n-js";
import QuestionAdder from "../components/QuestionAdder";
import AnimatedText from '../components/AnimatedText'
import Progress from "../components/Progress";
import { BlurView } from "expo-blur";

export default class AddMediaContent extends React.PureComponent{
  constructor(props){
    super(props)
    
    this.progressValue = new Animated.Value(0)
    this.pointOpacityValue = new Animated.Value(0)
    this.errorTextOpacity = new Animated.Value(0)

    this.inputs = {
      title: null,
      description: null,
      questions: [],
      file: null
    }

    this.state = {
      data: [],
      mediaBasePath: '',
      status: null,
      errorType: null,
    }
  }

  setInputs = (text, type) => {
    this.inputs[type] = text.trim()
  }

  onDrop = (files) => {
    this.inputs.file = files
  }

  removeFile = (type) => {
    let obj = {...this.state.files}
    delete obj[type]
    this.setState({ files: obj })
  }

  errorHandler = () => {

    const requiredData = [
      {key: 'title', translation: 'titleError'}, 
      {key: 'questions', translation: 'questionError'},
      {key: 'file', translation: 'fileError'}
    ]

    for(const obj of requiredData){
      if(!this.inputs[obj.key] || this.inputs[obj.key].length === 0){
        return obj
      }
    }

    return null
  }

  generateFormData = () => {
    const formData = new FormData()

    for(const input in this.inputs){
      let current = this.inputs[input]
      if(input === 'file'){
        formData.append(input, current.file)
      }else{
        formData.append(input, Array.isArray(current) ? JSON.stringify(current) : current)
      }
    }

    return formData
  }

  animateErrorText = (toValue, callback) => {
    Animated.timing(this.errorTextOpacity, {
      toValue: toValue,
      duration: 350,
      useNativeDriver: true
    }).start(()=> {
      callback && callback()
    })
  }

  getUploadProgress = async (mediaContent, date) => {
    try{
      const res = await API.uploadMediaContent(mediaContent.id)
      if(res.status == 'IN-PROGRESS'){
        this.animatePoints()

        Animated.timing(this.progressValue, {
          toValue: res.completionPercentage ? (res.completionPercentage / 100) : 0.1,
          duration: 1000,
          useNativeDriver: true
        }).start()
        setTimeout(() => {
          this.getUploadProgress(mediaContent, date)
        }, 10000)
      }else if(res.status == 'COMPLETED'){
        const content = await API.setMediaContentDate(mediaContent.id, date.format('YYYY-MM'))
        Animated.timing(this.progressValue, {
          toValue: 1,
          duration: 1000,
          useNativeDriver: true,
          easing: Easing.bezier(0.1, 0.3, 0.1, 0.8)
        }).start(()=> {
          this.setState({ status: res.status }, ()=> {
            this.props.route.params.addMediaContent({...content.mediaContent, isDeletable: true, startsAt: new Date(date.format('YYYY-MM') + '-01').getTime() }, this.props.route.params.index)
            setTimeout(() => {
              this.props.navigation.navigate('SmartTimeline')
            }, 1000);
          })
        })
      }
    }catch(e){
      console.log(e)
      console.log('buralar')
      this.setState({status: null})
      this.alert.setIsShowing({ 
        text: e.errorMessage , 
        isShowing: true, 
        type: 'warning',
        timeout: 5000,
      })
    }
  } 

  addMediaContent = async (date) => {
    this.setState({ errorType: null, status: 'IN-PROGRESS' })
    try {

      let formData = this.generateFormData()
      for (let pair of formData.entries()) {
        console.log(pair[0]+ ', ' + pair[1]); 
      }

      const { mediaContent, errorMessage } = await API.addMediaContent(formData)

      if(errorMessage){
        this.setState({ status: null })
        this.alert.setIsShowing({ 
          text: errorMessage , 
          isShowing: true, 
          type: 'warning',
          timeout: 5000,
        })
      }else{
        this.getUploadProgress(mediaContent, date)
      }
      

    }catch(e){
      console.log(e)
      this.setState({ status: null })
      this.alert.setIsShowing({ 
        text: e.errorMessage , 
        isShowing: true, 
        type: 'warning',
        timeout: 5000,
      })
    }
  }

  onFileChange = (file) => {
    if(file){
      this.inputs.file = file.file
    }else{
      this.inputs.file = null
    }
  }

  onButtonPressed = () => {
    this.inputs.questions = [...this.questionAdderRef.state.questions]

    const errorType = this.errorHandler()
    if(!errorType){
      this.animateErrorText(0,()=> {
        this.addMediaContent(this.props.route.params.date)
      })
      
    }else{
      this.setState({ errorType: errorType },()=> {
        this.animateErrorText(1)
      })
    }
  }

  formatText = (value) => {
    return `${((value / 1) * 100).toFixed(0)} %`
  }

  animatePoints = () => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(this.pointOpacityValue, {
          toValue: 1,
          duration: 300,
          useNativeDriver: true,
          delay: 500
        }),
        Animated.timing(this.pointOpacityValue, {
          toValue: 2,
          duration: 300,
          useNativeDriver: true,
          delay: 500

        }),
        Animated.timing(this.pointOpacityValue, {
          toValue: 3,
          duration: 300,
          useNativeDriver: true,
          delay: 500

        }),
      ])
    ).start()
  }

  loadingComponent = () => {
    if(this.state.status === null){
      return null
    }

    return (
      <Animated.View style={[styles.blurContainer, { opacity: this.loadingOpacity }]}>
        <BlurView style={styles.blur}>
          {
            this.state.status === 'COMPLETED' ?
            <View style={styles.loadingContainer}>
              <Image 
                style={{width: 80, height: 80}} 
                source={require('../assets/icons/check-icon.png')}
                resizeMode='contain'
              />
              <Text style={[FontStyles.title1.semibold, { color: Colors.dark.alpha1, marginTop: 36}]}>
                {I18n.t('announcementSendSuccess')}
              </Text>
              <Text style={[FontStyles.title3.regular, { color: Colors.dark.alpha08, marginTop: 24, lineHeight: 28}]}>
                {I18n.t('announcementSendSuccessBody')}
              </Text>
            </View>
            :
            <View style={styles.loadingContainer}>
              <Text style={[FontStyles.body.medium, { color: Colors.dark.alpha1, lineHeight: 28, textAlign: 'center' }]}>
                {I18n.t('mediaContentUploading')}
              </Text>
              <View style={{flexDirection: 'row', marginTop: 8 }}>
                <Text style={[FontStyles.body.medium, { color: Colors.dark.alpha1}]}>
                  {I18n.t('processing')}
                </Text>
                {
                  new Array(3).fill(0).map((_, index)=> {
                    const opacity = this.pointOpacityValue.interpolate({
                      inputRange: [index, index + 1],
                      outputRange: [0, 1],
                      extrapolate: 'clamp'
                    })

                    return (
                      <Animated.Text 
                        key={'p' + index} 
                        style={[FontStyles.body.medium, { color: Colors.dark.alpha1, opacity: opacity, marginLeft: index === 0 ? 2 : 0 }]}>
                        .
                      </Animated.Text>
                    )
                  })
                }
              </View>
                
              <View style={{ width: '40%', marginTop: 8 }}>
                <AnimatedText
                  animValue={this.progressValue}
                  containerStyle={[FontStyles.body.medium, { color: Colors.dark.alpha08, textAlign: 'right' }]}
                  textColor={Colors.primaryBlue.alpha1}
                  formatter={this.formatText}
                />
                <Progress
                  style={{ width: '100%', marginTop: 4 }}
                  value={this.progressValue}
                />
              </View>
            </View>
          }
        </BlurView>
      </Animated.View>
      
    )
  }

  render(){
    return (
      <View style={ styles.container }>
        <ScrollView
          style={{ flex: 1 }}
          contentContainerStyle={{ paddingVertical: 24 }}
          showsVerticalScrollIndicator={false}
        >
          <View style={styles.innerContainer}>
            <View style={styles.leftColumn}>
              <Input
                title={i18n.t('mediaContentTitle')}
                titleDescription={i18n.t('mediaContentTitleBody')}
                onChangeText={this.setInputs}
                type={'title'}
                style={{ width: '100%' }}
                inputStyle={{ width: '100%' }}
                maxLength={32}
              />
              <Input
                title={i18n.t('mediaContentDescription')}
                titleDescription={i18n.t('mediaContentDescriptionBody')}
                onChangeText={this.setInputs}
                type={'description'}
                style={{ marginTop: 24, width: '100%' }}
                inputStyle={{ width: '100%', height: 200 }}
                maxLength={4000}
              />

              <FileAdder
                onChange={this.onFileChange}
                subText={I18n.t('addMediaContentBody')}
                multiple={false}
                acceptedFormats={"video/*, audio/*"}
                max={1}
              />
              <View style={styles.buttonContainer}>
                <Animated.Text style={[FontStyles.footnote.medium, { color: Colors.red.alpha1, opacity: this.errorTextOpacity, textAlign: 'center' }]}>
                  {this.state.errorType ? i18n.t(this.state.errorType.translation) : 'sss'}
                </Animated.Text>
                <Button
                  text={i18n.t('next')}
                  style={styles.saveButton}
                  onPress={this.onButtonPressed}
                />
              </View>

            </View>
            <QuestionAdder
              ref={ref => this.questionAdderRef = ref}
              color={Colors.primaryBlue.alpha1}
              title={I18n.t('mediaContentQuestionsTitle')}
              description={I18n.t('mediaContentQuestionsBody')}
              enableCorrectAnswer
              
            />
          </View>
        </ScrollView>
        {this.loadingComponent()}
        <WebAlert ref={ref => this.alert = ref}/>
      </View>
      
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1, 
    backgroundColor: '#F3FAFF', 
    borderRadius: 15, 
    overflow: 'hidden'
  },  
  innerContainer: {
    flex: 1,
    flexDirection: 'row',
    borderRadius: 15,
    paddingHorizontal: 24,
    
  },

  leftColumn: {
    flex: 1.5,
    paddingRight: 36,
  },
  rightColumn: {
    flex: 1,
    paddingRight: 24,
  },

  fileDropInput: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    cursor: 'pointer',
    outlineStyle: 'none'
  },
  saveButton: {
    paddingVertical: 20,
    width: '100%',
    backgroundColor: Colors.primaryBlue.alpha1,
    alignSelf: 'baseline',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 8,
    borderRadius: 12.5
  },
  buttonContainer: {
    marginTop: 20, 
    width: '50%', 
    minWidth: 200 
  },
  loadingContainer: {
    width: '100%',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  blurContainer: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
  },
  blur: {
    width: '100%',
    height: '100%',
  }
})