import { Pressable, Text } from "react-native";
import React, { memo, useEffect, useState } from 'react'

function Hoverable({
  style,
  onPress,
  disabled,
  hoverStyle,
  onHoverIn,
  onHoverOut,
  text,
  textStyle,
  hoverTextStyle
}){

  const [ isHovering, setHovering ] = useState(false)

  useEffect(()=> {
    if(isHovering){
      onHoverIn && onHoverIn()
    }else{
      onHoverOut && onHoverOut()
    }
  },[isHovering])

  return (
    <Pressable 
      style={[style, isHovering && hoverStyle]}
      onPress={()=> {
        setHovering(false)
        onPress && onPress()
      }}
      
      disabled={disabled}
      onMouseEnter={()=> setHovering(true)}
      onMouseLeave={()=> setHovering(false)}
    >
      <Text selectable={false} style={[textStyle, isHovering && hoverTextStyle]}>
        {text}
      </Text>
    </Pressable>
  )
}

export default memo(Hoverable)