import { memo } from "react"
import { View, Text, StyleSheet } from "react-native"
import FontStyles from "../utils/FontStyles"
import Colors from "../utils/Colors"

function Question({ question, participantCount, index, type }){
  return (
    <View style={[{ marginTop: 50 }]}>
      <Text style={[FontStyles.body.medium, { color: Colors.dark.alpha1 }]}>
        {`${index + 1}. ${question.text}`}
      </Text>
      {
        participantCount === 0 &&
        <Text style={[FontStyles.footnote.medium, { color: Colors.red.alpha08, marginTop: 8 }]}>
          {'Henüz bu soru kimse tarafından cevaplanmamış.'}
        </Text>
      }
      {
        question.options.map((option, index)=> {
          return (
            <View key={option} style={[styles.optionContainer]}>
              <View style={styles.option}>
                <Text style={[FontStyles.subhead.regular, { color: Colors.dark.alpha1, marginRight: 12, flex: 1 }]}>
                  {option}
                </Text>
                <Text style={[FontStyles.footnote.medium, { color: question.correctOption === index ? Colors.greenLight.alpha1 : Colors.gray.alpha06 }]}>
                  <Text style={{color: question.correctOption === index ? Colors.greenLight.alpha08 : Colors.dark.alpha04, marginRight: 8}}>
                    {'(' + question.answerDistribution[index] + ')'}
                  </Text>
                  {(Math.round( 100 * question.answerDistribution[index] / participantCount) || 0 )+ '%'}
                </Text>
              </View>
              <View style={styles.percentageContainer}>
                <View style={[styles.percantage, {width: `${( (100 * question.answerDistribution[index] / participantCount) || 0).toFixed(0) }%`, backgroundColor: question.correctOption === index ? Colors.greenLight.alpha1 : type === 'CONTEST' ? Colors.purple.alpha08 : Colors.primaryBlue.alpha1 }]}/>
              </View> 
            </View>
          )
        })
      }
    </View>
  )
}

const styles = StyleSheet.create({
  percentageContainer: {
    width: '100%',
    borderRadius: 5,
    height: 8,
    backgroundColor: Colors.lightGray.alpha1,
    overflow: 'hidden',
    marginTop: 8
  },
  percantage: {
    height: '100%',
    borderRadius: 5,
  },
  optionContainer: {
    width: '100%',
    marginTop: 20
  },
  option: {
    flexDirection: 'row', 
    width: '100%', 
    alignItems: 'flex-end'
  }
})

export default memo(Question)
