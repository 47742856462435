import { StyleSheet, Text, View, TouchableOpacity, ActivityIndicator, ViewPropTypes } from 'react-native';
import FontStyles from '../utils/FontStyles';
import Colors from '../utils/Colors';
import {Feather} from '@expo/vector-icons'
import React from 'react'

const shadow = {
  shadowColor: Colors.primaryBlue.alpha1,
  shadowOffset: { width: 0, height: 5 },
  shadowOpacity: 0.5 ,
  shadowRadius: 15,
  elevation: 2
}

class Button extends React.PureComponent{

  render(){
    return (
      <TouchableOpacity 
        style={[styles.container, this.props.enableShadow && shadow, this.props.shadowStyle, this.props.style]} 
        onPress={this.props.onPress} 
        activeOpacity={this.props.activeOpacity}
        disabled={this.props.disabled}
      >
        {
          this.props.loading ?
          <ActivityIndicator color={this.props.loadingColor}/>
          :
          <>
            <Text style={[FontStyles.headline.medium, {color: 'white'}, this.props.textStyle]}>
              {this.props.text}
            </Text>
            {
              this.props.iconName !== undefined &&
              <View style={[styles.iconContainer, this.props.iconContainerStyle]}>
                <Feather 
                  name={this.props.iconName} 
                  size={this.props.iconSize} 
                  color={this.props.iconColor}
                />
              </View>
            }
          </>
        }
      </TouchableOpacity>
    )
  }
  
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    borderRadius: 20,
    height: 64,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: Colors.primaryBlue.alpha1,
    
  },
  iconContainer: {
    width: 32,
    height: 32,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 20,
    backgroundColor: 'rgba(64, 61, 82, 0.2)'
  }
})

  // Button.propTypes = {
  //   text: PropTypes.string.isRequired,
  //   enableShadow: PropTypes.bool,
  //   style: ViewPropTypes.style,
  //   activeOpacity: PropTypes.number,
  //   disabled: PropTypes.bool,
  //   onPress: PropTypes.func,
  //   loading: PropTypes.bool,
  //   loadingColor: PropTypes.string,
  //   iconContainerStyle: ViewPropTypes.style,
  //   textStyle: Text.propTypes.style,
  //   iconName: PropTypes.string,
  //   iconSize: PropTypes.number,
  //   iconColor: PropTypes.string,
  // }

  Button.defaultProps = {
    activeOpacity: 0.95,
    enableShadow: false,
    loadingColor: 'white',
    iconSize: 24,
    iconColor: 'white',
  }

  export default Button