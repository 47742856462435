import AnimatedText from "./AnimatedText";
import { StyleSheet, Text, View, Image, TextInput, TouchableOpacity } from "react-native";
import FontStyles from '../../../utils/FontStyles'
import Colors from '../../../utils/Colors'
import i18n from "i18n-js";
import Button from '../../../components/Button'
import { memo, useEffect, useState } from "react";
import Animated, { Extrapolate, interpolate, useAnimatedStyle, withTiming } from 'react-native-reanimated'
import API from '../../../utils/API'

const languages = [
  {
    icon: require('../../../assets/icons/turkish.png'),
    lang: 'tr'
  },
  {
    icon: require('../../../assets/icons/english.png'),
    lang: 'en'

  }
]

function LoginScreen({ opacity, height, screenNavigationValue, buttonWidth, onEmailVerified }){
  const [ language, setLanguage ] = useState(i18n.locale)
  const [ loading, setLoading ] = useState(false)
  const [ email, setEmail ] = useState('')
  const [ error, setError ] = useState(null)


  const warningOpacity = useAnimatedStyle(()=> {
    return {
      opacity: opacity.value
    }
  })

  const navigationTransform = useAnimatedStyle(()=> {
    const y = interpolate(
      screenNavigationValue.value,
      [1, 1.1],
      [0, height],
      Extrapolate.CLAMP
    )

    const op = interpolate(
      screenNavigationValue.value,
      [0,1],
      [1,0],
      Extrapolate.CLAMP
    )

    return {
      transform: [
        {
          translateY: y
        }
      ],
      opacity: op
    }
  })

  useEffect(()=> {
    if(error){
      opacity.value = withTiming(1, { duration: 350 })
    }
  },[error])

  const login = () => {
    setLoading(true)
    API.requestLoginCode(email).then((res)=> {
      onEmailVerified(email, res.id)
      opacity.value = withTiming(0, {duration: 250})
      screenNavigationValue.value = withTiming(1.1, {duration: 350})
      setLoading(false)
    }).catch((e)=> {
      console.log(e)
      setLoading(false)
      setError(e)
    })
  }

  return (
    <Animated.View 
      style={[
        styles.inputContainer, 
        navigationTransform, 
        { 
          minWidth: buttonWidth + (height * 0.287 / 2) + 25, 
          paddingLeft: height * 0.287 / 2,
        }
      ]}
    >
      <View style={{flex: 1}}/>
      <AnimatedText
        opacity={opacity}
        language={language}
        title={i18n.t('login')}
        subText={i18n.t('loginSubText')}
        errorTitle={error?.errorTitle || i18n.t('wrongEmail')}
        errorSubText={error?.errorMessage || i18n.t('wrongEmailSubText')}
      />
      <TextInput
        style={[FontStyles.body.regular, styles.input, { width: buttonWidth }]}
        placeholder={i18n.t('email')}
        onChangeText={(text)=> setEmail(text)}
        onSubmitEditing={login}
      />
      <Button
        style={[styles.button, {width: buttonWidth}]}
        enableShadow
        text={i18n.t('send')}
        onPress={login}
        loading={loading}
      />
      <View style={[styles.footerContainer, { minHeight: 150 }]}>
        <Animated.View style={[styles.contactContainer, warningOpacity]}>
          <Text style={[FontStyles.callout.regular, { color: Colors.dark.alpha08 }]}>
            {i18n.t('loginProblem')}
          </Text>
          <TouchableOpacity>
            <Text style={[FontStyles.callout.regular, { color: Colors.secondaryBlue.alpha08, marginTop: 8 }]}>
              {i18n.t('contactUs')}
            </Text>
          </TouchableOpacity>
        </Animated.View>
        <View style={styles.languageContainer}>
          {
            languages.map((item,index)=> {
              return (
                <TouchableOpacity key={item.lang} activeOpacity={0.95} onPress={()=> {
                    i18n.locale = item.lang
                    setLanguage(item.lang)
                  }}
                >
                  <Image style={{ width: 50, height: 50 }} source={item.icon}/>
                </TouchableOpacity>
              )
            })
          }
        </View>
      </View>
      
    </Animated.View>
  )
}

const styles = StyleSheet.create({
  inputContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white'
  },
  input: {
    backgroundColor: Colors.lightGray.alpha1,
    color: Colors.gray.alpha08,
    borderRadius: 20,
    height: 64,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  languageContainer: {
    width: 120,
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: '10%'
  },
  button: {
    marginTop: 20, 
  },
  image: {
    width: '100%',
    height : '100%'
  },
  contactContainer: {
    width: '100%',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center'
  },
  footerContainer: {
    flex: 1, 
    alignItems: 'center', 
    justifyContent: 'flex-end',
    
  }
})

export default memo(LoginScreen)