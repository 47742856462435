import React from "react";
import { StyleSheet, View, Animated, Text } from "react-native";
import Colors from "../../utils/Colors";
import API from '../../utils/API'
import MonthlyContent from "../../components/MonthlyContent";
import ContestDetails from "../../components/ContestDetails";
import SurveyDetails from "../../components/SurveyDetails";
import MediaContentDetails from '../../components/MediaContentDetails'
import moment from "moment";
import WebAlert from "../../components/WebAlert";
import FontStyles from "../../utils/FontStyles";
import I18n from "i18n-js";


const bg = '#F3FAFF'

const paddingHorizontal = 24


function monthDiff(d1, d2) {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth() + 1;
  return months <= 0 ? 0 : months;
}

export default class SmartTimeline extends React.PureComponent{
  constructor(props){
    super(props)
    this.scrollAnimValue = new Animated.Value(0)

    this.state = {
      contents: [],
      containerWidth: null,
      unplannedEducationPackages: [],
      addThemeSelectedIndex: null
    }
  }

  componentDidMount(){
    this.getTimelineData((scrollIndex)=> {
      this.scrollRef.scrollToIndex({ index: scrollIndex, viewPosition: 0.5 })
    })
  }

  getTimelineData = async (callback) => {
    const contents = await API.getTimeline()
    let arr = [ 
      ...contents.contests.map(item => { return { ...item, type: 'CONTEST' }}), 
      ...contents.surveys.map(item => { return { ...item, type: 'SURVEY' }}), 
      ...contents.plannedEducationPackages.map(item => { return { ...item, type: 'EDUCATION-PACKAGE' }}), 
      ...contents.plannedCompanyOwnedMediaContents.map(item=> { return { ...item, startsAt: new Date(`${item.plannedMonth}-01`), isDeletable: new Date(`${item.plannedMonth}-01`).getTime() > new Date().getTime() ? true : false }} )
    ]

    const currentMonthObj = new Date(contents.currentTimePrimitivesForCompany.month);
    const startingMonthObj = new Date(contents.companyMembership.startingMonth);

    const finalArr = await this.generateTimelineData(arr, contents.companyMembership)

    const unplannedEducationPackages = await this.generateEducationPackages(contents.unplannedEducationPackages)
    
    this.setState({ contents: finalArr, unplannedEducationPackages: unplannedEducationPackages, membership: contents.companyMembership},()=> {
      if(callback){
        callback(Math.abs((currentMonthObj.getFullYear() * 12 + currentMonthObj.getMonth()) - (startingMonthObj.getFullYear() * 12 + startingMonthObj.getMonth())))
      }
    })
  }

  generateTimelineData = async (arr, membership) => {
    let diff = monthDiff(new Date(membership.startingMonth), new Date(membership.endingMonth))
    let finalArr = new Array(diff).fill(null) 

    for(let i = 0; i < arr.length; i++){
      let currentEvent = arr[i]
      const monthIndex = moment(new Date(currentEvent.startsAt || currentEvent.plannedMonth)).diff(moment(new Date(membership.startingMonth)), 'months', true)

      if(currentEvent.type === 'EDUCATION-PACKAGE'){
        let p = await API.getEducationPackageDetails(currentEvent.id)
        
        let contents = [
          ...p.miniTests.map(item => { const startDate = new Date(`${currentEvent.plannedMonth}-${String(item.weekOrder*7+1).padStart(2, '0')}`).getTime(); return { ...item, type: 'MINI-TEST', startsAt: startDate, endsAt: startDate + (7 * 24 * 60 * 60 * 1000)} }),
          ...p.mediaContents.map(item => { return { ...item, startsAt: new Date(`${currentEvent.plannedMonth}-01`)} })
        ]

        if(finalArr[monthIndex]){
          finalArr[monthIndex].contents = [...finalArr[monthIndex].contents, ...contents]
          if(finalArr[monthIndex].packages){
            finalArr[monthIndex].packages.push(currentEvent)
          }else{
            finalArr[monthIndex]['packages']= [currentEvent]
          }
        }else{
          finalArr[monthIndex] = { contents: contents, packages: [currentEvent] }
        }
        
      }else{
        if(finalArr[monthIndex]){
          finalArr[monthIndex].contents = [...finalArr[monthIndex].contents, currentEvent]
        }else{
          finalArr[monthIndex] = {contents: [currentEvent]}
        }
      }
    }


    for(let j = 0; j < finalArr.length; j++){
      if(finalArr[j]){
        finalArr[j]['date'] = moment(new Date(`${membership.startingMonth}-01`)).add(j, 'M')
        finalArr[j].contents.sort((a,b) => a.startsAt - b.startsAt)
      }else{
        finalArr[j] = {date: moment(new Date(`${membership.startingMonth}-01`)).add(j, 'M')}
      }
    }

    let end = monthDiff(new Date(membership.startingMonth), new Date())

    return finalArr.slice(0, end + 1)
  }

  onPressRow = (item) => {
    if(item.type === 'VIDEO' || item.type === 'PODCAST'){
      this.mediaContentDetailsRef.setIsShowing(true, item.id, item.startsAt, item.eligibleUserCount)
    }else if(item.type === 'CONTEST'){
      this.contestDetailsRef.setIsShowing(true, item.id)
    }else if(item.type === 'SURVEY'){
      this.surveyDetailsRef.setIsShowing(true, item.id)
    }else if(item.type === 'MINI-TEST'){
      this.contestDetailsRef.setIsShowing(true, item.id, item)
    }
  }

  handleAvailablePackagePress = (index) => {
    this.setState({ addThemeSelectedIndex: index })
  }

  handleAlert = (data) => {
    this.alert.setIsShowing(data)
  }

  renderItem = ({ item, index }) => {
    return (
      <View style={{width: (this.state.containerWidth - paddingHorizontal  * 2) / 3, minWidth: 250 }}>
        <MonthlyContent
          data={item}
          onPressRow={this.onPressRow}
          availablePackages={this.state.unplannedEducationPackages}
          index={index}
          getTimelineData={this.getTimelineData}
          showAvailablePackages={this.state.addThemeSelectedIndex === index}
          handleAvailablePackagePress={this.handleAvailablePackagePress}
          startingMonth={this.state.membership.startingMonth}
          handleAlert={this.handleAlert}
          navigation={this.props.navigation}
          removeEducationPackage={this.removeEducationPackage}
          addEducationPackage={this.addEducationPackage}
          removeMediaContent={this.removeMediaContent}
          addMediaContent={this.addMediaContent}
        />
      </View>
    )
  }

  onLayout = ({ nativeEvent }) => {
    this.setState({ containerWidth: nativeEvent.layout.width })
  }

  generateEducationPackages = async (data) => {
    let arr = []
    for(let i = 0; i < data.length; i++){
      let currentEvent = data[i]
      const contents = await API.getEducationPackageDetails(currentEvent.id)
      let c = [
        ...contents.miniTests.map(item => { return { ...item, type: 'MINI-TEST'}}),
        ...contents.mediaContents
      ]

      let obj = {
        ...data[i],
        contents: c
      }

      arr.push(obj)
    }

    
    return arr
  }

  listEmpty = () => {
    return (
      <Text style={[FontStyles.body.medium, { color: Colors.dark.alpha1, marginTop: 16, textAlign: 'center' }]}>
        {I18n.t('loading')}...
      </Text>
    )
  }

  removeEducationPackage = (monthIndex, index) => {
    let contents = [...this.state.contents]
    let contentObj = {
      removed: [],
      stayed: []
    }

    //remove from packages
    let removedPackage = contents[monthIndex].packages.splice(index, 1)[0]
    removedPackage.plannedMonth = null

    //determine removed and stayed contents
    for(let i = 0; i < contents[monthIndex].contents.length; i++){
      let content = contents[monthIndex].contents[i]
      if(content.educationPackageId !== removedPackage.id){
        contentObj.stayed.push(content)
      }else{
        contentObj.removed.push(content)
      }
    }

    //add removed contents to package object
    removedPackage['contents'] = contentObj.removed
    //set stayed contents to contents object
    contents[monthIndex].contents = contentObj.stayed

    //add removed package to unplanned education packages
    let availablePackages = [...this.state.unplannedEducationPackages, removedPackage]

    this.setState({
      contents: contents,
      unplannedEducationPackages: availablePackages
    },()=> {
      this.handleAlert({ 
        isShowing: true, 
        type: 'success', 
        text: `${removedPackage.name} isimli eğitim paketi kaldırıldı.`
      })
    })

  }
  
  addEducationPackage = async (packageIndexArr, monthIndex) => {
    //packageIndexArr -> contains index of added packages. 

    let packages = [...this.state.unplannedEducationPackages]
    let contents = [...this.state.contents]

    const date = this.state.contents[monthIndex].date.format('YYYY-MM')

    try{
      
      for(let i = 0; i < packageIndexArr.length; i++){
        let index = packageIndexArr[i]
        await API.putEducationPackageToMonth( packages[index].id, date )
        //remove package from unplanned packages
        let addedPackage = packages.splice(index, 1)[0]
        //change plannedMonth of new added package from null to related date
        addedPackage.plannedMonth = date
        // add new package (above removed package) to the packages array and also related contents to contents array
        if(contents[monthIndex]['packages']){ 
          contents[monthIndex]['packages'] = [...contents[monthIndex]['packages'], addedPackage]
          contents[monthIndex]['contents'] = [...contents[monthIndex]['contents'], ...addedPackage.contents.map(item => { 
            if(item.type === 'MINI-TEST'){
              const startDate = new Date(`${date}-${String(item.weekOrder*7+1).padStart(2, '0')}`).getTime() 
              return {...item, startsAt:  startDate, endsAt: startDate + (7 * 24 * 60 * 60 * 1000)}
            }else{
              return {...item, startsAt: new Date(`${date}-01`).getTime() }
            }
          })
        ]
        }else{
          contents[monthIndex]['packages'] = [addedPackage]
          if(contents[monthIndex]['contents']){
            contents[monthIndex]['contents'] = [...contents[monthIndex]['contents'], ...addedPackage.contents.map(item => { 
              if(item.type === 'MINI-TEST'){
                const startDate = new Date(`${date}-${String(item.weekOrder*7+1).padStart(2, '0')}`).getTime() 
                return {...item, startsAt:  startDate, endsAt: startDate + (7 * 24 * 60 * 60 * 1000)}
              }else{
                return {...item, startsAt: new Date(`${date}-01`).getTime() }
              }
            })]
          }else{
            contents[monthIndex]['contents'] = [...addedPackage.contents.map(item => { 
              if(item.type === 'MINI-TEST'){
                const startDate = new Date(`${date}-${String(item.weekOrder*7+1).padStart(2, '0')}`).getTime() 
                return {...item, startsAt:  startDate, endsAt: startDate + (7 * 24 * 60 * 60 * 1000)}
              }else{
                return {...item, startsAt: new Date(`${date}-01`).getTime() }
              }
            })]
          }
        }
      }

      this.setState({ contents: contents, unplannedEducationPackages: packages, addThemeSelectedIndex: null })

    }catch(e){
      this.handleAlert({
        isShowing: true,
        type: 'warning',
        text: e.errorMessage
      })
    }
  }

  addMediaContent = (mediaContent, monthIndex) => {
    let contents = this.state.contents
    let currentMonthObject = { ...contents[monthIndex] }
    if(currentMonthObject['contents']){
      currentMonthObject['contents'] = [mediaContent, ...currentMonthObject['contents']]
    }else{
      currentMonthObject['contents'] = [mediaContent]
    }

    contents[monthIndex] = currentMonthObject
    this.setState({
      contents: contents
    })
  }

  removeMediaContent = async (item, index, monthIndex) => {
    let contents = [...this.state.contents]
    try{
      await API.removeMediaContent(item.id)
      let arr = [...contents[monthIndex].contents]
      arr.splice(index, 1)

      let obj = {
        date: contents[monthIndex].date,
        contents: arr,
        packages: contents[monthIndex].packages
      }

      contents[monthIndex] = obj

      this.setState({
        contents: contents
      })

      this.handleAlert({
        text: 'Medya içeriği başarılı bir şekilde kaldırıldı.',
        type: 'success',
        isShowing: true
      })

    }catch(e){
      this.handleAlert({
        text: e.errorMessage,
        type: 'warning',
        isShowing: true
      })
    }
  }

  render(){
    const scrollIndicatorAnimation = this.state.containerWidth && this.state.contents.length && (((this.state.containerWidth / 3) * this.state.contents.length) - this.state.containerWidth > 0) ? {
      width: this.scrollAnimValue.interpolate({
        inputRange: [ 0, ((this.state.containerWidth / 3) * this.state.contents.length) - this.state.containerWidth ],
        outputRange: [ 0, this.state.containerWidth + 44 ],
        extrapolate: 'clamp'
      })
    }
    : null

    return (
      <View 
        style={styles.container} 
        onLayout={this.onLayout}
      >
        <Animated.FlatList
          data={this.state.contents}
          showsHorizontalScrollIndicator={false}
          ref={ref => this.scrollRef = ref}
          contentContainerStyle={{paddingHorizontal: paddingHorizontal, paddingVertical: 17.5}}
          onScroll={Animated.event([{ nativeEvent: { contentOffset: { x: this.scrollAnimValue } }}])}
          renderItem={this.renderItem}
          ListEmptyComponent={this.listEmpty}
          keyExtractor={(_,index)=> index + 'sxy'}
          windowSize={11}
          horizontal
          getItemLayout={(_, index) => (
            {length: (this.state.containerWidth - paddingHorizontal  * 2) / 3, offset: (this.state.containerWidth - paddingHorizontal  * 2) / 3 * index, index}
          )}
        />
        <View style={styles.scrollIndicatorContainer}>
          <Animated.View 
            style={[ styles.scrollIndicator, scrollIndicatorAnimation ]}
          />
        </View>
        <ContestDetails
          ref={ref => this.contestDetailsRef = ref}
        />
        <SurveyDetails
          ref={ref => this.surveyDetailsRef = ref}
        />
        <MediaContentDetails
          ref={ref => this.mediaContentDetailsRef = ref}
        />
        <WebAlert ref={ref => this.alert = ref}/>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: bg,
    borderRadius: 15,
    width: '100%',
    overflow: 'hidden'
  },
  scrollIndicator: {
    height: '100%', 
    borderRadius: 15, 
    backgroundColor: Colors.secondaryBlue.alpha1
  },
  scrollIndicatorContainer: {
    position: 'absolute', 
    bottom: 0, 
    left: 4, 
    height: 4
  }

})