import { View, Animated, StyleSheet, Text, Image, Pressable, Linking, ScrollView, Easing } from "react-native"
import { BlurView } from "expo-blur"
import React from "react"
import FontStyles from "../utils/FontStyles"
import Colors from "../utils/Colors"
import I18n from "i18n-js"
import moment from "moment"
import { Feather } from '@expo/vector-icons'
import Button from "./Button"
import API from "../utils/API"
import Progress from "./Progress"
import AnimatedText from "./AnimatedText"

// this.inputs = {
//   title: null, -> string
//   text: null, -> string
//   tagIds: null, -> array
//   link: null, -> 
//   date: null,
//   image, 
//   video
//   
// }

export default class AnnouncementPreview extends React.PureComponent{
  constructor(props){
    super(props)
    this.opacityValue = new Animated.Value(0)
    this.progressValue = new Animated.Value(0)
    this.pointOpacityValue = new Animated.Value(0)

    this.state={
      isShowing: false,
      data: null,
      status: null,
      buttonShowing: true,

    }
  }

  componentDidMount(){
    this.animatePoints()
  }

  sendAnnouncement = () => {
    const formData = this.generateFormData()
    for (let pair of formData.entries()) {
      console.log(pair[0]+ ', ' + pair[1]); 
    }
    
    this.setState({status: 'IN-PROGRESS'},()=> {
      this.animatePoints()

      API.createAnnouncement(formData).then(({announcement})=> {

        if(announcement.jobId){
          this.getUploadProgress(announcement)
        }else{
          API.setAnnouncementDate(announcement.id, this.state.data.publishedAt).then(()=> {
            this.props.handleAlert && this.props.handleAlert({
              text: I18n.t('announcementSuccess'),
              type: 'success',
              isShowing: true
            })

            this.props.addAnnouncement({...announcement, publishedAt: this.state.data.publishedAt})
            this.setState({status: 'COMPLETED'})
          }).catch((e)=> {
            this.props.handleAlert && this.props.handleAlert({
              text: e.errorMessage,
              type: 'warning',
              isShowing: true
            })
            this.setState({status: null})
          })
        }
      }).catch((e)=> {
        this.props.handleAlert && this.props.handleAlert({
          text: e.errorMessage,
          type: 'warning',
          isShowing: true
        })
        this.setState({status: null})
        console.log(e)
      })
    })
  }

  generateFormData = () => {
    const formData = new FormData()
    let tagIds = this.state.data.tags.map(item => item.id)
    let inputs = {...this.state.data}
    inputs['tagIds'] = tagIds

    for(const prop in inputs){
      let currentValue = inputs[prop]

      if(!(!currentValue || (typeof currentValue == 'string' && currentValue.length === 0) || (Array.isArray(currentValue) && currentValue.length === 0))){
        switch(prop){
          case 'title': 
            formData.append('title', currentValue)
            break
          case 'text':
            formData.append('text', currentValue)
            break
          case 'tagIds':
            formData.append('tagIds', JSON.stringify(currentValue))
            break
          case 'link':
            formData.append('link', (currentValue.indexOf('http://') < 0 && currentValue.indexOf('https://') < 0) ? ('http://' + currentValue) : currentValue)
            break
          case 'video': 
            formData.append('video', currentValue.file)
            break
          case 'image': 
            formData.append('photo', currentValue.base64)
            break
        }
      }
    }

    return formData
  }

  getUploadProgress = (announcement) => {
    API.getAnnouncement(announcement.id).then((res)=> {
      if(res.status == 'IN-PROGRESS'){
        Animated.timing(this.progressValue, {
          toValue: res.completionPercentage ? (res.completionPercentage / 100) : 0.1,
          duration: 1000,
          useNativeDriver: true
        }).start()
        setTimeout(() => {
          this.getUploadProgress(announcement.id)
        }, 10000)
      }else if(res.status == 'COMPLETED'){
        API.setAnnouncementDate(announcement.id, this.state.data.publishedAt).then(()=> {
          this.props.addAnnouncement({...announcement, publishedAt: this.state.data.publishedAt})

          Animated.timing(this.progressValue, {
            toValue: 1,
            duration: 1000,
            useNativeDriver: true,
            easing: Easing.bezier(0.1, 0.3, 0.1, 0.8)
          }).start(()=> {
            this.setState({ status: res.status })
          })
        })
      }

    }).catch((e)=> {
      console.log(e)
    })

    //get announcement detail from backend and update progress value
    //should be called recursively
    //call every 10 second or later
    //after upload has finished call setAnnouncementTime with date from this.state.data
    //at the end show an UI to show success or fail
    //clear all inputs at the end and close preview
  } 

  formatText = (value) => {
    return `${((value / 1) * 100).toFixed(0)} %`
  }

  setIsShowing = (isShowing, data) => {
    console.log(data);
    if(isShowing){
      this.setState({ isShowing: isShowing, data: data }, ()=> {
        Animated.timing(this.opacityValue, {
          toValue: 1,
          duration: 300,
          useNativeDriver: false
        }).start()
      })

    }else{
      Animated.timing(this.opacityValue, {
        toValue: 0,
        duration: 300,
        useNativeDriver: false
      }).start(()=> {
        this.setState({ isShowing: isShowing, data: null, status: null })
      })
    }
  }

  animatePoints = () => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(this.pointOpacityValue, {
          toValue: 1,
          duration: 300,
          useNativeDriver: true,
          delay: 500
        }),
        Animated.timing(this.pointOpacityValue, {
          toValue: 2,
          duration: 300,
          useNativeDriver: true,
          delay: 500

        }),
        Animated.timing(this.pointOpacityValue, {
          toValue: 3,
          duration: 300,
          useNativeDriver: true,
          delay: 500

        }),
      ])
    ).start()
  }

  modalInner = () => {
    if(this.state.status == 'IN-PROGRESS'){
      return (
        <View style={styles.loadingContainer}>
          <Text style={[FontStyles.body.medium, { color: Colors.dark.alpha1, lineHeight: 28, textAlign: 'center' }]}>
            {I18n.t('announcementSending')}
          </Text>
          <View style={{flexDirection: 'row', marginTop: 8 }}>
            <Text style={[FontStyles.body.medium, { color: Colors.dark.alpha1}]}>
              {I18n.t('processing')}
            </Text>
            {
              new Array(3).fill(0).map((_, index)=> {
                const opacity = this.pointOpacityValue.interpolate({
                  inputRange: [index, index + 1],
                  outputRange: [0, 1],
                  extrapolate: 'clamp'
                })

                return (
                  <Animated.Text 
                    key={'p' + index} 
                    style={[FontStyles.body.medium, { color: Colors.dark.alpha1, opacity: opacity, marginLeft: index === 0 ? 2 : 0 }]}>
                    .
                  </Animated.Text>
                )
              })
            }
          </View>

          <View style={{ width: '40%', marginTop: 8 }}>
            <AnimatedText
              animValue={this.progressValue}
              containerStyle={[FontStyles.body.medium, { color: Colors.dark.alpha08, textAlign: 'right' }]}
              textColor={Colors.primaryBlue.alpha1}
              formatter={this.formatText}
            />
            <Progress
              style={{ width: '100%', marginTop: 4 }}
              value={this.progressValue}
            />
          </View>
        </View>
      )
    }else if(this.state.status == 'COMPLETED'){
      return (
        <View style={styles.loadingContainer}>
          <Image 
            style={{width: 80, height: 80}} 
            source={require('../assets/icons/check-icon.png')}
            resizeMode='contain'
          />
          <Text style={[FontStyles.title1.semibold, { color: Colors.dark.alpha1, marginTop: 36}]}>
            {I18n.t('announcementSendSuccess')}
          </Text>
          <Text style={[FontStyles.title3.regular, { color: Colors.dark.alpha08, marginTop: 24, lineHeight: 28}]}>
            {I18n.t('announcementSendSuccessBody')}
          </Text>
        </View>
      )
    }

    return (
      <View style={{ height: '85%', width: '65%' }}>
        <Text style={[FontStyles.title3.medium, { color: Colors.dark.alpha1, marginBottom: 12 }]}>
          {I18n.t('announcementPreview')}
        </Text>
        <ScrollView 
          style={styles.innerContainer}
          contentContainerStyle={{ paddingHorizontal: 24, paddingTop: 16, paddingBottom: 24 }}
          showsVerticalScrollIndicator={false}
        >
          {
            
            this.state.data.photo &&
            <Image
              style={styles.image}
              resizeMode='cover'
              source={{ uri: this.props.mediaBasePath + this.state.data.photo }}
            />
          }
          
          <View style={styles.titleContainer}>
            <Text style={[FontStyles.title3.semibold, { color: Colors.dark.alpha1 }]}>
              {this.state.data.title}
            </Text>
            <Text style={[FontStyles.subhead.regular, { color: Colors.dark.alpha06 }]}>
              {moment(this.state.data.publishedAt).utcOffset(API.timezoneOffset).format('DD MMM YYYY')}
            </Text>
          </View>
          <Text style={[FontStyles.callout.regular, { color: Colors.dark.alpha1, marginTop: 12, lineHeight: 28 }]}>
            {this.state.data.text}
          </Text>
          <View style={styles.tagContainer}>
            {
              this.state.data.tags &&
              this.state.data.tags.map((tag, index)=> {
                return (
                  <View key={tag.id} style={[styles.tag, { backgroundColor: tag.colorHex, marginTop: 16, marginRight: index !==  this.state.data.tags.length - 1 ? 12 : 0}]}>
                    <Text style={[FontStyles.footnote.medium, { color: 'white' }]}>
                      {tag.name}
                    </Text>
                  </View>
                )
              })
            }
          </View>
          {
            (this.state.data.link || this.state.data.video || this.state.data.photo) &&
            <View style={{ width: '100%', marginTop: 16 }}>
              <Text style={[FontStyles.title3.medium, { color: Colors.dark.alpha1}]}>
                {'Eklentiler'}
              </Text>
              {
                this.state.data.photo &&
                <View
                  style={styles.attachmentContainer}
                >
                  <Feather
                    name= 'image'
                    size={24}
                    color={Colors.secondaryBlue.alpha1}
                    style={{marginLeft: -2}}
                  />
                  <Text style={[ FontStyles.subhead.regular, { color: Colors.dark.alpha08, marginLeft: 8 } ]}>
                    {this.state.data.photo}
                  </Text>
                </View>
              }
              {
                this.state.data.video &&
                <View
                  style={styles.attachmentContainer}
                >
                  <Feather
                    name= 'video'
                    size={24}
                    color={Colors.secondaryBlue.alpha1}
                  />
                  <Text style={[ FontStyles.subhead.regular, { color: Colors.dark.alpha08, marginLeft: 8 } ]}>
                    {this.state.data.video?.file?.name || this.state.data.video}
                  </Text>
                </View>
              }
              {
                this.state.data.link &&
                <Pressable
                  style={styles.attachmentContainer}
                  onPress={()=> {
                  let url = ''
                  if(this.state.data.link.indexOf("http://") < 0 && this.state.data.link.indexOf("https://") < 0){
                    url = "http://" + this.state.data.link;
                  }
                  Linking.openURL(url)
                }}>
                  <Feather
                    name= 'link'
                    size={24}
                    color={Colors.secondaryBlue.alpha1}
                  />
                  <Text style={[ FontStyles.subhead.regular, { color: Colors.primaryBlue.alpha1, marginLeft: 8, textDecorationLine: 'underline', textDecorationColor: Colors.primaryBlue.alpha1 } ]}>
                    {this.state.data.link}
                  </Text>
                </Pressable>
              }
            </View>
          }
          {
            !this.state.data.id &&
            <Button
              text={I18n.t('send')}
              style={styles.submitButton}
              onPress={this.sendAnnouncement}
            />
          }              

        </ScrollView>
      </View>
      
    )
  }

  render(){

    if(!this.state.isShowing){
      return null
    }

    return (
      <Animated.View style={[styles.container, { opacity: this.opacityValue }]}>
        <BlurView 
          style={styles.blur} 
          intensity={100} 
          tint={'light'}
        >
          {this.modalInner()}
          <Pressable style={styles.closeButton} onPress={() => this.setIsShowing(false)}>
            <Feather name={"x"} color={Colors.white.alpha1} size={28}/>
          </Pressable>

        </BlurView>
      </Animated.View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
  },
  blur: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  row: {
    width: '100%',
    marginTop: 24
  },
  innerContainer: {
    width: '100%',
    flex: 1,
    backgroundColor: Colors.white.alpha1,
    borderRadius: 15,
  },
  tagContainer: {
    width: '100%', 
    flexDirection: 'row', 
    flexWrap: 'wrap'
  },
  tag: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderRadius: 5,
    marginTop: 16,
    alignItems: 'center',
    alignSelf: 'flex-start',
    minWidth: 50,    
  },
  closeButton: {
    backgroundColor: Colors.primaryBlue.alpha1,
    borderRadius: 222,
    height: 48,
    width: 48,
    position: "absolute",
    right: "3.5%",
    top: "4%",
    alignSelf: "flex-end",
    justifyContent: "center",
    alignItems: "center",
  },
  attachmentContainer: {
    flexDirection: 'row', 
    marginTop: 16, 
    alignItems: 'center'
  },
  submitButton: {
    marginTop: 32, 
    width: '50%', 
    alignSelf: 'center' 
  },
  titleContainer: {
    flexDirection: 'row', 
    justifyContent: 'space-between', 
    marginTop: 24
  },
  image: {
    width: 300, 
    height: 200, 
    borderRadius: 10, 
    alignSelf: 'center' 
  },
  loadingContainer: {
    width: '100%',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }
})