import { StyleSheet, Pressable, Image, Text, View } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'
import { FontAwesome5, Feather } from '@expo/vector-icons'
import API from '../utils/API.js';
import Colors from '../utils/Colors'
import FontStyles from '../utils/FontStyles'
import moment from 'moment'
import i18n from 'i18n-js'
import { memo } from 'react'

function RowItemAnnouncement (props){
  const { item, index } = props
  return(
    <View style={styles.container}>
      <Pressable 
        activeOpacity={0.95} 
        style={styles.rowContainer} 
        onPress={props.onPress}
        >
        <View style={[styles.image, { overflow: 'hidden' }]}>
          {
            item.video ?
              <LinearGradient
                colors={[ Colors.secondaryBlue.alpha08, Colors.primaryBlue.alpha08, Colors.primaryBlue.alpha09 ]} 
                style={styles.gradient} 
                start={{ x: 0 , y: 0 }}
                end={{ x: 1 , y: 1 }}
              >
                <View style={styles.playIconContainer}>
                  <FontAwesome5 
                    name={'play'} 
                    size={20} 
                    color={Colors.white.alpha1} 
                    style={{paddingLeft: 1}}
                  />
                </View>
                
              </LinearGradient>
            :
            <Image 
              source={require('../assets/images/announcement.png')} 
              style={{ width: '100%', height: '100%' }} 
              resizeMode='cover'
            />
          }
        </View>
        
        <View style={{ flex: 1, marginHorizontal: 12 }}>
          <Text numberOfLines={3} style={[FontStyles.subhead.medium, { color: Colors.dark.alpha1, lineHeight: 21}]}>
            {item.title}
          </Text>
          <Text style={[FontStyles.footnote.regular, { color: Colors.dark.alpha08, marginTop: 4 }]}>
            {i18n.t('announcement') + ' • ' + moment(item.publishedAt).utcOffset(API.timezoneOffset).format('DD MMM   HH:mm')}
          </Text>
        </View>
        <Feather name='chevron-right' size={20} color={Colors.gray.alpha06}/>
      </Pressable>
      <Pressable style={styles.removeButton} onPress={()=>props.onRemovePressed(item, index)}>
        <Feather name='trash-2' size={18} color={'white'}/>
      </Pressable>
    </View>
    
  )
}

export default memo(RowItemAnnouncement)

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row', 
    width: '100%',
    alignItems: 'center',
    marginTop: 16
  },
  rowContainer: {
    flex: 1,
    paddingHorizontal: 12,
    paddingVertical: 12,
    flexDirection: 'row',
    alignSelf: 'center',
    backgroundColor: 'white',
    borderRadius: 17.5,
    shadowColor: '#C4C4C4',
    shadowOffset: { width: 0, height: 4 },
    shadowRadius: 5,
    shadowOpacity: 0.15,
    alignItems: 'center',
    elevation: 7,
  },
  playIconContainer: {
    width: "70%", 
    height: "70%", 
    borderRadius: 200, 
    backgroundColor :Colors.white.alpha01, 
    justifyContent: 'center', 
    alignItems: 'center'
  },
  image: {
    width: 68, 
    height: 68, 
    borderRadius: 15
  },
  gradient: {
    width: '100%', 
    height: '100%', 
    position: 'absolute', 
    justifyContent: 'center', 
    alignItems: 'center' 
  },
  removeButton: {
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    backgroundColor: Colors.red.alpha1,
    marginLeft: 12
  }
})